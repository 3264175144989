import React, { Component } from "react"
import PersonItem from "../PersonItem"
import AsyncSelect from "react-select/lib/Async";
import { request } from "../../../api-client"
import localization from '../../../config/localization'
import PersonPhotosDialog from "../PersonPhotosDialog"
import Select from 'react-select'
import Paginator from '../../../components/Letflow/Paginator/Paginator'


class GridPeople extends Component {
    constructor(props) {  
      super(props)
      this.state = {
        people: [],
        client: null,
        loading: true,
        selectedPerson: null,
        searchPerson: null,
        identificationFilter: { value: 'all', label: localization.get('people.filter_by_identification.all') },
        currentPage: 1,
        totalPages: 0,
        itemsPerPage: 24
      }
    } 
  
    componentDidMount = () => {
      let selectedClientFromLocalStorage = JSON.parse(localStorage.getItem("selectedclient"));
  
      if (selectedClientFromLocalStorage) {
        this.setState({client: selectedClientFromLocalStorage}, this.requestPeople);
      }
    }

    requestPeople = ({page = 0} = {}) => {
      const { identificationFilter } = this.state
      const options = {
        page: page,
        perPage: this.state.itemsPerPage
      }
      
      if (identificationFilter.value !== 'all') {
        if (identificationFilter.value === 'blocked') {
          options.filterBy = [{column: "blocked", filter: 1}]
        } else {
          options.filterBy = [{column: "use_name", filter: identificationFilter.value === 'identified' ? 1 : 0}]
        }
      }

      return request.people.getAllForClient(this.state.client.value, 'mainImage', options)
        .then(response => {
          this.setState({ 
            people: response.data,
            loading: false,
            totalPages: response.meta.last_page,
            currentPage: response.meta.current_page
          })
        })
    }

    setSelectedPerson = (person) => {
      this.setState({ selectedPerson: person })
    }

    getFilteredPeople = () => {
      const { people, searchPerson, identificationFilter } = this.state
      let filtered = people

      if (searchPerson) {
        filtered = filtered.filter(person => person.id === searchPerson.value)
      }

      if (identificationFilter.value !== 'all' && identificationFilter.value !== 'blocked') {
        filtered = filtered.filter(person => 
          identificationFilter.value === 'identified' ? person.name : !person.name
        )
      }

      return filtered
    }

    handlePageChange = (page) => {
      this.setState({ loading: true });
      const filters = this.state.identificationFilter.value === 'blocked' 
        ? [{column: "blocked", filter: 1}] 
        : [];

      this.requestPeople({ filters, page: page });
    }
  
    render() {
      const { client, people, loading, selectedPerson, searchPerson, identificationFilter, currentPage, totalPages } = this.state
      const identificationOptions = [
        { value: 'all', label: localization.get('people.filter_by_identification.all') },
        { value: 'identified', label: localization.get('people.filter_by_identification.identified') },
        { value: 'unidentified', label: localization.get('people.filter_by_identification.unidentified') },
        { value: 'blocked', label: localization.get('people.filter_by_identification.blocked') }
      ]

        return (
          <div style={{display: 'flex', flexDirection: 'column', gap: '16px', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{width: "100%", maxWidth: 300, display: "inline-block", textAlign: "left"}}>
              <span>{localization.get('client')}</span>
              <AsyncSelect
                styles={{
                  menu: base => ({
                    ...base,
                    zIndex: 10
                  })
                }}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0
                })}
                placeholder={localization.get('client')}
                loadingMessage={() => localization.get('loading')}
                noOptionsMessage={() => localization.get('no_options')}
                onChange={client => {
                  localStorage.setItem("selectedclient", `${JSON.stringify(client)}`);
                  return this.setState({client: null}, () => this.setState({client},
                    () => this.requestPeople()))
                }}
                value={client}
                defaultOptions
                loadOptions={inputValue => {
                  let filters = [{column: "name", filter: inputValue}]
                  return request.client.getAll({filterBy: filters}, '')
                    .then(res => res.map(client => ({value: client.id, label: client.name})))
                }}
              />
            </div>
            {client && 
            <div style={{width: "100%", maxWidth: 800, display: "flex", gap: "16px", }}>
              <div style={{flex: 1}}>
                <span>{localization.get('people.search_person')}</span>
                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 10
                    })
                  }}
                  value={searchPerson}
                  onChange={searchPerson => this.setState({ searchPerson })}
                  loadOptions={(inputValue, callback) => {
                    const options = {
                      filterBy: [{column: "name", filter: inputValue}, {column: "use_name", filter: 1}]
                    }

                    return request.people.getAllForClient(this.state.client.value, 'mainImage', options)
                      .then(response => response.data
                        .map(person => ({...person, value: person.id, label: person.name})))
                  }}
                  defaultOptions
                  defaultValue={searchPerson}
                  placeholder={localization.get('people.search_person')}
                />
              </div>
              
              <div style={{width: 200}}>
                <span>{localization.get('people.filter_by_identification')}</span>
                <Select
                  value={identificationFilter}
                  onChange={identificationFilter => {
                    this.setState({ identificationFilter }, this.requestPeople)                  
                  }}
                  options={identificationOptions}
                />
              </div>
            </div>
            }
            <div style={{width: "100%", display: "flex", gap: "16px", flexWrap: "wrap", justifyContent: 'center'}}>
              {!loading && this.getFilteredPeople().length > 0 &&
                this.getFilteredPeople().map(person => (
                  <PersonItem 
                    key={person.id}
                    person={person} 
                    onClick={() => this.setSelectedPerson(person)}
                  />
                ))
              }
            </div>
            {totalPages > 1 && (
              <Paginator
                pages={totalPages}
                currentPage={currentPage - 1}
                onPageChange={this.handlePageChange}
              />
            )}
            <PersonPhotosDialog
              open={!!selectedPerson}
              onClose={() => this.setSelectedPerson(null)}
              person={selectedPerson}
              people={people}
              client={client}
              refreshPeople={() => this.requestPeople()}
              onBlock={id => {
                this.setState({ people: people.filter(person => person.id !== id) })
              }}
              onNameUpdate={(id, newName) => {
                this.setState({ people: people.map(person => person.id === id ? { ...person, name: newName } : person) })
              }}
            />
          </div>
        )
    }
    }
              
export default GridPeople
              
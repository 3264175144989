import React from 'react';

export const DownloadContext = React.createContext();
const MAX_SIMULTANEOUS_DOWNLOADS = 3;

export class DownloadProvider extends React.Component {
  state = {
    downloadingTracks: {},
    downloadQueue: []
  };

  startDownload = (trackId) => {
    const currentDownloads = Object.keys(this.state.downloadingTracks).length;
    
    if (currentDownloads < MAX_SIMULTANEOUS_DOWNLOADS) {
      this.setState(prevState => ({
        downloadingTracks: {
          ...prevState.downloadingTracks,
          [trackId]: {
            status: 'downloading',
            progress: 0
          }
        }
      }));
    } else {
      this.setState(prevState => ({
        downloadQueue: [...prevState.downloadQueue, trackId]
      }));
    }
  };

  finishDownload = (trackId) => {
    this.setState(prevState => {
      const newDownloadingTracks = { ...prevState.downloadingTracks };
      delete newDownloadingTracks[trackId];

      // Si hay elementos en la cola, comenzar la siguiente descarga
      if (prevState.downloadQueue.length > 0) {
        const nextTrackId = prevState.downloadQueue[0];
        newDownloadingTracks[nextTrackId] = {
          status: 'downloading',
          progress: 0
        };

        return {
          downloadingTracks: newDownloadingTracks,
          downloadQueue: prevState.downloadQueue.slice(1)
        };
      }

      return {
        downloadingTracks: newDownloadingTracks
      };
    });
  };

  isDownloading = (trackId) => {
    return !!this.state.downloadingTracks[trackId];
  };

  isQueued = (trackId) => {
    return this.state.downloadQueue.includes(trackId);
  };

  render() {
    const value = {
      startDownload: this.startDownload,
      finishDownload: this.finishDownload,
      isDownloading: this.isDownloading,
      isQueued: this.isQueued,
      downloadingTracks: this.state.downloadingTracks,
      downloadQueue: this.state.downloadQueue
    };

    return (
      <DownloadContext.Provider value={value}>
        {this.props.children}
      </DownloadContext.Provider>
    );
  }
}
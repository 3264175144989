import React, {Component} from "react";
import localization from "../../../../config/localization";
import {Formik} from "formik";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../../components/Letflow/Snackbar/GlobalSnackbar";
import FormContent from "./FormView";
import {request} from "../../../../api-client";
import moment from "moment";
import {getUpdateFormImageUrlFromItem} from "../../../../utils";
import AvailableLanguages from "../../../../config/localization/availableLanguages.json";


class FormController extends Component {
  state = {
    submitted: false,
    id: '',
    streamId: '',
    startTime: null,
    endTime: null,
    videoId: null,
    startImageId: null,
    endImageId: null,
    fake: 0,
    loaded: false,
    videoPreview: null,
    startImagePreview: null,
    endImagePreview: null,
    video: { src: null, file: null },
    startImage: null,
    endImage: null,
    source: '',
    timer: null,
    showCountdown: true,
    hasSuggestedCarousel: false,
    suggestedCarouselPlaylist: null,
    suggestedView: "16:9",
    isPassed: false,
    hasToken: false,
    allowedTokens: "",
    translations: AvailableLanguages.map(language => ({
      language: language,
      name: "",
      description: "",
    })),
    hasRegionBlock: "false",
    allowedRegions: [],
  };

  componentDidMount = () => {
    if (!this.props.create) {
      request.liveStream.get(this.props.channelId, this.props.livestreamId)
        .then(livestream => {
          const translations = AvailableLanguages.map(language => {
            const translation = livestream.translations.find(tr => tr.locale ===language)

            return  {
              language: language,
              name: translation ? translation.name: "",
              description: translation ? translation.description: "",
            }
          })

          return this.setState({
            id: livestream.id,
            translations,
            streamId: livestream.stream_id,
            startTime: moment.utc(livestream.start_time).local(),
            endTime: livestream.end_time ? moment.utc(livestream.end_time).local() : null,
            isEnded: moment.utc(livestream.end_time).local().diff(moment()) < 0,
            fake: livestream.fake,
            source: livestream.source,
            timer: moment.utc(livestream.timer).local(),
            showCountdown: livestream.show_countdown,
            hasSuggestedCarousel: livestream.has_suggested,
            suggestedCarouselPlaylist: livestream.suggestedPlaylist ? {value: livestream.suggestedPlaylist.id, label: livestream.suggestedPlaylist.title} : null,
            suggestedView: livestream.suggested_view,
            video: livestream.video
              ? { src: livestream.video.url, file: null, filename: livestream.video.original_name}
              : null,
            startImage: livestream.startImage
              ? { src: getUpdateFormImageUrlFromItem({image: livestream.startImage}), filename: livestream.startImage.original_name }
              : null,
            endImage: livestream.endImage
              ? { src: getUpdateFormImageUrlFromItem({image: livestream.endImage}), filename: livestream.endImage.original_name }
              : null,
            selectedContentCreator: livestream.contentCreator && {value: livestream.contentCreator.id, label: livestream.contentCreator.name},
            hasToken: !!livestream.allowed_tokens,
            allowedTokens: livestream.allowed_tokens,
            hasRegionBlock: !!livestream.allowedRegions ? "true" : "false",
            allowedRegions: livestream.allowedRegions ? livestream.allowedRegions : [],
          })
        })
        .then(() => this.setState({loaded: true}))
    }
    else {
      this.setState({loaded: true})
    }
  }

  validate = values => {
    const errors = {};

    if (!values.timer) {
      errors.startTime = localization.get("validator.is_required");
    }

    if (!values.startTime) {
      errors.startTime = localization.get("validator.is_required");
    }
    
    if (!values.fake && !values.streamId) {
      errors.streamId = localization.get("validator.is_required");
    }
    if (values.fake && values.source === 'bloomclouds' && !values.videoId && this.props.create) {
      errors.videoId = localization.get("validator.is_required");
    }

    if (values.timer > values.startTime) {
      GlobalSnackbar.show({message: localization.get('livestream.timer_error'), type: GlobalSnackbarTypes.ERROR})
      errors.fake = localization.get("error");
    }

    if (values.endTime) {
      if (values.startTime > values.endTime) {
        GlobalSnackbar.show({message: localization.get('livestream.time_error'), type: GlobalSnackbarTypes.ERROR})
        errors.fake = localization.get("error");
      }
    }

    if (values.source && values.source.includes('vimeo') && !values.streamId.includes('vimeo.com')) {
      errors.streamId = localization.get("error_vimeo");
    }
    if (!values.startImageId && !values.startImage) {
      errors.startImageId = localization.get("validator.is_required");
    }
    if (!values.endImageId && !values.endImage) {
      errors.endImageId = localization.get("validator.is_required");
    }

    return errors;
  };

  initialValues = () => ({
    translations: this.state.translations,
    streamId: this.state.streamId,
    startTime: this.state.startTime,
    endTime: this.state.endTime,
    isEnded: this.state.isEnded,
    radio: this.state.fake ? 'fake' : 'live',
    videoId: this.state.videoId,
    startImageId: this.state.startImageId,
    endImageId: this.state.endImageId,
    fake: this.state.fake,
    disabled: false,
    videoPreview: this.state.videoPreview,
    startImagePreview: this.state.startImagePreview,
    endImagePreview: this.state.endImagePreview,
    video: this.state.video,
    startImage: this.state.startImage,
    endImage: this.state.endImage,
    handleCloseForm: (resetForm) => this.handleCloseForm(resetForm),
    onSetPreview: (values, setFieldValue, type) => this.setPreview(values, setFieldValue, type),
    showPreview: !this.props.create,
    allowFakeLivestreams: this.props.allowFakeLivestreams,
    source: this.state.source,
    channelId: this.props.channelId,
    selectedContentCreator: this.state.selectedContentCreator,
    showCountdown: this.state.showCountdown,
    timer: this.state.timer,
    hasSuggestedCarousel: this.state.hasSuggestedCarousel,
    suggestedCarouselPlaylist: this.state.suggestedCarouselPlaylist,
    suggestedView: this.state.suggestedView,
    allowedTokens: this.state.allowedTokens,
    hasToken: this.state.hasToken,
    allowedRegions: this.state.allowedRegions,
    hasRegionBlock: this.state.hasRegionBlock,
  });

  setPreview = (values, setFieldValue, type) => {
    if (type === 'video') {
      setFieldValue('video', { src: values.videoPreview, filename: null})
    } else if (type === 'startImage') {
      let item = {image: {id: values.startImageId, src: values.startImagePreview}}
      setFieldValue('startImage', {src: getUpdateFormImageUrlFromItem(item)})
    } else if (type === 'endImage') {
      let item = {image: {id: values.endImageId, src: values.endImagePreview}}
      setFieldValue('endImage', {src: getUpdateFormImageUrlFromItem(item)})
    }
  }

  submitRequest = (data) => {
    if (this.props.create) {
      return request.liveStream.create(data)
    } else {
      return request.liveStream.update(data)
    }
  }

  handleCloseForm = (resetForm) => {
    this.props.exitForm()
    resetForm({})
  }

  render = () => {
    if (this.state.loaded) {
      return (
        <Formik
          initialValues={this.initialValues()}
          validate={this.validate}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            const data = {
              ...values,
              channelId: this.props.channelId,
              livestreamId: this.state.id,
              contentCreatorId: values.selectedContentCreator && values.selectedContentCreator.value,
              allowedRegions: values.hasRegionBlock == "true" ?  values.allowedRegions : [],
            }
            
            this.submitRequest(data)
              .then(() => GlobalSnackbar.showGenericSuccess())
              .then(() => this.handleCloseForm(actions.resetForm))
              .then(() => this.setState({ submitted: true }))
              .catch((res) => {
                if (res.status === 409) {
                  GlobalSnackbar.show({message: localization.get('livestreams.already_exist'), type: GlobalSnackbarTypes.ERROR})
                } else {
                  GlobalSnackbar.showGenericError()
                }
              })
              .finally(() => actions.setSubmitting(false));
          }}
        >
          {formikProps => (FormContent(formikProps))}
        </Formik>
      );
    } else {
      return <div style={{height: 300}}></div>
    }

  };
}

export default FormController;

import {get, appendPaginationQueryParamsToUrl} from '../utils'

export default host => (clientId, filters = {}) => {
  const queryParams = Object.entries(filters)
    .reduce((acc, [key, value]) => {
      if (value !== null) {
        acc.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
      return acc;
    }, [])
    .join('&');

  const url = `${host}/clients/${clientId}/persons/searchImages${queryParams ? `?${queryParams}` : ''}`;

  return get(appendPaginationQueryParamsToUrl(url, {
    alreadyHasQueryParams: !!queryParams
  }))
    .then(response => response.json());
}

import React, { Component } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent,
  TextField,
  Typography,
  CircularProgress,
  Tooltip,
  IconButton,
  DialogActions
} from '@material-ui/core';
import styled from 'styled-components';
import { request } from "../../api-client"
import { makeFitImageUrlWithSize } from '../../utils';
import { get } from 'lodash';
import localization from '../../config/localization';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import BlockIcon from '@material-ui/icons/Block';
import StarIcon from '@material-ui/icons/Star';
import { Clear } from '@material-ui/icons';
import Sync from '@material-ui/icons/Sync';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GlobalConfirmationDialog from '../../components/Letflow/Dialog/GlobalConfirmationDialog';
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../components/Letflow/Snackbar/GlobalSnackbar";
import AsyncSelect from "react-select/lib/Async";
import Button from '@material-ui/core/Button';

const PhotosGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
  padding: 16px 0;
`;

const PhotoItem = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .actions-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover .actions-overlay {
    opacity: 1;
  }
`;

const ActionButton = styled(IconButton)`
  color: white;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 4px;
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }

  .MuiSvgIcon-root {
    font-size: 20px;
    color: white;
    opacity: 0.9;
  }
`;

const ProfileImage = styled.div`
  min-width: 50px;
  height: 50px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: ${props => {
    if (props.boundingBox) {
      const x = props.boundingBox.Left * 100;
      const y = props.boundingBox.Top * 100;
      return `${x}% ${y}%`;
    }
    return 'center';
  }};
  background-color: #f0f0f0;
`;

class PersonPhotosDialog extends Component {
	constructor(props) {    
		super(props);
		this.state = {  
			name: '',
			isEditing: false,
			loading: false,
			person: null,
			selectedPhoto: null,
			photoDialogOpen: false,
			isLocked: false,
      fusionPerson: null,
      fusionPersonDialogOpen: false
		};
	}

	getPerson = () => {
		this.setState({ loading: true });
		return request.people.show(this.props.person.id)
			.then(person => this.setState({ 
				person, 
				name: person.name,
				loading: false 
			}))
			.catch(() => this.setState({ loading: false }));
	}

	updatePerson = (data) => request.people.update(this.props.person.id, data)


  handleNameChange = (event) => {
    this.setState({ name: event.target.value });
  };

  handleSubmit = () => {
    const { onNameUpdate, person } = this.props;
    this.setState({ isEditing: false });
		this.updatePerson({ name: this.state.name });
    onNameUpdate(person.id, this.state.name);
    GlobalSnackbar.show({
      message: localization.get('form.person_updated'),
      type: GlobalSnackbarTypes.SUCCESS
    });
  };

  startEditing = () => {
    this.setState({ isEditing: true });
  };

  componentDidUpdate(prevProps) {
    if (get(prevProps, 'person.id') !== get(this.props, 'person.id') && get(this.props, 'person.id')) {
        this.handleNameChange({ target: { value: get(this.props.person, 'name', "") } });
      	this.getPerson();
    }
  }

	handleClose = () => {
		const { onClose } = this.props;
		this.setState({
			name: '',
			isEditing: false,
			loading: false,
			person: null
		});
		onClose();
	};

	handlePhotoClick = (photo) => {
		this.setState({ 
			selectedPhoto: photo,
			photoDialogOpen: true 
		});
	};

	handlePhotoDialogClose = () => {
		this.setState({ 
			selectedPhoto: null,
			photoDialogOpen: false 
		});
	};

	handleSetMainPhoto = (photo, event) => {
		event.stopPropagation(); 

    request.people.setMainImage(this.props.person.id, photo.id)
      .then(() => {
        this.props.refreshPeople();
        this.handleClose();
      })
	};

	handleDeletePhoto = (photo, event) => {
		event.stopPropagation(); 

    GlobalConfirmationDialog.show({
      title: localization.get('form.delete_photo'),
      content: localization.get('form.delete_photo_confirmation'),
      request: () => request.people.removePhoto(this.props.person.id, photo.id)
        .then(() => {
          this.setState({person: {
            ...this.state.person,
            images: this.state.person.images.filter(p => p.id !== photo.id)
          }})
        })
    })
	};

  handleFusionPerson = (event) => {
		event.stopPropagation(); 

    return request.people.mergePeople(this.state.fusionPerson.value, this.props.person.id)
        .then(() => {
          GlobalSnackbar.show({
            message: localization.get('form.person_merging'),
            type: GlobalSnackbarTypes.SUCCESS
          });
          this.props.refreshPeople();
          this.handlePhotoDialogClose();
          this.handleClose();
        })
      .catch(error => {
        GlobalSnackbar.show({
          message: "error",
          type: GlobalSnackbarTypes.ERROR
        });
      });
	};

	handleToggleLock = () => {
    GlobalConfirmationDialog.show({
      title: localization.get('form.block_person'),
      request: () => this.updatePerson({ blocked: 1 })
        .then(() => {
          this.props.onBlock(this.props.person.id);
          this.handleClose();
      })
    })
	};

  handleFusionPersonDialogClose = () => {
    this.setState({fusionPersonDialogOpen: false, fusionPerson: null})
  }

	
  render() {
    const { open } = this.props;
    const { name, person, loading, selectedPhoto, photoDialogOpen, isLocked } = this.state;
    const mainImage = get(person, 'mainImage') || get(person, 'images[0]');
    const boundingBox = get(mainImage, 'face_metadata.boundingBox');

		
    return (
      <>
        <Dialog 
          open={open} 
          onClose={this.handleClose}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
              <ProfileImage>
                {mainImage && (
                  <StyledImage 
                    src={makeFitImageUrlWithSize({image: mainImage}, 'md')} 
                    alt={name || 'Sin nombre'}
                    boundingBox={boundingBox}
                  />
                )}
              </ProfileImage>

              <TextField
                value={name}
                onChange={this.handleNameChange}
                // onBlur={this.handleSubmit}
                autoFocus
                variant="standard"
                placeholder={localization.get('people.add_name')}
                sx={{ fontSize: '1.5rem' }}
                disabled={isLocked}
                fullWidth
              />
              
              <Tooltip title={localization.get('form.save_person')}>
                <IconButton 
                  onClick={this.handleSubmit}
                  disabled={isLocked}
                  size="small"
                >
                  <SaveIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title={localization.get('form.block_person')}>
                <IconButton 
                  onClick={() => this.handleToggleLock()}
                  size="small"
                >
                 <BlockIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={localization.get('form.fusion_person')}>
                <IconButton 
                  onClick={() => this.setState({fusionPersonDialogOpen: true})}
                  size="small"
                >
                 <Sync />
                </IconButton>
              </Tooltip>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
              <IconButton onClick={this.handleClose}>
                <Clear />
              </IconButton>
            </div>
            </div>
          </DialogTitle>
          
          <DialogContent>
            <Typography variant="subtitle1" color="textSecondary">
              {get(person, 'images', []).length} {localization.get('people.photos')}
            </Typography>
            
            {loading ? (
              <div style={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                minHeight: 200 
              }}>
                <CircularProgress />
              </div>
            ) : (
              <PhotosGrid>
                {get(person, 'images', []).map((photo) => {
                  const boundingBox = get(photo, 'face_metadata.boundingBox');
                  return (
                    <PhotoItem 
                      key={photo.id}
                      onClick={() => this.handlePhotoClick(photo)}
                    >
                      <img 
                        src={makeFitImageUrlWithSize({image: photo}, 'md')} 
                        alt=""
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          objectPosition: boundingBox 
                            ? `${boundingBox.Left * 100}% ${boundingBox.Top * 100}%`
                            : 'center'
                        }}
                      />
                      <div className="actions-overlay">
                        <Tooltip title={localization.get('people.view_photo')}>
                          <ActionButton
                            onClick={(e) => this.handlePhotoClick(photo)}
                            size="small"
                          >
                            <VisibilityIcon style={{color: "ccc"}}/>
                          </ActionButton>
                        </Tooltip>

                        <Tooltip title={localization.get('people.set_as_main_photo')}>
                          <ActionButton
                            onClick={(e) => this.handleSetMainPhoto(photo, e)}
                            size="small"
                          >
                            <StarIcon style={{color: "ccc"}} />
                          </ActionButton>
                        </Tooltip> 
                        
                        <Tooltip title={localization.get('people.delete_photo')}>
                          <ActionButton
                            onClick={(e) => this.handleDeletePhoto(photo, e)}
                            size="small"
                          >
                            <DeleteIcon style={{color: "ccc"}} />
                          </ActionButton>
                        </Tooltip>
                      </div>
                    </PhotoItem>
                  );
                })}
              </PhotosGrid>
            )}
          </DialogContent>
        </Dialog>

        <Dialog
          open={photoDialogOpen}
          onClose={this.handlePhotoDialogClose}
          onBackdropClick={this.handlePhotoDialogClose}
          onEscapeKeyDown={this.handlePhotoDialogClose}
          maxWidth="xl"
          fullWidth
        >
          <DialogContent style={{ padding: 0 }}>
            {selectedPhoto && (
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '80vh'
              }}>
                <img
                  src={makeFitImageUrlWithSize({image: selectedPhoto}, 'lg')}
                  alt=""
                  style={{
                    // maxWidth: '100%',
                    maxHeight: '80vh',
                    objectFit: 'contain'
                  }}
                />
              </div>
            )}
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.fusionPersonDialogOpen}
          onClose={this.handleFusionPersonDialogClose}
          onBackdropClick={this.handleFusionPersonDialogClose}
          onEscapeKeyDown={this.handleFusionPersonDialogClose}
        >
          <DialogTitle>{localization.get('form.fusion_person')}</DialogTitle>
          <DialogContent style={{ padding: "0 20px 80px 20px" }}>
            <p>{localization.get('form.fusion_person_disclaimer')}</p>
            <div style={{flex: 1}}>
                <span>{localization.get('people.search_person')}</span>
                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 10
                    })
                  }}
                  value={this.state.fusionPerson}
                  onChange={fusionPerson => this.setState({ fusionPerson })}
                  loadOptions={(inputValue, callback) => {
                    const options = {
                      filterBy: [{column: "name", filter: inputValue}, {column: "use_name", filter: 1}]
                    }

                    return request.people.getAllForClient(this.props.client.value, 'mainImage', options)
                      .then(response => response.data
                        .map(person => ({...person, value: person.id, label: person.name})))
                  }}
                  defaultOptions
                  placeholder={localization.get('people.search_person')}
                />
              </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleFusionPersonDialogClose}>{localization.get('cancel')}</Button>
            <Button disabled={!this.state.fusionPerson} onClick={this.handleFusionPerson}>{localization.get('form.fusion_person')}</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default PersonPhotosDialog; 
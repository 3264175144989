import React, {Component} from 'react'
import HomeElementCreateDialog from './HomeElementCreateDialog';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
  Select,
  CardContent, CardActions, Checkbox, FormControlLabel
} from '@material-ui/core';
import {Add, ArrowBack, Home, Star, StarBorder, Brush, Edit, Delete, Launch, LiveTv} from '@material-ui/icons';
import HomeGridElement from './HomeGridElement';
import HomeCarouselElement from './HomeCarouselElement';
import HomeBanners from './HomeBanners';
import {request} from '../../api-client';
import {BigSpinner} from '../../components/Letflow/Loading';
import localization from '../../config/localization';
import browserHistory from '../../utils/browserHistory';
import {
  addHttpsToUrl,
  slugify
} from "../../api-client/core/authentication/utils";
import { Draggable } from "react-beautiful-dnd";
import HomeFeaturedElement from "./HomeFeaturedElement";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../components/Letflow/Snackbar/GlobalSnackbar";
import TextField from "../../components/Letflow/Form/TextField";
import Button from "../../components/CustomButtons/Button";
import {Link} from "react-router-dom";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import MenuItem from "@material-ui/core/MenuItem";
import GlobalConfirmationDialog from "../../components/Letflow/Dialog/GlobalConfirmationDialog";
import Card from "../../components/Card/Card";
import CardText from "../../components/Card/CardText";
import CardHeader from "../../components/Card/CardHeader";
import HomeUniqueElement from "./HomeUniqueElement";
import FilePondUploader from "../../components/Letflow/FilePondUploader";
import {getUpdateFormImageUrlFromItem} from "../../utils";
import {ImagePicker} from "../../components/Letflow/Form";
import HomeElementList from "./HomeElementList";
import HomeGridAndCarouselBase from './HomeGridAndCarouselBase';
import {AllViewContainer} from "../../components/Letflow/ViewContainer";
import {get} from "lodash";
import LiveStreams from "../Channels/LiveStreams";

const homeElementViewFactories = {
  'banner': ({ clientId, showBanner, homeElement, channelId, onUpdateSuccess, onUpdateFailed, section }) =>
   <HomeBanners
      {...homeElement}
      showBanner={showBanner}
      channelId={channelId}
      clientId={clientId}
      onUpdateSuccess={onUpdateSuccess}
      onUpdateFailed={onUpdateFailed}
      section={section}
    />,
  'grid': ({ clientId, homeElement, channelId, onUpdateSuccess, onUpdateFailed, permissions, contentCreatorTypes, availableChannelPlaylists, availableGlobalPlaylists, channel, section, sections, index }) =>
    <Draggable key={homeElement.id.toString()} draggableId={homeElement.id.toString()} index={index} style={{width: "100%"}}>
      {provided => (
        <div>
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
            <HomeGridElement
              {...homeElement}
              provided={provided}
              channelId={channelId}
              permissions={permissions}
              clientId={clientId}
              contentCreatorTypes={contentCreatorTypes}
              onUpdateSuccess={onUpdateSuccess}
              onUpdateFailed={onUpdateFailed}
              channel={channel}
              section={section}
              sections={sections}
              availableChannelPlaylists={availableChannelPlaylists}
              availableGlobalPlaylists={availableGlobalPlaylists}
            />
          </div>
          {provided.placeholder}
        </div>
      )}
    </Draggable>,
  'carousel': ({ clientId, homeElement, channelId, onUpdateSuccess, onUpdateFailed, permissions, contentCreatorTypes, channel, availableChannelPlaylists, availableGlobalPlaylists, section, sections, index }) =>
    <Draggable key={homeElement.id.toString()} draggableId={homeElement.id.toString()} index={index} >
      {provided => (
        <div>
          <div ref={provided.innerRef} {...provided.draggableProps} >
            <HomeCarouselElement
              {...homeElement}
              provided={provided}
              channelId={channelId}
              permissions={permissions}
              clientId={clientId}
              contentCreatorTypes={contentCreatorTypes}
              onUpdateSuccess={onUpdateSuccess}
              onUpdateFailed={onUpdateFailed}
              channel={channel}
              section={section}
              sections={sections}
              availableChannelPlaylists={availableChannelPlaylists}
              availableGlobalPlaylists={availableGlobalPlaylists}
            />
          </div>
          {provided.placeholder}
        </div>
      )}
    </Draggable>,
  'featured': ({ clientId, homeElement, channelId, onUpdateSuccess, onUpdateFailed, permissions, contentCreatorTypes, channel, availableChannelPlaylists, availableGlobalPlaylists, section, sections, index }) =>
    <Draggable key={homeElement.id.toString()} draggableId={homeElement.id.toString()} index={index} >
      {provided => (
        <div>
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
            <HomeFeaturedElement
              {...homeElement}
              provided={provided}
              channelId={channelId}
              permissions={permissions}
              clientId={clientId}
              contentCreatorTypes={contentCreatorTypes}
              onUpdateSuccess={onUpdateSuccess}
              onUpdateFailed={onUpdateFailed}
              channel={channel}
              section={section}
              sections={sections}
              availableChannelPlaylists={availableChannelPlaylists}
              availableGlobalPlaylists={availableGlobalPlaylists}
            />
          </div>
          {provided.placeholder}
        </div>
      )}
    </Draggable>,
    'unique': ({ clientId, homeElement, channelId, onUpdateSuccess, onUpdateFailed, permissions, contentCreatorTypes, channel, availableChannelPlaylists, availableGlobalPlaylists, section, sections, index }) =>
    <Draggable key={homeElement.id.toString()} draggableId={homeElement.id.toString()} index={index} >
      {provided => (
        <div>
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
            <HomeUniqueElement
              {...homeElement}
              provided={provided}
              channelId={channelId}
              permissions={permissions}
              clientId={clientId}
              contentCreatorTypes={contentCreatorTypes}
              onUpdateSuccess={onUpdateSuccess}
              onUpdateFailed={onUpdateFailed}
              channel={channel}
              section={section}
              sections={sections}
              availableChannelPlaylists={availableChannelPlaylists}
              availableGlobalPlaylists={availableGlobalPlaylists}
            />
          </div>
          {provided.placeholder}
        </div>
      )}
    </Draggable>,
}

class HomeEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      requestingHomeElements: true,
      homeElements: [],
      creatingNewHomeElement: false,
      orderOfCreation: -1,
      permissions: [],
      contentCreatorTypes: [],
      channelEditor: this.props.channelId,
      clientId: this.props.clientId,
      channel: null,
      availableChannelPlaylists: [],
      availableGlobalPlaylists: [],
      sections: [],
      addSectionDialog: false,
      editingSection: false,
      newSectionName: "",
      section: {value: null, label: ""},
      starHover: false,
      image: { id: null, src: null, file: null },
      imageId: null,
      hasBackgroundImage: false,
      active: true,
      main: false,
      editingHomeElementDialog: false,
      editingHomeElement: null,
    }
  }

  componentDidMount = () => {
    this.requestChannel()
      .then(this.requestSections)
      .then(this.requestHomeElements)
      .then(this.requestChannelPlaylist())
      .then(this.requestGlobalPlaylist())
  }

  requestContentCreatorTypes = (column, filter) =>
    request.contentCreatorType.getAll({filterBy: [{column: column, filter: filter}, {column: "include_all", filter: true}]})
    .then(contentCreatorTypes => this.setState({
      contentCreatorTypes: contentCreatorTypes.map(contentCreatorType => ({
        id: contentCreatorType.id,
        type: contentCreatorType.type,
        value: contentCreatorType.slug,
        label: contentCreatorType.name,
        allowVideo: contentCreatorType.allow_video,
        allowAudio: contentCreatorType.allow_audio,
        isTalent: contentCreatorType.is_talent,
        })
      )
    }))

  requestGlobalPlaylist = () =>
    request.playlist.getAllGlobal({filterBy: [{column: "client", value: this.state.clientId}]})
      .then(res => this.setState({
        availableGlobalPlaylists: res.data.map(playlist => ({
          value: playlist.id,
          label: playlist.title
        }))
      }))

  requestChannelPlaylist = () =>
      request.playlist
        .getAll({
          filterBy: [
            {column: "channel_lists", filter: true},
            {column: 'channel_id', filter: this.props.channelId}
          ]
        })
        .then(res =>
          this.setState({
            availableChannelPlaylists: res.map(playlist => ({value: playlist.id, label: playlist.title}))
        }))
        
  requestChannel = () =>
    request.channel.getForDashboard(this.props.channelId, "include=domain;client;adUnits;languages")
      .then(channel => this.setState({ clientName: channel.name, showBanner: channel.show_banner, clientId: channel.client.id, channel }))


  requestChannelElements = () => request.channel.getElementsMetadata(this.props.channelId, true, true, false, this.state.section.value)
      .then(homeElements => homeElements.map(homeElement => ({ ...homeElement, items: homeElement.items && homeElement.items.data })))


  requestHomeElements = () =>
    this.setState({ requestingHomeElements: true }, () => {
        this.requestChannelElements()
          .then(homeElements => {
          const customElementsItems = homeElements.filter(hm => !!hm.parent)

          this.setState({
            homeElements: homeElements.filter(hm => !hm.parent).map(hm => {
              if (hm.data_source === "custom") {
                hm.items = customElementsItems.filter(customElementsItem => customElementsItem.parent === hm.id);
              }
              return hm
            }),
            requestingHomeElements: false,
          })
        })
      .then(() => this.requestContentCreatorTypes("channel_id", this.props.channelId ))
    })

  requestSections = () =>
    request.section.getAll(this.props.channelId, "")
      .then(response => this.setState({
          section: {value: response[0].id, label: response[0].name, main: response[0].main, image: response[0].image, active: response[0].active},
          sections: response
            .map(section => ({...section, value: section.id, label: section.name}))
            .sort((a, b) => {
              if (a.main && !b.main) return -1;
              if (!a.main && b.main) return 1;
              if (a.active && !b.active) return -1;
              if (!a.active && b.active) return 1;
              return 0;
            })
        }))

  addUpdateSection  = () =>
    request.section.update(this.props.channelId, this.state.editingSection, {name: this.state.newSectionName, imageId: this.state.imageId, hasBackgroundImage: this.state.hasBackgroundImage, active: this.state.active })
      .then(()  => this.setState({
          newSectionName: "",
          addSectionDialog: false,
          editingSection: false,
          imageId: null,
          active: true,
          main: false,
          image: { id: null, src: null, file: null },
          hasBackgroundImage: false
        },
        () => GlobalSnackbar.show({
          message: localization.get("section.updated"),
          type: GlobalSnackbarTypes.SUCCESS
        })))
      .then(()  => this.requestSections())
      .catch(() => GlobalSnackbar.show({ message: localization.get("section.fail"), type: GlobalSnackbarTypes.ERROR }))


  addNewSection = () => request.section.create(this.props.channelId, {name: this.state.newSectionName, imageId: this.state.imageId, hasBackgroundImage: this.state.hasBackgroundImage, active: this.state.active  })
    .then(()  => this.setState({
        newSectionName: "",
        addSectionDialog: false,
        editingSection: false,
        imageId: null,
        image: { id: null, src: null, file: null },
        hasBackgroundImage: false,
        active: true,
        main: false,
      },
      () => GlobalSnackbar.show({
        message: localization.get("section.added"),
        type: GlobalSnackbarTypes.SUCCESS
      })))
    .then(()  => this.requestSections())
    .catch(() => GlobalSnackbar.show({ message: localization.get("section.fail"), type: GlobalSnackbarTypes.ERROR }))

  makeMainSection = () =>
    GlobalConfirmationDialog.show({
      title: localization.get("section.make_main"),
      content: localization.get("section.confirm_make_main", this.state.section.label),
      request: () => request.section.makeMain(this.props.channelId, this.state.section.value).then(this.requestSections),
      confirmationLabel: localization.get("continue"),
    });

  deleteSection = () =>
    GlobalConfirmationDialog.showDelete({
      elementName: this.state.section.label,
      deleteRequest: () => request.section.delete(this.props.channelId, this.state.section.value).then(this.requestSections).then(this.requestHomeElements),
      onSuccess: () => GlobalSnackbar.show({ message: localization.get("section.deleted"), type: GlobalSnackbarTypes.SUCCESS }),
      onFail: () => GlobalSnackbar.show({ message: localization.get("section.fail"), type: GlobalSnackbarTypes.ERROR }),
      doubleConfirmation: true,
    })

  onImageChange = image => image ? this.setState({imageId: image.id, image: {src: null, filename: null }, imagePreview: this.formNewUrl(image.path)}) : this.setState({imageId: null, image: { src: null, filename: null }})
  setPreview = () => {
      let item = {image: {id: this.state.imageId, src: this.state.imagePreview}}
      this.setState({image : {src: getUpdateFormImageUrlFromItem(item)}})
  }
  formNewUrl = (path) => process.env.REACT_APP_CDN_HOST.concat('/' + path)
  setElements= elements => this.setState({homeElements: elements})


  addSectionDialog = () =>
    <Dialog open={this.state.addSectionDialog} onClose={() => this.setState({main: false, active: true, newSectionName: "", addSectionDialog: false, editingSection: false, imageId: null, image: { id: null, src: null, file: null }, hasBackgroundImage: false})}>
      <DialogTitle style={{ width: "100%" }} id="simple-dialog-title">{localization.get(this.state.editingSection ? "section.update" : "section.add")}</DialogTitle>
      <DialogContent style={{ width: 500,  }}>
        <TextField
          value={this.state.newSectionName}
          onChange={e => this.setState({ newSectionName: e.target.value })}
          label={localization.get("section.name")}
          fullWidth />
        <br/>
        <FormControlLabel
          control={
            <Checkbox
              id="active"
              checked={this.state.active}
              onChange={() => this.setState({ active: !this.state.active })}
            />
          }
          disabled={this.state.main}
          label={localization.get('form.active')}
        />
        <br/>
        <FormControlLabel
          control={
            <Checkbox
              id="hasBackgroundImage"
              checked={this.state.hasBackgroundImage}
              onChange={() => this.setState({ hasBackgroundImage: !this.state.hasBackgroundImage })}
            />
          }
          style={{marginBottom: 10}}
          label={localization.get('section.has_image')}
        />
        <br/>
        {this.state.hasBackgroundImage &&
          <>
            <span>{localization.get('section.image')}</span>
            {(this.state.image && this.state.image.src) &&
              <ImagePicker image={this.state.image.src} previewOnly={true} id={this.state.image.id}/>}
            <FilePondUploader
              disableForm={this.state.submitting}
              updateDisableForm={value => this.setState({submitting: value})}
              file={this.state.image}
              id={"image"}
              type={"image"}
              allowedTypes={["image/*"]}
              onIdChange={this.onImageChange}
              setPreview={this.setPreview}
              clientId={this.state.clientId}
            />
          </>
        }
      </DialogContent>
      <DialogActions style={{justifyContent: "center"}}>
        <Button onClick={() => !this.state.editingSection ? this.addNewSection() : this.addUpdateSection()} color='default' style={{ backgroundColor: "black" }} disabled={this.state.submitting}>
          {localization.get("save")}
        </Button>
      </DialogActions>
    </Dialog>

    onEditHomeElement = () => 
      <Dialog
        open={this.state.editingHomeElementDialog} 
        onClose={() => this.setState({editingHomeElementDialog: false, editingHomeElement: null})}
        onBackdropClick={() => this.setState({editingHomeElementDialog: false, editingHomeElement: null})}
        maxWidth="xl"
      >
        {this.state.editingHomeElementDialog &&
          <>
            <DialogTitle>{localization.get(this.state.editingHomeElement.type == "banner" ? 'update_banners' : 'update_home_element')}</DialogTitle>
            {this.state.editingHomeElement.type == "banner" ? 
                <HomeBanners
                  {...this.state.editingHomeElement}
                  showBanner={this.state.showBanner}
                  permissions={this.state.permissions}
                  channelId={this.props.channelId}
                  clientId={this.state.clientId}
                  onUpdateSuccess={() => this.setState({editingHomeElementDialog: false, editingHomeElement: null}, this.requestHomeElements)}
                  onClose={() => this.setState({editingHomeElementDialog: false, editingHomeElement: null}, this.requestHomeElements)}
                  onUpdateFailed={() => GlobalSnackbar.show({ message: localization.get("home_editor.update_failed"), type: GlobalSnackbarTypes.ERROR }).then(() => this.setState({editingHomeElementDialog: false, editingHomeElement: null}))}
                  section={this.state.section}
                  onChangeShowBanner={showBanner => this.setState({showBanner}, this.requestHomeElements)}
                />
            :
              <HomeGridAndCarouselBase
                {...this.state.editingHomeElement}
                channelId={this.props.channelId}
                permissions={this.state.permissions}
                clientId={this.state.clientId}
                contentCreatorTypes={this.state.contentCreatorTypes}
                onUpdateSuccess={() => this.setState({editingHomeElementDialog: false, editingHomeElement: null}, this.requestHomeElements)}
                onClose={(element) => {
                  if (get(element, 'type') && get(element, 'id')) {
                    this.setState({ homeElements: this.state.homeElements.map(homeElement => homeElement.id === element.id ? element : homeElement) })
                  }

                  this.setState({editingHomeElementDialog: false, editingHomeElement: null})
                }}
                onUpdateFailed={() => GlobalSnackbar.show({ message: localization.get("home_editor.update_failed"), type: GlobalSnackbarTypes.ERROR }).then(() => this.setState({editingHomeElementDialog: false, editingHomeElement: null}))}
                channel={this.state.channel}
                section={this.state.section}
                sections={this.state.sections}
                availableChannelPlaylists={this.state.availableChannelPlaylists}
                availableGlobalPlaylists={this.state.availableGlobalPlaylists}
                isUnique={this.state.editingHomeElement.type === "unique"}
                isModal
                useDialogButtons
              />
            } 
          </>
        }
      </Dialog>


  render = () => {
    
    return (
      <div>
        {this.state.channel && 
        <AllViewContainer
                onAdd={() => this.setState({ creatingNewHomeElement: true, orderOfCreation: this.state.homeElements.length + 1 })}
                ref="viewContainer"
              >
          <LiveStreams showDialog={this.state.showLiveStreamSettings} channelId={this.state.channel.id} allowFakeLivestreams={get(this.state, "channel.client.allow_fake_livestreams")} channel={this.state.activeChannelComplete} handleCloseDialog={() => this.setState({showLiveStreamSettings: false})}/>
          {this.onEditHomeElement()}
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: "10px 0"}}>
            <div style={{width: '100%', position: "relative", float: "left", maxWidth: 1000, display: 'flex'}}>
              <CardHeader color="dark" text style={{left: -15}}>
                <CardText style={{ backgroundColor: "dark" }} color={"dark"}>
                  <h4>{this.state.channelEditor ? localization.get("channel.update_home", this.state.channel.name) : this.state.clientName}</h4>
                </CardText>
              </CardHeader>
              <div style={{marginLeft: 15}}>
                <IconButton
                  onClick={() => {
                    let url = !!this.state.channel.domain ? addHttpsToUrl(this.state.channel.domain.url) : `/channel/${this.state.channel.id}-${slugify(this.state.channel.name)}`;

                    return window.open(`${url}/?no_cache=true`, "_blank")
                  }}
                  style={{position: 'relative'}}
                >
                  <Tooltip title={localization.get(window.location.pathname.includes('channels') ? 'channel.visit' : 'client.visit')}>
                    <Home/>
                  </Tooltip>
                </IconButton>
                  <IconButton
                title={localization.get('livestreams.schedule')}
                onClick={() => this.setState({showLiveStreamSettings: true, activeChannel: this.state.channel.id, allowFakeLivestreams: get(this.state, "channel.client.allow_fake_livestreams"), activeChannelComplete: this.state.channel})}
              >
                <LiveTv />
              </IconButton>

                {(window.location.pathname.includes('channels')) &&
                  <>
                    <IconButton
                      onClick={() => browserHistory.push(`/panel/channels/${this.state.channel.id}/preferences`)}
                      style={{position: 'relative'}}
                    >
                      <Tooltip title={localization.get(localization.get('channel.customization'))}>
                        <Brush />
                      </Tooltip>
                    </IconButton>

                    <IconButton
                      onClick={() => browserHistory.push(`/panel/channels/${this.state.channel.id}/edit`)}
                      style={{position: 'relative'}}
                    >
                      <Tooltip title={localization.get('channel.editor')}>
                        <Edit />
                      </Tooltip>
                    </IconButton>
                  </>
                }
              </div>
            </div>
            {this.state.channelEditor &&
              <Card style={{ width: '100%', maxWidth: 1000, background: "var(--dashboard-section-header-background-color)"}}>
                <CardContent>
                <Typography variant='display1'>{localization.get('section')}</Typography>
                {this.addSectionDialog()}
                <div style={{display: 'flex', justifyContent: 'center', width: "100%"}}>
                  <p style={{padding: "8px 15px"}}>{localization.get('section.select')}</p>
                  {this.state.sections && this.state.sections.length > 0 &&
                    <Select
                      style={{width: "50%", marginBottom: 15}}
                      onChange={e => {
                        this.setState({section: this.state.sections.find(section => section.value === e.target.value)},
                          () => this.requestHomeElements())
                      }}
                      value={this.state.section.value}
                    >
                      {this.state.sections.map(section => <MenuItem value={section.value}>{section.label} {section.main ? `(${localization.get("section.main")})`: !section.active ? `(${localization.get("catalog.inactive")})` : ""}</MenuItem>)}
                    </Select>
                  }

                  {!!this.state.section.active &&
                    <Link
                      to={
                        `${this.state.channel.domain ? addHttpsToUrl(this.state.channel.domain.url) : `/channel/${this.state.channel.id}-${slugify(this.state.channel.name)}`}` +
                        `${this.state.section.main ? "" : `/home/${this.state.section.value}-${slugify(this.state.section.label).replace(/-/g, '_')}`}?no_cache=true`
                      }
                      target="_blank">
                      <IconButton
                        style={{float: 'right', bottom: 5}}
                      >
                        <Tooltip title={localization.get('section.visit')}>
                          <Launch />
                        </Tooltip>
                      </IconButton>
                    </Link>
                  }
                  <IconButton
                    style={{float: 'right', bottom: 5}}
                    onClick={() => {
                      this.setState({
                        addSectionDialog: true,
                        editingSection: this.state.section.value,
                        newSectionName: this.state.section.label,
                        hasBackgroundImage: !!this.state.section.image,
                        active: this.state.section.active,
                        main: this.state.section.main,
                        image: this.state.section.image ?
                          { id: this.state.section.image.id, src: this.state.section.image.url } :
                          { id: null, src: null, file: null }
                      })
                    }}
                  >
                    <Tooltip title={localization.get('section.edit')}>
                      <Edit />
                    </Tooltip>
                  </IconButton>
                  {!this.state.section.main ?
                    <>
                      {!!this.state.section.active &&
                        <Tooltip title={localization.get('section.make_main')}>
                          <IconButton
                            style={{float: 'right', bottom: 5}}
                            onClick={this.makeMainSection}
                            onMouseEnter={() => this.setState({starHover: true})}
                            onMouseLeave={() => this.setState({starHover: false})}
                          >
                            {this.state.starHover ? <Star/> : <StarBorder/>}
                          </IconButton>
                        </Tooltip>
                      }
                      <Tooltip title={localization.get('section.delete')}>
                        <IconButton
                          style={{float: 'right', bottom: 5}}
                          onClick={this.deleteSection}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </>
                    :
                    <>
                      <IconButton
                        style={{float: 'right', bottom: 5}}
                      >
                        <Tooltip title={localization.get('section.already_main')}>
                          <Star style={{color: "#ccc"}}/>
                        </Tooltip>
                      </IconButton>
                      <Tooltip title={localization.get('section.delete')}>
                        <IconButton style={{float: 'right', bottom: 5}}>
                          <Delete style={{color: "#ccc"}}/>
                        </IconButton>
                      </Tooltip>
                    </>
                  }
                </div>
                </CardContent >

                <CardActions style={{ float: 'right' }}>
                  <IconButton
                    style={{position: 'absolute', right: 0, bottom: 0, margin: 5}}
                    onClick={() => this.setState({addSectionDialog: true})}
                  >
                    <Tooltip title={localization.get('section.add')}>
                      <Add />
                    </Tooltip>
                  </IconButton>
                </CardActions>
              </Card >
            }
            {this.makeSelectElementTypeDialog()}
            
            {!this.state.requestingHomeElements && this.state.homeElements.length === 0 &&
              <IconButton style={{margin: '10px 0 10px 0'}}
              onClick={() => this.setState({
                creatingNewHomeElement: true,
                orderOfCreation: 1
              })}><Add/></IconButton>
            }
            {!this.state.requestingHomeElements &&
            <HomeElementList 
                homeElements={this.state.homeElements}
                setHomeElements={homeElements => this.setState({homeElements})}
                clientId={this.state.clientId}
                onEditHomeElement={element => this.setState({ editingHomeElementDialog: true, editingHomeElement: element })}
                onUpdateSuccess={this.requestHomeElements}
                showBanner={this.state.showBanner}
              />
            }
          </div>
          <IconButton onClick={this.props.history.goBack}><ArrowBack /></IconButton>
        </AllViewContainer>
        }
      </div>
    )
  }

  makeSelectElementTypeDialog = () => this.state.creatingNewHomeElement &&
    <HomeElementCreateDialog
      clientId={this.state.clientId}
      order={this.state.orderOfCreation}
      currentElementTypes={this.state.homeElements.map(he => he.type)}
      permissions={this.state.permissions}
      contentCreatorTypes={this.state.contentCreatorTypes}
      channel={this.state.channel}
      availableChannelPlaylists={this.state.availableChannelPlaylists}
      availableGlobalPlaylists={this.state.availableGlobalPlaylists}
      section={this.state.section}
      sections={this.state.sections}
      onClose={state => {
        this.setState({ creatingNewHomeElement: false })
        if (state === 'success') {
          this.requestHomeElements()
        }
      }}
   />

  makeHomeElements = () =>
    this.state.requestingHomeElements ? <BigSpinner /> : this.state.homeElements.map(element => {
      if(element.type === "banner") {
        element.order = 0;
      }
      return element
    }).sort((a,b) => a.order - b.order).map(this.makeHomeElementView)
}

export default HomeEditor;

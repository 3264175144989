import {get} from '../utils'
import {getActiveChannel, isPublicChannel, setActiveLivestreams} from "../authentication/utils";
import localization from "../../../config/localization";

export default host => (channelId, include = 'translations') =>
  get(`${host}${isPublicChannel() ? "/public": ""}/channels/${channelId}/livestreams/check_active?${include}${getActiveChannel() ? `&cLang=${localization.getLanguage()}` : ""}`)
    .then(response => response.json())
    .then(json => json.data)
    .then(data => {
      setActiveLivestreams(data);

      return data;
    });

import React from 'react';
import { 
  FacebookEmbed,
  InstagramEmbed,
  LinkedInEmbed,
  TikTokEmbed,
  TwitterEmbed,
  YouTubeEmbed
} from 'react-social-media-embed';

export default class EmbedBlock extends React.Component {
    constructor(props) {
      super(props);
    }

    static defaultProps = {
      width: 325,
      height: 'auto'
    };
  
    renderEmbed() {
      const { url, width, height } = this.props;
      
      if (url.includes('twitter.com') || url.includes('x.com')) {
        return <TwitterEmbed url={url} />;
      }
      
      if (url.includes('facebook.com')) {
        return <FacebookEmbed url={url} />;
      }

      if (url.includes('instagram.com')) {
        return <InstagramEmbed url={url} />;
      }

      if (url.includes('linkedin.com')) {
        return <LinkedInEmbed url={url} width={width} height={height} />;
      }

      if (url.includes('youtube.com') || url.includes('youtu.be')) {
        return <YouTubeEmbed url={url} width={width} height={height} />;
      }

      if (url.includes('tiktok.com')) {
        return <TikTokEmbed url={url} />;
      }
      
      return <iframe
        src={url}
        height={height || 'auto'}
        width={width || 'auto'}
       />
    }
  
    render() {
      return (
        <div className="social-media-embed" style={{ width:  'auto', height: 'auto' }}>
          {this.renderEmbed()}
        </div>
      );
    }
}
  
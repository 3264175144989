import React, {Component} from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridPeople from "./GridPeople.jsx";
import {AllViewContainer} from 'components/Letflow/ViewContainer';
import localization from "../../../config/localization";
import People from "../../../api-client/core/people";
import GridPhotos from "./GridPhotos.jsx";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridType: 'people',
    }
  }

  componentDidMount = () => {
      document.title = localization.get('people.list') + ' - Feater'
  }

  render = () =>
    <AllViewContainer
      viewIcon={<People />}
      ref='viewContainer'>
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody style={{padding: "10px 30px", marginBottom: 16, display: 'flex', flexDirection: 'column'}}>
                <RadioGroup
                  name="gridType"
                    value={this.state.gridType}
                    onChange={event => this.setState({gridType: event.target.value})}
                    style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                  >
                    <FormControlLabel value="people" control={<Radio color="default"/>} label={localization.get('people.list')}/>
                    <FormControlLabel value="photos" control={<Radio color="default"/>} label={localization.get('people.photos')}/>
                  </RadioGroup>
                {this.state.gridType === 'people' && <GridPeople {...this.props}/>}
                {this.state.gridType === 'photos' && <GridPhotos {...this.props}/>}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </AllViewContainer>

}

import React, {Component} from "react";
import {ServerSidePaginationTable, TableButton, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from '../../../api-client'
import {userCanCreateUser,} from "../../../api-client/core/authentication/utils";
import localization from "../../../config/localization";
import {Clear, Done} from "@material-ui/icons";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import {AllViewContainer} from "../../../components/Letflow/ViewContainer";

class UsersToApprove extends Component {

  constructor(props) {
    super(props)
    this.state = {
      showUsers: "all_roles",
      requestTableData: this.makeInitialTableDataRequest(),
      availablePerClient: 0,
      total: '',
      isChannel: props.isChannel,
    }
  }

  makeInitialTableDataRequest = () => paginationConfig => {
      paginationConfig = {...paginationConfig, filterBy: paginationConfig.filterBy.concat([{column: "role", filter: 'channel_user'}])}
      if(window.location.pathname.includes('approved')) {
        paginationConfig = {...paginationConfig, filterBy: paginationConfig.filterBy.concat([{column: "approved", filter: '0'}])}
      }

    return request.channel.usersToApprove(paginationConfig)
      .then((response) => {
        this.setState({ total: response.meta.total })
        return response
      })
  }

  componentDidUpdate = (_, prevState) => {
    if (prevState.requestTableData !== this.state.requestTableData) this.table && this.table.forceUpdate()
  }

  render = () => {

    const { requestTableData } = this.state

    return (
      <AllViewContainer onAdd={userCanCreateUser() ? this.onAdd : null} >
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <div>
                  <div style={{ width: "100%", float: "left", position: "relative" }}>
                    <ServerSidePaginationTable
                      ref={r => this.table = r}
                      columns={this.makeTableColumns()}
                      requestTableData={requestTableData}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </AllViewContainer>
    )
  }

  makeTableColumns = () => {

    const columns = []

    const nameColumn = {
      Header: localization.get('table.name'),
      accessor: "name",
      Cell: value => value.original.user && value.original.user.name,
      Filter: ({ filter, onChange }) => (
        <input
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ''}
          placeholder={localization.get('table.search')}
        />
      ),
    }

    const emailColumn = {
      Header: localization.get('table.email'),
      accessor: "email",
      Cell: value => value.original.user && value.original.user.email,
      Filter: ({ filter, onChange }) => (
        <input
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ''}
          placeholder={localization.get('table.search')}
        />
      ),
    }

    const channelColumn = {
      Header: localization.get('table.channel'),
      id: "channels",
      accessor: 'channels',
      Cell: value => value.original.channel && value.original.channel.name,
      sortable: false,
      Filter: ({ filter, onChange }) =>
        <input
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ''}
          placeholder={localization.get('table.search')}
        />
    }

    const clientColumn = {
      Header: localization.get('table.client'),
      id: "clients",
      accessor: channelUser => <span>{channelUser.channel && channelUser.channel.client.name}</span>,
      sortable: false,
      filterable: false,
    }


    const actionsColumn = {
      Header: localization.get('table.actions'), id: 'actions', sortable: false, filterable: false,
       accessor: object => {

        const builder = new TableButtonsBuilder()
        builder
          .withOther(
            <TableButton
              title={localization.get('approve')}
              onClick={() => request.channel.approve(object.channel.id, object.user.id, 'approve')
                .then(() => this.table.forceUpdate())}
            >
              <Done />
            </TableButton>)
          .withOther(
            <TableButton
              title={localization.get('table.decline')}
              onClick={() => request.channel.approve(object.channel.id, object.user.id, 'unapprove')
                .then(() => this.table.forceUpdate())}
            >
              <Clear />
            </TableButton>)

        return builder.build()
      },
      maxWidth: 150,
      Footer: <span
        style={{ float: "right", fontSize: "18px", marginTop: "15px" }}><strong>Total:  </strong>{this.state.total}</span>
    }

    columns.push(nameColumn);
    columns.push(emailColumn);
    columns.push(channelColumn);
    columns.push(clientColumn);

    columns.push(actionsColumn);

    return columns
  }

}

export default UsersToApprove

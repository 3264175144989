import React from 'react'
import {CloudDownload} from '@material-ui/icons'
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomTooltip from '../utils/CustomTooltip';
import { DownloadContext } from '../../contexts/DownloadContext';

class Download extends React.Component {
  static contextType = DownloadContext;

  handleClick = () => {
    const { onClick, trackId } = this.props;
    if (onClick && !this.context.isDownloading(trackId) && !this.context.isQueued(trackId)) {
      onClick();
    }
  }

  render() {
    const { tooltip, trackId } = this.props;
    const isDownloading = this.context.isDownloading(trackId);
    const isQueued = this.context.isQueued(trackId);

    return (
      <CustomTooltip title={
        isQueued ? 'En cola' :
        isDownloading ? 'Descargando...' :
        tooltip
      }>
        <div style={{ margin: '0 10px', display: 'flex', alignItems: 'center' }}>
          <div 
            className='soundbar-button' 
            onClick={this.handleClick} 
            style={{ 
              display: 'flex', 
              alignItems: 'center', 
              color: 'var(--secondary-font-color, dimgrey)',
              cursor: isDownloading || isQueued ? 'default' : 'pointer'
            }}
          >
            <div style={{ lineHeight: '0' }}>
              {isDownloading ? (
                <CircularProgress size={24} style={{ color: 'var(--secondary-font-color, dimgrey)' }} />
              ) : (
                <CloudDownload />
              )}
            </div>
          </div>
        </div>
      </CustomTooltip>
    )
  }
}

export default Download;
import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CloudDownload from "@material-ui/icons/CloudDownload";
import Delete from "@material-ui/icons/Delete";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import PlaylistPlay from "@material-ui/icons/PlaylistPlay";
import PlayArrow from "@material-ui/icons/PlayArrow";
import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import {IconButton} from "@material-ui/core";
import {Close, ImportExport, Star, StarBorder} from "@material-ui/icons";
import HomeItemDescriptionTags from "../HomeElements/HomeItemDescriptionTags";
import {TableButton, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {
  channelPathGenerator,
  getActiveChannel, channelHasPlaylists, getStoredChannel,
  homePathGenerator, slugify, channelDisableShare
} from "../../../api-client/core/authentication/utils";
import {request} from "../../../api-client";
import browserHistory from "../../../utils/browserHistory";
import {Link} from "react-router-dom";
import localization from "../../../config/localization";
import { onImageError } from "../HomeElements/utils";
import {ChevronLeft} from "@material-ui/icons"

const _ItemInfo = ({id, image, title, description, artist, clientId, tags, onSelectedTagsChanged, singleTagSelection, onAddFavorite, onRemoveFavorite, favorite, color, history, isAlbum = false, isPlaylist = false, isClickable = true, showButton = true, modal = false, allowDownload = false, onHandleClose, onReorder = undefined, onModalClose= null, useReorder = false}) => {
  let buttons = new TableButtonsBuilder()
  isPlaylist && buttons.withDelete(
    title,
    () => request.playlist.delete(id),
    closeState => {
      if (closeState === 'passed') {
        browserHistory.push(getActiveChannel() ? channelPathGenerator(`playlists`): homePathGenerator(`playlists`))
      }
    },
    false,
    "",
    "var(--main-font-color, rgb(0, 0, 0))"
  )
  isPlaylist && buttons.withEdit(getActiveChannel() ? channelPathGenerator(`playlist/${id}/edit`): homePathGenerator(`playlist/${id}/edit`), "var(--main-font-color, rgb(0, 0, 0))")
  isPlaylist && onReorder && useReorder && buttons.withOther(
      <TableButton
        title={localization.get('reorder')}
        onClick={onReorder}
      >
        <ImportExport style={{color: "var(--main-font-color, black)"}}/>
      </TableButton>
    )
  if (!channelDisableShare()) {
    buttons.withShare(isAlbum ? 'album' : 'playlist', id, "var(--main-font-color, rgb(0, 0, 0))")
  }
  isPlaylist && allowDownload && buttons.withOther(
    <TableButton
      title={localization.get('download')}
      onClick={() =>
        request.playlist.download(id)
          .then(response => window.open(response, '_blank'))
    }
    >
      <CloudDownload style={{color: "var(--main-font-color, rgb(0, 0, 0))"}}/>
    </TableButton>
  )

  let hasImage = !!image;
  const isMobile = window.innerWidth < 768;

  return (
    <div style={{display: 'flex', alignItems: "start", justifyContent: "space-between"}}>
      {hasImage && 
        <div className="image">
          <img src={image} alt='' onError={onImageError}/>
        </div>
      }
      <div className="album-header" style={hasImage ? {fontSize: "25px"} : {fontSize: "25px", paddingLeft: isMobile ? 0 : 20}}>
        <div style={{float: "left", position: "relative", width: "100%", display: "flex", flexDirection: isMobile ? "column" : "row"}}>
          <div style={{display: "flex", position: "relative", flexDirection: "row", alignItems: "center",  }}>
            {!!onHandleClose && isPlaylist && <ChevronLeft onClick={onHandleClose} style={{color: "var(--main-font-color, rgb(0, 0, 0))", cursor: "pointer", fontSize: 34}}/>}
            <h1 className="home-album-title">{title}</h1>
          </div>
          {(channelHasPlaylists())&&
          <div className="album-buttons">
            {buttons.build()}
            {isAlbum &&
            (favorite ? (
              <IconButton
                style={{ height: 35, width: 35 }}
                onClick={onRemoveFavorite}
              >
                <Star style={{color: "var(--main-font-color, inherit)"}} />
              </IconButton>
            ) : (
              <IconButton
                style={{ height: 35, width: 35}}
                onClick={onAddFavorite}
              >
                <StarBorder style={{color: "var(--main-font-color, inherit)"}} />
              </IconButton>
            ))
            }
          </div>
          }
        </div>
        {artist &&
          <div style={{ float: "left", position: "relative", width: "100%", top: "-7px"}}>
            <Link to={channelPathGenerator(`artista/${artist.id}-${slugify(artist.name)}`)} className="music-album">by {artist.name}</Link>
          </div>
        }
        <div style={{marginLeft: "-16px"}}>
        {tags && tags.length > 0 && <HomeItemDescriptionTags tags={tags} isClickable={isClickable} onSelectedTagsChanged={onSelectedTagsChanged} singleTagSelection={singleTagSelection}/>}
        </div>
        {description && <span className="album-description" dangerouslySetInnerHTML={{__html: description}}/>}
      </div>
      {!isMobile && <IconButton style={{ position: "relative", float: "left", top: 5, color: "var(--main-font-color, black)"}} onClick={() => !onHandleClose ? history.goBack() : onHandleClose()}><Close/></IconButton>}
    </div>
  )
}


const _ItemTable = ({ tableHead, tableData, classes }) =>
  <Table
    hover
    tableHead={tableHead}
    tableData={tableData}
    customCellClasses={[
      classes.center,
      classes.right,
      classes.right
    ]}
    customClassesForCells={[0, 3, 4]}
    customHeadCellClasses={[
      classes.center,
      classes.right,
      classes.right
    ]}
    customHeadClassesForCells={[0, 3, 4]}
  />

const _ItemTableButton = ({ onClick, icon, classes }) => {
  const IconComponent = icon
  return (
    <Button color="info" onClick={onClick} className={classes.actionButton} simple>
      <IconComponent className={classes.icon} />
    </Button>
  )
}

const _ItemTableDownloadButton = ({ url, classes, musicName }) =>
  <a href={url} download={musicName}>
    <ItemTableButton
      onClick={() => { }}
      icon={CloudDownload}
      classes={classes}
    />
  </a>

const _ItemTableDeleteButton = ({ onClick, classes }) =>
  <ItemTableButton
    onClick={onClick}
    icon={Delete}
    classes={classes}
  />

const _ItemTableAddToPlaylistButton = ({ onClick, classes }) =>
  <ItemTableButton
    onClick={onClick}
    icon={PlaylistAdd}
    classes={classes}
  />

const _ItemTablePlayButton = ({ onClick, classes }) =>
  <ItemTableButton
    onClick={onClick}
    icon={PlayArrow}
    classes={classes}
  />

const _ItemDrawer = ({ open, onDrawerClose, items, itemIdAccessor, itemTitleAccessor, onItemClicked, classes }) =>
  <Drawer
    style={{ zIndex: 10000 }}
    anchor="right"
    open={open}
    onClose={onDrawerClose}
  >
    <div
      tabIndex={0}
      role="button"
      onKeyDown={onDrawerClose}
    >
      <div className={classes.list}>
        <List>
          {items.map(item => {
            return (
              <ListItem key={itemIdAccessor(item)} onClick={() => onItemClicked(item)} button>
                <ListItemIcon>
                  <PlaylistPlay />
                </ListItemIcon>
                <ListItemText>{itemTitleAccessor(item)}</ListItemText>
              </ListItem>
            )
          })}
        </List>
      </div>
    </div>
  </Drawer>

const _ItemView = ({
  renderNavBar,
  renderItemInfo,
  renderItemTable,
  containerImage
}) =>
  <div id="innerinner">
    <div className="album-container" style={{ backgroundImage: `url(${containerImage})` }}/>
    <div className="album-card-container">
      <GridContainer>
        <GridItem xs={12}>
          <Card style={{
            borderRadius: (getActiveChannel() && getStoredChannel().preferences && getStoredChannel().preferences.content_creator && getStoredChannel().preferences.content_creator.card.shape === "square") ? "0px" : "30px",
            backgroundColor: "var(--main-color, #fff)",  opacity: "0.9"}}>
            <CardHeader> {renderItemInfo()} </CardHeader>
            <CardBody className="album-card-body"> {renderItemTable()} </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  </div>


const styles = {
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
};

const stylize = component => withStyles(Object.assign(pagesStyle, extendedTablesStyle, styles))(component)

export const ItemInfo = stylize(_ItemInfo);
export const ItemTable = stylize(_ItemTable);
export const ItemTableButton = stylize(_ItemTableButton);
export const ItemTableDownloadButton = stylize(_ItemTableDownloadButton);
export const ItemTableAddToPlaylistButton = stylize(_ItemTableAddToPlaylistButton);
export const ItemTablePlayButton = stylize(_ItemTablePlayButton);
export const ItemDrawer = stylize(_ItemDrawer);
export const ItemTableDeleteButton = stylize(_ItemTableDeleteButton);
export default stylize(_ItemView);

import React, {Component} from "react";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import {makeFitImageUrl, makeUrlWithTableParams} from "../../../utils";
import {FormControl, FormControlLabel, Radio, RadioGroup, Typography} from "@material-ui/core";
import {
  userCanDeleteClient,
  userCanEditClient,
  userisMultiClient,
} from "../../../api-client/core/authentication/utils";
import {LicensePlanDetails} from "../../LicensePlans/All/Table";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import {PlaylistAdd, DonutLarge} from "@material-ui/icons";
import AddCatalogDialog from "./AddCatalogDialog";
import {onImageError} from "../../../layouts/Home/HomeElements/utils";
import QuotaDialog from "./QuotaDialog";

export const types = [
  {id: "ttm", name: "TTM"},
  {id: "feater", name: "Feater"},
];

class ClientsTable extends Component {
  state = {
    total: "",
    showClients: false,
    clientCountries: [],
    managerClients: [],
    clientQuota: 0
  };

  componentDidMount = () => {
    this.setState({ showClients: eval(localStorage.getItem("showClients")) });
    request.client.getCountries().then(clientCountries => this.setState({clientCountries}));
    request.client.getAll({filterBy: [{column: "managers", filter: true}]})
      .then(response => {
        let managers = []
        response.forEach(client => {
            managers.push({value: client.id, name: client.name})
        })
        this.setState({managerClients: managers})
      })
  };

  reloadTable = () => this.refs.table.forceUpdate();

  handleAlertClosed = musicId => status => {
    if (status === "passed") this.refs.table.removeById(musicId);
  };

  buttons = client => {
    const builder = new TableButtonsBuilder();

    builder.withOther(<TableButton title={localization.get('client.quota')} onClick={() => this.setState({showQuotaDialog: true, clientId: client.id, clientName: client.name, clientQuota: client.total })}>
      <DonutLarge />
    </TableButton>)

    builder.withOther(
      <TableButton title={localization.get('catalog.add_catalog')} onClick={() => this.setState({showAddCatalogDialog: true, clientId: client.id})}>
        <PlaylistAdd/>
      </TableButton>
    )

    if (client.type !== 'trial') {
      userCanEditClient(client.id) && builder.withEdit(makeUrlWithTableParams(`/panel/clients/${client.id}/edit`));
      userCanDeleteClient() && builder.withDelete(client.name, () => request.client.delete(client.id), this.handleAlertClosed(client.id), true);
    }


    return builder.build();
  };

  columns = () => {
    let columns = []

    const imageColumns =
      {
        id: "image",
        accessor: client => (<img alt="Client" src={makeFitImageUrl(client, 50, 50)} width={50} onError={onImageError}/>),
        style: {
          float: "left",
        },
        resizeable: false,
        sortable: false,
        filterable: false,
        width: 60,
      };

    const nameColumn =
      {
        Header: localization.get("client.name"),
        accessor: "name",
        Filter: ({ filter, onChange }) => (
          <input
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ""}
            placeholder={localization.get('table.search')}
          />
        ),
      };

    const typeColumn =
      {
        Header: localization.get("catalog.owner"),
        id: "type",
        accessor: client => localization.get(`client_type.${client.type}`),
        Filter: ({ filter, onChange }) => (
          <select
            style={{ width: "100%" }}
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ""}
          >
            <option value="" selected />
            {
              types.sort((a, b) => a.name.localeCompare(b.name))
                .map(type => <option value={type.id}>{type.name}</option>)
            }
          </select>
        ),
      };


    const countryColumn =
      {
        Header: localization.get("client.country"),
        id: "country[id]",
        accessor: client => client.country ? client.country.name : "",
        Filter: ({ filter, onChange }) => (
          <select
            style={{ width: "100%" }}
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ""}
          >
            <option value="" selected />
            {this.state.clientCountries.map(country => {
              return <option value={country.id}>{country.name}</option>;
            })}
          </select>
        ),
      };

      const convertToGigabytes = (bytes) => {
        const gigabytes = bytes / (1000000000);
        return Number(gigabytes.toFixed(2));
      }
      const quotaColumn =
      {
        Header: localization.get("client.rekognition_requests"),
        id: "rekognition_counter",
        accessor: client => client.rekognition_counter,
        filterable: false,
        sortable: true,
        style: {
          textAlign: "right",
        },
      };

    const actionColumn =
      {
        Header: localization.get('table.actions'),
        id: "actions",
        sortable: false,
        filterable: false,
        accessor: this.buttons,
        width: 190,
        Footer: (
          <span style={{ float: "right", fontSize: "18px", marginTop: "15px" }}>
          <strong>Total: </strong>
            {this.state.total}
        </span>
        ),
      };

    columns.push(imageColumns);
    columns.push(nameColumn);
    columns.push(typeColumn);
    columns.push(countryColumn);
    columns.push(quotaColumn);
    columns.push(actionColumn);

    return columns
  }

  render = () => {
    return (
      <div>
        {!userisMultiClient() &&
        <FormControl style={{width: "100%", float: "left", position: "relative"}}>
          <RadioGroup
            name="showClients"
            value={this.state.showClients}
            onChange={event => {
              localStorage.setItem("showClients", `"${event.target.value}"`)
              this.setState({ showClients: event.target.value })
              this.refs.table.forceUpdate();
            }}
            style={{ width: "100%", float: "left", position: "relative", flexDirection: "row" }}
          >
            <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="all" control={<Radio color="default" />} label={localization.get('clients.all')} />
            <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="active" control={<Radio color="default" />} label={localization.get('clients.active')} />
            <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="inactive" control={<Radio color="default" />} label={localization.get('clients.inactive')} />
          </RadioGroup>
        </FormControl>
        }

        <div style={{ width: "100%", float: "left", position: "relative" }}>
          <ServerSidePaginationTable
            ref="table"
            columns={this.columns()}
            subComponent={row => (
              <div>
                <br />
                <Typography variant='title'>{localization.get('license_plans.title')}</Typography>
                <Typography variant='subheading'>{row.original.licensePlan.name}</Typography>
                <LicensePlanDetails licensePlan={row.original.licensePlan} />
              </div>
            )}
            requestTableData={paginationConfig => {

              if (this.state.showClients === "active") {
                paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'demo', filter: false }]) }
              } else if (this.state.showClients === "inactive") {
                paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'demo', filter: true }]) }
              }

              return request.client.getAll(paginationConfig).then(response => {
                this.setState({ total: response.meta.total});
                if (userisMultiClient()) {
                  response.data.forEach(client => client.expanded = false);
                }
                return response;
              });
            }}
          />
        </div>
        <AddCatalogDialog
          show={this.state.showAddCatalogDialog}
          clientId={this.state.clientId}
          handleClose={() => this.setState({showAddCatalogDialog: false})}
        />
        {this.state.showQuotaDialog && this.state.clientId &&
        <QuotaDialog
          show={this.state.showQuotaDialog}
          clientId={this.state.clientId}
          clientName={this.state.clientName}
          handleClose={() => this.setState({showQuotaDialog: false, clientQuota: 0})}
          clientQuota={this.state.clientQuota}
        />
        }
      </div>
    );
  };
}

export default ClientsTable;

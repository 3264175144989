import {SelectValidator, TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import localization from "../../../../config/localization";
import React from "react";
import {DateTimePicker, MuiPickersUtilsProvider} from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import {Event} from "@material-ui/icons";
import moment from "moment";
import {Button, Checkbox, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup} from "@material-ui/core";
import FilePondUploader from "../../../../components/Letflow/FilePondUploader";
import {ErrorMessage} from "formik";
import styled from "styled-components";
import {ImagePicker, VideoPicker} from "../../../../components/Letflow/Form";
import AsyncSelect from "react-select/lib/Async";
import {request} from "../../../../api-client";
import AvailableLanguages from "../../../../config/localization/availableLanguages.json";
import TranslatableInputs from "../../../../components/Letflow/TranslatableInputs";
import RegionSelector from "../../../../components/Letflow/RegionSelector";


const formNewUrl = (path) => {
  return process.env.REACT_APP_CDN_HOST.concat('/' + path)
}

const FormView = (formikProps) => {
  const {values, handleChange, setFieldValue, handleSubmit, resetForm} = formikProps
  return (
    <div style={{padding: '0 25px'}}>
      <ValidatorForm onSubmit={() => handleSubmit()}>
        <TranslatableInputs
          isEditorialContent
          color="dark"
          children={AvailableLanguages.map(language => {
            const currentLangTranslation = values.translations.find(tr => tr.language === language);

            return {
              language: language,
              content: [
                <TextValidator
                  style={{width: "100%", marginBottom: "20px"}}
                  id={`name${language}`}
                  label={localization.get('form.name')}
                  name={`name${language}`}
                  value={currentLangTranslation.name}
                  onChange={e => {
                    const translations = values.translations.map(tr => {
                      if (tr.language === language) {
                        return {...tr, name: e.target.value}
                      }
                      return tr
                    });

                    return setFieldValue('translations', translations)
                  }}
                />,
                <TextValidator
                  style={{width: "100%", marginBottom: "20px"}}
                  id={`description${language}`}
                  label={localization.get('form.description')}
                  name={`description${language}`}
                  value={currentLangTranslation.description}
                  onChange={e => {
                    const translations = values.translations.map(tr => {
                      if (tr.language === language) {
                        return {...tr, description: e.target.value}
                      }
                      return tr
                    });

                    return setFieldValue('translations', translations)
                  }}
                />
              ]
            }
          })}
        />
      <br/>
        {!values.isEnded ?
          <>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateDiv style={{width: "100%"}}>
              <DateTimePicker
                autoOk
                autoSubmit
                disablePast
                format={"dd/MM/yyyy hh:mm a"}
                dateRangeIcon={<Event/>}
                label={localization.get('livestream.timer')}
                value={values.timer}
                onChange={(value) => setFieldValue('timer', value)}
              />
              <ErrorMessage name="startTime" className="web-like-error">
                {msg => <div className="web-like-error font12">{msg}</div>}
              </ErrorMessage>
            </DateDiv>
          </MuiPickersUtilsProvider>
          <br/>
          <br/>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateDiv>
          <DateTimePicker
          autoOk
          autoSubmit
          disablePast
          format={"dd/MM/yyyy hh:mm a"}
          dateRangeIcon={<Event/>}
          label={localization.get('livestream.start_time')}
          value={values.startTime}
          onChange={(value) => setFieldValue('startTime', value)}
          />
          <ErrorMessage name="startTime" className="web-like-error">
        {msg => <div className="web-like-error font12">{msg}</div>}
          </ErrorMessage>
          </DateDiv>
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateDiv style={{marginLeft: 45}}>
          <DateTimePicker
          autoOk
          disablePast
          format={"dd/MM/yyyy hh:mm a"}
          dateRangeIcon={<Event/>}
          label={localization.get('livestream.end_time')}
          value={values.endTime}
          onChange={(value) => setFieldValue('endTime', value)}
          minDate={values.startTime}
          disabled={!values.startTime}
          />
          <ErrorMessage name="endTime" className="web-like-error">
        {msg => <div className="web-like-error font12">{msg}</div>}
          </ErrorMessage>
          </DateDiv>
          </MuiPickersUtilsProvider>

      <FormControl style={{marginTop: 10}}>
        <RadioGroup
          name="radio"
          value={values.radio}
          onChange={e => {
            setFieldValue('radio', e.target.value)
            setFieldValue('videoId', null)
            setFieldValue('startImageId', null)
            setFieldValue('endImageId', null)
            setFieldValue('streamId', '')
            setFieldValue('source', '')
            setFieldValue('fake', e.target.value === 'fake' ? 1 : 0)
          }}
          style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
        >
          <FormControlLabel value="live" control={<Radio color="default"/>} label={localization.get('livestream')}/>
          <FormControlLabel value="fake" control={<Radio color="default"/>} label={localization.get('livestream.fake')}/>
        </RadioGroup>
      </FormControl>

      <FormControl style={{display: 'block'}}>
        <SelectValidator
          label={localization.get("form.source")}
          id="source"
          name="source"
          value={values.source}
          onChange={e => {
            setFieldValue('source', e.target.value)
          }}
          style={{width: 175}}
          validators={['required']}
          errorMessages={[localization.get('validator.is_required')]}
        >
          {values.radio === 'live' && <MenuItem value="youtube">{localization.get('form.youtube')}</MenuItem>}
          {values.radio === 'live' && <MenuItem value="vimeoLive">{localization.get('form.vimeo')}</MenuItem>}
          {values.radio === 'fake' && <MenuItem value="vimeo">{localization.get('form.vimeo')}</MenuItem>}
          {values.radio === 'fake' && values.allowFakeLivestreams && <MenuItem value="bloomclouds">{localization.get('client.flowlike')}</MenuItem>}
        </SelectValidator>
      </FormControl>

        <FormControl >
          <FormControlLabel
            control={
              <Checkbox
                checked={values.showCountdown}
                onChange={() => {
                  setFieldValue('showCountdown', !values.showCountdown)
                }}
                color="default"
              />
            }
            label={localization.get("show_countdown")}
          />
        </FormControl>

      {values.source &&
        <>
          {values.source !== 'bloomclouds' ?
            <>
              <TextValidator
                style={{width: "100%", margin: "20px 0"}}
                id="streamId"
                label={localization.get(values.source === 'youtube' ? 'form.stream_youtube_id' : 'form.stream_vimeo_url')}
                name="streamId"
                value={values.streamId}
                onChange={handleChange}
                validators={['required']}
                errorMessages={[localization.get('validator.is_required')]}
              />
              <ErrorMessage name="streamId" className="web-like-error">
                {msg => <div className="web-like-error font12">{msg}</div>}
              </ErrorMessage>
            </>
            :
            <>
              <br/>
              <div style={{fontSize: 16}}>{localization.get('video')}</div>
              <ErrorMessage name="videoId" className="web-like-error">
                {msg => <div className="web-like-error font12">{msg}</div>}
              </ErrorMessage>
              {values.video && values.showPreview && <VideoPicker video={values.video.src} previewOnly={true}/>}
              <FilePondUploader
                disableForm={values.disabled}
                updateDisableForm={value => setFieldValue('disabled', value)}
                id={"video"}
                type={"video"}
                allowedTypes={["video/mp4"]}
                onIdChange={video => {
                  setFieldValue('videoId', video ? video.id : null)
                  setFieldValue('videoPreview', video ? formNewUrl(video.path) : null)
                }}
                setPreview={() => values.onSetPreview(values, setFieldValue, 'video')}
                channelId={values.channelId}
              />
            </>
          }

          <div style={{fontSize: 16}}>{localization.get('livestream.start_image')} ({localization.get("livestreams.images_helper")})</div>
          <ErrorMessage name="startImageId" className="web-like-error">
            {msg => <div className="web-like-error font12">{msg}</div>}
          </ErrorMessage>
          {values.startImage && values.showPreview && <ImagePicker image={values.startImage.src} previewOnly={true} id={values.startImage.id} />}
          <FilePondUploader
            channelId={values.channelId}
            disableForm={values.disabled}
            updateDisableForm={value => setFieldValue('disabled', value)}
            id={"startImage"}
            type={"image"}
            allowedTypes={["image/*"]}
            onIdChange={image => {
              setFieldValue('startImageId', image ? image.id : null)
              setFieldValue('startImagePreview', image ? formNewUrl(image.path) : null)
            }}
            setPreview={() => values.onSetPreview(values, setFieldValue, 'startImage')}
          />

          <div style={{fontSize: 16}}>{localization.get('livestream.end_image')} ({localization.get("livestreams.images_helper")})</div>
          <ErrorMessage name="endImageId" className="web-like-error">
            {msg => <div className="web-like-error font12">{msg}</div>}
          </ErrorMessage>
          {values.endImage && values.showPreview && <ImagePicker image={values.endImage.src} previewOnly={true} id={values.endImage.id}/>}
          <FilePondUploader
            disableForm={values.disabled}
            updateDisableForm={value => setFieldValue('disabled', value)}
            id={"endImage"}
            type={"image"}
            allowedTypes={["image/*"]}
            onIdChange={image => {
              setFieldValue('endImageId', image ? image.id : null)
              setFieldValue('endImagePreview', image ? formNewUrl(image.path) : null)
            }}
            setPreview={() => values.onSetPreview(values, setFieldValue, 'endImage')}
            channelId={values.channelId}
          />
        </>
      }

        <FormControl fullWidth style={{marginTop: 10}}>
          <span>{localization.get('livestream.content_creator')}</span>
          <AsyncSelect
            styles={{
              menu: base => ({
                ...base,
                zIndex: 10
              })
            }}
            theme={theme => ({
              ...theme,
              borderRadius: 0
            })}
            placeholder={localization.get('conversation.filter_users')}
            loadingMessage={() => localization.get('loading')}
            noOptionsMessage={() => localization.get('no_options')}
            defaultOptions
            value={values.selectedContentCreator}
            onChange={(selectedContentCreator) => {
              setFieldValue('selectedContentCreator', selectedContentCreator)
            }}
            loadOptions={inputValue =>
              request.contentCreator
                .getAll({ filterBy: [{ column: "name", filter: inputValue }, { column: "channel_id", filter: values.channelId }] })
                .then(res => res.map(contentCreator => ({ value: contentCreator.id, label: `${contentCreator.name} - (${contentCreator.type.name})` })))
            }
          />
        </FormControl>

        <div style={{width: "100%"}} >
          <div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.hasSuggestedCarousel}
                    onChange={() => setFieldValue('hasSuggestedCarousel', !values.hasSuggestedCarousel)}
                    color="default"
                  />
                }
                label={localization.get('form.has_suggested_carousel_talent')}
              />
            </div>

            {values.hasSuggestedCarousel &&
              <>
                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 10
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0
                  })}
                  placeholder={localization.get('conversation.filter_users')}
                  loadingMessage={() => localization.get('loading')}
                  noOptionsMessage={() => localization.get('no_options')}
                  onChange={suggestedCarouselPlaylist => setFieldValue('suggestedCarouselPlaylist', suggestedCarouselPlaylist)}
                  value={values.suggestedCarouselPlaylist}
                  loadOptions={inputValue => {
                    let filterBy = [
                      { column: "title", filter: inputValue },
                      { column: 'channel_lists', filter: true },
                      { column: "channel_id", filter: values.channelId },
                    ];

                    return request.playlist.getAll({orderBy: {column: "title", type: "ASC"}, filterBy})
                      .then(lists => lists.map(list => ({value: list.id, label: list.title})))
                  }}
                  defaultOptions
                />

                <br/>
                <FormControl >
                  <div>{localization.get('form.view_type')}</div>
                  <RadioGroup
                    id="suggestedView"
                    name="suggestedView"
                    value={values.suggestedView}
                    onChange={handleChange}
                    style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                  >
                    <FormControlLabel value="16:9" control={<Radio color="default"/>}
                                      label={"16:9"}/>
                    <FormControlLabel value="square" control={<Radio color="default"/>}
                                      label={localization.get('shape.square')}/>
                    <FormControlLabel value="round" control={<Radio color="default"/>}
                                      label={localization.get('shape.round')}/>
                  </RadioGroup>
                </FormControl>
              </>
            }
          </div>
        </div>
          </>
          :
          <FormControl fullWidth style={{marginTop: 10}}>
            <span>{localization.get('livestream.content_creator')}</span>
            <AsyncSelect
              styles={{
                menu: base => ({
                  ...base,
                  zIndex: 10
                })
              }}
              theme={theme => ({
                ...theme,
                borderRadius: 0
              })}
              placeholder={localization.get('conversation.filter_users')}
              loadingMessage={() => localization.get('loading')}
              noOptionsMessage={() => localization.get('no_options')}
              defaultOptions
              value={values.selectedContentCreator}
              onChange={(selectedContentCreator) => {
                setFieldValue('selectedContentCreator', selectedContentCreator)
              }}
              loadOptions={inputValue =>
                request.contentCreator
                  .getAll({ filterBy: [{ column: "name", filter: inputValue }, { column: "channel_id", filter: values.channelId }] })
                  .then(res => res.map(contentCreator => ({ value: contentCreator.id, label: `${contentCreator.name} - (${contentCreator.type.name})` })))
              }
            />
          </FormControl>
        }

        <FormControl >
          <FormControlLabel
            control={
              <Checkbox
                checked={values.hasToken}
                onChange={() => {
                  if (values.hasToken) setFieldValue('allowedTokens', "")
                  setFieldValue('hasToken', !values.hasToken)
                }}
                color="default"
              />
            }
            label={localization.get("livestream.has_tickets")}
          />
        </FormControl>

        {values.hasToken &&
          <TextValidator
            style={{ width: "100%", marginBottom: "20px" }}
            id="allowedTokens"
            label={localization.get('livestream.allowed_tickets')}
            name="allowedTokens"
            helperText={localization.get('livestream.allowed_tickets.helper')}
            value={values.allowedTokens}
            onChange={(event) => {
              if(/[a-z-0-9\,]+$/.test(event.target.value) || event.target.value === ",") handleChange(event);
            }}
          />
        }
        <div style={{marginTop: 10}}>
          <h4>{localization.get('form.region')} {values.allowedRegions.length > 0 && <span style={{fontSize: 12}}>{localization.get('continents.country.selected', values.allowedRegions.length)}</span>}</h4>
          <FormControl>
            <RadioGroup
              name="hasRegionBlock"
              value={values.hasRegionBlock}
              onChange={e => setFieldValue("hasRegionBlock", e.target.value)}
              style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
            >
              <FormControlLabel style={{width: '200px'}} value="false" control={<Radio color="default"/>} label={localization.get('form.all_world')}/>
              <FormControlLabel style={{width: '200px'}} value="true" control={<Radio color="default"/>} label={localization.get('form.has_region_block')}/>
            </RadioGroup>
          </FormControl>

          {values.hasRegionBlock === "true" && <RegionSelector selectedCountries={values.allowedRegions} onChange={(values) => setFieldValue('allowedRegions', values)} />}
        </div>

      <div style={{margin: '20px 0 5px', float: 'right'}}>
        <Button onClick={() => values.handleCloseForm(resetForm)} color='default'>{localization.get("cancel")}</Button>
        <Button type="submit" color='info' disabled={values.disabled}>{localization.get("form.schedule")}</Button>
      </div>
      </ValidatorForm>
    </div>
  )
}

export default FormView

const DateDiv = styled.div`
  display: inline-flex;
  flex-direction: column;
`

import "./TopContainer.css";
import React, {Component} from "react";
import styled from "styled-components";
import localization from "../../../config/localization";
import {getActiveChannel, getStoredChannel} from "../../../api-client/core/authentication/utils";

export const TopContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: ${props => props.alternative ? 'column' : 'row'};
  @media (max-width: 600px) {
    flex-direction: ${props => props.alternative ? 'column' : 'column-reverse'};
    padding: 0px 10px;
  }
`;

export const InfoContainer = styled.div`
  margin-top: ${props => props.alternative ? '20px' : '150px'};
  @media (max-width: 600px) {
    margin-top: 20px;
  }
`;

export const Name = styled.h1`
  flex: 1;
  margin: 0px 10px 25px 0px;
  color: white;
  font-size: 2em;
  line-height: 1em;
  @media (min-width: 600px) {
    font-size: 3.5em;
    line-height: 1.5em;
  }
`;

export const Country = styled.p`
  flex: 1;
  color: ${props => props.color || "white" };
  font-size: 15px;
  margin-bottom: 5px!important;
`

export const Birthdate = styled.p`
  flex: 1;
  color: white;
  font-size: 15px;
  margin-bottom: 20px!important;
`

export class Description  extends Component{
  constructor(props) {
    super(props);

    this.state = {
      isOverFlow: false,
      isExpanded: false
    }

    this.refOverFlow = null;
    this.setIsOverFlow = element => {
      this.refOverFlow = element
    }

    this.checkHeight = () => {
      if (this.refOverFlow) {
        this.setState({isOverFlow: this.refOverFlow.scrollHeight > this.refOverFlow.clientHeight})
      }
    }
  }

  componentDidMount() {
    this.checkHeight()
  }

  render = () => (
    <>
    <div
      id={'audiovisual-creator-description'}
      ref={this.setIsOverFlow}
      style={{
        //maxWidth: "800px",
        marginRight: "20px",
        color: this.props.color,
        fontSize: 15,
        paddingTop: 25,
        borderTop: "1px solid #ffffff59",
        maxHeight: (!this.props.useShowMore || this.state.isExpanded || this.props.height === "100%") ? "100%" : 170,
        overflow: 'hidden'
      }}
      dangerouslySetInnerHTML={{ __html: this.props.text }}
    />
      {this.state.isOverFlow && this.props.height === '175px' && this.props.useShowMore &&
      <SpanTopButton
        onClick={() => {
          this.setState({isExpanded: !this.state.isExpanded})
          this.props.onWidthChange()
        }}
        color={this.props.color}
      >
        <p style={{textAlign: "center"}}>{localization.get(this.state.isExpanded ? 'spotlight.see_less' : 'see_more')}</p>
      </SpanTopButton>
      }
    </>
  );
}

export class BottomDescription  extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOverFlow: false,
      isExpanded: false
    }

    this.refOverFlow = null;
    this.setIsOverFlow = element => {
      this.refOverFlow = element
    }
    this.checkHeight = () => {
      if (this.refOverFlow) {
        this.setState({isOverFlow: this.refOverFlow.scrollHeight > this.refOverFlow.clientHeight})
      }
    }
  }

  componentDidMount() {
    this.checkHeight()
  }

  render = () => (
    <>
      <div
        ref={this.setIsOverFlow}
        style={{
          marginRight: "20px",
          paddingTop: 25,
          fontSize: window.innerWidth < 650 ? 12 : 18,
          color: this.props.color || 'black',
          maxHeight: this.state.isExpanded || this.props.height === "100%" ? "100%" : 170,
          overflow: 'hidden'
        }}
        dangerouslySetInnerHTML={{__html: this.props.text}}
      />
      {this.state.isOverFlow && this.props.height === '175px' &&
      <SpanBotButton
        onClick={() => this.setState({isExpanded: !this.state.isExpanded})}>{localization.get(this.state.isExpanded ? 'spotlight.see_less' : 'see_more')} </SpanBotButton>
      }
    </>
  );
}

const SpanBotButton = styled.span`
  color: rgba(255,255,255,255.6);
  cursor: pointer;
  font-size: 20px;
  &:hover {
    text-decoration: underline;
  }
`

const SpanTopButton = styled.div`
  color: ${props => props.color ? props.color : getActiveChannel() ? getStoredChannel().preferences.container_description.color_hover : '#fff'};
  cursor: pointer;
  font-size: 16px;
  border: 1px solid ${props => props.color ? props.color : getActiveChannel() ? getStoredChannel().preferences.container_description.color_hover : '#fff'};
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  padding: 3px 5px;
  width: 160px;
  position: relative;
  left: 80px;
  float: left;
  top: 30px;
  @media (hover: hover) and (pointer:fine) {
    &:hover {
      border: 1px solid ${props => props.color ? props.color : getActiveChannel() ? getStoredChannel().preferences.container_description.color_hover : '#000'};
      background-color: ${props => props.color ? 'rgba(0, 0, 0, 0.2)' : '#000'};
      color: ${props => props.color ? props.color : getActiveChannel() ? getStoredChannel().preferences.container_description.color_hover : '#fff'};
    }
  }
`


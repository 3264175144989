import getAll from './getAll'
import getAllWithoutArtist from './getAllWithoutArtist'
import getAllForClient from './getAllForClient'
import create from './create'
import del from './delete'
import update from './update'
import get from './get'
import favorites from './favorites'
import notify from './notify'
import searchFor from './searchFor'
import exportUsers from './exportUsers'
import getArtistCandidates from './getArtistCandidates'
import globalSearch from './globalSearch'
import updateLinkNotification from './updateLinkNotification'
import createFromDemo from './createFromDemo'
import channelFavorites from './channelFavorites'
import notifyChannel from './notifyChannel'
import getDownloadLink from './getDownloadLink'
import completeForm from './completeForm'
import completeFormUnlogged from './completeFormUnlogged'
import makeAdmin from './makeAdmin'
import updateAddress from './updateAddress'
import acceptTermsOfUseForChannel from './acceptTermsOfUse'
import resetPassword from './resetPassword'
import hasAccessLevel from './hasAccessLevel'
import getRecentSearch from './getRecentSearch'
import addRecentSearch from './addRecentSearch'
import globalEntitySearch from './globalEntitySearch'

import {get as _get, post} from '../utils';

const filtersToQuery = filters =>
  filters.reduce((acc, next) => {
    const [column, value] = next.split(':')
    return acc + '&' + column + '=' + value
  }, '')

export default host => ({
  getAll: getAll(host),
  getArtistCandidates: getArtistCandidates(host),
  getAllWithoutArtist: getAllWithoutArtist(host),
  getAllForClient: getAllForClient(host),
  create: create(host),
  delete: del(host),
  update: update(host),
  get: get(host),
  channelFavorites: channelFavorites(host),
  favorites: favorites(host),
  notify: notify(host),
  notifyChannel: notifyChannel(host),
  searchFor: searchFor(host),
  exportUsers: exportUsers(host),
  globalSearch: globalSearch(host),
  updateLinkNotification: updateLinkNotification(host),
  createFromDemo: createFromDemo(host),
  getDownloadLink: getDownloadLink(host),
  makeAdmin: makeAdmin(host),
  resetPassword: resetPassword(host),

  acceptTermsOfUse: () => post(`${host}/user/acceptTermsOfUse`),

  listFlowlikeAdmins: (page = 1, perPage = 12, filters = []) =>
    _get(`${host}/messaging/users/administrator?page=${page}&per_page=${perPage}${filtersToQuery(filters)}`)
      .then(res => res.json()),

  listClientUsers: (clientsIds, page = 1, perPage = 12, filters = []) =>
    _get(`${host}/messaging/users/user?${clientsIds.map(clientId => `clients_ids[]=${clientId}`).join('&')}&page=${page}&per_page=${perPage}${filtersToQuery(filters)}`)
      .then(res => res.json()),

  createProjectInvitee: (email, pitch_id) =>
    post(`${host}/users/storeProjectParticipant`, JSON.stringify({ email, pitch_id })),

  getAdminNotifications: () => _get(`${host}/user/notifications`).then(res => res.json()),

  completeForm: completeForm(host),
  completeFormUnlogged: completeFormUnlogged(host),
  updateAddress: updateAddress(host),
  acceptTermsOfUseForChannel: acceptTermsOfUseForChannel(host),
  hasAccessLevel: hasAccessLevel(host),
  getRecentSearch: getRecentSearch(host),
  addRecentSearch: addRecentSearch(host),
  globalEntitySearch: globalEntitySearch(host)
})

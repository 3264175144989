import React from "react";
import { channelPathGenerator, getActiveChannel, getStoredChannel } from "../api-client/core/authentication/utils";
import MyList from "../layouts/Channel/MyList";
import SmartSearch from "../layouts/Channel/SmartSearch";
import SmartSearchContentCreator from "../layouts/Home/SmartSearch/SmartSearchContentCreator";
import SmartSearchContentCreation from "../layouts/Home/SmartSearch/SmartSearchContentCreation";
import SmartSearchMusic from "../layouts/Home/SmartSearch/SmartSearchMusic";
import Music from "../layouts/Channel/Music";
import ContentCreator from "../layouts/Home/ContentCreator";
import PublicContentCreation from "../layouts/Channel/PublicContentCreation";
import PublicReel from "../views/PublicReel";
import Album from "../layouts/Home/Album";
import Artist from "../layouts/Home/Artist";
import Browse from "../layouts/Channel/Browse";
import Contact from "../layouts/Home/Contact";
import LiveStreamCarousel from "../layouts/Channel/LiveStream/Carousel";
import LiveStream from "../layouts/Channel/LiveStream";
import MyProfile from "../layouts/Channel/Profile";
import ErrorPage from "../layouts/Channel/ErrorPage";
import ChannelPlaylist from "../layouts/Channel/Playlist";
import TalentProfile from "../layouts/Channel/TalentProfile";
import Drive from "../layouts/Home/Drive";
import Reels from "../layouts/Home/Reels";
import Links from "../layouts/Home/Links";
import Projects from "../layouts/Home/Projects";
import HomePlaylist from "../layouts/Home/Playlists/HomePlaylist";
import Account from "../layouts/Home/Account";
import MyPurchases from "../layouts/Channel/MyPurchases";
import ClaimGift from "../layouts/Channel/ClaimGift";
import { request } from "../api-client";
import browserHistory from "../utils/browserHistory";
import HomePlaylistUpdate from "../layouts/Home/Playlists/HomePlaylistUpdate";
import HomePlaylists from "../layouts/Home/Playlists/HomePlaylists";
import localization from "../config/localization";
import PublicPlaylist from "../views/PublicPlaylist";
import Search from "../layouts/Channel/Search";
// import Embed from "../components/EmbeddablePlayer";

export const getChannelRoutes = (channel) => {
  const _MyList = (props) => <MyList channel={channel} {...props}/>
  const _SmartSearch = (props) => <SmartSearch channel={channel} {...props}/>
  const _SmartSearchContentCreator = (props) => <SmartSearchContentCreator channel={channel} searchType="content" {...props}/>
  const _SmartSearchEvents = (props) => <SmartSearchContentCreator channel={channel} searchType="event" {...props}/>
  const _SmartSearchContentCreation = (props) => <SmartSearchContentCreation channel={channel} {...props}/>
  const _SmartSearchMusic = props => <SmartSearchMusic channel={channel} {...props}/>
  const _Music = (props) => <Music channel={channel} {...props}/>
  const _ContentCreator = (props) => <ContentCreator contentCreatorId={props.match.params.contentCreatorId}
                                              channel={channel} {...props}/>
  const _PublicContentCreator = (props) => <ContentCreator channel={channel} contentCreatorId={props.match.params.contentCreatorId} {...props} isPublic={true}/>
  const _PublicContentCreation = (props) => <PublicContentCreation channel={channel} contentCreationId={props.match.params.contentCreationId} {...props} isPublic={true}/>
  const _PublicReel = (props) => <PublicReel channel={channel} {...props} isPublic={true}/>
  const _Album = (props) => <Album albumId={props.match.params.albumId} channel={channel} {...props}/>
  const _Artist = (props) => <Artist artistId={props.match.params.artistId} channel={channel} {...props}/>
  const _Browse = (props) => <Browse elementId={props.match.params.elementId} channel={channel} {...props}/>
  const _Contact = props => <Contact onSubmit={(name, email, description) => request.channel.contact(name, email, description)}
                              afterSubmit={() => browserHistory.push(channelPathGenerator(``))} {...props}/>
  const _LiveStreamCarousel = props => <LiveStreamCarousel channel={channel} {...props}/>
  const _LiveStream = props => <LiveStream channel={channel} {...props}/>
  const _MyProfile = (props) => <MyProfile channel={channel} {...props}/>
  const _ErrorPage = (props) => <ErrorPage channel={channel} {...props}/>
  const _ChannelPlaylist = (props) => <ChannelPlaylist channel={channel} {...props}/>
  const _TalentProfile = (props) => <TalentProfile channel={channel} {...props}/>
  const _Drive = props => <Drive client={channel.client} {...props}/>
  const _Reels = props => <Reels client={channel.client} {...props}/>
  const _Links = props => <Links client={channel.client} {...props}/>
  const _Projects = (props) => <Projects client={channel.client} {...props}/>
  const _ClientPlaylist = props => <HomePlaylist client={channel.client} {...props}/>
  const _Account = (props) => <Account client={channel.client} {...props} />
  const _MyPurchases = (props) => <MyPurchases channel={channel} {...props}/>
  const _ClaimGift = (props) => <ClaimGift channel={channel} {...props}/>
  const _PublicPlaylist = (props) => <PublicPlaylist channel={channel} token={props.match.params.token} {...props}/>
  const _Search = (props) => <Search channel={channel} {...props}/>
  // const _Embed = (props) => <Embed channel={channel} {...props}/>

  let routes = [
    {path: "/lista/:playlistId-:playlistName", component: _ChannelPlaylist},
    {path: "/artista/:artistId-:artistName", component: _Artist},
    {path: "/album/:albumId-:albumTitle", component: _Album},
    {path: "/album/:albumId", component: _Album},
    {path: "/browse/:elementId-:elementName", component: _Browse},
    {path: "/contacto", component: _Contact},
    {path: "/vivos", component: _LiveStreamCarousel},
    {path: "/vivo/:livestreamId-:livestreamName", component: _LiveStream},
    {path: "/contenido/:contentCreatorId-:contentCreatorName", component: _ContentCreator},
    {path: "/public/content_creators/:contentCreatorId", component: _PublicContentCreator},
    {path: "/public/content_creations/:contentCreationId", component: _PublicContentCreation},
    {path: "/public/playlists/:token-:playlistName", component: _PublicPlaylist},
    {path: "/public/reel/:reelId", component: _PublicReel},
    // {path: "/public/embed/:contentId", component: _Embed},
    
    {path: "/mi-lista", component: _MyList},
    {path: "/error-page", component: _ErrorPage},
    {path: "/musica/:musicId-:musicTitle", component: _Music},
    {path: "/mi_perfil", component: _MyProfile},
    {path: "/mis-compras", component: _MyPurchases},
    {path: "/busqueda-inteligente/content_creations", component: _SmartSearchContentCreation},
    {path: "/busqueda-inteligente/contenido/:contentId-:contentName", component: _SmartSearchContentCreator},
    {path: "/busqueda-inteligente/eventos/:contentId-:contentName", component: _SmartSearchEvents},
    
    {path: "/busqueda-inteligente/musica", component: _SmartSearchMusic},
    {path: "/busqueda-inteligente", component: _SmartSearch},
    {path: "/claim", component: _ClaimGift},
    {path: "/busqueda-global", component: _Search},
  ];

  if (channel.use_tools) {
    routes.push({path: "/drive", component: _Drive});
    routes.push({path: "/reels", component: _Reels});
    routes.push({path: "/links", component: _Links});
    routes.push({path: "/projects", component: _Projects});
  }

  if (channel.use_tools || channel.use_playlists) {
    routes.push({path: "/playlist/:playlistId/edit", component: HomePlaylistUpdate});
    routes.push({path: "/playlists", component: HomePlaylists});
  }

  if (channel.use_tools || channel.use_licenses) {
    routes.push({path: "/account", component: _Account});
  }

  routes.push({path: "/playlist/:playlistId-:playlistName", component: _ClientPlaylist});
  routes.push({path: "/playlist/:playlistId", component: _ClientPlaylist});
  if (channel.community) routes.push({ path: "/profile", component: _TalentProfile});
  return routes;
}

export const routeHasTranslation = route => {
  const excluded = ['login', 'register', 'password', 'set-password', 'user', 'login-code', 'miba', 'gcba', 'panel']
  return !excluded.some(r => route.startsWith(`/${r}`));
}

export const navigateToCurrentLangRoute = (lang) => {
  localStorage.setItem("default_language", lang)
  localStorage.setItem("preferredLang", lang)
  localization.setLanguage(lang);

  let availableLangs = getActiveChannel() ? getStoredChannel().languages.map(lang => lang.language).filter(lang => lang !== 'es') : ['en']
  let currentUrl = window.location && `${window.location.pathname}${window.location.search}`;
  currentUrl = currentUrl.startsWith('/') ? currentUrl.slice(1,currentUrl.length) : currentUrl;
  let splitted = currentUrl.split('/');
  if(splitted.length > 0 && splitted[0] == 'channel') {
    splitted = splitted.slice(2,splitted.length);
  }
  let relativePath = splitted.join('/');
  if (routeHasTranslation(`/${relativePath}`)) {
    if (splitted.length > 0 && availableLangs.includes(splitted[0])) {
      splitted = splitted.slice(1,splitted.length);
    }
    if(splitted.length > 0 && splitted[0] == 'home') {
      splitted = splitted.slice(2,splitted.length);
    }
    relativePath = splitted.join('/');
    browserHistory.push(channelPathGenerator(relativePath));
    window.location.reload();
  }
}

export const getUrlLang = (languages = []) => {
  let availableLangs = languages.length > 0 ? languages : (getActiveChannel() ? getStoredChannel().languages.map(lang => lang.language).filter(lang => lang !== 'es') : ['en']);

  let currentUrl = window.location && `${window.location.pathname}`;
  currentUrl = currentUrl.startsWith('/') ? currentUrl.slice(1,currentUrl.length) : currentUrl;
  let splitted = currentUrl.split('/');
  if(splitted.length > 0 && splitted[0] == 'channel') {
    splitted = splitted.slice(2,splitted.length);
  }
  let relativePath = splitted.join('/');
  if (routeHasTranslation(`/${relativePath}`)) {
    if (splitted.length > 0 && availableLangs.includes(splitted[0])) {
      return splitted[0];
    } else {
      return 'es';
    }
  }
  return null;
}
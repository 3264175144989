import React, { Component } from 'react';
import { IconButton, ClickAwayListener} from '@material-ui/core';
import { Clear, ExpandMore } from '@material-ui/icons';
import Drawer from '@material-ui/core/Drawer';
import AsyncSelect from "react-select/lib/Async";
import localization from "../../../config/localization";
import CustomDateInput from "../../../components/Letflow/CustomDatePicker/index";
import FaceFilters from '../../../components/FaceFilters';
import { getActiveClient } from '../../../api-client/core/authentication/utils';

class FilterContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTags: [],
      showMoreFilters: false
    };
  }

  handleDrawerOpenClose = () => {
    this.props.handleOpenSidebar(!this.props.open);
  }

  render() {
    const {open} = this.props
    const rotate = open ? "rotate(90deg)" : "rotate(270deg)";
    const dateOptions = [
      {value: "today", label: localization.get("smart_search.today")},
      {value: "tomorrow", label: localization.get("smart_search.tomorrow")},
      {value: "thisWeekend", label: localization.get("smart_search.this_weekend")},
      {value: "thisWeek", label: localization.get("smart_search.this_week")},
      {value: "nextWeek", label: localization.get("smart_search.next_week")},
      {value: "next30days", label: localization.get("smart_search.next_thirty_days")},
      {value: "custom", label: localization.get("smart_search.range")},
    ]
    
    return (
      <div style={{display: "flex", alignItems: "stretch", marginTop: window.innerWidth > 600? 15: 0 }}>
        <ClickAwayListener onClickAway={() => window.innerWidth < 600 && this.props.open && this.props.handleOpenSidebar(false)}>
          <Drawer
              variant="permanent"
              open={open}
              PaperProps={{
                style: {          
                  width: open ? 275 : window.innerWidth > 600 ? 60 : 20,
                  flexShrink: '0',
                  borderRight: "none",
                  zIndex: "2",
                  backgroundColor: "transparent",
                  transition: 'margin, width 0.3s',
                  display: 'flex',
                  flexDirection: 'row',
                  paddingRight: 25,
                  zIndex: 1000,
                  position: window.innerWidth > 600 ? "relative": "absolute",
                  overflowX: "hidden"
                }
              }}
          >
             <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: 20, 
              paddingBottom: 20, 
              borderTopRightRadius: 20, 
              borderBottomRightRadius: 20, 
              boxShadow: '1px 1px 3px 0px rgba(0,0,0,.1)', 
              width: 25,
              height: 90,
              background: 'var(--main-color, white)',
              cursor: 'pointer',
              top: 'calc((100vh - 70px) / 2 - 45px)',
              position: 'absolute',
              left: open ? 249 : window.innerWidth > 600 ? 30 : 0,
              transition: 'left 0.3s',
            }} onClick={this.handleDrawerOpenClose}>
              <ExpandMore style={{fontSize: 32, transform: rotate}} />
            </div>
            <div style={{
              width: open ? 270 : window.innerWidth > 600 ? 30 : 0,
              backgroundColor: 'var(--main-color, white)', 
              boxShadow: "0 1px 10px 0 rgba(0,0,0,.1)",
              padding: open ? "10px 20px" : "10px 0",
              transition: 'width 0.3s',
              paddingTop: window.innerWidth > 600 ? 0 : 20,
            }}>
              <div style={{ display: "flex", justifyContent: "end", height: 50}}>
                {open && this.props.selectedTags && this.props.selectedTags.length > 0 &&
                  <IconButton onClick={this.props.onCleanSearch} style={{left: open ? 10: 0}}>
                    <Clear />
                  </IconButton>
                }
              </div>

              <div style={{visibility: open ? "visible" : "hidden", display: "flex", flexDirection: "column", transition: 'width 0.3s', minHeight: "90vh", overflowY: "auto", overflowX: "hidden"}}>
                {this.props.supportEventSearch && 
                  <>
                    <div className="sidebar-tag-group-title">{localization.get("navbar.search_by_events")}</div>
                    <AsyncSelect
                      key={"0"}
                      placeholder={localization.get("smart_search.event_search")}
                      styles={{
                        input: base => ({
                            ...base, 
                            margin: "5px 0",
                        }),
                        menu: base => ({
                            ...base,
                            zIndex: 10,
                            borderColor: "var(--sidebar-selector-font-color, black)",
                            backgroundColor: "var(--sidebar-selector-background-color, white)",
                            color: "var(--sidebar-selector-font-color, black)",
                        }),
                        control: (baseStyles, state) => {
                            return {
                            ...baseStyles,
                            borderColor: "rgba(0, 0, 0, 0)",
                            backgroundColor: "var(--sidebar-selector-background-color, white)",
                            color: "var(--sidebar-selector-font-color, black)",
                        }},
                        singleValue: styles => ({ ...styles, color: "var(--sidebar-selector-font-color, black)" }), 
                        valueContainer: styles => ({ ...styles, color: "var(--sidebar-selector-font-color, black)"}), 
                      }}
                      theme={theme => {
                        return {
                            ...theme,
                            backgroundColor: "var(--sidebar-selector-background-color, white)",
                            borderRadius: 5,
                            borderColor: "var(--sidebar-selector-font-color, black)",
                            backgroundColor: "var(--sidebar-selector-background-color, white)",
                            color: "var(--sidebar-selector-font-color, black)",
                        }
                      }}
                      style={{margin: "10px 0"}}
                      isOptionDisabled={(option) => option.disabled}
                      value={dateOptions.find(i => i.value == this.props.selectedRange)}
                      onChange={selectedRange => this.props.onSelectedDateRangeChange(selectedRange.value, true)}
                      defaultOptions={dateOptions}
                    />
                  </>
                }
                {this.props.selectedRange && this.props.selectedRange === "custom" &&
                  <div style={{display: "flex", flexDirection: "column", }}>
                    <CustomDateInput 
                      style={{margin: "5px 0"}}
                      onChange={(event) => this.props.onChange("customFrom", event.target.value)}
                      placeholder={localization.get("form.from")}
                      value={this.props.from}
                      minDate={new Date().toISOString().split('T')[0]}  
                    />
                    <CustomDateInput 
                      style={{margin: "0 0 10px"}}
                      onChange={(event) => this.props.onChange("customTo", event.target.value)}
                      placeholder={localization.get("form.to")}
                      value={this.props.to}
                      minDate={this.props.from}  
                    />
                  </div>
                }
        

                {this.props.people && this.props.people.length > 0 && 
                  <div style={{ margin: "5px 0",}}>
                    <span>{localization.get('people.list')}</span>
                    <AsyncSelect
                      input={(styles) => ({ ...styles, margin: "5px 0", })}
                      styles={{
                        singleValue: (provided, state) => {
                          const isSelected = state.data.value != "0";
                          return ({ 
                            ...provided, 
                            fontWeight: isSelected ? 'bold' : 'normal',
                            color: state.isDisabled ? 'gray' : "var(--main-font-color, black)",
                        })},
                        input: base => ({
                            ...base, 
                            margin: "5px 0",
                        }),
                        menu: base => ({
                          ...base,
                          zIndex: 10,
                          borderColor: "var(--main-font-color, black)",
                          backgroundColor: "var(--main-color, white)",
                          color: "var(--main-font-color, black)",
                        }),
                        control: (baseStyles, state) => {
                          return {
                          ...baseStyles,
                          borderColor: "var(--main-font-color, black)",
                          backgroundColor: "var(--main-color, white)",
                          color: "var(--main-font-color, black)",
                        }},
                        option: (base, state) => {
                          return {
                          ...base,
                        }},
                      }}
                      isMulti
                      isClearable={this.props.selectedPeople.length > 0}
                      theme={theme => {
                        return {
                          ...theme,
                          backgroundColor: "var(--main-color, white)",
                          border3Radius: 5,

                          borderColor: "var(--main-font-color, black)",
                          backgroundColor: "var(--main-color, white)",
                          color: "var(--main-font-color, black)",
                        }
                      }}
                      style={{margin: "10px 0"}}
                      value={this.props.selectedPeople}
                      onChange={e => this.props.onSelectPeople(e)}
                      loadOptions={(inputValue, callback) => {
                        callback(this.props.people.map(t => ({label: t.name, value: t.id}))
                          .filter(t => t.label.toLowerCase().includes(inputValue.toLowerCase())))
                      }}
                      defaultOptions={this.props.people.map(t => ({label: t.name, value: t.id}))}
                    />
                    <div style={{margin: "10px 0"}}>
                      <div 
                        onClick={() => this.setState({showMoreFilters: !this.state.showMoreFilters})}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          color: 'var(--main-font-color, black)',
                          padding: '8px 0'
                        }}
                      >
                        <ExpandMore 
                          style={{
                            fontSize: 20, 
                            transform: this.state.showMoreFilters ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.3s'
                          }}
                        />
                        <span style={{marginLeft: 8}}>
                          {localization.get("smart_search.more_filters")}
                        </span>
                      </div>

                      {this.state.showMoreFilters && (
                        <div style={{marginTop: "10px"}}>
                          <FaceFilters
                            clientId={getActiveClient()}
                            onFiltersChange={(filterParams) => {
                              if (this.props.onFaceFiltersChange) {
                                this.props.onFaceFiltersChange(filterParams);
                              }
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                }

                {this.props.tagGroups.map((tagGroup, key) =>
                  <div style={{ margin: "5px 0",}}>
                    <AsyncSelect
                      input={(styles) => ({ ...styles, margin: "5px 0", })}
                      styles={{
                        singleValue: (provided, state) => {
                          const isSelected = state.data.value != "0";
                          return ({ 
                            ...provided, 
                            fontWeight: isSelected ? 'bold' : 'normal',
                            color: state.isDisabled ? 'gray' : "var(--main-font-color, black)",
                        })},
                        input: base => ({
                            ...base, 
                            margin: "5px 0",
                        }),
                        menu: base => ({
                          ...base,
                          zIndex: 10,
                          borderColor: "var(--main-font-color, black)",
                          backgroundColor: "var(--main-color, white)",
                          color: "var(--main-font-color, black)",
                        }),
                        control: (baseStyles, state) => {
                          return {
                          ...baseStyles,
                          borderColor: "var(--main-font-color, black)",
                          backgroundColor: "var(--main-color, white)",
                          color: "var(--main-font-color, black)",
                        }},
                        option: (base, state) => {
                          return {
                          ...base,
                        }},
                      }}
                      theme={theme => {
                        return {
                          ...theme,
                          backgroundColor: "var(--main-color, white)",
                          borderRadius: 5,

                          borderColor: "var(--main-font-color, black)",
                          backgroundColor: "var(--main-color, white)",
                          color: "var(--main-font-color, black)",
                        }
                      }}
                      isDisabled={tagGroup.tags.every(t => t.state== "disable")}
                      style={{margin: "10px 0"}}
                      isOptionDisabled={(option) => option.disabled}
                      value={tagGroup.tags.find(t => t.state == "selected")  ? 
                        { value: tagGroup.tags.find(t => t.state == "selected").id, 
                          label: tagGroup.tags.find(t => t.state == "selected").name
                        }  : {label: tagGroup.name, value: "0"}
                      }
                      onChange={e => {
                        const selectedValue = e ? e : { value: "0", label: tagGroup.name };
                        const prevSelectedTag = tagGroup.tags.find(t => t.state == "selected") ? tagGroup.tags.find(t => t.state == "selected").id : "0";                      
                        let currentTags = this.props.selectedTags.filter(selectedTag => selectedTag != prevSelectedTag);

                        if (selectedValue.value != "0") currentTags.push(selectedValue.value);

                        return this.props.onSelectTag(currentTags, this.props.unselectedTags, selectedValue.value)
                      }}
                      loadOptions={(inputValue, callback) => {
                        callback([{label: tagGroup.name, value: "0"}, ...tagGroup.tags.filter(t =>  t.state != "disable").map(t => ({label: t.name, value: t.id}))]
                          .filter(t => t.label.toLowerCase().includes(inputValue.toLowerCase())))
                      }}
                      defaultOptions={[{label: tagGroup.name, value: "0"}, ...tagGroup.tags.filter(t =>  t.state != "disable").map(t => ({label: t.name, value: t.id}))]}
                      isClearable={tagGroup.tags.find(t => t.state == "selected")}
                    />
                  </div>
                )}
              </div>
            </div>
          </Drawer>
        </ClickAwayListener>
        <div style={{width: "100%", height: "90vh", display: "flex", flexDirection: "column", paddingTop: 10}}>
          <div style={{width: "100%" }}>{this.props.filterInput()}</div>
          <div id="sidebarScrollingDiv" style={{overflowY: "scroll", width: "100"}}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default FilterContainer;
import React, {Component} from "react";
import {
  clearTokens,
  isLoggedUser,
  getStoredUser,
  homePathGenerator,
  ROLES,
  setIsPublicClient, storeActiveChannel, storeChannel,
} from "./api-client/core/authentication/utils";
import {request} from "./api-client";
import loggedOff from "./routes/roles/loggedOff";
import sysadminRoutes from "./routes/roles/sysadmin";
import userRoutes from "./routes/roles/user";
import readonlyUserRoutes from "./routes/roles/readonlyUser";
import trialUserRoutes from "./routes/roles/trialUser";
import renderRoutes from "./routes/roles/renderRoutes";
import EventManager, {events} from "./utils/EventManager";
import GlobalSnackbar from "./components/Letflow/Snackbar/GlobalSnackbar";
import FreshChat from "./utils/FreshChat";
import GlobalConfirmationDialog from "./components/Letflow/Dialog/GlobalConfirmationDialog";
import GlobalSoundBar from "./sound-bar/GlobalSoundBar";
import localization from "./config/localization";
import GlobalAddToPlaylistDialog from "./components/Letflow/AddToPlaylistDialog/GlobalAddToPlaylistDialog";
import GlobalLicenseWizardDialog from "./components/Letflow/Dialog/GlobalLicenseWizardDialog";
import {getValueFromQueryParam} from "./utils";
import browserHistory from "./utils/browserHistory";
import TermsOfUseDialog from "./components/Letflow/TermsOfUseDialog";
import projectInviteeRoutes from "./routes/roles/projectInvitee";
import ScrollMemory from "react-router-scroll-memory";
import {BrowserRouter} from "react-router-dom";
import LoadingSpinner from "assets/img/loading_spinner.gif";
// Datepicker styles
import "react-datepicker/dist/react-datepicker.css";
// Template styles
import "assets/scss/material-dashboard-pro-react.css?v=1.2.0";
import {canAccessClientHome} from "./api-client/core/authentication/publicHome";
import {canAccessChannel} from "./api-client/core/authentication/publicChannel";
import channelRoutes from "./routes/roles/channelRoutes";
import loggedOffChannel from "./routes/roles/loggedOffChannel";
import channelDomainRoutes from "./routes/roles/channelDomainRoutes";
import loggedOffChannelDomain from "./routes/roles/loggedOffChannelDomain";
import GlobalRedirectLoginConfirmationDialog from "./components/Letflow/Dialog/GlobalRedirectLoginConfirmationDialog";
import { getUrlLang } from "./routes/channel";
import AvailableLanguages from "./config/localization/availableLanguages.json";
import { DownloadProvider } from './contexts/DownloadContext';

const pauseSoundPlayerWhenBannerIsUmmuted = () => {
  EventManager.getInstance().subscribe(events.BANNER_SOUND_ENABLED, () =>
    GlobalSoundBar.pause()
  );
};

class App extends Component {
  state = {
    routes: null,
    maintenanceMode: window.maintenance
  };

  constructor() {
    super();
    // this.favoritesAnalyticsHandler = new FavoritesAnalyticsHandler();
  }

  componentWillMount() {
    if(!localStorage.getItem("keepLogged")) {
      localStorage.setItem("keepLogged", "keep")
    }
  }

  componentDidMount = () => {
    if(process.env.REACT_APP_ENV !== 'local' && window.location.hostname !== window.domainCurrent) {
      window.location.href = `${window.location.protocol}//${window.domainCurrent}${window.location.pathname}`
    }

    this.checkDomain()
      .then(() => {
        this.initializeSession();
        // this.favoritesAnalyticsHandler.subscribe();
        pauseSoundPlayerWhenBannerIsUmmuted();
    });
    EventManager.getInstance().subscribe(events.SET_MAINTENANCE_MODE, () => this.setState({ maintenanceMode: true }))
  };

  componentWillUnmount = () => {
    EventManager.getInstance().unsubscribe(events.SET_MAINTENANCE_MODE, () => this.setState({ maintenanceMode: true }))
  };

  checkDomain = () =>
    new Promise(resolve => {
      if (window.domainTargetType === "channel") {
        if (!localStorage.getItem("default_language")) {
          localStorage.setItem("default_language", window.domainLanguage)
        }

        const domainLanguages = window.domainLanguages.split(", ").filter(language => AvailableLanguages.includes(language))
        let urlLang = getUrlLang(domainLanguages);
        if (urlLang) {
          localization.setLanguage(urlLang);
        } else {
          localization.setLanguage(localStorage.getItem("default_language"))
        }
        storeActiveChannel({id: window.domainTargetId});
        storeChannel({
          id: window.domainTargetId,
          name: window.domainTargetName,
          visibility: window.domainTargetVisibility,
          login_type: window.domainTargetProvider,
          dashboard_login_type: window.domainDashboardTargetProvider,
        });
      }
      resolve()
   })

  initializeSession = () => {
    if (!isLoggedUser()) {
      FreshChat.anonymous();
      let clientData = window.location.pathname.split('/')[2]
      if(window.domainTargetType === "channel") {
        this.setState(
          {
            routes: window.domainTargetVisibility === "public" ? channelDomainRoutes : loggedOffChannelDomain()
          })
      }else {
        if (clientData) {
          if ((window.location.pathname.split('/')[1]) !== 'channel') {
            let client = {id: Number(clientData.split("-")[0]), name: clientData.split("-")[1] || "flowlike"}

            if (!isNaN(client.id)) {
              canAccessClientHome(client.id)
                .then(response => {
                  this.setState(
                    {
                      public: response,
                      routes: response ? readonlyUserRoutes(client) : loggedOff
                    }, () => setIsPublicClient(this.state.public))
                })
            } else {
              this.setState({routes: loggedOff})
            }

          } else {
            let channel = {id: Number(clientData.split("-")[0]), name: clientData.split("-")[1] || "flowlike"}

            if (!isNaN(channel.id)) {
              canAccessChannel(channel.id)
                .then(response => {
                  this.setState(
                    {
                      routes: response ? channelRoutes(channel) : loggedOffChannel(channel)
                    })
                })
            } else {
              this.setState({routes: loggedOff})
            }
          }
        } else {
          this.setState({routes: loggedOff})
        }
      }

    } else {
      this.requestUser()
        .then(this.gotoAlbumIfProvidedByQueryParams)
        .then(this.gotoArtistIfProvidedByQueryParams)
        .then(() => {
          window.domainTargetType !== "channel" && localization.setDefaultLanguage();
          localization.setMomentLocale();
        })
        .then(this.setRoutesFromUser)
        .catch(this.handleRequestFailure)
    }
  };

  gotoAlbumIfProvidedByQueryParams = async () => {
    const viewAlbum = getValueFromQueryParam("view_album");

    if (viewAlbum) {
      const user = getStoredUser();
      const gotoAlbum = client =>
        browserHistory.push(homePathGenerator(`album/${viewAlbum}`, client));

      if (user.clientRoles.length > 0) {
        return gotoAlbum(user.clientRoles[0].client);
      } else {
        const clients = await request.client.getAll();
        return gotoAlbum(clients[0]);
      }
    }
  };

  gotoArtistIfProvidedByQueryParams = async () => {
    const viewArtist = getValueFromQueryParam("view_artist");

    if (viewArtist) {
      const user = getStoredUser();
      const gotoAlbum = client =>
        browserHistory.push(homePathGenerator(`artist/${viewArtist}`, client));

      if (user.clientRoles.length > 0) {
        return gotoAlbum(user.clientRoles[0].client);
      } else {
        const clients = await request.client.getAll();
        return gotoAlbum(clients[0]);
      }
    }
  };

  requestUser = () => request.authentication.getUser();

  setRoutesFromUser = () => {
    const user = getStoredUser();

    FreshChat.loggedIn(
      user.id,
      user.name,
      user.email,
      user.role && user.role.id,
      user.client && user.client.id
    );


    const isSupervisor = ROLES.isSupervisor(user);
    const isSysAdmin = ROLES.isSysAdmin(user);
    const isReadOnly = ROLES.isReadOnly(user);
    const isMultiClient = ROLES.isMultiClient(user);
    const isTrial = ROLES.isTrial(user);
    const isArtist = ROLES.isArtist(user);
    const isProjectInvitee = ROLES.isProjectInvitee(user);
    const isContentCreator = ROLES.isContentCreator(user);
    const isChannelUser = ROLES.isChannelUser(user);
    const isContentSupervisor = ROLES.isContentSupervisor(user);


    let routes;

    if(window.domainTargetType === "channel") {
      routes = channelDomainRoutes
    }else {
      if (isSysAdmin || isSupervisor || isMultiClient || isArtist || isContentSupervisor) {
        routes = sysadminRoutes;
      } else if (isProjectInvitee) {
        routes = projectInviteeRoutes;
      } else if (isChannelUser) {
        const channel = user.channels[0].channel;
        routes = channelRoutes(channel);
      } else {
        const client = user.clientRoles[0].client;

        if (isReadOnly) {
          routes = readonlyUserRoutes(client);
        } else if (isTrial) {
          routes = trialUserRoutes(client);
        } else {
          routes = userRoutes(client);
        }
      }
    }

    if (!routes) {
      throw new Error(`user does not have a valid role: ${user.role.id}`);
    }

    this.setState({ routes });
  };

  handleRequestFailure = e => {
    FreshChat.anonymous();
    clearTokens()
    window.location.reload()
  };

  render = () => {
    const { routes } = this.state;

    const makeContent = () => (
      <BrowserRouter>
        <div>
          <ScrollMemory />
          <div style={{ height: "100%", width: "100%" }}>
            <TermsOfUseDialog />
            <GlobalSnackbar />
            <GlobalConfirmationDialog />
            <GlobalLicenseWizardDialog />
            <GlobalAddToPlaylistDialog />
            <GlobalRedirectLoginConfirmationDialog />
            <DownloadProvider>
              {this.makeGlobalSoundbar()}
              {renderRoutes(routes)}
            </DownloadProvider>
          </div>
        </div>
      </BrowserRouter>
    );

    if (this.state.maintenanceMode === true) { //keep "===" otherwise maintenanceMode will be always true (check index.html)
      return(
      <div
        style={{ height: "100vh", display: "flex", justifyContent: "center", alignContent: "center" }}
      >
        <h1 style={{textAlign: "center", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
          {localization.get("maintenance.text1")}
          <br/>
          <br/>
          {localization.get("maintenance.text2")}
        </h1>
      </div>
      )
    }

    if (!routes) {
      return (
        <div
          style={{ height: "100%", display: "flex", justifyContent: "center", alignContent: "center" }}
        >
          <img style={{width: 120, height: 120, marginTop: "45vh"}} src={LoadingSpinner} alt="Loading logo"/>
        </div>
      );
    }

    return makeContent();
  };

  makeGlobalSoundbar = () => (
    <GlobalSoundBar
      ref={this.bindSoundbarEventsToAnalytics}
      texts={this.makeGlobalSoundbarTexts()}
    />
  );

  bindSoundbarEventsToAnalytics = () => {
    // GlobalSoundBar.nextButtonPressed$().subscribe(track => analyticsInstance.skipTrack(track.title, Math.floor(track.seconds)));
    // GlobalSoundBar.previousButtonPressed$().subscribe(track => analyticsInstance.previousTrack(track.title, Math.floor(track.seconds)));
    // GlobalSoundBar.closeButtonPressed$().subscribe(() => analyticsInstance.closeSoundbar());
  };

  makeGlobalSoundbarTexts = () => ({
    license: localization.get("soundbar.license"),
    download: localization.get("soundbar.download"),
    addToPlaylist: localization.get("soundbar.add_to_playlist"),
    addToFavorites: localization.get("soundbar.add_to_favorites"),
    removeFromFavorites: localization.get("soundbar.remove_from_favorites")
  });
}

export default App;

import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import SwapHoriz from "@material-ui/icons/SwapHoriz";
import Search from "@material-ui/icons/Search";
import Help from "@material-ui/icons/Help";
import Notifications from "@material-ui/icons/Notifications";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import {Link, Redirect} from "react-router-dom";
import Button from "components/CustomButtons/Button.jsx";
import headerLinksStyle from "assets/jss/material-dashboard-pro-react/components/headerLinksStyle";
import {request} from "../../api-client";
import {
  getStoredUser,
  userIsArtist,
  userIsSupervisor,
  userIsSysAdmin
} from "../../api-client/core/authentication/utils";
import "./HeaderLink.css";
import {Tooltip} from "@material-ui/core";
import FreshChat from "../../utils/FreshChat";
import localization from "../../config/localization";
import EventManager from "../../utils/EventManager/EventManager";
import {events} from "../../utils/EventManager";
import browserHistory from "../../utils/browserHistory";

class HeaderLinks extends React.Component {
  state = {
    open: false,
    toSearch: false,
    toDashboard: false,
    countMusic: 0,
    countAlbum: 0,
    countOriginalMusic: 0,
    countRightManagement: 0,
    countContactMessage: 0,
    countProjectsWithNewMaterials: 0,
    countMusicArtist: 0,
    countArtistDemo: 0,
    countPendingLicenses: 0,
    countChannelUsersToApprove: 0,
  };

  updateAllNotifications = () => {
    request.user
      .getAdminNotifications()
      .then(data => ({
        countMusic: data.musics_pending_for_revision,
        countOriginalMusic: data.pending_original_music,
        countRightManagement: data.pending_right_procedures,
        countContactMessage: data.pending_contact_messages,
        countProjectsWithNewMaterials: data.projects_with_new_materials,
        countMusicArtist: data.artist_musics_pending_for_revision,
        countArtistDemo: data.pending_artist_demos,
        countPendingLicenses: data.pending_licenses,
        countChannelUsersToApprove: data.channel_users_to_approve,
      }))
      .then(data => this.setState(data));
  };

  componentDidMount = () => {
    if (userIsSupervisor() || userIsSysAdmin()) {
      this.updateNotifications();
      EventManager.getInstance().subscribe(
        events.UPDATE_DASHBOARD_NOTIFICATIONS,
        this.updateNotifications
      );
      this.autoUpdateNotificationsTimeout = setInterval(
        () => this.updateAllNotifications(),
        600000
      );
    }
  };

  componentWillUnmount = () => {
    EventManager.getInstance().unsubscribe(
      events.UPDATE_DASHBOARD_NOTIFICATIONS,
      this.updateNotifications
    );
    clearInterval(this.autoUpdateNotificationsTimeout);
  };

  updateNotifications = type => {
    this.updateAllNotifications();
  };

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  redirectSearch = () => {
    this.setState(() => ({ toSearch: true }));
  };

  redirectDashboard = () => {
    this.setState(() => ({ toDashboard: true }));
  };

  countNotifications = () =>
    Object.keys(this.state)
      .filter(key => key.startsWith("count"))
      .map(key => this.state[key])
      .reduce((sum, next) => sum + next, 0);

  render() {

    if (this.state.toSearch === true) {
      this.setState(() => ({ toSearch: false }));
      return <Redirect to="/panel/search" />;
    }

    if (this.state.toDashboard === true) {
      this.setState(() => ({ toDashboard: false }));
      return <Redirect to="/panel/dashboard" />;
    }

    const { classes, rtlActive } = this.props;
    const { open } = this.state;

    const dropdownItem =
      classes.dropdownItem +
      " " +
      classNames({
        [classes.dropdownItemRTL]: rtlActive
      });

    const managerClasses = classNames({
      [classes.managerClasses]: true
    });

    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive
    });

    const renderNotificationMessage = (countName, link, makeMessage) =>
      this.state[countName] && (
        <Link to={link}>
          <MenuItem onClick={this.handleClose} className={dropdownItem}>
            {makeMessage(this.state[countName])}
          </MenuItem>
        </Link>
      );

    return (
      <div id="header-link" className={wrapper}>
        {(userIsSupervisor() || userIsSysAdmin()) &&
          getStoredUser().artists.length > 0 && (
            <div className={managerClasses}>
              <Tooltip
                placement="bottom"
                title={
                  getStoredUser().artist_view
                    ? localization.get("view.admin")
                    : localization.get("view.artist")
                }
              >
                <Button
                  color="transparent"
                  justIcon
                  aria-label="Dashboard"
                  aria-owns={open ? "menu-list" : null}
                  aria-haspopup="true"
                  onClick={() => {
                    request.user
                      .update({
                        userId: getStoredUser().id,
                        artistView: !getStoredUser().artist_view
                      })
                      .then(() => window.location.reload());
                  }}
                  className={
                    rtlActive ? classes.buttonLinkRTL : classes.buttonLink
                  }
                  muiClasses={{
                    label: rtlActive ? classes.labelRTL : ""
                  }}
                  buttonRef={node => {
                    this.anchorEl = node;
                  }}
                >
                  <SwapHoriz />
                  <Hidden mdUp implementation="css">
                    <span
                      onClick={() => {
                        request.user
                          .update({
                            userId: getStoredUser().id,
                            artistView: !getStoredUser().artist_view
                          })
                          .then(() => window.location.reload());
                      }}
                      className={classes.linkText}
                    >
                      {getStoredUser().artist_view
                        ? localization.get("view.admin")
                        : localization.get("view.artist")}
                    </span>
                  </Hidden>
                </Button>
              </Tooltip>
            </div>
          )}

        {userIsSupervisor() && !getStoredUser().artist_view && (
          <div className={managerClasses}>
            <Tooltip placement="bottom" title="Dashboard">
              <Button
                color="transparent"
                justIcon
                aria-label="Dashboard"
                aria-owns={open ? "menu-list" : null}
                aria-haspopup="true"
                onClick={() => browserHistory.push("/panel/dashboard")}
                className={
                  rtlActive ? classes.buttonLinkRTL : classes.buttonLink
                }
                muiClasses={{
                  label: rtlActive ? classes.labelRTL : ""
                }}
                buttonRef={node => {
                  this.anchorEl = node;
                }}
              >
                <Dashboard />
                <Hidden mdUp implementation="css">
                  <span
                    onClick={() => browserHistory.push("/panel/dashboard")}
                    className={classes.linkText}
                  >
                    Dashboard
                  </span>
                </Hidden>
              </Button>
            </Tooltip>
          </div>
        )}
        {!userIsArtist() && !getStoredUser().artist_view && this.countNotifications() > 0 && (
            <div className={managerClasses}>
              <Button
                color="transparent"
                justIcon
                aria-label={localization.get("notifications")}
                aria-owns={open ? "menu-list" : null}
                aria-haspopup="true"
                onClick={this.handleClick}
                className={
                  rtlActive ? classes.buttonLinkRTL : classes.buttonLink
                }
                muiClasses={{
                  label: rtlActive ? classes.labelRTL : ""
                }}
                buttonRef={node => {
                  this.anchorEl = node;
                }}
              >
                <Notifications
                  className={
                    classes.headerLinksSvg +
                    " " +
                    (rtlActive
                      ? classes.links + " " + classes.linksRTL
                      : classes.links)
                  }
                />
                <span className={classes.notifications}>
                  {this.countNotifications()}
                </span>
                <Hidden mdUp implementation="css">
                  <span onClick={this.handleClick} className={classes.linkText}>
                    {localization.get("dashboard.notifications")}
                  </span>
                </Hidden>
              </Button>
              <Popper
                open={open}
                anchorEl={this.anchorEl}
                transition
                disablePortal
                placement="bottom"
                className={classNames({
                  [classes.popperClose]: !open,
                  [classes.pooperResponsive]: true,
                  [classes.pooperNav]: true
                })}
              >
                {({ TransitionProps }) => (
                  <Grow
                    {...TransitionProps}
                    id="menu-list"
                    style={{ transformOrigin: "0 0 0" }}
                  >
                    <Paper className={classes.dropdown}>
                      <ClickAwayListener onClickAway={this.handleClose}>
                        <MenuList role="menu">
                          {renderNotificationMessage(
                            "countMusic",
                            "/panel/musics?pending=true",
                            count =>
                              localization.get(
                                "notification.music_approval",
                                count
                              )
                          )}
                          {renderNotificationMessage(
                            "countMusicArtist",
                            "/panel/musics",
                            count =>
                              localization.get(
                                "notification.music_artist_approval",
                                count
                              )
                          )}
                          {renderNotificationMessage(
                            "countAlbum",
                            "/panel/albums",
                            count =>
                              localization.get(
                                "notification.album_review",
                                count
                              )
                          )}
                          {renderNotificationMessage(
                            "countOriginalMusic",
                            "/panel/original_music",
                            count =>
                              localization.get(
                                "notification.original_music",
                                count
                              )
                          )}
                          {renderNotificationMessage(
                            "countRightManagement",
                            "/panel/right_management",
                            count =>
                              localization.get(
                                "notification.right_management",
                                count
                              )
                          )}
                          {renderNotificationMessage(
                            "countContactMessage",
                            "/panel/contact_messages",
                            count =>
                              localization.get(
                                "notification.contact_message",
                                count
                              )
                          )}
                          {renderNotificationMessage(
                            "countProjectsWithNewMaterials",
                            "/panel/licenses",
                            count =>
                              localization.get(
                                "notification.new_material_license",
                                count
                              )
                          )}
                          {renderNotificationMessage(
                            "countArtistDemo",
                            "/panel/demos/artists",
                            count =>
                              localization.get(
                                "notification.pending_artist_demo",
                                count
                              )
                          )}
                          {renderNotificationMessage(
                            "countPendingLicenses",
                            "/panel/pending/licenses",
                            count =>
                              localization.get(
                                "notification.pending_licenses",
                                count
                              )
                          )}
                          {renderNotificationMessage(
                            "countChannelUsersToApprove",
                            "/panel/approved/channel_users",
                            count =>
                              localization.get(
                                "notification.channel_users_to_approve",
                                count
                              )
                          )}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          )}
        {!userIsArtist() &&
          !getStoredUser().artist_view &&
          (userIsSupervisor() || userIsSysAdmin()) && (
            <Tooltip title={localization.get("dashboard.general_search")}>
              <Button
                color="transparent"
                justIcon
                aria-label="Search"
                aria-owns={open ? "menu-list" : null}
                aria-haspopup="true"
                onClick={() => this.redirectSearch()}
                className={
                  rtlActive ? classes.buttonLinkRTL : classes.buttonLink
                }
                muiClasses={{
                  label: rtlActive ? classes.labelRTL : ""
                }}
                buttonRef={node => {
                  this.anchorEl = node;
                }}
              >
                <Search />
                <Hidden mdUp implementation="css">
                  <span
                    onClick={() => this.redirectSearch()}
                    className={classes.linkText}
                  >
                    {localization.get("dashboard.general_search")}
                  </span>
                </Hidden>
              </Button>
            </Tooltip>
          )}
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
};

export default withStyles(headerLinksStyle)(HeaderLinks);

import _getRole from "./getRole";
import _ from 'lodash'
import {Subject} from "rxjs";
import TagManager from 'react-gtm-module'
import localization from "../../../config/localization";
import { routeHasTranslation } from "../../../routes/channel";
import availableLanguages from "../../../config/localization/availableLanguages.json";
import {get} from 'lodash';
import {convertFromRaw, EditorState} from "draft-js";

export const ACCESS_TOKEN = "at";
export const REFRESH_TOKEN = "rt";
export const LOGGED_USER = "logged";
export const USER = "us";

// TOKENS

export const getAccessToken = () => sessionStorage.getItem(ACCESS_TOKEN);
export const storeAccessToken = token => sessionStorage.setItem(ACCESS_TOKEN, token);

export const getRefreshToken = () => localStorage.getItem("keepLogged") !== "keep" ?
  sessionStorage.getItem(REFRESH_TOKEN) : localStorage.getItem(REFRESH_TOKEN);
export const storeRefreshToken = token => localStorage.getItem("keepLogged") !== "keep" ?
  sessionStorage.setItem(REFRESH_TOKEN, token) : localStorage.setItem(REFRESH_TOKEN, token);


export const storeTokens = ({ access_token, refresh_token }) => {
  clearTokens()
  storeLoggedUser(true);
  if(shouldUseTokensInHeader()) {
    storeAccessToken(access_token);
    storeRefreshToken(refresh_token);
  }
};

export const shouldUseTokensInHeader = () => process.env.REACT_APP_ENV === "local" && process.env.REACT_APP_AUTH_BY_HEADERS;
export const storeLoggedUser = val => localStorage.getItem("keepLogged") !== "keep" ? sessionStorage.setItem(LOGGED_USER, val) : localStorage.setItem(LOGGED_USER, val);
export const isLoggedUser = () => localStorage.getItem("keepLogged") !== "keep" ? sessionStorage.getItem(LOGGED_USER) : localStorage.getItem(LOGGED_USER);
export const clearTokens = () => sessionStorage.removeItem(REFRESH_TOKEN) || localStorage.removeItem(REFRESH_TOKEN) || sessionStorage.removeItem(ACCESS_TOKEN) || sessionStorage.removeItem(USER) || sessionStorage.removeItem(LOGGED_USER) || localStorage.removeItem(LOGGED_USER);

// ROLES

export let ROLES = {
  SYS_ADMIN: 1,
  CLIENT_ADMIN: 2,
  USER: 3,
  READ_ONLY: 4,
  SUPERVISOR: 5,
  TRIAL: 6,
  MULTI_CLIENT: 7,
  SINGLE_CLIENT: 8,
  ARTIST: 9,
  PROJECT_INVITEE: 10,
  CONTENT_CREATOR: 13,
  CHANNEL_USER: 14,
  CONTENT_SUPERVISOR: 15,
  MUSIC_SUPERVISOR: 17,
  SIMPLE_MULTICLIENT: 18,
};

ROLES = {
  ...ROLES,
  sysAdminId: ROLES.SYS_ADMIN,
  clientAdminId: ROLES.CLIENT_ADMIN,
  userId: ROLES.USER,
  readOnlyId: ROLES.READ_ONLY,
  supervisorId: ROLES.SUPERVISOR,
  trialId: ROLES.TRIAL,
  multiClientId: ROLES.MULTI_CLIENT,
  singleClientId: ROLES.SINGLE_CLIENT,
  artistId: ROLES.ARTIST,
  projectInviteeId: ROLES.PROJECT_INVITEE,
  contentCreatorId: ROLES.CONTENT_CREATOR,
  userChannelId: ROLES.CHANNEL_USER,
  contentSupervisorId: ROLES.CONTENT_SUPERVISOR,
  musicSupervisorId : ROLES.MUSIC_SUPERVISOR,
  simpleMulticlientId: ROLES.SIMPLE_MULTICLIENT,
  [ROLES.SYS_ADMIN]: "sysadmin",
  [ROLES.CLIENT_ADMIN]: "clientadmin",
  [ROLES.USER]: "user",
  [ROLES.READ_ONLY]: "readonly",
  [ROLES.SUPERVISOR]: "supervisor",
  [ROLES.TRIAL]: "trial",
  [ROLES.MULTI_CLIENT]: "multiClient",
  [ROLES.SINGLE_CLIENT]: "singleClient",
  [ROLES.ARTIST]: "artist",
  [ROLES.PROJECT_INVITEE]: "project_invitee",
  [ROLES.CONTENT_CREATOR]: "content_creator",
  [ROLES.CHANNEL_USER]: "user_channel",
  [ROLES.CONTENT_SUPERVISOR]: "content_supervisor",
  [ROLES.MUSIC_SUPERVISOR]: "music_supervisor",
  [ROLES.SIMPLE_MULTICLIENT]: "simple_multiclient",

  isSysAdmin: user => user.role.id === ROLES.sysAdminId,
  isClientAdmin: user => user.role.id === ROLES.clientAdminId,
  isUser: user => user.role.id === ROLES.userId,
  isReadOnly: user => user.role.id === ROLES.readOnlyId,
  isSupervisor: user => user.role.id === ROLES.supervisorId,
  isTrial: user => user.role.id === ROLES.trialId,
  isMultiClient: user => user.role.id === ROLES.multiClientId,
  isSingleClient: user => user.role.id === ROLES.noneId,
  isArtist: user => user.role.id === ROLES.artistId,
  isProjectInvitee: user => user.role.id === ROLES.projectInviteeId,
  isContentCreator: user => user.role.id === ROLES.contentCreatorId,
  isChannelUser: user => user.role.id === ROLES.userChannelId,
  isContentSupervisor: user => user.role.id === ROLES.contentSupervisorId,
  isSimpleMulticlient: user => user.role.id === ROLES.simpleMulticlientId,
};

export const ROLES_IMPORTANCE = {
  [ROLES.trialId]: 0,
  [ROLES.projectInviteeId]: 0,
  [ROLES.userChannelId]: 1,
  [ROLES.readOnlyId]: 1,
  [ROLES.userId]: 2,
  [ROLES.musicSupervisorId]: 3,
  [ROLES.singleClientId]: 3,
  [ROLES.clientAdminId]: 4,
  [ROLES.contentSupervisorId]: 4,
  [ROLES.simpleMulticlientId]: 4,
  [ROLES.multiClientId]: 5,
  [ROLES.artistId]: 6,
  [ROLES.contentCreatorId]: 6,
  [ROLES.sysAdminId]: 7,
  [ROLES.supervisorId]: 8,
};

let storedUser;
let activeClient;
let activeClientType;
let licensePlan;
let publicClient = false;
let domainClient = null;
let clientName = "";
let activeChannel = false;
let storedChannel;
let isDomainChannel = false;
let channelCanAddToList = false;
let channelListName = "";
let activeSection = false;
let currentSection = false;
let useTools = false;
let channelCanLicense = false;
let channelIsPublic = false; 

let albumFavorites = [];
let musicFavorites = [];
let artistFavorites = [];
let contentCreatorFavorites = [];
let contentCreationFavorites = [];

let activeLivestreams = [];

const onUserChangedSubject = new Subject()
export const onUserChanged = onUserChangedSubject.asObservable()

export const setAlbumFavorites = (favorites) => albumFavorites = favorites;
export const setArtistFavorites = (favorites) => artistFavorites = favorites;
export const setMusicFavorites = (favorites) => musicFavorites = favorites;
export const setContentCreatorFavorites = (favorites) => contentCreatorFavorites = favorites;
export const setContentCreationFavorites = (favorites) => contentCreationFavorites = favorites;


export const getAlbumFavorites = () => albumFavorites;
export const getArtistFavorites = () => artistFavorites;
export const getMusicFavorites = () => musicFavorites;
export const getContentCreatorFavorites = () => contentCreatorFavorites;
export const getContentCreationFavorites = () => contentCreationFavorites;

export const storeUser = user => {
  storedUser = user
  onUserChangedSubject.next(storedUser)
};
export const getStoredUser = () => storedUser;
export const hasStoredUser = () => Boolean(storedUser);
export const getStoredUserClientId = () => getStoredUser() && getStoredUser().client && getStoredUser().client.id;
export const getStoredUserRole = () => getStoredUser() && ROLES[getStoredUser().role.id];
export const getStoredUserRoleId = () => getStoredUser() && getStoredUser().role.id;
export const getSignatureTime = () => process.env.REACT_APP_SIGNATURE_TIME

export const storeClientType = client => activeClientType = client.type;
export const storeClientName = client => clientName = client.name;
export const storeClientLicencePlan = client => licensePlan = client.licensePlan;
export const clientCanLicense = () => licensePlan.only_own_channels || licensePlan.only_other_purpose

export const getActiveClientType = () => activeClientType;
export const hasActiveClientType = () => Boolean(activeClientType);

export const storeActiveChannel = channel => activeChannel = channel.id;
export const storeChannel = channel => {
  storedChannel = channel;
  channelIsPublic = channel.visibility === "public";
}
export const storeIsDomainClient = value => isDomainChannel = value;
export const storeIsPublicChannel = value => channelIsPublic = value;

export const getActiveChannel = () => activeChannel;
export const getStoredChannel = () => storedChannel;
export const isPublicChannel = () => channelIsPublic;
export const getIsDomainChannel = () => isDomainChannel;
export const storeChannelCanAddToList = value => channelCanAddToList = value
export const channelCanAddToFavorites = () => channelCanAddToList;
export const storeChannelFavoriteListName = value => channelListName = value
export const channelFavoriteListName = () => channelListName;
export const storeActiveSection = value =>  activeSection = value;
export const getActiveSection = () => activeSection; // returns false for home
export const storeCurrentSection = value =>  currentSection = value;
export const getCurrentSection = () => currentSection;
export const setUseTools = value => useTools = value;
export const getUseTools = () => useTools && !!getStoredUser();
export const channelHasPlaylists = () => getUseTools() || (storedChannel && storedChannel.use_playlists);
export const channelHasLicenses = () => getUseTools() || (storedChannel && storedChannel.use_licenses);
export const channelDisableShare = () => storedChannel && storedChannel.disable_share;

export const clientIsTypeCatalog = () => hasActiveClientType() && getActiveClientType() === "catalog";

export const catalogClientView = () => clientIsTypeCatalog() && !userIsSysAdmin();

export const getActiveSoundProducer = () => {
  let soundProducer = getStoredUser().clientRoles.find(clientRole => clientRole.client.type === "sound_producer")
  if (soundProducer) {
    return soundProducer.client
  }
  return soundProducer
}

export const getActiveFilmProducer = () => {
  let filmProducer = getStoredUser().clientRoles.find(clientRole => clientRole.client.type === "film_producer")
  if (filmProducer) {
    return filmProducer.client
  }
  return filmProducer
}

export const updateStoredUser = data => {
  storedUser = ({ ...storedUser, ...data })
  onUserChangedSubject.next(storedUser)
}

export const userIsOrOutranksRole = role => ROLES_IMPORTANCE[getStoredUserRoleId()] >= ROLES_IMPORTANCE[role];
export const userIsAnyOf = roles => roles.includes(getStoredUserRoleId());

export const setActiveClient = id => (activeClient = id);
export const getActiveClient = () => activeClient;

export const userIsSysAdmin = () => getStoredUserRoleId() === ROLES.sysAdminId || getStoredUserRoleId() === ROLES.supervisorId;
export const userIsSupervisor = () => getStoredUserRoleId() === ROLES.supervisorId;
export const userIsArtist = () => getStoredUserRoleId() === ROLES.artistId;
export const userisMultiClient = () => getStoredUserRoleId() === ROLES.multiClientId;
export const userisSingleClient = () => getStoredUserRoleId() === ROLES.singleClientId;
export const userIsContentSupervisor = () => getStoredUserRoleId() === ROLES.contentSupervisorId;
export const userIsClientAdmin = () => userHasClientRole(ROLES.CLIENT_ADMIN);
export const userIsClient = () => userHasClientRole(ROLES.USER) || userHasClientRole(ROLES.MUSIC_SUPERVISOR);
export const userIsReadOnly = () => userHasClientRole(ROLES.READ_ONLY);
export const userIsMusicSupervisor = () => userHasClientRole(ROLES.MUSIC_SUPERVISOR);
export const userIsSimpleMulticlient = () => getStoredUserRoleId() === ROLES.simpleMulticlientId;

export const userIsTrial = () => getStoredUser() && getStoredUser().is_trial;

const userHasClientRole = (roleId, clientId = activeClient) => {
  const user = getStoredUser();

  if (!user) return false;

  const client = user.clientRoles.find(x => x.client.id == clientId);
  
  if (!client) return false;

  return client.role.id == roleId;
};

const _userCanLicenseTracks = (clientId = activeClient) => {

  const user = getStoredUser()

  if (!user) return false

  if (userIsSupervisor() || userIsSysAdmin()) return true

  const client = user.clientRoles.find(x => x.client.id == clientId);

  if (!client) return false

  return client.can_license
}

export const userCanEditCreators = (clientId = activeClient) => {
  const user = getStoredUser()

  if (!user || (storedChannel && storedChannel.visibility == "public") || (storedChannel && storedChannel.editable_for == "none")) return false

  if (userIsSupervisor() || userIsSysAdmin()) return true;

  if (storedChannel.editable_for != "everyone") return false; //if not supervisor return false

  const client = user.clientRoles.find(x => x.client.id == clientId);

  if (!client) return false

  return client.allow_edit || userIsContentSupervisor();
}


export const userHasRestrictedAccess = (channel = storedChannel) => {
  const user = getStoredUser();

  if (!user || get(channel, 'purpose', 'default') != 'cms') return false;

  const channelRole = user.channels.find(x => x.channel && x.channel.id == channel.id);
  
  return channelRole && channelRole.restricted;
};

// Permissions

// Reservations (Deprecated)

export const userCanMakeReservations = () =>                      userIsSupervisor() || userIsSysAdmin() || userIsClientAdmin();
export const userCanRequestReservations = () =>                   userIsSupervisor() || userIsClientAdmin();

// Dashboard

// - Client

export const userCanCreateClient = () =>                          userIsSupervisor() || userIsSysAdmin() || (userisMultiClient() && getStoredUser().can_create_clients);
export const userCanDeleteClient = () =>                          userIsSupervisor() || userIsSysAdmin();
export const userCanEditClient = (clientId) =>                    userIsSupervisor() || userIsSysAdmin() || userHasClientRole(ROLES.CLIENT_ADMIN, clientId);
export const userCanEditClientHome = (clientId) =>                userIsSupervisor() || userIsSysAdmin() || userHasClientRole(ROLES.CLIENT_ADMIN, clientId);

// Licenses

export const userCanLicenseTracks =                               _userCanLicenseTracks;
export const userCanViewLicenses = () =>                          userIsSupervisor() || userIsSysAdmin() || userIsClientAdmin();

// Clients

export const userCanRequestAllClients = () =>                     userIsSupervisor() || userIsSysAdmin() || userisMultiClient();
export const userCanHandleMultipleClients = () =>                 userIsSupervisor() || userIsSysAdmin() || userisMultiClient();
export const userCanSeeLicenses = () =>                           getStoredUser() ? getStoredUser().can_see_licenses : false
export const setIsPublicClient = isPublic =>                      publicClient = isPublic;
export const isPublicClient = () =>                               publicClient;
export const setDomainClient = client =>                          domainClient = client;
export const getDomainClient = () =>                              domainClient;

// Users
export const userCanRequestAllUsers = () =>                       userIsSupervisor() || userIsSysAdmin() || userisMultiClient();
export const userCanCreateUser = () =>                            userIsSupervisor() || userIsSysAdmin() || (userisMultiClient() && getStoredUser().can_edit_admins);
export const userCanCreateUserWithClientRole = (client, role) =>  userIsSupervisor() || userIsSysAdmin() || userisMultiClient() || ROLES_IMPORTANCE[getStoredUser().clientRoles.find(cr => cr.client.id == client).role.id] >= ROLES_IMPORTANCE[role]
export const userCanCreateUserWithGeneralRole = (role) =>         ROLES_IMPORTANCE[getStoredUser().role.id] >= ROLES_IMPORTANCE[role]

export const userHasClientsInCommonWith = (otherUser) =>          _.intersection(getStoredUser().clientRoles.map(x => x.client.id), otherUser.clientRoles.map(x => x.client.id)).length > 0

// Musics

export const userCanRequestAllMusics = () =>                      userIsSupervisor() || userIsSysAdmin();
export const userCanDownloadUnMarkedTracksWithoutLicence = () =>  userIsSupervisor() || userIsSysAdmin();
export const userCanDownloadTracks = () =>                        userIsSupervisor() || userIsSysAdmin() || userIsClientAdmin() ||      userIsArtist() || userIsClient() || userIsTrial();

// Management

export const userCanDeleteItems = () =>                           !userIsReadOnly();
export const userCanEditItems = () =>                             userIsSupervisor() || userIsSysAdmin() || userIsArtist()      || userisMultiClient() || userIsClientAdmin() || userIsClient() || userIsTrial() || userIsContentSupervisor();
export const userCanSharePlaylists = () =>                        userIsSupervisor() || userIsSysAdmin() || userisMultiClient() || userIsClientAdmin() || userIsClient()      || userIsTrial();
export const userCanDuplicatePlaylists = () =>                    userIsSupervisor() || userIsSysAdmin() || userisMultiClient();
export const userCanSaveQueries = () =>                           userIsSupervisor() || userIsSysAdmin() || userIsClientAdmin() || userIsClient() || userIsTrial();
export const userCanCreatePlaylists = () =>                       userIsSupervisor() || userIsSysAdmin() || userIsClientAdmin() || userIsClient() || userIsTrial() || (userHasRestrictedAccess());
export const userCanAddTracksToPlaylists = () =>                  userIsSupervisor() || userIsSysAdmin() || userIsClientAdmin() || userIsClient() || userIsTrial() || userIsMusicSupervisor();
export const userCanVisitAllClientHomes = () =>                   userIsSupervisor() || userIsSysAdmin();

export const userCanEditLicensePlans = () =>                      userIsSupervisor() || userIsSysAdmin();
export const userCanDeleteLicensePlans = () =>                    userIsSupervisor() || userIsSysAdmin();
export const setChannelCanLicense = (client) =>                   channelCanLicense = (userIsSupervisor() || userIsSysAdmin() || userCanLicenseTracks() || userIsMusicSupervisor()) && (!!useTools || getStoredChannel().use_licenses) && clientCanSeeMusics(client);
export const getChannelCanLicense = ()      =>                    channelCanLicense;
// Project

export const userCanUploadProjectMaterials = () =>                userIsSupervisor() || userIsSysAdmin() || userIsClientAdmin();
export const userCanDeleteProjectMaterials = () =>                userIsSupervisor() || userIsSysAdmin() || userIsClientAdmin();

export const userCanCreateConversations = () =>                   userIsSupervisor() || userIsSysAdmin() || userisMultiClient() || userIsClientAdmin() || userIsClient();

export const userCanSelectAdministratorsForPitch = () =>          userIsSupervisor() || userIsSysAdmin();

export const getRole = _getRole;

export const clientCanSeeSpeakers = (client) => client.permissions && client.permissions.some(permission => permission.entity === 'speaker');

export const setPageTitle = (text = "") => document.title = ( !!text ? text + ' - ' : '') + (!!storedChannel ? storedChannel.name : (clientName ? clientName : 'Feater'))

export const setActiveLivestreams = (livestreams) => activeLivestreams = livestreams;
export const getActiveLivestreams = () => activeLivestreams;

export const clientCanSeeMusics = (client) => client.permissions && client.permissions.some(permission => permission.entity === 'music');
export const clientCanSeeContentCreators = (client) => client.permissions && client.permissions.some(permission => permission.entity === 'content_creator');

export const checkIfTwins = () => window.location.hostname.endsWith('twinsmusic.com.ar')
export const homePathGenerator = (path, client) => client ? `/home/${client.id}-${slugify(client.name)}${path ? `/${path}` : "" }` : `/home/${activeClient}-${slugify(clientName)}${path ? `/${path}` : "" }`
export const slugify = (string = "element") => {
  try {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')

    const newString = string.toString().toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text

    return newString || "default"
  } catch(e) {
    return "element"
  }
}

export const checkIfMobile = () => (window.innerWidth <= 800 ) && ( window.innerHeight <= 900 )

export const channelPathGenerator = (path, channel) => {
  let pathname;
  let activeLang = localization.getLanguage();
  let splitPath = path.split('/');
  const clearablePaths = ["channel", "home"].concat(availableLanguages)

  // Clear path
  if (clearablePaths.includes(splitPath[0])) {
    // clear channel route
    if (splitPath[0] === "channel") {
      splitPath.shift()
      splitPath.shift()
    }

     // clear language
     if (splitPath[0] && availableLanguages.includes(splitPath[0])) {
       //remove /en /es /pt...
       splitPath.shift()
     }

     // clear section
     if (splitPath[0] === "home") {
       splitPath.shift()
       splitPath.shift()
     }

     pathname = `/${splitPath.join('/')}`;
  }else {
    pathname = `/${path}`;
  }
  let hasTranslation = routeHasTranslation(pathname);
  if (activeSection) {
    pathname = `/home/${activeSection.id}-${activeSection.name}${pathname}`
  }
  if (hasTranslation) {
    pathname = activeLang !== 'es' ? `/${activeLang}${pathname}` : pathname;
  }
  if(!isDomainChannel) {
    pathname =  channel ? `/channel/${channel.id}-${slugify(channel.name)}${pathname}` : `/channel/${storedChannel.id}-${slugify(storedChannel.name)}${pathname}`
  }

  return pathname
}


export const rootPath = (path = `/`) => {
  let activeLang = localization.getLanguage();
  let pathname = path;
  let hasTranslation = routeHasTranslation(pathname);
  if (hasTranslation) {
    pathname = activeLang !== 'es' ? `/${activeLang}${pathname}` : pathname;
  }
  if(!isDomainChannel) {
    pathname =  `/channel/${storedChannel.id}-${slugify(storedChannel.name)}${pathname}`
  }

  return pathname
}

export const parseRouting = path => {
  if(activeSection) return `/home/${activeSection.id}-${activeSection.name}/` + path

  return  '/' + path
}


export const translateTypes = (type) => {
  switch (type) {
    case "video_content_creation":
    case "content_creator":
    case "contentCreator":
      return "contenido"
    case "music":
      return "musica"
    case "artist":
      return "artista"
    default:
      return type
  }
}

export const setChannelPageTitle = text => document.title = (storedChannel ? storedChannel.name : 'Feater') + ' - ' +  (text || '')

export const addHttpsToUrl = url => (!url.startsWith('http')) ? "https://"+url : url

export const GAevent = (categoryName, eventName, labelName, value = 0, useTagManager = true) => {
  if (activeChannel && storedChannel.google_tag_manager_id && useTagManager) {
    TagManager.initialize({
      gtmId: storedChannel.google_tag_manager_id,
      events: {
        categoryName: categoryName,
        eventName: eventName,
        labelName: labelName,
      }
    })
  }
}

export const generateLink = (kind, id, title, openInHome) => {
  if (!!getActiveChannel()) {
    if (openInHome) {
      return `${channelPathGenerator('' )}?${translateTypes(kind)}=${id}-${slugify(title)}`;
    } else {
      return channelPathGenerator(`${translateTypes(kind)}/${id}-${slugify(title)}`);
    }
  }
  if (!!getActiveClient()) {
    let path = '';
    switch (kind) {
      case 'artist':
        path = `artist/${id}`;
        break;
      case 'production':
        path = `projects/${id}/thread`;
        break;
      case 'playlist':
        path = `${kind}/${id}`;
        break;
      case 'content_creator':
        path = `${kind}/${id}`;
        break;
    }
    return homePathGenerator(path);
  }

  return '#';
}

export const getMainTranslation = (entity, attr, probablyHtml = false) => {
  const mainLang = (process.env.REACT_APP_ENV === "local") ? (!!getStoredChannel() ? getStoredChannel().language : "es") : window.domainLanguage;
  let text = entity[attr];

  if (!!entity.translations) {
    const mainTranslation = entity.translations.find(e => e.locale === mainLang)

    text = get(mainTranslation, attr, entity[attr]) || entity[attr];
  }

  if (probablyHtml && text && text.startsWith("{")) {
    const editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(text)));
    const content = editorState.getCurrentContent();
    text = content.getPlainText();
  }

  return text;
}


export const getTitleByLang = (translations, lang, defaultTitle) => {
  const translation = translations.filter(trans => trans.locale == lang)[0]
  return (translation && translation.title) ? translation.title : defaultTitle;
}




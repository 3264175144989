import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { request } from '../../../api-client';
import ShakaPlayer from '../../../components/Letflow/ShakaPlayer';
import { makeImageUrlWithSize } from '../../../layouts/Home/HomeElements/utils';
import localization from "../../../config/localization";
import { Pause, VolumeUp, Play } from '@material-ui/icons';
import analyticsInstance from '../../../analytics/analyticsInstance';

class AdDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ad: null,
      open: true,
      timeRemaining: 0,
      clicked: false,
      muted: window.innerWidth < 600,
      viewed: false,
    };
  }

  componentDidMount() {
    this.fetchAd();
  }

  fetchAd = async () => {
    const { type, id, name } = this.props.adTarget;
    const ad = await request.ads.getAdForChannel(type, id);
    
    if (!ad) return this.props.onClose();

    this.setState({ ad, timeRemaining: ad.duration });
    this.trackAdView();
    this.startTimer();
    analyticsInstance.adDisplay({
      name: ad.name,
      type: type === "contentCreators" ? "Content" : "Section",
      content: name
    });
  }

  trackAdView = () => request.ads.trackAdView(this.state.ad.id, {viewed: this.state.viewed ? 1 : 0, clicked: this.state.clicked ? 1 : 0}).then(() => this.setState({viewed: true}));

  startTimer = () => {
    this.timer = setInterval(() => {
      this.setState(prevState => ({
        timeRemaining: prevState.timeRemaining - 1
      }), () => {
        if (this.state.timeRemaining <= 0) {
          clearInterval(this.timer);
          this.state.ad.type === 'video' && this.handleClose(); 
        }
      });
    }, 1000);
  }

  handleClose = async () => {
    if (this.state.timeRemaining <= 0) {
      clearInterval(this.timer);
      this.setState({ open: false });

      if (this.props.onClose) {
        this.props.onClose();
      }
    }
  }

  handleAdClick = () => {
    analyticsInstance.adClick({
      name: this.state.ad.name,
      type: this.props.adTarget.type === "contentCreators" ? "Content" : "Section",
      content: this.props.adTarget.name
    });

    this.setState({ clicked: true }, () => {
      const { ad } = this.state;
      if (ad && ad.link) {
        window.open(ad.link, '_blank');
        this.trackAdView();
      }
    });
  }

  renderAdContent = () => {
    const { ad, timeRemaining } = this.state;
    if (!ad) return null;

    const image = {image: ad.type === 'video' ? ad.video.thumbnail : (window.innerWidth > 600 ? ad.image : ad.mobileImage)};
    return (
      <div onClick={this.handleAdClick} style={{ cursor: ad.link ? 'pointer' : 'default' , position: 'relative', maxWidth: '100%', maxHeight: '100%' }}>
        <p 
					onClick={e => {
						e.preventDefault()
						e.stopPropagation()
						this.handleClose()
					}}
					style={{
              position: 'absolute', top: 10, right: 10, zIndex: 5, 
              color: 'white',
              textShadow: '1px 1px 2px black', 
							textTransform: 'uppercase',
							fontSize: 16,
							cursor: 'pointer',
							fontWeight: 'bold',
							opacity: 0.8,
							backgroundColor: 'rgba(0, 0, 0, 0.5)',
							padding: '5px 10px',
							borderRadius: 5,
            }}>
						{localization.get(`form.close`)}
					</p>
        {ad.type === 'video' ? (
          <>
            <a onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()

              this.setState({muted: !this.state.muted})
            }}
              className="default-home-banner-video-button"
              style={{
                position: 'absolute', top: 10, left: 10, zIndex: 5, 
                color: 'white',
                textShadow: '1px 1px 2px black', 
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Pause />
            </a>
          <ShakaPlayer
            video={ad.video}
            preview={false}
            id="ad-video"
            muted={this.state.muted}
            ref={`video-${ad.id}`}
            loop={true}
            preload={"auto"}
            autoPlay={true}
						thumbnail={makeImageUrlWithSize(image, "xl", true)}
            styles={{
              minWidth: "100%",
              minHeight: "100%",
							width: '100%!important',
							height: '100%!important'
            }}
            controls={false}
            isBanner
          />
          </>
        ) : (
          <img 
            src={makeImageUrlWithSize(image, window.innerWidth > 600 ? "xl" : "md", true)} 
            alt="Anuncio" 
            style={{  
              objectFit: 'contain',
              width: '100%',
              maxHeight: '80vh',
              margin: '0 auto',
              display: 'block'
            }} 
          />
        )}
        {timeRemaining > 0 && (
          <p style={{
            position: 'absolute',
            bottom: 10,
            left: 10,
            color: 'white',
            textShadow: '1px 1px 2px black', 
            zIndex: 5,
          }}>
            {localization.get(`ads.closing_time_${this.state.ad.type}`, timeRemaining)}
          </p>
        )}
      </div>
    );
  }

  render() {
    const { open, ad } = this.state;

    if (!ad) return null;

    return (
      <Dialog
        maxWidth="xl"
        open={open}
        onClose={this.handleClose}
        aria-labelledby="ad-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        PaperProps={{
          style: {
            minHeight: '100vh',
            minWidth: '100vw',
            maxHeight: '100vh',
            maxWidth: '100vw',
            backgroundColor: "rgba(0,0,0,0.8)",
            padding: "40px",
            height: '100vh',
            width: '100vw'
          },
        }}
      >
        <DialogContent style={{ padding: 0, position: 'relative', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {this.renderAdContent()}
        </DialogContent>
      </Dialog>
    );
  }
}

export default AdDialog;

import React, { Component } from 'react'
import localization from "../../config/localization";
import {
  Button,
  Checkbox,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle, FormControl, FormControlLabel, IconButton,
  MenuItem, Radio, RadioGroup,
  Select, Tooltip,
} from "@material-ui/core";
import {TextValidator} from "react-material-ui-form-validator";
import {Close, Done, Help} from "@material-ui/icons";
import FilePondUploader from "../../components/Letflow/FilePondUploader";
import TranslatableInputs from "../../components/Letflow/TranslatableInputs";
import {ImagePicker} from "../../components/Letflow/Form";
import {request} from "../../api-client";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../components/Letflow/Snackbar/GlobalSnackbar";
import AvailableLanguages from "../../config/localization/availableLanguages.json";
import {get} from "lodash";
import ColorPicker from "../../components/Letflow/ColorPicker";

class ConfigCategoryDialog extends Component {
  state = {
    category: null,
    originalCategory: null,
  }

  componentWillReceiveProps(nextProps) {
    if(!nextProps.editingCategory || !this.state.category && !!nextProps.editingCategory || this.state.category.id != nextProps.editingCategory.id) {
      this.setState({ category: nextProps.editingCategory, originalCategory: nextProps.editingCategory })
    }
  }

  handleClose = cat => {
    if ((cat.show_on_global_search || cat.show_on_smart_search) && !cat.allow_content_search && !cat.allow_creation_search && !cat.allow_event_search) {
      GlobalSnackbar.show({
        message: localization.get('form.validation.search_for'),
        type: GlobalSnackbarTypes.ERROR,
      })

      return;
    }
    this.props.updateCategory(cat);

    return this.props.openCloseDialog();
  }

  render = () => {
    const { category, originalCategory } = this.state;
    const setCategory = (category) => {

      this.setState(prevState => {
        return ({ category: category, originalCategory: prevState.originalCategory })
      })
    }

    if(!category) return null;

    const translations = AvailableLanguages.map(language => {
      const translation = category.translations.find(tr => tr.locale ===language)

      return  {
        locale: language,
        language: language,
        content_title: translation ? translation.content_title: "",
        event_title: translation ? translation.event_title: "",
        material_title: translation ? translation.material_title: "",
      }
    })

    return (
      <Dialog open={this.props.open} onBackdropClick={() => this.handleClose(originalCategory)} onEscapeKeyDown={() => this.handleClose(originalCategory)}>
        <DialogTitle style={{background: "var(--dashboard-section-header-background-color, #ccc)", color: "var(--dashboard-section-header-font-color, #FFF)",}}>{localization.get('config')}: {category.name}</DialogTitle>
        <DialogContent style={window.innerWidth > 600 ? {width: "600px"} : {}}>
          <br/>
          <span>{localization.get('form.show_content_on')}</span>
          <br/>
          <FormControlLabel
            control={
              <Checkbox
                style={{color: "#4cc2d5"}}
                checked={category.show_on_global_search}
                onChange={() => setCategory({...category, show_on_global_search: !category.show_on_global_search })}
              />
            }
            label={localization.get("form.show_on_global_search")}
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{color: "#4cc2d5"}}
                checked={category.show_on_smart_search}
                onChange={() => {
                  if (category.show_on_smart_search) {
                    setCategory({
                      ...category,
                      show_on_smart_search: !category.show_on_smart_search,
                      allow_content_search: false,
                      allow_event_search: false,
                      allow_creation_search: false,
                    })
                  }else {
                    setCategory({...category, show_on_smart_search: !category.show_on_smart_search})
                  }
                }}
              />
            }
            label={localization.get("form.show_on_smart_search")}
          />

          <br/>
          <span>{localization.get('form.allowed_search_by')}</span>
          <br/>
          <FormControlLabel
            control={
              <Checkbox
                style={{color: "#4cc2d5"}}
                checked={category.allow_content_search}
                onChange={() => setCategory({...category, allow_content_search: !category.allow_content_search})}
              />
            }
            label={localization.get("content_creators")}
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{color: "#4cc2d5"}}
                checked={category.allow_event_search}
                onChange={() => setCategory({...category, allow_event_search: !category.allow_event_search})}
              />
            }
            label={localization.get("events")}
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{color: "#4cc2d5"}}
                checked={category.allow_creation_search}
                onChange={() => setCategory({...category, allow_creation_search: !category.allow_creation_search})}
              />
            }
            label={localization.get("content_creations.materials")}
          />
          {this.props.client.use_rekognition &&
            <FormControlLabel
              control={
                <Checkbox
                  style={{color: "#4cc2d5"}}
                  checked={category.allow_people_search}
                  disabled={!category.allow_creation_search && !category.allow_content_search && !category.allow_event_search} 
                  onChange={() => setCategory({...category, allow_people_search: !category.allow_people_search})}
                />
              }
              label={localization.get("form.use_people_search")}
            />
          }
          <h5>{localization.get('form.show_on_global_search')}</h5>
          <FormControlLabel
            control={
              <Checkbox
                style={{color: "#4cc2d5"}}
                checked={category.allow_tag_search}
                disabled={!category.allow_creation_search && !category.allow_content_search && !category.allow_event_search} 
                onChange={() => setCategory({...category, allow_tag_search: !category.allow_tag_search})}
              />
            }
            label={localization.get("form.show_tag_in_global_search")}
          />
          <h5>{localization.get('smart_search')}</h5>
          {(!!category.allow_content_search || !!category.allow_event_search) &&
            <>
              <br/>
              <span>{localization.get('form.view_type_content')}</span>
              <br/>
              <Select
                value={category.view_type}
                onChange={(event) => setCategory({...category, view_type: event.target.value }) }
              >
                <MenuItem value={"grid4"}>{localization.get('form.grid')} x4</MenuItem>
                <MenuItem value={"grid6"}>{localization.get('form.grid')} x6</MenuItem>
                <MenuItem value={"grid4_rounded"}>{localization.get('form.grid_rounded')} x4</MenuItem>
                <MenuItem value={"grid6_rounded"}>{localization.get('form.grid_rounded')} x6</MenuItem>
                <MenuItem value={"featured2"}>{localization.get('form.featured_up')}</MenuItem>
                <MenuItem value={"featured2_alternative"}>{localization.get('form.featured_down')}</MenuItem>
                <MenuItem value={"movie"}>{localization.get('form.movieView')}</MenuItem>
                <MenuItem value={"movie_alternative"}>{localization.get('form.movieView.alternative')}</MenuItem>
                <MenuItem value={"rectangle_event"}>{localization.get('form.event_view')}</MenuItem>
              </Select>

              <FormControlLabel
                style={{marginLeft: 15}}
                control={
                  <Checkbox
                    style={{color: "#4cc2d5"}}
                    checked={category.content_sidebar_view}
                    onChange={() => setCategory({
                        ...category,
                        content_sidebar_view: !category.content_sidebar_view,
                        show_tag_selection: !category.content_sidebar_view ? false : category.show_tag_selection,
                        showTagSelection: !category.content_sidebar_view ? "0": category.showTagSelection,
                        showFilterOnSmartSearch: !category.content_sidebar_view ? "0": category.showFilterOnSmartSearch,
                        show_filter_on_smart_search: !category.content_sidebar_view ? false : category.show_tag_selection,
                      })}
                  />
                }
                label={localization.get("form.sidebar_view")}
              />
             {!!category.allow_event_search &&
                <FormControlLabel
                style={{marginLeft: 15}}
                control={
                  <Checkbox
                    style={{color: "#4cc2d5"}}
                    checked={category.random_order_events}
                    onChange={() => setCategory({
                        ...category,
                        random_order_events: !category.random_order_events,
                      })}
                  />
                }
                label={localization.get("form.order_date_random")}
              />
              }
              {category.view_type == "rectangle_event" &&
              <div style={{width: "100%", display: "flex"}}>
                <FormControl style={{marginRight: 15}}>
                <Tooltip title={localization.get('form.pill_background_color')} placement='top'>
                  <label style={{height: 40}}> {localization.get('form.pill_background_color')} </label>
                </Tooltip>
                <ColorPicker
                  id={'pillBackgroundColor'}
                  handleColorChange={color =>
                    setCategory({...category, preferences: {...category.preferences, pillBackgroundColor: color}})
                  }
                  startColor={get(category,"preferences.pillBackgroundColor")}
                  disableAlpha={true}
                />
                </FormControl>
                <FormControl >
                <Tooltip title={localization.get('form.pill_font_color')} placement='top'>
                  <label style={{height: 40}}> {localization.get('form.pill_font_color')} </label>
                </Tooltip>
                <ColorPicker
                  id={'pillFontColor'}
                  handleColorChange={color =>
                    setCategory({...category, preferences: {...category.preferences, pillFontColor: color}})
                  }
                  startColor={get(category,"preferences.pillFontColor")}
                  disableAlpha={true}
                />
                </FormControl>
              </div>
              }
              <br/>
            </>
          }

          {!!category.allow_creation_search &&
            <>
              <br/>
              <span>{localization.get('form.view_type_creation')}</span>
              <br/>
              <Select
                value={category.creation_view_type}
                onChange={(event) => setCategory({...category, creation_view_type: event.target.value }) }
              >
                <MenuItem value={"grid4"}>{localization.get('form.grid')}</MenuItem>
                <MenuItem value={"list"}>{localization.get('form.list')}</MenuItem>
                <MenuItem value={"movie"}>{localization.get('form.movieView')}</MenuItem>
                <MenuItem value={"movie_alternative"}>{localization.get('form.movieView.alternative')}</MenuItem>
              </Select>

              <FormControlLabel
                style={{marginLeft: 15}}
                control={
                  <Checkbox
                    style={{color: "#4cc2d5"}}
                    checked={category.sidebar_view}
                    onChange={() => setCategory({
                        ...category,
                        sidebar_view: !category.sidebar_view,
                        show_tag_selection: !category.sidebar_view ? false : category.show_tag_selection,
                        showTagSelection: !category.sidebar_view ? "0": category.showTagSelection,
                        showFilterOnSmartSearch: !category.sidebar_view ? "0": category.showFilterOnSmartSearch,
                        show_filter_on_smart_search: !category.sidebar_view ? false : category.show_tag_selection,
                      })}
                  />
                }
                label={localization.get("form.sidebar_view")}
              />
              <br/>
            </>
          }
          {!!category.show_on_smart_search &&
            <>
              <br/>
              <FormControl>
                <span>{localization.get('form.show_tag_selection')}</span>
                <RadioGroup
                  name="showTagSelection"
                  value={category.showTagSelection}
                  onChange={(event) =>
                    setCategory({...category, show_tag_selection: event.target.value === "1", showTagSelection: event.target.value})
                  }
                  style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                >
                  <FormControlLabel value="1" disabled={category.sidebar_view || category.content_sidebar_view} control={<Radio color="default"/>}
                                    label={localization.get('table.active')}/>
                  <FormControlLabel value="0" disabled={category.sidebar_view || category.content_sidebar_view} control={<Radio color="default"/>}
                                    label={localization.get('form.disabled')}/>
                </RadioGroup>
              </FormControl>
              <br/>
            </>
          }
          <FormControl>
            <span>{localization.get('form.show_search_input_on_smart_search')}</span>
            <RadioGroup
              name="showSearchInputOnSmartSearch"
              value={category.showSearchInputOnSmartSearch}
              onChange={(event) =>
                setCategory({...category, show_search_input_on_smart_search: event.target.value === "1", showSearchInputOnSmartSearch: event.target.value})
              }
              style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
            >
              <FormControlLabel value="1" control={<Radio color="default"/>} label={localization.get('table.active')}/>
              <FormControlLabel value="0" control={<Radio color="default"/>} label={localization.get('form.disabled')}/>
            </RadioGroup>
          </FormControl>
          <FormControl>
            <span>{localization.get('form.show_filters_on_smart_search')}</span>
            <RadioGroup
              name="showFilterOnSmartSearch"
              value={category.showFilterOnSmartSearch}
              onChange={(event) =>
                setCategory({...category, show_filter_on_smart_search: event.target.value === "1", showFilterOnSmartSearch: event.target.value})
              }
              style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
            >
              <FormControlLabel value="1" disabled={category.sidebar_view || category.content_sidebar_view} control={<Radio color="default"/>}
                                label={localization.get('table.active')}/>
              <FormControlLabel value="0" disabled={category.sidebar_view || category.content_sidebar_view} control={<Radio color="default"/>}
                                label={localization.get('form.disabled')}/>
              {/*<FormControlLabel value="header" control={<Radio color="default"/>} label={localization.get('form.show_on_header')}/>*/}
            </RadioGroup>
          </FormControl>

          <div style={{width: "100%"}}>
            <span>{localization.get('form.search_type')}</span>
            <div style={{width: "100%", float: "left", position: "relative", display: "flex", justifyContent: "space-between"}}>
              <RadioGroup
                name="searchType"
                value={category.searchType}
                onChange={(event) => {
                  switch (event.target.value) {
                    case "single":
                      category.support_negative_search = 0;
                      category.has_multiple_tags_allowed = 0;
                      break;
                    case "multi":
                      category.support_negative_search = 0;
                      category.has_multiple_tags_allowed = 1;
                      break;
                    case "negative":
                      category.support_negative_search = 1;
                      category.has_multiple_tags_allowed = 0;
                      break;
                  }

                  category.searchType = event.target.value;
                  setCategory(category)
                }}
                style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
              >
                <FormControlLabel value="single" control={<Radio color="default"/>}
                                  label={localization.get('form.search_single')}/>
                <FormControlLabel value="negative" control={<Radio color="default"/>}
                                  label={localization.get('form.search_negative')}/>
                <FormControlLabel value="multi" control={<Radio color="default"/>}
                                  label={localization.get('form.search_multi')}/>
              </RadioGroup>
              <IconButton>
                <Tooltip
                  title={localization.get('form.search_type_helper')}
                  placement='top'>
                  <Help />
                </Tooltip>
              </IconButton>
            </div>
            <br/>
          </div>
          <br/>
          {category.searchType === "multi" &&
            <>
              <FormControl style={{width: "100%", float: "left", position: "relative"}}>
                <span>{localization.get('form.smart_search_type_content')}</span>
                <div style={{
                  width: "100%",
                  float: "left",
                  position: "relative",
                  display: "flex",
                  justifyContent: "space-between"
                }}>
                  <RadioGroup
                    name="restricted_content_search"
                    value={category.restricted_content_search}
                    onChange={(event) =>
                      setCategory({...category, restricted_content_search: event.target.value})
                    }
                    style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                  >
                    <FormControlLabel value="1" control={<Radio color="default"/>}
                                      label={localization.get('table.restricted')}/>
                    <FormControlLabel value="0" control={<Radio color="default"/>}
                                      label={localization.get('table.comprehensive')}/>
                  </RadioGroup>
                  <IconButton>
                    <Tooltip
                      title={localization.get('form.smart_search_type_helper')}
                      placement='top'>
                      <Help/>
                    </Tooltip>
                  </IconButton>
                </div>
              </FormControl>

              <FormControl style={{width: "100%", float: "left", position: "relative"}}>
                <span>{localization.get('form.smart_search_type_materials')}</span>
                <div style={{
                  width: "100%",
                  float: "left",
                  position: "relative",
                  display: "flex",
                  justifyContent: "space-between"
                }}>
                  <RadioGroup
                    name="restricted_material_search"
                    value={category.restricted_material_search}
                    onChange={(event) => setCategory({...category, restricted_material_search: event.target.value})}
                    style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                  >
                    <FormControlLabel value="1" control={<Radio color="default"/>}
                                      label={localization.get('table.restricted')}/>
                    <FormControlLabel value="0" control={<Radio color="default"/>}
                                      label={localization.get('table.comprehensive')}/>
                  </RadioGroup>

                  <IconButton>
                    <Tooltip
                      title={localization.get('form.smart_search_type_helper')}
                      placement='top'>
                      <Help/>
                    </Tooltip>
                  </IconButton>
                </div>
              </FormControl>
            </>
          }

          {!category.allow_event_search && category.contentCreatorType.type === "editorial" &&
            <FormControl style={{width: "100%", float: "left", position: "relative"}}>
              <span>{localization.get('form.allow_filter_by_publish')}</span>
              <div style={{
                width: "100%",
                float: "left",
                position: "relative",
                display: "flex",
                justifyContent: "space-between"
              }}>
                <RadioGroup
                  name="support_publish_search"
                  value={category.support_publish_search ? "1": "0"}
                  onChange={(event) => setCategory({...category, support_publish_search: event.target.value === "1"})}
                  style={{width: "100%", float: "left", position: "relative", flexDirection: "row"}}
                >
                  <FormControlLabel value="1" control={<Radio color="default"/>}
                                    label={localization.get('table.active')}/>
                  <FormControlLabel value="0" control={<Radio color="default"/>}
                                    label={localization.get('form.disabled')}/>
                </RadioGroup>

                <IconButton>
                  <Tooltip
                    title={localization.get('form.smart_search_type_helper')}
                    placement='top'>
                    <Help/>
                  </Tooltip>
                </IconButton>
              </div>
            </FormControl>
          }
          <br/>

          <span>{localization.get('contact_email_helper')}</span>
          <TextValidator
            style={{width: "100%", marginBottom: "20px"}}
            label={localization.get('contact_email')}
            id="contact_email"
            name="contact_email"
            value={category.contact_email}
            onChange={e => setCategory({...category, contact_email: e.target.value}) }
          />
          <br/>
          <span>{localization.get('form.creator_view_type')}</span>
          <br/>
          <Select
            value={category.creator_view_type}
            onChange={(event) => setCategory({...category, creator_view_type: event.target.value }) }
          >
            <MenuItem value={"carousel"}>{localization.get('carousel')}</MenuItem>
            <MenuItem value={"grid"}>{localization.get('form.grid')}</MenuItem>
          </Select>
          <br/>
          <br/>
          <span>{localization.get('form.creator_view_type_mobile')}</span>
          <br/>
          <Select
            value={category.creator_view_type_mobile}
            onChange={(event) => setCategory({...category, creator_view_type_mobile: event.target.value }) }
          >
            <MenuItem value={"carousel"}>{localization.get('carousel')}</MenuItem>
            <MenuItem value={"grid"}>{localization.get('form.grid')}</MenuItem>
          </Select>

          <br/>
          <br/>
          {!!category.allow_content_search && !!category.show_on_smart_search &&
            <>
              {<h4>{localization.get('image.content')}</h4>}
              {!!category.content_image_id && <ImagePicker id={category.content_image_id} image={request.file.image.makeFittedUrl(category.content_image_id, 200, 200)} previewOnly={true} />}
              <FilePondUploader
                disableForm={category.disableForm}
                updateDisableForm={() => setCategory({...category, disableForm: !category.disableForm})}
                file={{}}
                id={"contentImage"}
                type={"image"}
                allowedTypes={["image/*"]}
                onIdChange={(image) => setCategory({...category, content_image_id: image.id})}
                clientId={this.props.clientId}
              />
            </>
          }


          {!!category.allow_event_search && !!category.show_on_smart_search &&
            <>
              {<h4>{localization.get('image.event')}</h4>}
              {!!category.event_image_id && <ImagePicker id={category.event_image_id}  image={request.file.image.makeFittedUrl(category.event_image_id, 200, 200)} previewOnly={true}/>}
              <FilePondUploader
                disableForm={category.disableForm}
                updateDisableForm={() => setCategory({...category, disableForm: !category.disableForm})}
                file={{}}
                id={"eventImage"}
                type={"image"}
                allowedTypes={["image/*"]}
                onIdChange={(image) => setCategory({...category, event_image_id: image.id})}
                clientId={this.props.clientId}
              />
            </>
          }

          {!!category.allow_creation_search && !!category.show_on_smart_search &&
            <>
              {<h4>{localization.get('image.material')}</h4>}
              {!!category.material_image_id && <ImagePicker id={category.material_image_id} image={request.file.image.makeFittedUrl(category.material_image_id, 200, 200)} previewOnly={true}/>}
              <FilePondUploader
                disableForm={category.disableForm}
                updateDisableForm={() => setCategory({...category, disableForm: !category.disableForm})}
                file={{}}
                id={"materialImage"}
                type={"image"}
                allowedTypes={["image/*"]}
                onIdChange={(image) => {
                  setCategory({...category, material_image_id: image.id})
                }}
                clientId={this.props.clientId}
              />
            </>
          }
           {(!!category.allow_content_search || !!category.allow_creation_search || !!category.allow_event_search) &&
             !!category.show_on_smart_search &&
            <TranslatableInputs
              children={AvailableLanguages.map(language => {
                const currentLangTranslation = translations.find(tr => tr.language === language);

                return {
                  language: language,
                  content: [
                    <>
                  {!!category.allow_content_search &&
                    <TextValidator
                      style={{width: "100%", marginBottom: 20}}
                      id={`contentLabel${language}`}
                      label={localization.get('form.contentLabel')}
                      name={`contentLabel${language}`}
                      value={currentLangTranslation.content_title}
                      onChange={e => {
                        const newTranslations = translations.map(tr => {
                          if (tr.language === language) {
                            return {...tr, content_title: e.target.value}
                          }
                          return tr
                        });

                        setCategory({ ...category, translations: newTranslations })
                      }}
                    />
                  }
                  {!!category.allow_event_search &&
                    <TextValidator
                      style={{width: "100%", marginBottom: 20}}
                      id={`eventLabel${language}`}
                      label={localization.get('form.eventLabel')}
                      name={`eventLabel${language}`}
                      value={currentLangTranslation.event_title}
                      onChange={e => {
                        const newTranslations = translations.map(tr => {
                          if (tr.language === language) {
                            return {...tr, event_title: e.target.value}
                          }
                          return tr
                        });

                        setCategory({ ...category, translations: newTranslations })
                      }}
                    />
                  }
                  {!!category.allow_creation_search &&
                    <TextValidator
                      style={{width: "100%", marginBottom: 20}}
                      id={`materialLabel${language}`}
                      label={localization.get('form.materialLabel')}
                      name={`materialLabel${language}`}
                      value={currentLangTranslation.material_title}
                      onChange={e => {
                        const newTranslations = translations.map(tr => {
                          if (tr.language === language) {
                            return {...tr, material_title: e.target.value}
                          }
                          return tr
                        });

                        setCategory({ ...category, translations: newTranslations })
                      }}
                    />
                  }
                    </>
                  ]
                }
              })}
            />
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose(originalCategory)} ><Close /></Button>
          <Button onClick={() => this.handleClose(category)}><Done /></Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default ConfigCategoryDialog;
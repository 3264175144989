import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Edit, Delete, Visibility, VisibilityOff, DragIndicator } from '@material-ui/icons';
import localization from '../../../config/localization';
import { IconButton } from '@material-ui/core';
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import {request} from '../../../api-client';
import { get } from 'lodash';
import styled from "styled-components";
import GlobalConfirmationDialog from '../../../components/Letflow/Dialog/GlobalConfirmationDialog';
import "./styles.css";

class Table extends Component {
  state = {
    rows: this.props.homeElements,
    draggingId: false, 
  };

  componentWillReceiveProps = (nextProps, prevProps) => {
    if (nextProps.homeElements) {
      this.setState({ rows: nextProps.homeElements });
    }
  
};

  onDragStart = (result) => {
    this.setState({ draggingId: result.draggableId });
  };

  onDragEnd = (result) => {
    this.setState({ draggingId: false });

    if (!result.destination) return;

    const items = Array.from(this.state.rows);
    const [reorderedItem] = items.splice(result.source.index, 1);


    if (reorderedItem.type !== 'banner' && result.destination.index == 0) {
      GlobalSnackbar.show({
        message: localization.get('home_editor.banner_must_be_first'),
        type: GlobalSnackbarTypes.ERROR,
      })
      return; 
    }

    items.splice(result.destination.index, 0, reorderedItem);
    
    return this.updateOrder(items)
  };

  updateOrder = async (items) => {
    const orderData = items.map((element, index) => {
        return ({...element, order: index})
      })

    this.props.setHomeElements(orderData);

    return request.client.home.elements.updateOrder(this.props.clientId, orderData)
      .then(() => {
        GlobalSnackbar.show({
          message: localization.get('home_editor.order_updated'),
          type: GlobalSnackbarTypes.SUCCESS,
        })
      })
      .catch(error => GlobalSnackbar.showGenericError())
    }

  updateActive = async (element) => {
    element.active = !element.active
    this.setState({ rows: this.state.rows })
    request.client.home.elements.updateActive(this.props.clientId, element.id, !element.active ? 0 : 1)
  }
  requestDelete = (id) => request.client.home.elements.delete(this.props.clientId, id)
    .then(this.props.onUpdateSuccess)
    .catch(error => GlobalSnackbar.showGenericError())
  handleDelete = element => GlobalConfirmationDialog.showDelete({ elementName: element.reference, deleteRequest: () => this.requestDelete(element.id)})

  render() {
    const getRowColor = (row) => {
      if(!row.active) return "#ccc";
      
      switch (row.type) {
        case 'banner':
          const banners = get(row, "items", []);

          if (this.props.showBanner == "none" || !banners.find(banner => banner.active)) return "#ccc";

          return '#cceeff'; // azul claro
        case 'featured':
          return '#ccffcc'; // verde claro
        case 'carousel':
          return '#ffffcc'; // amarillo claro
        case 'grid':
          return '#ffcccc'; // rojo claro
        case 'unique':
          return 'rgb(229 222 250)'; // púrpura claro
        case 'event_grid':
          return '#ffcc99'; // naranja claro
        default:
          return '#fff'; // blanco por defecto
      }
    };

    const getFeaturedType = (row) => row.inverted ? "inverted" : row.editorial ? "editorial" : row.editorial_inverted ? "editorial_inverted" : row.movie ? (row.alternative ? "movie_alternative" : "movie") : (row.news || row.news_inverted) ? "news" : "default";
    const type = (row) => row.grid_featured ? 'grid_featured' : row.type;
    const getTitle = row => row.type == "banner" ? localization.get(`account.banner.${this.props.showBanner}`) : row.reference;
    const getTemplate = row => row.type == "banner" ?  "Banner" : (["grid_featured", "featured"].includes(type(row)) ? localization.get(`home_editor.${getFeaturedType(row)}`) : (get(row, 'shape') ? localization.get(`shape.${get(row, 'shape')}`): "x1"))


    return (
      <>
        <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
          <Droppable droppableId="table">
            {(provided) => (
              <HomeElementList
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid #ddd' }}
              >
                <thead>
                  <tr style={{ backgroundColor: '#f2f2f2', borderBottom: '1px solid #ddd' }}>
                    <th style={{ padding: '8px', width: 24 }} />
                    <th style={{ padding: '8px', textAlign: 'left' }}>{localization.get('reference')}</th>
                    <th style={{ padding: '8px', textAlign: 'left' }}>{localization.get('type')}</th>
                    <th style={{ padding: '8px', textAlign: 'left' }}>{localization.get('home_editor.style')}</th>
                    <th style={{ padding: '8px', textAlign: 'left' }}>{localization.get('home_editor.data_source')}</th>
                    <th style={{ padding: '8px', textAlign: 'right', width: 148 }}></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.rows.filter(hm => !hm.parent).sort((a, b) => a.order - b.order)
                    .map((row, index) => (
                    <Draggable key={row.id} draggableId={row.id} index={index} isDragDisabled={row.type === 'banner'} style={{minWidth: "100%", display: "flex", justifyContent: "space-between"}}>
                      {(provided) => (
                        <tr
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            backgroundColor: getRowColor(row),
                            ...provided.draggableProps.style,
                          }}
                          class="table-hm-row"
                        >
                          <td >{row.type !== "banner" && <DragIndicator class="table-hm-drag" style={{cursor: "drag"}}/>}</td>
                          <td style={{ padding: "0 10px" }}>{getTitle(row)} {!row.active && <span style={{textTransform: "uppercase"}}>({localization.get('catalog.inactive')})</span>}</td>
                          <td style={{ width: 180 }}>{localization.get(`home_editor.${row.type}_element`)} {row.type == "grid" && `${row.columns}x${row.rows_to_show}`} {row.type == "featured" && `${row.shape}`}</td>
                          <td style={{ width: 180 }}>{getTemplate(row)}</td>
                          <td style={{ width: 250 }}>{localization.get(`data_source.${row.data_source}`)}</td>
                          <td style={{ width: 148, textAlign: "end", display: "flex", justifyContent: "center", alignItems: "center"}}>
                            {get(this.state, "draggingId") != row.id &&
                              <>
                                {row.type != "banner" && <IconButton onClick={() => this.updateActive(row)}>{row.active ? <Visibility /> : <VisibilityOff /> }</IconButton> }
                                <IconButton onClick={() => this.props.onEditHomeElement(row)}><Edit /></IconButton>
                                {row.type != "banner" && <IconButton onClick={() => this.handleDelete(row)}><Delete /></IconButton>}
                              </>
                            }
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  ))}
                </tbody>
              </HomeElementList>
            )}
          </Droppable>
        </DragDropContext>
      </>
    );
  }
}

export default Table;


const HomeElementList = styled.table`
@media (max-width: 1300px) {
  .responsive-cell {
    display: none;
  }
}

@media (max-width: 1240px) {
  th:nth-child(4),
  td:nth-child(4) {
    display: none;
  }
}

@media (max-width: 1100px) {
  th:nth-child(3),
  td:nth-child(3) {
    display: none;
  }
}
`
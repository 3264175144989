import React, {Component} from "react";
import {TableButton, TableButtonsBuilder} from "../../components/Letflow/Table";
import {CloudDownload, MoreVert} from "@material-ui/icons";
import {
  Button,
  CardContent,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle, FormControl, FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  Paper, Radio, RadioGroup, Typography,
} from "@material-ui/core";
import {request} from "../../api-client";
import {getMostImportantAlbum, makeWaveformUrl} from "../../utils";
import LicenseSubcomponent from "./LicenseSubcomponent";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import {LicenseTypeIds, MediaTypeIds, SadaicCategories} from "../../components/Letflow/LicenseWizard/utils/constants";
import {licenseTypeIdToString} from "../../components/Letflow/LicenseWizard/utils/mappers";
import moment from "moment";
import ServerSidePaginationTable from "../../components/Letflow/Table/ServerSidePaginationTable";
import {getStoredUser, userCanDeleteProjectMaterials, userIsArtist, userIsSupervisor} from "../../api-client/core/authentication/utils";
import localization from "../../config/localization";
import IconButton from "@material-ui/core/IconButton";
import Remove from "@material-ui/core/SvgIcon/SvgIcon";
import GlobalSoundBar from "../../sound-bar/GlobalSoundBar";
import red from "@material-ui/core/es/colors/red";
import CatalogSelection from "../../components/Letflow/CatalogSelection";
import FilePondUploader from "../../components/Letflow/FilePondUploader";
import GlobalSnackbar from "../../components/Letflow/Snackbar/GlobalSnackbar";
import {get} from 'lodash'

const states = {
  EXPIRED: "EXPIRED",
  ON_GOING: "ON_GOING",
};

const OWN_MEDIA_TYPES = {
  TELEVISION: localization.get('license.television'),
  RADIO: localization.get('license.radio'),
  INTERNET: localization.get('license.internet'),
  //RADIO_INTERNET: localization.get('license.radio_internet'),
  VPNT: localization.get('license.vpnt'),
  TV_INCLUSION: localization.get('license.tv_inclusion'),
  ALL: localization.get('license.all'),
  toArray: () => [
    OWN_MEDIA_TYPES.TELEVISION,
    OWN_MEDIA_TYPES.RADIO,
    //OWN_MEDIA_TYPES.RADIO_INTERNET,
    OWN_MEDIA_TYPES.INTERNET,
    OWN_MEDIA_TYPES.ALL,
    OWN_MEDIA_TYPES.VPNT,
    OWN_MEDIA_TYPES.TV_INCLUSION,
  ],
  toDisplayString: media => {
    switch (media) {
      case OWN_MEDIA_TYPES.TELEVISION:
        return localization.get('license.television');
      case OWN_MEDIA_TYPES.RADIO:
        return localization.get('license.radio');
      // case OWN_MEDIA_TYPES.RADIO_INTERNET:
      //   return localization.get('license.radio_internet');
      case OWN_MEDIA_TYPES.INTERNET:
        return localization.get('license.internet');
      case OWN_MEDIA_TYPES.ALL:
        return localization.get('license.all');
      case OWN_MEDIA_TYPES.VPNT:
        return localization.get('license.vpnt');
      case OWN_MEDIA_TYPES.TV_INCLUSION:
        return localization.get('license.tv_inclusion');
    }
  },
};

export const mapMediaFromApiLicense = license => {
  switch (license.type.id) {
    case LicenseTypeIds.CORPORATIVO_CANALES_PROPIOS:
      return OWN_MEDIA_TYPES.INTERNET;
    case LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL_ARGENTINA:
      switch (license.category.id) {
        case SadaicCategories.RESTRINGIDO_I:
          return OWN_MEDIA_TYPES.TELEVISION;
        case SadaicCategories.RESTRINGIDO_II_III:
          return OWN_MEDIA_TYPES.RADIO;
        case SadaicCategories.RESTRINGIDO_IV:
          return OWN_MEDIA_TYPES.INTERNET;
        case SadaicCategories.USO_TOTAL:
          return OWN_MEDIA_TYPES.ALL;
        case SadaicCategories.VPNT:
          return OWN_MEDIA_TYPES.VPNT;
        case SadaicCategories.INCLUSION_EN_TV:
          return OWN_MEDIA_TYPES.TV_INCLUSION;
      }
    case LicenseTypeIds.PUBLICIDAD_INSTITUCIONAL:
      if (license.medias.length === 1) {
        switch (license.medias[0].id) {
          case MediaTypeIds.INTERNET:
            return OWN_MEDIA_TYPES.INTERNET;
          case MediaTypeIds.RADIO:
            return OWN_MEDIA_TYPES.RADIO;
          case MediaTypeIds.TELEVISION:
            return OWN_MEDIA_TYPES.TELEVISION;
          case MediaTypeIds.ALL_MEDIA:
            return OWN_MEDIA_TYPES.ALL;
        }
      } else {
        return OWN_MEDIA_TYPES.RADIO_INTERNET;
      }
  }
  return "";
};

const canDeleteIfWhitelisted = () => {
  let whitelistedEmails = process.env.REACT_APP_WHITELIST_LICENSES_DELETE
  if (!whitelistedEmails) {
    return false
  }
  whitelistedEmails = whitelistedEmails.split(',')
  return whitelistedEmails.find(whitelistedEmail => whitelistedEmail === getStoredUser().email)
}

class LicenseListing extends Component {
  state = {
    licenseTotalCount: 0,
    licenseWithNewMaterialTotalCount: 0,
    menuAnchor: null,
    selectedLicense: null,
    showLicenses: "all",
    brand: null,
    brands: [],
  };

  constructor(props) {
    super(props);
    this.menuButtonRefs = {};
    this.uploadButtonRefs = {};
  }

  componentDidMount = () => {
    document.title = localization.get('title.on_going_license') + ' - Feater';
    (!userIsArtist() && !getStoredUser().artist_view) && this.fetchOnGoingLicensesWithNewMaterials({ perPage: 12, page: 0 });
     request.brands.getAll({orderBy: {column: "name", type: "ASC"}})
      .then(res => this.setState({ brands: res.map(brand => ({value: brand.id, label: brand.name}))}))
  };

  fetchOnGoingLicenses = pagination => {
    if (!pagination.orderBy) {
      pagination.orderBy = { column: "starts_at", type: "desc" }
    }
    if (this.state.catalog) {
      pagination.filterBy.push({ column: 'catalog_id', filter: this.state.catalog.value });
    }

    if (this.state.showLicenses !== 'all') {
      pagination.filterBy.push({ column: 'state', filter: this.state.showLicenses });
    }

    // if (userIsArtist()) {
      return request.license.getAll(pagination).then(res => this.handleObtainedLicensesPayload(res, "licenseTotalCount"));
    // }
    // return request.license.getAllOnGoing(pagination).then(res => this.handleObtainedLicensesPayload(res, "licenseTotalCount"));
  };


  fetchOnGoingLicensesWithNewMaterials = pagination =>
    request.license
      .getAllOnGoingWithNewMaterials(pagination)
      .then(res => this.handleObtainedLicensesPayload(res, "licenseWithNewMaterialTotalCount"));

  handleObtainedLicensesPayload = (licensesPayload, totalCountStateProperty) => {
    this.setState({ [totalCountStateProperty]: licensesPayload.meta.total });
    return { ...licensesPayload, data: licensesPayload.data.map(this.mapLicenses) };
  };


  makeDownloadLink = () => {
    let downloadLink = this.state.brand ? `?brand_id=${this.state.brand}` : "";

    console.log(this.state.brand)
    request.license.getDownloadLink(downloadLink)
        .then(url => window.open(url, "_blank"));
  };

  mapLicenses = license => ({
    id: license.id,
    client: license.client,
    user: license.user ? license.user.name : '',
    track: license.music,
    sheet: license.sheet && license.sheet.url,
    receipts: license.receipts.length > 0 && request.license.getLicenseReceiptsZipUrl(license.id),
    contract: license.contract && license.contract.url,
    state: license.state === "accepted" ? states.ON_GOING : license.state === "expired" ? states.EXPIRED : null,
    starts_at: license.starts_at,
    ends_at: license.ends_at,
    exclusive: license.exclusivity,
    exclusive_until: license.exclusive_until || undefined,
    project: license.project || undefined,
    albums: license.music.albums,
    artist: license.music && license.music.artist ? license.music.artist : undefined,
    licenseType: license.type,
    regions: license.regions || undefined,
    extents: license.extents || undefined,
    price: license.price || undefined,
    discount: license.discount || undefined,
    reductions: license.reductions || undefined,
    unlimited_reductions: license.unlimited_reductions || undefined,
    media: mapMediaFromApiLicense(license),
    paymentStatus: license.payment_status || undefined,
    brand: license.brand || null
  });

  showMaterials = () => {
    const { project } = this.state;
    return (
      <Dialog
        open={this.state.showMaterialDialog}
        onBackdropClick={() => this.setState({ showMaterialDialog: false })}
        onEscapeKeyDown={() => this.setState({ showMaterialDialog: false })}
      >
        <DialogTitle>{localization.get('account.license.button.materials')}</DialogTitle>
        <DialogContent style={{ minWidth: 400 }}>
          {project.urls.length > 0 && (
            <React.Fragment>
              <Paper style={{ padding: "0 15px" }}>
                <br />
                <Typography variant="title">URLs</Typography>
                <br />
                {project.urls.map((x, i) => (
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <a style={{ color: "black", textDecoration: "underline" }} href={x}>
                      {x}
                    </a>
                    <IconButton
                      onClick={() => this.handleDelete(project, i, "url")}
                      disabled={!userCanDeleteProjectMaterials()}
                    >
                      <Remove />
                    </IconButton>
                  </div>
                ))}
                <div style={{ height: 20 }} />
              </Paper>
              <br />
              <br />
            </React.Fragment>
          )}
          {this.renderMediaGrid(
            "Videos",
            project.videos,
            "video",
            x => (
              <video style={{ maxWidth: 300 }} src={x.url} controls />
            )
          )}
          {this.renderMediaGrid(
            "Audios",
            project.audios,
            "audio",
            x => (
              <audio style={{ maxWidth: 300 }} src={x.url} controls />
            )
          )}
        </DialogContent>
      </Dialog>
    )
  }

  renderMediaGrid = (title, medias, type, content) =>
    medias.length > 0 && (
      <React.Fragment>
        <Paper style={{ padding: "0 15px" }}>
          <br />
          <Typography variant="title">{title}</Typography>
          <br />
          {medias.map(x => (
            <Grid item>
              <Card style={{ width: "100%" }}>
                <CardContent>{content(x)}</CardContent>
              </Card>
            </Grid>
          ))}
        </Paper>
        <br />
        <br />
      </React.Fragment>
    );

  handleAlertClosed = id => status => {
    if (status === "passed") this.refs.table.removeById(id);
  };

  columns = total => {
    let columns = [
      {
        id: "starts_at",
        Header: localization.get('table.starts_at'),
        accessor: "starts_at",
        filterable: false,
        Cell: cell => {
          return <span>{moment(cell.value).format("DD-MM-YYYY")}</span>;
        },
      },
      {
        id: "brand[id]",
        Header: localization.get('table.client'),
        accessor: license => license.brand ? license.brand.name : license.client ? license.client.name : "", 
        sortable: false,
        Filter: ({ filter, onChange }) => (
          <select value={filter ? filter.value : ""} onChange={e => { this.setState({brand: e.target.value}); onChange(e.target.value) }}>
            <option value={""}>---</option>
            {this.state.brands.map(x => (
              <option key={x.value} value={x.value}>
                {x.label}
              </option>
            ))}
          </select>
        ),
        
      },
      {
        id: "music[title]",
        Header: localization.get('table.track_title'),
        accessor: license => license.track.title,
        sortable: false,
      },
      {
        id: "catalog_id",
        Header: localization.get('table.catalog'),
        accessor: license => <span>{license.track.catalog && license.track.catalog.name}</span>,
        sortable: false,
        filterable: false,
      },
      {
        id: "musicDealer",
        Header: localization.get('music_dealer'),
        accessor: license => <span>{get(license, 'track.catalog.musicDealer.name')}</span>,
        sortable: false,
        filterable: false,
      },
      {
        id: "actions",
        Header: localization.get('table.actions'),
        sortable: false,
        filterable: false,
        Footer: (
          <span style={{ float: "right", fontSize: "18px", marginTop: "15px" }}>
          <strong>Total: </strong>
            {total}
        </span>
        ),
        accessor: license => {
          const builder = new TableButtonsBuilder();

          {userIsSupervisor() &&
            builder.withDelete(localization.get('audit_log.license'), () => request.license.delete(license.id),
              this.handleAlertClosed(license.id),
              false, `${localization.get('license.track')}: ${license.track.title}`)
          }
          builder.withOther(
            <span ref={r => (this.menuButtonRefs[license.id] = r)}>
            <TableButton
              title={localization.get("see_more")}
              onClick={() =>
                this.setState({ menuAnchor: this.menuButtonRefs[license.id], selectedLicense: license })
              }
            >
              <MoreVert/>
            </TableButton>
          </span>
          )
          return builder.build();
        },
      },
    ];

    if(userIsArtist() || getStoredUser().artist_view) {
      columns = columns.filter((column) => !(['actions', 'price'].includes(column.id)))
    }

    return columns
  }

  downloadTrack = () => request.music.download(this.state.selectedLicense.track.id);

  playButtonPressed = () => {
    const { selectedLicense } = this.state;

    const tableLicenses = this.refs.table.getData()

    const tableLicenseTracks = tableLicenses.map(tableLicense => tableLicense.track)

    const playButtonTracks = tableLicenseTracks.map(tableLicenseTrack => ({
      fetchAudioUrl: () => request.music.audioUrl(tableLicenseTrack.id),
      waveform: makeWaveformUrl(tableLicenseTrack),
      id: tableLicenseTrack.id,
      title: tableLicenseTrack.title,
      image:
        tableLicenseTrack.albums.length > 0 &&
        request.file.image.makeCroppedUrl(getMostImportantAlbum(tableLicenseTrack.albums).image.id, 200, 200),
      subtitle: tableLicenseTrack.albums.length > 0 && getMostImportantAlbum(tableLicenseTrack.albums).title,
      onDownload: () => request.music.download(tableLicenseTrack.id),
    }))

    const currentLicenseTrackIndex = tableLicenses.findIndex(tableLicense => tableLicense.id === selectedLicense.id)

    GlobalSoundBar.loadTracksAndPlay(playButtonTracks, currentLicenseTrackIndex)
  }

  updateLicense = () => this.setState({showUpdateLicenseDialog: true})

  updateLicenseDialog = () =>
    <Dialog maxWidth='sm' fullWidth={true} open={this.state.showUpdateLicenseDialog}>
      <DialogTitle>
        {localization.get("license.update")}
      </DialogTitle>
      <DialogContent style={{paddingBottom: 0}}>
        <FilePondUploader
          id={"binary"}
          allowedTypes={["application/pdf"]}
          onIdChange={file => this.setState({newContractId: file ? file.id : null})}
          isLicense
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => this.setState({showUpdateLicenseDialog: false})}>{localization.get("cancel")}</Button>
        <Button onClick={this.onUpdateLicense}>{localization.get("form.submit")}</Button>
      </DialogActions>
    </Dialog>

  onUpdateLicense = () => {
    request.license.update(this.state.selectedLicense.id, {newContractId: this.state.newContractId})
      .then(() => GlobalSnackbar.showGenericSuccess())
      .then(() => this.setState({showUpdateLicenseDialog: false}, () => this.refs.table.forceUpdate()))
      .catch(() => GlobalSnackbar.showGenericError())
  }

  render = () => {

    const { selectedLicense } = this.state;

    const MenuWrapper = ({ anchorStateProperty, children }) => (
      <Menu
        anchorEl={this.state[anchorStateProperty]}
        open={this.state[anchorStateProperty] !== null}
        onClose={() => this.setState({ [anchorStateProperty]: null })}
      >
        {children}
      </Menu>
    );

    const MenuItemWrapper = ({ anchorStateProperty, children, onClick = () => { } }) => (
      <MenuItem
        onClick={() => {
          onClick();
          this.setState({ [anchorStateProperty]: null });
        }}
      >
        {children}
      </MenuItem>
    );

    return (
      <div>
        {this.updateLicenseDialog()}
        {this.state.project && this.showMaterials()}
        {/** Actions menu */}
        <MenuWrapper anchorStateProperty="menuAnchor">

          {selectedLicense && selectedLicense.track && (
            <a onClick={() => this.playButtonPressed()} href={'#'}>
              <MenuItemWrapper anchorStateProperty="menuAnchor">{localization.get('license.play')}</MenuItemWrapper>
            </a>
          )}
          {selectedLicense && selectedLicense.contract && (
            <a target="_blank" href={this.state.selectedLicense.contract}>
              <MenuItemWrapper anchorStateProperty="menuAnchor">{localization.get('license.download_license')}</MenuItemWrapper>
            </a>
          )}
          {selectedLicense && selectedLicense.track && (
            <MenuItemWrapper anchorStateProperty="menuAnchor" onClick={this.downloadTrack}>
              {localization.get('license.download_track')}
            </MenuItemWrapper>
          )}
          {selectedLicense && selectedLicense.track && (
            <MenuItemWrapper anchorStateProperty="menuAnchor" onClick={this.updateLicense}>
              {localization.get('license.update')}
            </MenuItemWrapper>
          )}
        </MenuWrapper>

        {/** Tables */}

        {/** Has new materials */}
        {(!userIsArtist() && !getStoredUser().artist_view && this.state.licenseWithNewMaterialTotalCount > 0) && (
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardBody>
                  <Typography variant="title">{localization.get('licenses.with_new_comercials')}</Typography>
                  <br />
                  <ServerSidePaginationTable
                    ref="table"
                    requestTableData={pagination => this.fetchOnGoingLicensesWithNewMaterials(pagination)}
                    columns={this.columns(this.state.licenseWithNewMaterialTotalCount)}
                    subComponent={row => <LicenseSubcomponent row={row} />}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        )}

        {/** On going licenses */}
        <GridContainer>
          <GridItem xs={12}>
            <Card style={{paddingTop: "15px"}}>
              <FormControl style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <RadioGroup
                  name="showLicenses"
                  value={this.state.showLicenses}
                  onChange={event => {
                    this.setState({ showLicenses: event.target.value })
                    this.refs.table.forceUpdate();
                  }}
                  style={{ width: "100%", float: "left", position: "relative", flexDirection: "row", padding: "0px 20px"}}
                >
                  <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="all" control={<Radio color="default" />} label={localization.get('clients.all')} />
                  <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="accepted" control={<Radio color="default" />} label={localization.get('account.license.on_going')} />
                  <FormControlLabel style={{ width: "fit-content", float: "left", position: "relative" }} value="expired" control={<Radio color="default" />} label={localization.get('account.license.expired')} />
                </RadioGroup>
                <IconButton onClick={this.makeDownloadLink}>
                  <CloudDownload />
                </IconButton>
              </FormControl>
              
              <CatalogSelection
                catalog={this.state.catalog}
                onChange={(catalog) => this.setState({catalog}, () => this.refs.table.forceUpdate())}
                hasRelation={'has_licenses'}
              />
              <CardBody style={{width: "100%", float: "left", position: "relative"}}>
                <ServerSidePaginationTable
                  ref="table"
                  requestTableData={pagination => this.fetchOnGoingLicenses(pagination)}
                  columns={this.columns(this.state.licenseTotalCount)}
                  subComponent={row => <LicenseSubcomponent row={row} />}
                  defaultSorted={[
                    {
                      id: "starts_at",
                      desc: true
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  };
}

export default LicenseListing;

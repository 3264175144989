import {post} from '../utils'

export default host => (channelId, data) =>
  post(`${host}/channels/${channelId}/user/recentSearch`, makeJson(data))
    .then(response => response.json())
    .then(json => json.data);


const makeJson = (data) => {
    const json = {}

    json.query = data.query;
    json.searchable_id = data.searchableId;
    json.searchable_type = data.searchableType;

    return JSON.stringify(json);
}

import React, {Component} from 'react'
import {
  Button,
  Checkbox, Dialog, DialogActions, DialogContent, DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem, Radio, RadioGroup, Select, TextField,
  Typography
} from '@material-ui/core';
import {Close, Done} from '@material-ui/icons'
import localization from '../../config/localization';
import {request} from '../../api-client';
import GlobalSnackbar, {GlobalSnackbarTypes} from '../../components/Letflow/Snackbar/GlobalSnackbar';
import GlobalConfirmationDialog from '../../components/Letflow/Dialog/GlobalConfirmationDialog';
import TranslatableInputs from "../../components/Letflow/TranslatableInputs";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import AsyncSelect from "react-select/lib/Async";
import HomeElementCreateDialogTagSelection from "./HomeElementCreateDialogTagSelection";
import HomeElementStyleSelection from "./HomeElementStyleSelection";
import CustomElement from "./CustomElement";
import FilePondUploader from "../../components/Letflow/FilePondUploader";
import {getUpdateFormImageUrlFromItem, validateUrl} from "../../utils";
import {ImagePicker} from "../../components/Letflow/Form";
import {DatePicker, MuiPickersUtilsProvider} from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import EditorialEditor from "../../components/Letflow/RichTextEditor/EditorialEditor";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import {stateToHTML} from "draft-js-export-html";
import {clearUnwantedStateStyles} from "../../components/Letflow/RichTextEditor/utils";
import AvailableLanguages from "../../config/localization/availableLanguages.json";
import {get} from "lodash";
import ColorPicker from "../../components/Letflow/ColorPicker";
import HomeElementEventPreferences from "./HomeElementEventPreferences";

export const dataSourceTypes = [
  {value: 'home', label: localization.get(`data_source.home`)},
  {value: 'my_list', label: localization.get(`data_source.my_list`)},
  {value: 'contact_form', label: localization.get(`data_source.contact`)},
  {value: 'smart_search', label: localization.get(`data_source.advanced_search`)},
  {value: 'drop_down', label: localization.get(`data_source.drop_down`)},
  {value: 'artist', label: localization.get(`data_source.artist`)},
  {value: 'content_creator_talent', label: localization.get(`data_source.content_creator`)},
  {value: 'content_creator_audiovisual', label: localization.get(`data_source.content_creator_audiovisual`)},
  {value: 'content_creator_editorial', label: localization.get(`data_source.content_creator_editorial`)},
  {value: 'video_content_creation', label: localization.get(`data_source.video_content_creation`)},
  {value: 'channel_playlist', label: localization.get(`data_source.channel_playlist`)},
  {value: 'user_library', label: localization.get(`data_source.user_library`)},
  {value: 'catalog', label: localization.get(`data_source.catalog`)},
  {value: 'recommended', label: localization.get(`data_source.recommended`)},
  {value: 'production', label: localization.get(`data_source.production`)},
  {value: 'section', label: localization.get(`section.access`)},
  {value: 'single_content_creator', label: localization.get(`data_source.single_content_creator`)},
  {value: 'link', label: localization.get(`data_source.link`)},
  {value: 'custom', label: localization.get(`data_source.custom`)},
  {value: 'custom_embed', label: localization.get(`data_source.custom_embed`)},
  {value: 'text', label: localization.get(`data_source.text`)},
  {value: 'video', label: localization.get(`data_source.video_banner`)},
  {value: 'image', label: localization.get(`data_source.image`)},
  {value: 'google_ad', label: localization.get(`data_source.google_ad`)},
  {value: 'change_language', label: localization.get(`data_source.change_language`)},
  {value: 'embed', label: localization.get(`data_source.embed`)},
  {value: 'lite_smart_search', label: localization.get(`data_source.lite_smart_search`)},
  {value: 'playlist_link', label: localization.get(`data_source.playlist_link`)},
  {value: 'playlist_link', label: localization.get(`data_source.playlist_link`)},
  {value: 'static', label: localization.get(`data_source.static`)},
];

const isCustomDate = val => !(["none", "today", "tomorrow", "week", "weekend", "next_week", "month", "range", "yesterday", "week", "last_week", "customHours"]).includes(val);


export const checkDataSourcesAgainstPermissions = (permissions, dataSourceTypes, channel = null, channelId = null, parent = null, custom = false, unique = false) => {
  let dataSourceTypesUpdated = dataSourceTypes;
  const creatingElements = !!channel && !!channel.creatingElements
  const isRedirectButtonElement = !!channel && !!channel.redirectButton
  const isEmbed = !!channel && !!channel.isEmbed;

  if ((permissions.length > 0 && !permissions.find(permission => permission.entity === 'content_creator'))) {
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'content_creator_talent')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'content_creator_audiovisual')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'content_creator_editorial')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'video_content_creation')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'single_content_creator')
  }

  if ((permissions.length > 0 && !permissions.find(permission => permission.entity === 'music'))) {
    const musicDataSources = ['catalog', 'artist'];
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => !musicDataSources.includes(dataSourceType.value))
  }

  if (permissions.length > 0 && !permissions.find(permission => permission.entity === 'pitch') || !!channel) {
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'production')
  }

  if (!!channel) {
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'user_library')
    if (channel.type === "content") {
      const musicDataSources = ['catalog', 'artist'];
      dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => !musicDataSources.includes(dataSourceType.value))
      dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'recommended')
    }
  }else {
    if(!channelId) {
      dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'channel_playlist')
      dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'custom')
      dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'custom_embed')
    }
  }

  if(parent && parent.id) {
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'video_content_creation')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'my_list')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'playlist_link')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'home')
    if (parent.data_source === "sub_navbar" || parent.type === "footer") {
      dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'drop_down')
    }
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'custom')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'custom_embed')
  }


  if(!creatingElements) {
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'link')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'my_list')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'playlist_link')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'smart_search')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'contact_form')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'home')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'drop_down')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'single_content_creator')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'section')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'change_language')
  }else if (unique) {
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'text')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'video')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'image')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'google_ad')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'video_content_creation')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'lite_smart_search')
  }else {
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'video_content_creation')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'custom')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'custom_embed')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'recommended')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'artist')
  }

  if (custom) {
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'production')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'user_library')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'my_list')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'playlist_link')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'smart_search')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'contact_form')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'home')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'drop_down')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'recommended')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'video_content_creation')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'artist')
  }else {
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'static')
  }

  if (isRedirectButtonElement) {
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'production')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'user_library')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'my_list')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'playlist_link')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'smart_search')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'contact_form')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'home')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'drop_down')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'recommended')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'link')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'change_language')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'embed')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'single_content_creator')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'section')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'channel_playlist')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'text')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'video')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'image')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'google_ad')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'video_content_creation')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'lite_smart_search')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'artist')
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'static')
  }

  if (isEmbed) {
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value == 'embed')
  }else {
    dataSourceTypesUpdated = dataSourceTypesUpdated.filter(dataSourceType => dataSourceType.value !== 'embed')
  }

  return dataSourceTypesUpdated
};

class HomeGridAndCarouselBase extends Component {

  constructor(props) {
    super(props)

    if (!props.title) {
      this.originalTitle = "";
    } else if (props.title.startsWith("{")) {
      const editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(props.title)));
      const content = editorState.getCurrentContent();
      this.originalTitle = content.getPlainText();
    } else {
      this.originalTitle = props.title;
    }

    this.originalDataSource = props.data_source
    this.originalColumns = props.columns || 4
    this.originalIncludeGold = props.include_gold || 0

    let isHtmlTitle = false;
    const translations = AvailableLanguages.map(language => {
      const translation = props.translations && props.translations.find(tr => tr.locale ===language)

      if (translation) {
        let content;
        if(!translation.title || !translation.title.startsWith("{")) {
          content = translation.title;
        } else {
          isHtmlTitle=true
          content = EditorState.createWithContent(convertFromRaw(JSON.parse(translation.title)));
        }


        return {
          language: translation.locale,
          title: content,
          author: translation.additions && translation.additions.split(';')[0] || "",
          category: translation.additions && translation.additions.split(';')[1] || "",
          synopsis: translation.synopsis,
        };
      }

      return  {
        language: language,
        title: "",
        author: "",
        category: "",
        synopsis: "",
      }
    })

    if (isHtmlTitle) {
      translations.map(tr => {
        if (typeof tr.title !== "object") {
          return {...tr, title: EditorState.createEmpty()}
        }
        return tr
      })
    }

    const uniqueTexts = AvailableLanguages.map(language => {
      const translation = { language }
      if (props[`text_${language}`] && props[`text_${language}`] !== 'undefined') {
        translation.text = EditorState.createWithContent(convertFromRaw(JSON.parse(props[`text_${language}`])))
      }else {
        translation.text = new EditorState.createEmpty();
      }

      return translation;
    })

    this.state = {
      translations: translations,
      dataSource: this.findDataSource(props.data_source, props.contentCreatorTypes, props.embed_element),
      columns: props.columns || 4,
      requestingUpdate: false,
      includeGold: props.include_gold || 0,
      rowsToShow: props.rows_to_show || 4,
      rowsToShowMobile: props.rows_to_show_mobile || props.rows_to_show || 4,
      permissions: props.permissions,
      dataSourceTypes: dataSourceTypes,
      onlyImage: props.only_image,
      catalogs: (props.catalogs && props.catalogs.length > 0) ? props.catalogs.map(catalog => ({value: catalog.id, label: catalog.name})) : [],
      artists: (props.artists && props.artists.length > 0) ? props.artists.map(artist => ({value: artist.id, label: artist.name})) : [],
      selectedTags: (props.tags && props.tags.length > 0) ? props.tags : [],
      unselectedTags: (props.not_tags && props.not_tags.length > 0) ? props.not_tags : [],
      tagGroups: [],
      openTagsDialog: false,
      contentCreatorTypes: props.contentCreatorTypes || [],
      contentCreatorTypesForCreations:  props.contentCreatorTypes.map(creators => ({value: creators.value, label: creators.label, slug: creators.value})) || [],
      contentType: props.content_type || "all",
      contentCreatorType: props.content_creator_type,
      tagsAlreadyRequested: false,
      channel: props.channel,
      channelPlaylist: props.playlist_id && props.availableChannelPlaylists ? props.availableChannelPlaylists.find(item => item.value === props.playlist_id) : null,
      availableChannelPlaylists: props.availableChannelPlaylists || [],
      shape: props.shape,
      numbered: props.numbered || false,
      fixedTitle: props.fixed_title || false,
      withBackground: props.with_background || false,
      availableCatalogs: [],
      navbarViewType: "grid",
      link: props.link || "",
      featuredType: props.inverted ? "inverted" : props.editorial ? "editorial" : props.editorial_inverted ? "editorial_inverted" : props.movie ? (props.alternative ? "movie_alternative" : "movie") : (props.news || props.news_inverted) ? "news" : "default",
      useFeaturedFormat: props.featured_format || false,
      singleContentCreator:  props.content_creator_id ? {value:props.content_creator_id, label:props.content_creator_name} :null,
      square: props.square || false,
      hasMargin: props.has_margin || false,
      spacing: props.spacing || "none",
      newTab: props.new_tab || false,
      items: props.items || [],
      disableForm: false,
      imageId: props.image_id || null,
      image: props.image_id ? { id: props.image_id, src: getUpdateFormImageUrlFromItem({image: {id: props.image_id}}), file: null } : { src: null, file: null },
      mobileImageId: null,
      mobileImage: props.mobile_image_id ? { id: props.mobile_image_id, src: getUpdateFormImageUrlFromItem({image: {id: props.mobile_image_id}}), file: null } : { src: null, file: null },
      sections: props.sections || [],
      currentSection: props.target_section_id ? {value: props.target_section_id, label: props.target_section_name} : null,
      rounded: !!props.rounded || false,
      halfHeight: !!props.half_height || false,
      mobileHalfHeight: !!props.mobile_half_height || false,
      backgroundColor: props.background_color || "rgba(255,255,255,1)",
      fontColor: props.font_color || "rgba(0,0,0,1)",
      backgroundStyle: props.background_style || "default",
      globalPlaylists: props.global_playlists_ids && props.global_playlists_ids.length > 0 ? props.availableGlobalPlaylists.filter(playlist => props.global_playlists_ids.includes(playlist.value.toString())) : [],
      selectPlaylists: props.global_playlists_ids && props.global_playlists_ids.length > 0 ? "1": "0",
      availableGlobalPlaylists: props.availableGlobalPlaylists,
      filterByDate: props.filter_by_date ? (isCustomDate(props.filter_by_date)? "custom" : props.filter_by_date) :'none',
      useFilterByDate: (!props.filter_by_date || props.filter_by_date == 'none') ? "0" : "1",
      customDate: isCustomDate(props.filter_by_date) ? props.filter_by_date : "",
      rangeStartDate: props.start_date ? moment(props.start_date) : null,
      rangeEndDate: props.end_date  ? moment(props.end_date) :null,
      uniqueTexts: uniqueTexts,
      videoId: props.video_id || null,
      video: props.video || null,
      showAuthor: !!props.show_author,
      textPosition: props.text_position || (props.news_inverted ? 'inverted' : props.news ? 'default' : null),
      withText: props.with_text || false,
      playOnHover: !!props.play_on_hover || false,
      limitContent: props.limit_content || 30,
      orderBy: props.order_by || "default",
      filterByPublishDate: props.filter_by_publish_date ? (isCustomDate(props.filter_by_publish_date)? "custom" : props.filter_by_publish_date) :'none',
      useFilterByPublishDate: (!props.filter_by_publish_date || props.filter_by_publish_date == 'none') ? "0" : "1",
      customPublishDate: isCustomDate(props.filter_by_publish_date) ? props.filter_by_publish_date : props.filter_by_publish_date === "customHours" ? props.sub_hours : "",
      rangeStartPublishDate: props.start_publish_date ? moment(props.start_publish_date) : null,
      rangeEndPublishDate: props.end_publish_date  ? moment(props.end_publish_date) :null,
      selectedLanguage: props.lang || null,
      selectedTab: "es",
      embed: props.link || "",
      videos: AvailableLanguages.map(language => {
        const video = props.videos && props.videos[language]
        if (language === "es" && !video) {
          return  {
            language: language,
            image_id: props.image_id || null,
            image: props.image_id ? { id: props.image_id, src: getUpdateFormImageUrlFromItem({image: {id: props.image_id}}), file: null } : { src: null, file: null },
            video_id: props.video_id || null,
            video: props.video || null,
            play_on_hover: !!props.play_on_hover || false,
          }
        }

        return  {
          language: language,
          image_id: get(video, "image.id"),
          image: get(video, "image.id") ? { id: video.image_id, src: getUpdateFormImageUrlFromItem({image: {id: video.image_id}}), file: null } : { src: null, file: null },
          video_id: get(video, "video.id"),
          video: get(video, "video"),
          play_on_hover: get(video, "play_on_hover", "0") === "1",
        }
      }),
      images: AvailableLanguages.map(language => {
        const image = props.images && props.images[language]

        if (language === "es" && !image) {
          return  {
            language: language,
            image_id: props.image_id || null,
            image: props.image_id ? { id: props.image_id, src: getUpdateFormImageUrlFromItem({image: {id: props.image_id}}), file: null } : { src: null, file: null },
            mobileImage: props.mobile_image_id ? { id: props.mobile_image_id, src: getUpdateFormImageUrlFromItem({image: {id: props.mobile_image_id}}), file: null } : { src: null, file: null },
            link: props.link || null,
            new_tab: props.new_tab || false,
            mobile_image_id: props.mobile_image_id || false,
          }
        }
        return  {
          language: language,
          image_id: get(image, "image.id"),
          image: get(image, "image.id") ? { id: image.image.id, src: getUpdateFormImageUrlFromItem({image: {id: image.image.id}}), file: null } : { src: null, file: null },
          link: get(image, "link", ""),
          new_tab: get(image, "new_tab", "0") !== "0",
          mobile_image_id: get(image, "mobileImage.id"),
          mobileImage: get(image, "mobileImage.id") ? { id: image.mobile_image_id, src: getUpdateFormImageUrlFromItem({image: {id: image.mobileImage.id}}), file: null } : { src: null, file: null },
      }
      }),
      mobileFontResize: props.mobile_font_resize || 100,
      htmlTitle: isHtmlTitle,
      smartSearchContentType: props.content_type || "material",
      smartSearchContentCreatorType: get(props, "metadata.content_creator_type", props.smart_search_content_creator_type),
      availableTagGroups: [],
      dateSearch: props.date_search || "none",
      searchViewType: props.search_view_type || "grid6",
      reference: props.reference || "",
      validateReference: false,
      useRegular: !!props.use_regular,
      useDots: !!props.use_dots,
      pillBackgroundColor: props.pill_background || "rgba(255,255,255,1)",
      pillFontColor: props.pill_color || "rgba(0,0,0,1)",
      mobileGrid: props.mobile_grid || false,
      isPaginatedGrid: props.paginated_grid || false,
      hideStarterText: props.hide_starter_text || false,
      useSearchInput: !!props.use_search_input,
      people: [],
      availablePeople: [],
      peopleIds: props.people_ids || [],
      linkTo: props.link_to || "default",
      activeColor: get(props, "preferences.active_color", "rgb(255, 0, 0)"),
      hoverBackgroundColor: get(props, "preferences.hover_background_color", "rgb(0, 0, 0)"),
      hoverTextColor: get(props, "preferences.hover_text_color", "rgb(255, 255, 255)"),
      hideDuration: get(props, "hide_duration", false),
    }

    if (this.state.dataSource == "lite_smart_search") this.onEditTags(this.state.smartSearchContentType == 'material' ? 'video_content_creation' : 'content_creator', this.state.smartSearchContentCreatorType, false, true)

  }

  componentDidMount = () => {
    this.setState({dataSourceTypes: checkDataSourcesAgainstPermissions(this.state.permissions, dataSourceTypes, this.state.channel, this.props.channelId, this.props.parent, this.props.custom, this.props.isUnique)})
    this.props.data_source === 'catalog' && this.requestAndMapCatalogs()

    request.people.getAllForClient(this.props.clientId, "", {filterBy: [ {column: "use_name", filter: 1}]})
      .then(people => this.setState({
        availablePeople: people.data.map(person => ({value: person.id, label: person.name})),
        people: people.data.filter(person => this.state.peopleIds.includes(person.id.toString())).map(person => ({value: person.id, label: person.name}))
      }))
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.permissions !== nextProps.permissions || this.props.custom !== nextProps.custom) {
      this.setState({dataSourceTypes: checkDataSourcesAgainstPermissions(nextProps.permissions, dataSourceTypes, this.state.channel, this.props.parent, nextProps.custom, nextProps.isUnique)})
    }

    if (nextProps.contentCreatorTypes !== this.state.contentCreatorTypes) {
      this.setState({
        contentCreatorTypes: nextProps.contentCreatorTypes,
        contentCreatorTypesForCreations: nextProps.contentCreatorTypes.map(creators => ({value: creators.value, label: creators.label, slug: creators.value})) || [],
        dataSource: this.findDataSource(this.state.dataSource, nextProps.contentCreatorTypes),
        contentType: nextProps.content_type || "all",
      })
    }

    if (nextProps.availableChannelPlaylists) {
      this.setState({
        availableChannelPlaylists: nextProps.availableChannelPlaylists,
        channelPlaylist: nextProps.availableChannelPlaylists.find(item => item.value == this.props.playlist_id),
      })
    }

    if (nextProps.data_source == "lite_smart_search") {
      this.onEditTags(nextProps.content_type == 'material' ? 'video_content_creation' : 'content_creator', nextProps.content_creator_type, false)
        .then(() => {
          
          this.setState({
            smartSearchContentCreatorType: nextProps.content_creator_type || get(nextProps, "metadata.content_creator_type", nextProps.smart_search_content_creator_type),
            availableTagGroups: this.state.tagGroups.reduce((acc, curr) => {
              if (nextProps.tag_groups.includes(`${curr.id}`)) {
                acc.push({value: curr.id, label: curr.name})
              }
              
              return acc;
            }, [])
        })
      })
    }
  }

  findDataSource = (dataSource, contentCreatorTypes, embedElement = false) => {
    if(embedElement && dataSource === 'custom') { 
      return 'custom_embed'
    }else if (dataSource !== 'content_creator') {
      return dataSource
    } else {
      let talentTypes = contentCreatorTypes.map(type => type.isTalent && type.value)
      let audiovisualTypes = contentCreatorTypes.map(type => !type.isTalent && type.type!=="editorial" && type.value)
      let editorialTypes = contentCreatorTypes.map(type => !type.isTalent && type.type==="editorial" && type.value)

      if (talentTypes.includes(this.props.content_creator_type)) {
        return 'content_creator_talent'
      }
      if (audiovisualTypes.includes(this.props.content_creator_type)) {
        return 'content_creator_audiovisual'
      }

      if (editorialTypes.includes(this.props.content_creator_type)) {
        return 'content_creator_editorial'
      }
      return dataSource
    }
  }

  hasContent = (editorState) => {
    let text =editorState;

    if (this.state.htmlTitle) {
      const content = editorState.getCurrentContent();
      text = content.getPlainText();
    }

    return !!text;
  }

  checkIfHasTitle = () => this.state.translations.some(tr => this.hasContent(tr.title))

  onChange = e => {
    this.setState({[e.target.id || e.target.name]: e.target.value})
    if ( e.target.name !== 'columns' && e.target.name !== 'rowsToShow' && e.target.name !== 'rowsToShowMobile') {
      this.setState({columns: 4, rowsToShow: 4, rowsToShowMobile: 4})
    }
  }
  formNewUrl = (path) => process.env.REACT_APP_CDN_HOST.concat('/' + path)
  onImageChange = image => image ? this.setState({imageId: image.id, image: {src: null, filename: null }, imagePreview: this.formNewUrl(image.path)}) : this.setState({imageId: null, image: { src: null, filename: null }})
  setPreview = () => {
    let item = {image: {id: this.state.imageId, src: this.state.imagePreview}}
    this.setState({image : {src: getUpdateFormImageUrlFromItem(item)}})
  }
  onImageMobileChange = image => image ? this.setState({imageMobileId: image.id, mobileImage: {src: null, filename: null }, mobileImagePreview: this.formNewUrl(image.path)}) : this.setState({mobileImageId: null, mobileImage: { src: null, filename: null }})
  setMobilePreview = () => {
    let item = {imageMobile: {id: this.state.imageMobileId, src: this.state.mobileImagePreview}}
    this.setState({imageMobile : {src: getUpdateFormImageUrlFromItem(item)}})
  }

  requestUpdate = () => {
      if (this.state.dataSource === 'catalog' && this.state.catalogs.length === 0) {
        GlobalSnackbar.show({
          message: localization.get('album.catalog_required'),
          type: GlobalSnackbarTypes.ERROR
        })
      } else if (this.state.dataSource === "video" && !this.state.videos.some(v => v.video_id && v.image_id)) {
        GlobalSnackbar.show({
          message: localization.get('home_editor.validate_image_video'),
          type: GlobalSnackbarTypes.ERROR,
        })
      } else if (this.state.dataSource === "image" && !this.state.images.some(v => v.image_id)) {
        GlobalSnackbar.show({
          message: localization.get('home_editor.validate_image'),
          type: GlobalSnackbarTypes.ERROR,
        })
      }else if (this.state.translations.some(tr => `${tr.author};${tr.category}`.length > 255)) {
        GlobalSnackbar.show({
          message: localization.get( 'home_editor.validate_texts'),
          type: GlobalSnackbarTypes.ERROR,
        })
      }else if (this.state.translations.some(tr => tr.synopsis && tr.synopsis.length > 600)) {
        GlobalSnackbar.show({
          message: localization.get( "home_editor.validate_synopsis_length"),
          type: GlobalSnackbarTypes.ERROR,
        })
      }else if (this.state.dataSource === "change_language" && !this.state.selectedLanguage) {
        GlobalSnackbar.show({
          message: localization.get( "change_language.validation"),
          type: GlobalSnackbarTypes.ERROR,
        })
      }else if (this.state.dataSource == "link" && !validateUrl(this.state.link)) {
        GlobalSnackbar.show({
          message: localization.get( "home_editor.validate_link"),
          type: GlobalSnackbarTypes.ERROR,
        })
      }else if (this.state.dataSource == "embed" && !validateUrl(this.state.embed)) {
        GlobalSnackbar.show({
          message: localization.get( "home_editor.validate_link"),
          type: GlobalSnackbarTypes.ERROR,
        })
      } else  if (this.state.dataSource === 'lite_smart_search' && this.state.availableTagGroups.length == 0 ) {
        GlobalSnackbar.show({
          message: localization.get( "home_editor.validate_tags"),
          type: GlobalSnackbarTypes.ERROR,
        })
      }else if (this.state.dataSource === 'lite_smart_search' && this.state.limitContent > 300 ) {
        GlobalSnackbar.show({
          message: localization.get( "home_editor.validate_limit_content"),
          type: GlobalSnackbarTypes.ERROR,
        })
      }else if (!this.state.reference && !this.state.channel.redirectButton) {
        GlobalSnackbar.show({
          message: localization.get( "home_editor.validate_reference"),
          type: GlobalSnackbarTypes.ERROR,
        })
        this.setState({validateReference: true})
      } else {
        this.setState({ requestingUpdate: true })
        let link = this.state.link; 

        if (link && link.includes('/busqueda-inteligente/') && !link.includes('clean_search=true')) {
          link+="&clean_search=true"
        }

        let data = {
          ...this.state,
          link: link,
          contentCreatorType: this.state.contentCreatorType,
          featuredType: this.state.featuredType === 'movie_alternative' ? 'movie' : this.state.featuredType,
          alternative: this.state.featuredType === 'movie_alternative',
          section: this.state.currentSection,
          dataSource: this.state.dataSource === 'content_creator_talent' || this.state.dataSource === 'content_creator_audiovisual' ||  this.state.dataSource === 'content_creator_editorial' ? 'content_creator' :this.state.dataSource === 'custom_embed' ? "custom" : this.state.dataSource,
          catalogs: this.state.catalogs.map(catalog => catalog.value),
          artists: this.state.artists.map(artist => artist.value),
          channelPlaylist: this.state.channelPlaylist && this.state.channelPlaylist.value,
          globalPlaylists: this.state.globalPlaylists,
          filterByDate: isCustomDate(this.state.filterByDate) ? this.state.customDate :this.state.filterByDate,
          rangeStartDate: this.state.rangeStartDate,
          rangeEndDate: this.state.rangeEndDate,
          filterByPublishDate: isCustomDate(this.state.filterByPublishDate) ? this.state.customPublishDate : this.state.filterByPublishDate,
          subHours: this.state.filterByPublishDate === 'customHours' ? this.state.customPublishDate : 0,
          rangeStartPublishDate: this.state.rangeStartPublishDate,
          rangeEndPublishDate: this.state.rangeEndPublishDate,
          orderBy: this.state.orderBy,
          lang: this.state.selectedLanguage,
          videos: this.state.videos.filter(v => v.video_id && v.image_id).map(video => ({...video, play_on_hover: video.play_on_hover ? 1: 0})),
          images: this.state.images.filter(i => i.image_id).map(image => ({...image, new_tab: image.new_tab ? 1: 0})),
          translations: this.state.translations.map(tr => ({
            ...tr,
            title: !this.state.htmlTitle ? tr.title : JSON.stringify(clearUnwantedStateStyles(convertToRaw(tr.title.getCurrentContent()))),
            additions: `${tr.author};${tr.category}`,
          })),
          uniqueTexts: this.state.uniqueTexts.map(tr => ({
            ...tr,
            text: (tr.text && stateToHTML(tr.text.getCurrentContent()) === '<p><br></p>') ? "" : JSON.stringify(clearUnwantedStateStyles(convertToRaw(tr.text.getCurrentContent())))
          })),
          mobileFontResize: this.state.mobileFontResize,
          embed: this.state.dataSource == "embed" ? this.state.embed: null,
          embedElement: this.state.dataSource === 'custom_embed',
          gridFeatured: !!this.props.grid_featured,
          smartSearchContentType: this.state.smartSearchContentType,
          smartSearchContentCreatorType: this.state.smartSearchContentCreatorType,
          availableTagGroups: this.state.availableTagGroups,
          dateSearch: this.state.dateSearch,
          searchViewType: this.state.searchViewType, 
          reference: this.state.reference,
          useRegular: this.state.useRegular,
          useDots: this.state.useDots,
          useSearchInput: this.state.useSearchInput,
          backgroundColor: this.state.backgroundColor,
          fontColor: this.state.fontColor,
          pillBackgroundColor: this.state.pillBackgroundColor,
          pillFontColor: this.state.pillFontColor,
          mobileGrid: this.state.mobileGrid,
          hideStarterText: this.state.hideStarterText,
          isPaginatedGrid: (["content_creator_audiovisual", "content_creator_talent", "content_creator_editorial"]).includes(this.state.dataSource) && this.state.isPaginatedGrid,
          people: this.state.people.map(person => person.value),
          linkTo: this.state.linkTo,
          activeColor: this.state.activeColor,
          hoverBackgroundColor: this.state.hoverBackgroundColor,
          hoverTextColor: this.state.hoverTextColor,
          hideDuration: this.state.hideDuration,
        }

        request.client.home.elements.update(this.props.clientId, this.props.id, data)
          .then(response => {
            GlobalSnackbar.show({
              message: localization.get('home_editor.element_updated'),
              type: GlobalSnackbarTypes.SUCCESS,
            })

            this.setState({ requestingUpdate: false, openTagsDialog: false })
            if(this.props.isModal) {
              response.data.image_id = this.state.imageId || this.props.image_id;
              response.data.mobile_image_id = this.state.mobileImageId || this.props.mobile_image_id;
              response.data.tags = this.state.selectedTags;
              response.data.not_tags = this.state.unselectedTags;
              response.data.link = this.state.dataSource == "embed" ? this.state.embed : link;
              response.data.new_tab = this.state.newTab;
              response.data.filter_by_date = this.state.filterByDate;
              response.data.start_date = this.state.rangeStartDate;
              response.data.end_date = this.state.rangeEndDate;
              response.data.filter_by_publish_date = this.state.filterByPublishDate;
              response.data.start_publish_date = this.state.rangeStartPublishDate;
              response.data.end_publish_date = this.state.rangeEndPublishDate;
              response.order_by = this.state.orderBy;
              response.data.sub_hours = this.state.filterByPublishDate === 'customHours' ? this.state.customPublishDate : 0;
              response.lang = this.state.selectedLanguage;
              response.videos = this.state.videos.filter(v => v.video_id && v.image_id);
              response.images = this.state.images.filter(i => i.image_id);
              response.alternative = this.state.featuredType === 'movie_alternative';
              response.mobile_font_resize = this.state.mobileFontResize;
              response.content_type = this.state.contentType;
              response.data.embed = this.state.embed;
              response.embed =  this.state.dataSource == "embed" ? this.state.embed : this.state.link;
              response.data.smart_search_content_type = this.state.smartSearchContentType;
              response.data.smart_search_content_creator_type = this.state.smartSearchContentCreatorType;
              response.data.available_tag_groups = this.state.availableTagGroups;
              response.data.date_search = this.state.dateSearch;
              response.data.items = this.state.items;
              response.data.background_color = this.state.backgroundColor;
              response.data.font_color = this.state.fontColor;
              response.data.pill_background_color = this.state.pillBackgroundColor;
              response.data.pill_font_color = this.state.pillFontColor;
              response.data.mobile_grid = this.state.mobileGrid;
              response.data.link_to = this.state.linkTo;
              response.data.active_color = this.state.activeColor;
              response.data.hover_background_color = this.state.hoverBackgroundColor;
              response.data.hover_text_color = this.state.hoverTextColor;
              response.data.hide_duration = this.state.hideDuration;
              this.props.onClose(response.data)
            }
          })
          .catch((e) => {
            console.log(e)  
            GlobalSnackbar.show({
              message: localization.get('home_editor.element_update_failed'),
              type: GlobalSnackbarTypes.ERROR,
            })
            this.setState({ requestingUpdate: false })
            if(this.props.isModal) {
              this.props.onClose({id: this.props.id})
            }else{
              this.props.onUpdateFailed()
            }
          })
      }
  }

  clearAllTags = () =>
    new Promise(resolve => {
      this.setState( {tagGroups: this.state.tagGroups.map(tagGroup => {
          return {...tagGroup,
            tags: tagGroup.tags.map(tag => {
              return {...tag, state: 'available'}
            })
          }
        })
      })
      resolve()
    }).then(() => this.setState({selectedTags: [], unselectedTags: []}))

  makeSubmitButton = () => <Button
    onClick={this.requestUpdate}
    disabled={this.state.requestingUpdate || (this.originalTitle === this.state.title && this.originalDataSource === this.state.dataSource && this.state.columns === this.originalColumns && this.state.includeGold === this.originalIncludeGold)}>
    {localization.get('store')}
  </Button>

  requestDelete = () => request.client.home.elements.delete(this.props.clientId, this.props.id).then(this.props.onUpdateSuccess).catch(this.props.onUpdateFailed)

  handleDelete = () => GlobalConfirmationDialog.showDelete({ elementName: this.originalTitle, deleteRequest: this.requestDelete })

  onSelectedTagsChanged = selectedTag => {
    if (this.state.selectedTags.includes(selectedTag.toString())) { //Remove from selected / add to unselected tags
      this.state.unselectedTags.push(selectedTag.toString())
      this.setState({
        selectedTags: this.state.selectedTags.filter(tags => tags !== selectedTag.toString()),
        unselectedTags: this.state.unselectedTags
      }, () => this.updateTagColor(selectedTag.toString(), "unselected"))
    }else if(this.state.unselectedTags.includes(selectedTag.toString())) { //Remove from unselected / add to available tags
      this.setState({
        unselectedTags: this.state.unselectedTags.filter(tags => tags !== selectedTag.toString()),
      }, () => this.updateTagColor(selectedTag.toString(), "available"))
    }else { //Remove from available / add to selected tags
      this.state.selectedTags.push(selectedTag.toString());
      this.setState({selectedTags: this.state.selectedTags}, () => this.updateTagColor(selectedTag, "selected"))
    }
  };

  paintAllTags = () =>
    new Promise(resolve => {
      this.state.selectedTags.map(tag => this.updateTagColor(tag, "selected"))
      this.state.unselectedTags.map(tag => this.updateTagColor(tag, "unselected"))
      resolve()
    })

  onEditTags = (dataSource, type = this.state.contentCreatorType, openDialog = true, assingAvailableTagGroups) => {
    if (dataSource === 'catalog') {
      this.setState({tagsAlreadyRequested: true})
      return request.tagGroup.getAllOfTypeMusic()
        .then(tagGroups => this.setState(state => ({...state, tagGroups})))
        .then(() => this.setState({openTagsDialog: true}))
        .then(() => this.paintAllTags())
    }

    if (dataSource === 'content_creator') {
      return request.tagGroup.getAll(
        {filterBy: [
          { column: 'type', filter: type},
          { column: 'creation_group', filter: '0'}
        ]
        })
        .then(tagGroups => this.setState({tagGroups}))
        .then(() => this.setState({openTagsDialog: openDialog}))
        .then(() => this.paintAllTags())
        .then(() => {
          if (assingAvailableTagGroups) {
            this.setState({availableTagGroups: this.state.tagGroups.reduce((acc, curr) => {
              const tagGroups = get(this.props, "metadata.tag_groups", this.props.tag_groups)
              if (tagGroups.includes(`${curr.id}`)) {
                acc.push({value: curr.id, label: curr.name})
              }
              
              return acc;
            }, [])
            })
          }
        })
    }

    if (dataSource === 'video_content_creation') {
      return request.tagGroup.getAllPrivate({filterBy: [
          { column: 'type', filter: type},
          { column: "creation", filter: 1 },
          { column: "dashboard", filter: 1 }
        ]})
        .then(tagGroups => this.setState({tagGroups}))
        .then(() => this.setState({openTagsDialog: openDialog}))
        .then(() => this.paintAllTags())
        .then(() => {
          if (assingAvailableTagGroups) {
            this.setState({availableTagGroups: this.state.tagGroups.reduce((acc, curr) => {
              const tagGroups = get(this.props, "metadata.tag_groups", this.props.tag_groups)
              if (tagGroups.includes(`${curr.id}`)) {
                acc.push({value: curr.id, label: curr.name})
              }
              
              return acc;
            }, [])
            })
          }
        })
    }
  }

  updateTagColor = (selectedTag, value) =>
    this.setState( {tagGroups: this.state.tagGroups.map(tagGroup => {
        return {...tagGroup,
          tags: tagGroup.tags.map(tag => {
            if (tag.id.toString() == selectedTag.toString()) {
              return {...tag, state: value}
            }
            return tag
          })
        }
      })
    })

  onTagsTest = () => {
    return request.client.home.elements.test(
      this.props.clientId, {
        type: this.state.dataSource === 'content_creator_talent' || this.state.dataSource === 'content_creator_audiovisual' || this.state.dataSource === 'content_creator_editorial' ? 'content_creator' : this.state.dataSource,
        catalogs: this.state.catalogs.map(catalog => catalog.value),
        artists: this.state.artists.map(artist => artist.value),
        selectedTags: this.state.selectedTags,
        contentCreatorType: this.state.contentCreatorType,
        unselectedTags: this.state.unselectedTags,
        content_creator_type: this.state.contentCreatorType,
        contentType: this.state.contentType,
        limitContent: this.state.limitContent,
        filterByDate: isCustomDate(this.state.filterByDate) ? this.state.customDate : this.state.filterByDate,
        rangeStartDate: this.state.rangeStartDate,
        rangeEndDate: this.state.rangeEndDate,
        channelId: this.state.channel ? this.state.channel.id : false,
        channelPlaylist: this.state.channelPlaylist && this.state.channelPlaylist.value,
        filterByPublishDate: isCustomDate(this.state.filterByPublishDate) ? this.state.customPublishDate : this.state.filterByPublishDate,
        subHours: this.state.filterByPublishDate === 'customHours' ? this.state.customPublishDate : 0,
        rangeStartPublishDate: this.state.rangeStartPublishDate,
        rangeEndPublishDate: this.state.rangeEndPublishDate,
        orderBy: this.state.orderBy,
        testing: true
      }
    )
  }

  onVideoChange = video => video ? this.setState({videoId: video.id, video: {src: null}, videoPreview: this.formNewUrl(video.path)}) : this.setState({videoId: null, video: {src: null}})
  formNewUrl = (path) => process.env.REACT_APP_CDN_HOST.concat('/' + path)
  onContentChange = (e, field) => this.setState({[field]: e})
  handleCancel = () => this.setState({openTagsDialog: false})
  onChangeTranslation = (value, target, language) => {
    const translations = this.state.translations.map(tr => {
      if (tr.language === language) {
        return {...tr, [target]: value}
      }
      return tr
    });

    this.setState({ translations });
  }

  onChangeUniqueTranslation = (value, target, language) => {
    const uniqueTexts = this.state.uniqueTexts.map(tr => {
      if (tr.language === language) {
        return {...tr, [target]: value}
      }
      return tr
    });

    this.setState({ uniqueTexts });
  }
  onChangeTranslatable = (state, value, target, language) => {
    const translations = this.state[state].map(tr => {
      if (tr.language === language) {
        return {...tr, [target]: value}
      }

      return tr
    });

    return this.setState({ [state]: translations});
  }


  renderTagsDialog = () =>{
    const isEventGrid = this.props.type === "event_grid";
  
    return (
      <Dialog maxWidth={false} open={this.state.openTagsDialog} onEscapeKeyDown={this.handleCancel} onBackdropClick={this.handleCancel}>
        <DialogTitle>{localization.get('home_editor.tag_selection.edit_title')}</DialogTitle>
      {['content_creator_talent', 'content_creator_audiovisual', 'content_creator_editorial'].includes(this.state.dataSource) ?
        <DialogContent style={{maxWidth: 690}}>
          {this.state.useFilterByPublishDate === "0" &&
            <>
              <p>{localization.get('home_editor.use_date_filter')}</p>
              <RadioGroup
                name="useFilterByDate"
                value={this.state.useFilterByDate}
                onChange={e => {
                  this.setState({useFilterByDate: e.target.value})
                  if (e.target.value === "0") {
                    this.setState({filterByDate: "none", rangeStartDate: null, rangeEndDate: null})
                  }
                }}
                style={{marginRight: 30, float: "left", position: "relative", flexDirection: "row"}}
              >
                <FormControlLabel value="0" control={<Radio color="default"/>}
                                  label={localization.get('home_editor.not_use')}/>
                <FormControlLabel value="1" control={<Radio color="default"/>}
                                  label={localization.get('home_editor.use_filter')}/>
              </RadioGroup>
            </>
          }
          {this.state.useFilterByDate === "1" ?
            <div style={{
              display: "flex",
              flexDirection: this.state.filterByDate === "range" ? "column" : "row",
              width: "100%"
            }}>
              <br/>
              <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                <inputLabel>{localization.get('range')}</inputLabel>
                <Select style={{marginRight: 15, width: "40%"}} name='filterByDate' id="filterByDate"
                        value={this.state.filterByDate} onChange={this.onChange}>
                  <MenuItem value={"none"}>{localization.get('home_editor.not_use')}</MenuItem>
                  <MenuItem value={"today"}>{localization.get('smart_search.today')}</MenuItem>
                  <MenuItem value={"tomorrow"}>{localization.get('smart_search.tomorrow')}</MenuItem>
                  <MenuItem value={"week"}>{localization.get('smart_search.this_week')}</MenuItem>
                  <MenuItem value={"next_week"}>{localization.get('smart_search.next_week')}</MenuItem>
                  <MenuItem value={"weekend"}>{localization.get('smart_search.this_weekend')}</MenuItem>
                  <MenuItem value={"month"}>{localization.get('smart_search.this_month')}</MenuItem>
                  <MenuItem value={"custom"}>{localization.get('smart_search.x_days')}</MenuItem>
                  <MenuItem value={"range"}>{localization.get('smart_search.range')}</MenuItem>
                </Select>
              </div>
              {this.state.filterByDate === "custom" ?
                <TextField
                  style={{width: 180, marginTop: 5}}
                  name="customDate"
                  id="customDate"
                  label={localization.get('days_from_today')}
                  value={this.state.customDate}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    // if value is not blank, then test the regex
                    if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length < 4) {
                      this.onChange({target: {id: "customDate", value: e.target.value}})
                    }
                  }}
                  min={1}
                  max={3}
                />
                : this.state.filterByDate === "range" ?
                  <div style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                    margin: "15px 0"
                  }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        style={{width: "40%"}}
                        autoOk
                        keyboard
                        clearable
                        onlyCalendar
                        disablePast
                        defaultValue={this.state.rangeStartDate}
                        format={"dd/MM/yyyy"}
                        label={localization.get('smart_search.date_start')}
                        value={this.state.rangeStartDate}
                        onChange={date => this.setState({rangeStartDate: date})}
                      />
                      <DatePicker
                        style={{width: "40%"}}
                        autoOk
                        keyboard
                        clearable
                        onlyCalendar
                        disablePast
                        defaultValue={this.state.rangeEndDate}
                        format={"dd/MM/yyyy"}
                        label={localization.get('smart_search.date_end')}
                        value={this.state.rangeEndDate}
                        onChange={date => this.setState({rangeEndDate: date})}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  : null}
            </div>
            : this.state.dataSource === 'content_creator_editorial' && !isEventGrid ?
              <>
                <p style={{
                  float: "left",
                  position: "relative",
                  width: "100%"
                }}>{localization.get('home_editor.use_publish_date_filter')}</p>
                <RadioGroup
                  name="useFilterByPublishDate"
                  value={this.state.useFilterByPublishDate}
                  onChange={e => {
                    this.setState({useFilterByPublishDate: e.target.value})

                    if (e.target.value === "0") {
                      this.setState({
                        filterByPublishDate: "none",
                        rangeStartPublishDate: null,
                        rangeEndPublishDate: null
                      })
                    }
                  }}
                  style={{marginRight: 30, float: "left", position: "relative", flexDirection: "row"}}
                >
                  <FormControlLabel value="0" control={<Radio color="default"/>}
                                    label={localization.get('home_editor.not_use')}/>
                  <FormControlLabel value="1" control={<Radio color="default"/>}
                                    label={localization.get('home_editor.use_filter')}/>
                </RadioGroup>
                {this.state.useFilterByPublishDate === "1" && 
                  <div style={{display: "flex", flexDirection: "row", width: "100%"}}>
                    <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                      <inputLabel>{localization.get('range')}</inputLabel>
                      <Select style={{marginRight: 15, width: 150}} name='filterByPublishDate' id="filterByPublishDate"
                              value={this.state.filterByPublishDate} onChange={this.onChange}>
                        <MenuItem value={"none"}>{localization.get('home_editor.not_use')}</MenuItem>
                        <MenuItem value={"today"}>{localization.get('smart_search.today')}</MenuItem>
                        <MenuItem value={"yesterday"}>{localization.get('smart_search.yesterday')}</MenuItem>
                        <MenuItem value={"week"}>{localization.get('smart_search.last_seven_days')}</MenuItem>
                        <MenuItem value={"month"}>{localization.get('smart_search.last_thirty_days')}</MenuItem>
                        <MenuItem value={"custom"}>{localization.get('smart_search.x_days')}</MenuItem>
                        <MenuItem value={"customHours"}>{localization.get('smart_search.x_hours')}</MenuItem>
                        <MenuItem value={"range"}>{localization.get('smart_search.range')}</MenuItem>
                      </Select>
                    </div>
                    {(this.state.filterByPublishDate === "custom" || this.state.filterByPublishDate === "customHours") ?
                      <TextField
                        style={{width: 550, marginTop: 5}}
                        name="customPublishDate"
                        id="customPublishDate"
                        label={localization.get(this.state.filterByPublishDate !== "custom" ? 'hours.past_now' : 'days_past_today')}
                        value={this.state.customPublishDate}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          // if value is not blank, then test the regex
                          if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length < 4) {
                            this.onChange({target: {id: "customPublishDate", value: e.target.value}})
                          }
                        }}
                        min={1}
                        max={3}
                      />
                      : this.state.filterByPublishDate === "range" ?
                        <div style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "space-between",
                          margin: "15px 0"
                        }}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                              style={{width: "40%"}}
                              autoOk
                              keyboard
                              clearable
                              onlyCalendar
                              disableFuture
                              defaultValue={this.state.rangeStartPublishDate}
                              format={"dd/MM/yyyy"}
                              label={localization.get('smart_search.date_start')}
                              value={this.state.rangeStartPublishDate}
                              onChange={date => this.setState({rangeStartPublishDate: date})}
                            />
                            <DatePicker
                              style={{width: "40%"}}
                              autoOk
                              keyboard
                              clearable
                              onlyCalendar
                              disableFuture
                              defaultValue={this.state.rangeEndPublishDate}
                              format={"dd/MM/yyyy"}
                              label={localization.get('smart_search.date_end')}
                              value={this.state.rangeEndPublishDate}
                              onChange={date => this.setState({rangeEndPublishDate: date})}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        : null}
                  </div>
                }
              </> : null}
          <HomeElementCreateDialogTagSelection 
            channel={this.state.channel} clearAllTags={this.clearAllTags}
            tagGroups={this.state.tagGroups}
            onSelectedTagsChanged={this.onSelectedTagsChanged}
            onTest={this.onTagsTest}
            radio={this.state.selectedTags.length > 0 || this.state.unselectedTags.length > 0 ? "filter" : "all"}
          />
        </DialogContent>
        :
        <DialogContent>
          <HomeElementCreateDialogTagSelection
            channel={this.state.channel}
            clearAllTags={this.clearAllTags}
            tagGroups={this.state.tagGroups}
            onSelectedTagsChanged={this.onSelectedTagsChanged}
            onTest={this.onTagsTest}
            radio={this.state.selectedTags.length > 0 || this.state.unselectedTags.length > 0 ? "filter" : "all"}/>
        </DialogContent>
      }
      <DialogActions>
        <Button onClick={this.handleCancel} disabled={this.state.submitting}><Close /></Button>
        <Button onClick={this.handleCancel}><Done /></Button>
      </DialogActions>
    </Dialog>
    )
  }

  requestAndMapCatalogs = () => {
    request.catalog.getAll({filterBy: [
          {column: "client_id", filter: this.props.clientId},
          {column: "confirmed", filter: true},
          {column: 'filter_content', filter: 'music'}
        ]})
      .then(res => {
        this.setState({availableCatalogs: res.map(catalog => ({value: catalog.id, label: catalog.name}))})

        if (this.props.catalogs_ids && this.props.catalogs_ids.length > 0) {
          let catalogs = res.filter(catalog => this.props.catalogs_ids.includes(catalog.id+''))
            .map(catalog => ({value: catalog.id, label: catalog.name}))
          this.setState({catalogs})
        }
      })
      if (get(this.props, "artists_ids")) {
        request.artist.getAll({filterBy: [ {column: "artist_ids", filter: this.props.artists_ids.toString() } ]})
        .then(res => this.setState({artists: res.map(artist => ({value: artist.id, label: artist.name}))}))
      }
  }

  render = () => {
    const isEventGrid = this.props.type === "event_grid";
    
    return (
      <>
      <div  style={{width: '100%', minWidth: 600, overflow: "scroll", padding: "0 24px 14px"}}>
        <Typography variant='display1'>{this.props.elementTitle}</Typography>
          <FormControl style={{width: '100%', marginTop: '10px'}}>
            {!this.state.channel.redirectButton &&
              <ValidatorForm>
                <TextValidator
                  id="reference"
                  label={localization.get('form.reference')}
                  name="reference"
                  value={this.state.reference}
                  onChange={this.onChange}
                  type="text"
                  fullWidth
                  error={this.state.validateReference}
                  helperText={localization.get('form.reference_helper')}
                  validators={['required']}
                  errorMessages={[localization.get('home_editor.validate_reference')]}
                />
                <TranslatableInputs
                  ref={instance => this.child = instance}
                  children={AvailableLanguages.map(language => {
                    const currentLangTranslation = this.state.translations.find(tr => tr.language === language);
                    const translatableInputs = [
                      <>
                        {!this.state.htmlTitle ?
                          <TextValidator
                            style={{width: "100%", marginBottom: 15}}
                            id={`title${language}`}
                            label={localization.get('home_editor.title')}
                            name={`title${language}`}
                            value={currentLangTranslation.title}
                            onChange={e => this.onChangeTranslation(e.target.value, "title", language)}
                          /> :
                          <>
                            <TextValidator
                              style={{display: "none"}}
                              id={`title${language}`}
                              label={localization.get('home_editor.title')}
                              name={`title${language}`}
                              value={currentLangTranslation.title}
                              onChange={e => this.onChangeTranslation(e.target.value, "title", language)}
                            />
                            <div id="ignore">
                              <EditorialEditor
                                toolbarOptions={['inline', 'fontSize', 'textAlign', 'colorPicker', 'link', 'emoji']}
                                editorState={get(currentLangTranslation, "title", "")}
                                onChange={e => this.onChangeTranslation(e, "title", language)}
                              />
                            </div>
                          </>
                        }
                      </>,
                      ((this.props.type === "custom" || this.props.isCustom) &&
                        <>
                          <TextValidator
                            style={{width: "100%", marginBotton: 15}}
                            id={`category${language}`}
                            label={localization.get('form.section')}
                            name={`category${language}`}
                            value={currentLangTranslation.category}
                            onChange={e => this.onChangeTranslation(e.target.value, "category", language)}
                            disabled={this.state.disabled}
                          />
                          <TextValidator
                            style={{width: "100%", marginBotton: 15}}
                            id={`author${language}`}
                            label={localization.get('form.extras')}
                            name={`author${language}`}
                            value={currentLangTranslation.author}
                            onChange={e => this.onChangeTranslation(e.target.value, "author", language)}
                            disabled={this.state.disabled}
                          />
                          <textarea
                            style={{width: "100%", border: "none", marginTop: 20, borderBottom: "1px solid #ccc"}}
                            id={`synopsis${language}`}
                            label={localization.get('form.synopsis')}
                            helperText={localization.get("form.synopsis_helper")}
                            name={`synopsis${language}`}
                            value={currentLangTranslation.synopsis}
                            onChange={e => this.onChangeTranslation(e.target.value, "synopsis", language)}
                            disabled={this.state.disabled}
                            multiline
                            rows={4}
                          />
                        </>
                      )
                    ];

                    if (!['navbar', 'footer'].includes(this.props.type) && this.props.type !== 'custom' && !this.props.isCustom) {
                      translatableInputs.unshift(<FormControlLabel
                        control={
                          <Checkbox
                            style={{color: "#4cc2d5"}}
                            checked={this.state.htmlTitle}
                            onChange={() => {
                              const translations = this.state.translations.map(tr => ({
                                ...tr,
                                title: this.state.htmlTitle ? "" : new EditorState.createEmpty(),
                              }));

                              this.setState({htmlTitle: !this.state.htmlTitle, translations})
                            }}
                          />
                        }
                        label={localization.get('form.title_html')}
                      />)
                    }
                    return {
                      language: language,
                      content: translatableInputs,
                    }
                  })}
                />
              </ValidatorForm>
            }
            {this.props.isCustom &&
              <>
                {this.state.image && this.state.image.src && <ImagePicker image={this.state.image.src} previewOnly={true} id={this.state.image.id}/>}
                <div style={{color: "#757575", textAlign: 'center', marginTop: 10}}>{localization.get('original')}</div>
                <FilePondUploader
                  disableForm={this.state.disableForm}
                  updateDisableForm={value => this.setState({disableForm: value})}
                  file={this.state.image}
                  id={"image"}
                  type={"image"}
                  allowedTypes={["image/*"]}
                  onIdChange={this.onImageChange}
                  setPreview={this.setPreview}
                  clientId={this.props.clientId}
                />
                {this.state.mobileImage && this.state.mobileImage.src && <ImagePicker image={this.state.mobileImage.src} previewOnly={true} id={this.state.mobileImage.id} />}
                <div style={{color: "#757575", textAlign: 'center', marginTop: 10}}>{localization.get('mobile_image')}</div>
                <FilePondUploader
                  disableForm={this.state.disableForm}
                  updateDisableForm={value => this.setState({disableForm: value})}
                  file={this.state.mobileImage}
                  id={"mobileImage"}
                  type={"image"}
                  allowedTypes={["image/*"]}
                  onIdChange={this.onImageMobileChange}
                  setPreview={this.setMobilePreview}
                  clientId={this.props.clientId}
                />
              </>
            }

            {['navbar'].includes(this.props.type) && !!this.state.channel.navbar_tabs && 
              <>
                <h4>{localization.get('home_editor.tab_color_config')}</h4>
                <div style={{display: "flex"}}>
                  <FormControl style={{ float:"left", width: "16.6%"}}>
                    <label style={{height: 25}}> {localization.get('home_editor.background_color')} </label>
                    <ColorPicker
                      id={'backgroundColor'}
                      handleColorChange={color => this.setState({ backgroundColor: color })}
                      startColor={this.state.backgroundColor}
                      disableAlpha={true}
                    />
                  </FormControl>
                  <FormControl style={{ float:"left", width: "16.6%"}}>
                    <label style={{height: 25}}> {localization.get('home_editor.font_color')} </label>
                    <ColorPicker
                      id={'fontColor'}
                      handleColorChange={color => this.setState({ fontColor: color })}
                      startColor={this.state.fontColor}
                      disableAlpha={true}
                    />
                  </FormControl>
                </div>
              </>
            }
            {!this.checkIfHasTitle() && !['navbar', 'footer'].includes(this.props.type) &&
              <>
                <span>{localization.get('home_editor.spacing')}</span>
                <div style={{display: "flex", marginBottom: "20px"}}>
                  <RadioGroup
                    name="shape"
                    value={this.state.spacing}
                    onChange={e => this.setState({spacing: e.target.value})}
                    style={{marginRight: 30, float: "left", position: "relative", flexDirection: "row"}}
                  >
                    <FormControlLabel value="none" control={<Radio color="default"/>} label={localization.get('none')}/>
                    <FormControlLabel value="small" control={<Radio color="default"/>}
                                      label={localization.get('home_editor.spacing.small')}/>
                    <FormControlLabel value="medium" control={<Radio color="default"/>}
                                      label={localization.get('home_editor.spacing.medium')}/>
                  </RadioGroup>
                  <br/>
                </div>
              </>
            }
            
            
            {!(this.props.type === "footer", this.state.dataSource  === "drop_down") &&
              <>
                <Select name='dataSource' value={this.state.dataSource} onChange={this.onChange}>
                  {this.state.dataSourceTypes.filter(ds => {
                    const isUniqueType = this.state.type === "unique";
                    const isGoogleAdAllowed = ds.value === "google_ad" && 
                      get(this.state.channel, 'google_publisher_id') && 
                      get(this.state.channel, 'adUnits', []).find(adunit => adunit.purpose === 'home');
                    
                    const uniqueTypeValues = ["text", "video", "image", "lite_smart_search"];
                    const eventGridTypeValues = ["content_creator_editorial"];
                    
                    if (isEventGrid) {
                      return eventGridTypeValues.includes(ds.value);
                    }


                  return (!isUniqueType && !uniqueTypeValues.includes(ds.value) && ds.value !== "google_ad") || 
                          (isUniqueType && (uniqueTypeValues.includes(ds.value) || isGoogleAdAllowed))
                  })
                    .map((dataSourceType, i) => 
                      <MenuItem key={i} value={dataSourceType.value}>{dataSourceType.label}</MenuItem>
                    )}
                </Select>
                <FormHelperText>{localization.get('home_editor.data_source')}</FormHelperText>
              </>
            }
            
            
            {(this.state.dataSource === 'content_creator_talent' || this.state.dataSource === 'content_creator_audiovisual' || this.state.dataSource === 'content_creator_editorial' || this.state.dataSource === 'content_creator') &&
              <React.Fragment>
                <br/>
                <Select name='contentCreatorType' value={this.state.contentCreatorType} onChange={this.onChange}>
                  {this.state.contentCreatorTypes
                    .sort((a, b) => (a.label > b.label) ? 1 : -1)
                    .map((contentCreatorType, i) => {
                      if (this.state.dataSource === 'content_creator_talent' && !contentCreatorType.isTalent) {
                        return false
                      }
                      if (this.state.dataSource === 'content_creator_audiovisual' && (contentCreatorType.isTalent || contentCreatorType.type === "editorial")) {
                        return false
                      }
                      if (this.state.dataSource === 'content_creator_editorial' && (contentCreatorType.isTalent || contentCreatorType.type !== "editorial")) {
                        return false
                      }
                      return (
                        <MenuItem key={i} value={contentCreatorType.value}>{contentCreatorType.label}</MenuItem>
                      )
                    })}
                </Select>
                <FormHelperText>{localization.get('content_creator_type')}</FormHelperText>

                <Button style={{marginTop: 20}}
                        onClick={() => this.onEditTags('content_creator')}>{localization.get('home_editor.tag_selection.edit_title')}</Button>

                {this.renderTagsDialog()}

                <TextField
                  id="limitContent"
                  label={localization.get('form.limit_content')}
                  name="limitContent"
                  value={this.state.limitContent}
                  onChange={this.onChange}
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 100 } }}
                />
                {!isEventGrid &&
                  <>
                    <span>{localization.get('account.license.order_by')}</span>
                    <RadioGroup
                      name="orderBy"
                    style={{width: "100%", flexDirection: "row" }}
                    value={this.state.orderBy}
                    onChange={event => this.setState({ orderBy: event.target.value })}
                  >
                    <FormControlLabel value="default" control={<Radio color="default" />} label={localization.get('form.order_weight')} />
                    <FormControlLabel value="alphabetical_asc" control={<Radio color="default" />} label={localization.get('form.alphabetical')} />
                    <FormControlLabel value="random" control={<Radio color="default" />} label={localization.get('form.order_random')} />
                    <FormControlLabel value="date_random" disabled={this.state.useFilterByDate === "0"} control={<Radio color="default" />} label={localization.get('form.order_date_random')} />
                    <FormControlLabel value="publish_desc" control={<Radio color="default" />} label={localization.get((this.state.dataSource == 'content_creator_talent') ? 'start_activity' : 'form.publish_date')} />
                    </RadioGroup>
                  </>
                }
              </React.Fragment>
            }
            {this.state.dataSource === 'recommended' &&
              <React.Fragment>
                <br/>
                <RadioGroup
                  name="selectPlaylists"
                  value={this.state.selectPlaylists}
                  onChange={e => this.setState({selectPlaylists: e.target.value})}
                  style={{marginRight: 30, float: "left", position: "relative", flexDirection: "row"}}
                >
                  <FormControlLabel value="0" control={<Radio color="default"/>}
                                    label={localization.get('home_editor.use_all')}/>
                  <FormControlLabel value="1" control={<Radio color="default"/>}
                                    label={localization.get('home_editor.use_custom')}/>
                </RadioGroup>
                {this.state.selectPlaylists === '1' &&
                  <AsyncSelect
                    name='globalPlaylists'
                    id={'globalPlaylists'}
                    placeholder={localization.get('conversation.filter_users')}
                    loadingMessage={() => localization.get('loading')}
                    isMulti
                    autoload
                    autoBlur={true}
                    backspaceRemoves={false}
                    noOptionsMessage={() => localization.get('no_options')}
                    value={this.state.globalPlaylists}
                    onChange={globalPlaylists => this.setState({globalPlaylists})}
                    defaultOptions
                    loadOptions={(inputValue, callback) => {
                      callback(this.state.availableGlobalPlaylists.filter(i =>
                        i.label.toLowerCase().normalize("NFD").includes(inputValue.toLowerCase())
                      ))
                    }}
                  />
                }
                <br/>
              </React.Fragment>
            }

            {this.state.dataSource === 'video_content_creation' &&
              <React.Fragment>
                <br/>
                <Select name='contentCreatorType' value={this.state.contentCreatorType} onChange={this.onChange}>
                  {this.state.contentCreatorTypesForCreations
                    .sort((a, b) => (a.label > b.label) ? 1 : -1)
                    .map((contentCreatorType, i) =>
                        <MenuItem key={i} value={contentCreatorType.value}>{contentCreatorType.label}</MenuItem>
                    )}
                </Select>
                <span>{localization.get('account.license.order_by')}</span>
                <RadioGroup
                  name="contentType"
                  style={{width: "100%", flexDirection: "row" }}
                  value={this.state.contentType}
                  onChange={event => this.setState({ contentType: event.target.value })}
                >
                  <FormControlLabel value="all" control={<Radio color="default" />} label={localization.get('all')} />
                  <FormControlLabel value="video" control={<Radio color="default" />} label={localization.get('table.allow_video')} />
                  <FormControlLabel value="audio" control={<Radio color="default" />} label={localization.get('table.allow_audio')} />
                  <FormControlLabel value="image" control={<Radio color="default" />} label={localization.get('table.allow_image')} />
                  <FormControlLabel value="binary" control={<Radio color="default" />} label={localization.get('table.allow_binary')} />
                  <FormControlLabel value="embed" control={<Radio color="default" />} label={localization.get('table.allow_embed')} />
                </RadioGroup>

                <FormHelperText>{localization.get('content_creator_type')}</FormHelperText>
                <Button style={{marginTop: 20}}
                        onClick={() => this.onEditTags('video_content_creation')}>{localization.get('home_editor.tag_selection.edit_title')}</Button>
                {this.renderTagsDialog()}
                {get(this.props, "channel.client.use_rekognition") && 
                  <>
                  <FormHelperText>{localization.get('people.list')}</FormHelperText>
                  <AsyncSelect
                    name='people'
                    id={'people'}
                    placeholder={localization.get('conversation.filter_users')}
                    loadingMessage={() => localization.get('loading')}
                    isMulti
                    autoload
                    autoBlur={true}
                    backspaceRemoves={false}
                    noOptionsMessage={() => localization.get('no_options')}
                    value={this.state.people}
                    onChange={people => this.setState({people})}
                    defaultOptions
                    loadOptions={(inputValue, callback) => {
                      callback(this.state.availablePeople.filter(i =>
                        i.label.toLowerCase().includes(inputValue.toLowerCase())
                      ))
                    }}
                  />
                </>
                }
                <TextField
                    id="limitContent"
                    label={localization.get('form.limit_content')}
                    name="limitContent"
                    value={this.state.limitContent}
                    onChange={this.onChange}
                    type="number"
                    InputProps={{ inputProps: { min: 0, max: 100 } }}
                  />
              </React.Fragment>
            }

            {this.state.dataSource === 'channel_playlist' &&
              <React.Fragment>
                <br/>
                <FormHelperText>{localization.get('playlist')}</FormHelperText>
                <AsyncSelect
                  name='channelPlaylist'
                  id={'channelPlaylist'}
                  placeholder={localization.get('conversation.filter_users')}
                  loadingMessage={() => localization.get('loading')}
                  autoload
                  autoBlur={true}
                  noOptionsMessage={() => localization.get('no_options')}
                  value={this.state.channelPlaylist}
                  onChange={channelPlaylist => this.setState({channelPlaylist})}
                  defaultOptions={this.state.availableChannelPlaylists}
                  loadOptions={(inputValue, callback) => {
                    callback(this.state.availableChannelPlaylists.filter(i =>
                      i.label.toLowerCase().includes(inputValue.toLowerCase())
                    ))
                  }}
                />
                <br/>
              </React.Fragment>
            }

            {this.state.dataSource === 'catalog' &&
              <>
                <FormControl fullWidth style={{marginTop: 30}}>
                  <span>{localization.get('home_editor.select_catalogs')}</span>
                  <AsyncSelect
                    styles={{
                      menu: base => ({
                        ...base,
                        zIndex: 100,
                      })
                    }}
                    theme={theme => ({
                      ...theme,
                      borderRadius: 0
                    })}
                    placeholder={localization.get('catalogs')}
                    loadingMessage={() => localization.get('loading')}
                    noOptionsMessage={() => localization.get('no_options')}
                    onChange={catalogs => this.setState({catalogs})}
                    value={this.state.catalogs}
                    loadOptions={(inputValue, callback) => {
                      callback(this.state.availableCatalogs.filter(i =>
                        i.label.toLowerCase().includes(inputValue.toLowerCase())
                      ))
                    }}
                    defaultOptions={this.state.availableCatalogs}
                    isMulti
                  />
                </FormControl>
                <FormControl fullWidth>
                <span>{localization.get('form.artist')}</span>
                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 10
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0
                  })}
                  name={"artist"}
                  placeholder={localization.get('form.artist')}
                  loadingMessage={() => localization.get('loading')}
                  noOptionsMessage={() => localization.get('no_options')}
                  onChange={artists => this.setState({artists})}
                  value={this.state.artists}
                  defaultOptions
                  isMulti
                  validators={['required']}
                  errorMessages={[localization.get('validator.is_required')]}
                  loadOptions={inputValue =>
                    request.artist
                      .getAll({ filterBy: [{ column: "name", filter: inputValue }] })
                      .then(res => res.map(artist => ({ value: artist.id, label: `${artist.name} - (${localization.get(artist.type)})` })))
                  }
                />
                </FormControl>

                {!this.props.isCustom &&
                  <>
                    <Button style={{marginTop: 20}}
                            onClick={() => this.onEditTags('catalog')}>{localization.get('home_editor.tag_selection.edit_title')}</Button>

                  </>
                }
                {this.renderTagsDialog()}
              </>
            }

            {this.state.dataSource === 'user_library' &&
              <FormControlLabel
                control={
                  <Checkbox
                    style={{color: "#4cc2d5"}}
                    checked={this.state.includeGold}
                    onChange={() => {
                      this.setState({includeGold: !this.state.includeGold})
                    }}
                  />
                }
                label={localization.get('form.include_gold')}
              />
            }

            {this.state.dataSource === 'link' &&
              <FormControl style={{width: '100%', marginTop: '10px'}}>
                <ValidatorForm>
                  <TextValidator
                    style={{width: "100%", marginBottom: "20px"}}
                    label={localization.get('url')}
                    id="link"
                    name="link"
                    value={this.state.link}
                    onChange={e => this.setState({link: e.target.value})}
                    validators={['required']}
                    errorMessages={[localization.get('validator.is_required')]}
                  />
                </ValidatorForm>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{color: "#4cc2d5"}}
                      checked={this.state.newTab}
                      onChange={() => this.setState({newTab: !this.state.newTab})}
                    />
                  }
                  label={localization.get('form.new_tab')}
                />
              </FormControl>
            }

            {this.state.dataSource === 'single_content_creator' &&
              <FormControl fullWidth style={{marginTop: 30}}>
                <span>{localization.get('home_editor.select_content_creator')}</span>
                <AsyncSelect
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 100,
                    })
                  }}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0
                  })}
                  placeholder={localization.get('content_creator')}
                  loadingMessage={() => localization.get('loading')}
                  noOptionsMessage={() => localization.get('no_options')}
                  onChange={singleContentCreator => this.setState({singleContentCreator})}
                  value={this.state.singleContentCreator}
                  loadOptions={inputValue => {
                    let filterBy = [
                      {column: "title", filter: inputValue},
                      {column: "client_id", filter: this.props.clientId}
                    ];
                    if (this.props.channelId) {
                      filterBy.push({column: "channel_id", filter: this.props.channelId})
                    }
                    return request.contentCreator.getAll({filterBy: filterBy})
                      .then(response => response.map(cc => ({value: cc.id, label: cc.name})))
                  }}
                />
              </FormControl>
            }

            {!['home', 'link', 'drop_down', 'my_list', 'contact_form', 'single_content_creator', 'section', 'playlist_link', 'static'].includes(this.state.dataSource) && !isEventGrid &&
              <HomeElementStyleSelection
                type={!['navbar', 'footer'].includes(this.props.type) ? (this.props.grid_featured ? 'grid_featured' : this.props.type) : this.props.view_type}
                shape={this.state.shape}
                fixedTitle={this.state.fixedTitle}
                withBackground={this.state.withBackground}
                numbered={this.state.numbered}
                onlyImage={this.state.onlyImage}
                columns={this.state.columns}
                rowsToShow={this.state.rowsToShow}
                rowsToShowMobile={this.state.rowsToShowMobile}
                onChange={this.onChange}
                onCheckboxChange={name => this.setState({[name]: !this.state[name]})}
                featuredType={this.state.featuredType}
                useFeaturedFormat={this.state.useFeaturedFormat}
                square={this.state.square}
                hasMargin={this.state.hasMargin}
                rounded={this.state.rounded}
                halfHeight={this.state.halfHeight}
                mobileHalfHeight={this.state.mobileHalfHeight}
                backgroundColor={this.state.backgroundColor}
                backgroundStyle={this.state.backgroundStyle}
                isNavbar={this.props.type === 'navbar'}
                showAuthor={this.state.showAuthor}
                textPosition={this.state.textPosition}
                withText={this.state.withText}
                useRegular={this.state.useRegular}
                useDots={this.state.useDots}
                useSearchInput={this.state.useSearchInput}
                pillBackgroundColor={this.state.pillBackgroundColor}
                pillFontColor={this.state.pillFontColor}
                mobileGrid={this.state.mobileGrid}
              />
            }

            {['custom', 'custom_embed'].includes(this.state.dataSource) &&
              <CustomElement
                customElements={this.state.items}
                contentCreatorTypes={this.state.contentCreatorTypes}
                onUpdateSuccess={items => this.setState({ items })}
                onUpdateFailed={() => console.log('failed')}
                channelId={this.props.channelId}
                clientId={this.props.clientId}
                section={this.props.section}
                parent={{id: this.props.id}}
                permissions={this.state.permissions}
                availableChannelPlaylists={this.state.availableChannelPlaylists}
                sections={this.state.sections}
                channel={this.state.channel}
                isEmbed={this.state.dataSource === 'custom_embed'}
              />
            }

            {this.state.dataSource === 'section' &&
              <React.Fragment>
                <br/>
                <FormHelperText>{localization.get('section')}</FormHelperText>
                <AsyncSelect
                  name='section'
                  id={'section'}
                  placeholder={localization.get('section')}
                  loadingMessage={() => localization.get('loading')}
                  autoload
                  autoBlur={true}
                  noOptionsMessage={() => localization.get('no_options')}
                  value={this.state.currentSection}
                  onChange={currentSection => this.setState({currentSection})}
                  defaultOptions={this.state.sections}
                  loadOptions={(inputValue, callback) => {
                    callback(this.state.sections.filter(i =>
                      i.label.toLowerCase().includes(inputValue.toLowerCase())
                    ))
                  }}
                />
                <br/>
              </React.Fragment>
            }

            {this.state.dataSource === "text" &&
              <>
                <br />
                <br />
                <ValidatorForm style={{maxWidth: 700}}>
                  <TranslatableInputs
                    color="dark"
                    isEditorialContent
                    children={AvailableLanguages.map(language => {
                      const currentLangTranslation = this.state.uniqueTexts.find(tr => tr.language === language);

                      return {
                        language: language,
                        content: [
                          <TextValidator
                            style={{display: "none"}}
                            id={`text${language}`}
                            label={localization.get('form.note')}
                            name={`text${language}`}
                            value={currentLangTranslation.text}
                            onChange={e => this.onChangeUniqueTranslation(e.target.value, 'text', language)}
                          />,
                          <div id="ignore">
                            <EditorialEditor
                              editorState={get(currentLangTranslation, "text", "")}
                              onChange={e => this.onChangeUniqueTranslation(e, 'text', language)}
                              noColors
                            />
                          </div>
                        ]
                      }
                    })}
                  />

                  <h4>{localization.get("form.mobile_font")}</h4>
                  <TextValidator
                    style={{ width: "100%" }}
                    id="mobileFontResize"
                    validators={['minNumber:0', 'maxNumber:100']}
                    type="number"
                    label={`${localization.get("form.mobile_resize_percentage")} %`}
                    name="mobileFontResize"
                    value={this.state.mobileFontResize}
                    onChange={e => this.setState({mobileFontResize: e.target.value})}
                  />
                </ValidatorForm>
              </>
            }

            {(this.state.dataSource === "image" || this.state.dataSource === "video") &&
              <TranslatableInputs
                color="dark"
                isEditorialContent
                disabled={this.state.disableForm}
                children={AvailableLanguages.map(language => {
                  return {
                    language: language,
                    content: [
                      <>
                        {this.state.dataSource === "image" &&
                          <>
                            <br/>
                            <FormControl style={{width: '100%', marginTop: '10px'}}>
                              <ValidatorForm>
                                <TextValidator
                                  style={{width: "100%", marginBottom: "20px"}}
                                  label={localization.get('url')}
                                  id="link"
                                  name="link"
                                  value={this.state.images.find(image => image.language === language).link || ""}
                                  onChange={e => this.onChangeTranslatable('images', e.target.value, 'link', language)}
                                />
                              </ValidatorForm>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    style={{color: "#4cc2d5"}}
                                    checked={this.state.images.find(image => image.language === language).new_tab || false}
                                    onChange={e => this.onChangeTranslatable('images', !this.state.images.find(image => image.language === language).new_tab , 'new_tab', language)}
                                  />
                                }
                                label={localization.get('form.new_tab')}
                              />
                            </FormControl>
                            <br/>

                            <div style={{
                              color: "#757575",
                              textAlign: 'center',
                              marginTop: 10
                            }}>{localization.get('image')}</div>
                            {this.state.images.find(image => image.language === language) &&
                              <ImagePicker
                                image={request.file.image.makeCroppedUrl(this.state.images.find(image => image.language === language).image_id, 400, 400)}
                                previewOnly={true}
                                id={this.state.images.find(image => image.language === language).image_id}/>}
                            <br/>
                            <FilePondUploader
                              disableForm={this.state.disableForm}
                              updateDisableForm={value => this.setState({disableForm: value})}
                              file={this.state.image}
                              id={"image"}
                              type={"image"}
                              allowedTypes={["image/*"]}
                              clientId={this.props.clientId}
                              onIdChange={async image => {
                                if (image) {
                                  await this.onChangeTranslatable('images', image.id, 'image_id', language);
                                  await this.onChangeTranslatable('images', {src: null, filename: null}, 'image', language);
                                  await this.onChangeTranslatable('images', this.formNewUrl(image.path), 'imagePreview', language);
                                } else {
                                  await this.onChangeTranslatable('images', null, 'image_id', language);
                                  await this.onChangeTranslatable('images', {src: null, filename: null}, 'image', language);
                                }
                              }}
                              setPreview={() => {
                                let item = {
                                  image: {
                                    id: this.state.images.find(image => image.language === language).image_id,
                                    src: this.state.images.find(image => image.language === language).imagePreview
                                  }
                                }
                                this.onChangeTranslatable('images', {src: getUpdateFormImageUrlFromItem(item)}, 'image', language);
                              }}
                            />
                            <br/>
                            {this.state.mobileImage && this.state.mobileImage.src &&
                              <ImagePicker image={this.state.mobileImage.src} previewOnly={true}
                                           id={this.state.mobileImage.id}/>}
                            <div style={{
                              color: "#757575",
                              textAlign: 'center',
                              marginTop: 10
                            }}>{localization.get('image_mobile')}</div>
                            <FilePondUploader
                              disableForm={this.state.disableForm}
                              updateDisableForm={value => this.setState({disableForm: value})}
                              file={this.state.mobileImage}
                              id={"mobileImage"}
                              type={"image"}
                              allowedTypes={["image/*"]}
                              clientId={this.props.clientId}
                              onIdChange={async image => {
                                if (image) {
                                  await this.onChangeTranslatable('images', image.id, 'mobile_image_id', language);
                                  await this.onChangeTranslatable('images', {src: null, filename: null}, 'mobileImage', language);
                                  await this.onChangeTranslatable('images', this.formNewUrl(image.path), 'imageMobilePreview', language);
                                } else {
                                  await this.onChangeTranslatable('images', null, 'mobile_image_id', language);
                                  await this.onChangeTranslatable('images', {src: null, filename: null}, 'mobileImage', language);
                                }
                              }}
                              setPreview={() => {
                                let item = {
                                  image: {
                                    id: this.state.images.find(image => image.language === language).mobile_image_id,
                                    src: this.state.images.find(image => image.language === language).imageMobilePreview
                                  }
                                }
                                this.onChangeTranslatable('images', {src: getUpdateFormImageUrlFromItem(item)}, 'mobileImage', language);
                              }}
                            />
                            <br/>
                          </>
                        }

                        {this.state.dataSource === "video" &&
                          <>
                            <br/>
                            <div style={{
                              color: "#757575",
                              textAlign: 'center',
                              marginTop: 10
                            }}>{localization.get('image')}</div>
                            {this.state.videos.find(video => video.language === language).image_id &&
                              <ImagePicker
                                image={request.file.image.makeCroppedUrl(this.state.videos.find(video => video.language === language).image_id, 400, 400)}
                                previewOnly={true}
                                id={this.state.videos.find(video => video.language === language).image_id}/>}
                            <br/>
                            <FilePondUploader
                              disableForm={this.state.disableForm}
                              updateDisableForm={value => this.setState({disableForm: value})}
                              file={this.state.image}
                              id={"image"}
                              type={"image"}
                              allowedTypes={["image/*"]}
                              clientId={this.props.clientId}
                              onIdChange={async image => {
                                if (image) {
                                  await this.onChangeTranslatable('videos', image.id, 'image_id', language);
                                  await this.onChangeTranslatable('videos', {src: null, filename: null}, 'image', language);
                                  await this.onChangeTranslatable('videos', this.formNewUrl(image.path), 'imagePreview', language);
                                } else {
                                  await this.onChangeTranslatable('videos', null, 'image_id', language);
                                  await this.onChangeTranslatable('videos', {src: null, filename: null}, 'image', language);
                                }
                              }}
                              setPreview={() => {
                                let item = {
                                  image: {
                                    id: this.state.videos.find(video => video.language === language).image_id,
                                    src: this.state.videos.find(video => video.language === language).imagePreview
                                  }
                                }
                                this.onChangeTranslatable('videos', {src: getUpdateFormImageUrlFromItem(item)}, 'image', language);
                              }}
                            />
                            <br/>
                            <div style={{
                              color: "#757575",
                              textAlign: 'center',
                              marginTop: 10
                            }}>{localization.get('video')}</div>
                            {this.state.videos.find(video => video.language === language).video &&
                              <video width='700px' controls>
                                <source
                                  src={this.state.videos.find(video => video.language === language).video.url}
                                  type='video/mp4'/>
                                Your browser does not support HTML5 video.
                              </video>}
                            <br/>
                            <FilePondUploader
                              disableForm={this.state.disableForm}
                              updateDisableForm={value => this.setState({disableForm: value})}
                              file={this.state[`video${language}`]}
                              id={"video"}
                              type={"video"}
                              allowedTypes={["video/mp4", "video/x-m4v", "video/*"]}
                              clientId={this.props.clientId}
                              onIdChange={async video => {
                                if (video) {
                                  await this.onChangeTranslatable('videos', video.id, 'video_id', language);
                                  await this.onChangeTranslatable('videos', {src: null}, 'video', language);
                                  await this.onChangeTranslatable('videos', this.formNewUrl(video.path), 'videoPreview', language);
                                } else {
                                  await this.onChangeTranslatable('videos', null, 'video_id', language);
                                  await this.onChangeTranslatable('videos', {src: null}, 'video', language);
                                }
                              }}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  style={{color: "#4cc2d5"}}
                                  checked={this.state.videos.find(video => video.language === language).play_on_hover}
                                  onChange={e => this.onChangeTranslatable('videos', !this.state.videos.find(video => video.language === language).play_on_hover, 'play_on_hover', language)}
                                />
                              }
                              label={localization.get('form.play_on_hover')}
                            />
                          </>
                        }
                      </>
                    ]
                  }
                })}
              />
            }

            {this.state.dataSource === "change_language" &&
              <>
                <div style={{margin: "10px 0"}}>{localization.get('artist_demo.select_language')}</div>
                <Select
                  name='selectedLanguage'
                  value={this.state.selectedLanguage}
                  onChange={this.onChange}
                >
                  {this.state.channel.languages.map(lang => <MenuItem
                    value={lang.language}>{localization.get(`language.${lang.language}`)}</MenuItem>)}
                </Select>
              </>
            }

            {this.state.dataSource === 'embed' &&
            <ValidatorForm>
                 <RadioGroup
                    name="contentType"
                    style={{width: "100%", flexDirection: "row" }}
                    value={"youtube"}
                    disabled
                  >
                    <FormControlLabel value="youtube" disabled control={<Radio color="default" />} label={"YouTube"} />
                  </RadioGroup>
                  <TextValidator
                    style={{ width: "100%", marginBottom: "20px" }}
                    label={"Youtube URL (https://www.youtube.com/watch?v=ID_VIDEO)"}
                    name="embed"
                    value={this.state.embed}
                    onChange={e => this.setState({embed: e.target.value})}
                  />
              </ValidatorForm>
            }

            {this.state.dataSource === 'lite_smart_search' &&
              <ValidatorForm>
                <inputLabel>{localization.get('global_playlist.destination_type')}</inputLabel>
                <RadioGroup
                    name="smartSearchContentType"
                    style={{width: "100%", flexDirection: "row" }}
                    value={this.state.smartSearchContentType}
                    onChange={e => {
                      this.onChange(e)
                      this.setState({ 
                        dateSearch: e.target.value == "material" ? "none" : this.state.smartSearchContentType,
                        availableTagGroups: []
                      })
                      if (!!this.state.smartSearchContentType) this.onEditTags(e.target.value === 'material' ? 'video_content_creation' : 'content_creator', this.state.smartSearchContentType, false)
                    }}
                  >
                    <FormControlLabel value="material" control={<Radio color="default" />} label={localization.get("content_creations.materials")} />
                    <FormControlLabel value="content" control={<Radio color="default" />} label={localization.get("content_creators")} />
                  </RadioGroup>
                  <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                    <inputLabel>{localization.get('content_creator_type')}</inputLabel>
                    <Select
                      name='smartSearchContentCreatorType'
                      value={this.state.smartSearchContentCreatorType}
                      onChange={e => {
                        this.onChange(e)
                        this.onEditTags(this.state.smartSearchContentType === 'material' ? 'video_content_creation' : 'content_creator', e.target.value, false)
                      }}
                    >
                    {this.state.contentCreatorTypes
                      .sort((a, b) => (a.label > b.label) ? 1 : -1)
                      .map((contentCreatorType, i) => {
                        return (<MenuItem key={i} value={contentCreatorType.value}>{contentCreatorType.label}</MenuItem>)
                      })}
                  </Select>
                </div>
                <br/>
                <TextField
                  id="limitContent"
                  label={localization.get('form.limit_content')}
                  name="limitContent"
                  value={this.state.limitContent}
                  onChange={this.onChange}
                  type="number"
                  fullWidth
                  InputProps={{ inputProps: { min: 0, max: 100 } }}
                />
                <br/>
                <br/>
                <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                  <inputLabel>{localization.get('tag_groups')}</inputLabel>
                  <AsyncSelect 
                    name='availableTagGroups'
                    id={'availableTagGroups'}
                    placeholder={localization.get('tag_groups')}
                    loadingMessage={() => localization.get('loading')}
                    isMulti
                    autoload
                    autoBlur={true}
                    backspaceRemoves={false}
                    noOptionsMessage={() => localization.get('no_options')}
                    value={this.state.availableTagGroups}
                    onChange={availableTagGroups => this.setState({availableTagGroups})}
                    defaultOptions={this.state.tagGroups.map(tg => ({value: tg.id, label: tg.name}))}
                    loadOptions={(inputValue, callback) => {
                      callback(this.state.tagGroups
                        .map(tg => ({value: tg.id, label: tg.name}))
                        .filter(i =>i.label.toLowerCase().normalize("NFD").includes(inputValue.toLowerCase())))
                    }}
                  />
                </div>
                <br/>
                <inputLabel>{localization.get('content_creators.date_search')}</inputLabel>
                <RadioGroup
                    name="dateSearch"
                    style={{width: "100%", flexDirection: "row" }}
                    value={this.state.dateSearch}
                    onChange={e => this.onChange(e)}
                  >
                    <FormControlLabel disabled={this.state.smartSearchContentType == "material"} value="none" control={<Radio color="default" />} label={localization.get("content_creators.none")} />
                    <FormControlLabel disabled={this.state.smartSearchContentType == "material"} value="event" control={<Radio color="default" />} label={localization.get("content_creators.events")} />
                    <FormControlLabel disabled={this.state.smartSearchContentType == "material"} value="publish" control={<Radio color="default" />} label={localization.get("content_creators.publish")} />
                  </RadioGroup>

                    {this.state.smartSearchContentType != "material" &&
                      <>
                        <inputLabel>{localization.get('form.view_type_content')}</inputLabel>
                        <br/>
                        <Select value={this.state.searchViewType} onChange={(event) => this.setState({searchViewType: event.target.value })} >
                          <MenuItem value={"grid6"}>{localization.get('form.grid')}</MenuItem>
                          <MenuItem value={"grid6_rounded"}>{localization.get('form.grid_rounded')}</MenuItem>
                          <MenuItem value={"featured2"}>{localization.get('form.featured_up')}</MenuItem>
                          <MenuItem value={"featured2_alternative"}>{localization.get('form.featured_down')}</MenuItem>
                          <MenuItem value={"movie"}>{localization.get('form.movieView')}</MenuItem>
                          <MenuItem value={"movie_alternative"}>{localization.get('form.movieView.alternative')}</MenuItem>
                        </Select>
                      </>
                    }
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{color: "#4cc2d5"}}
                        checked={this.state.hideStarterText}
                        onChange={e => this.setState({hideStarterText: e.target.checked})}
                      />
                    }
                    label={localization.get('content_creators.hide_starter_text')}
                  />
              </ValidatorForm>
            }

            {(["grid", 'grid_featured'].includes(this.props.type) || this.props.grid_featured) && ["content_creator_audiovisual", "content_creator_talent", "content_creator_editorial"].includes(this.state.dataSource) && 
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.isPaginatedGrid}
                    onChange={() => this.setState(prevState => ({ isPaginatedGrid: !prevState.isPaginatedGrid }))}
                    name="isPaginatedGrid"
                  />
                }
                label={localization.get('home_editor.paginated_grid')}
              />
            }
            
          </FormControl>
       
          {isEventGrid &&
            <>
              <inputLabel>{localization.get('content_creators.link_to')}</inputLabel>
                <RadioGroup
                name="linkTo"
                style={{width: "100%", flexDirection: "row" }}
                value={this.state.linkTo}
                onChange={e => this.onChange(e)}
              >
                <FormControlLabel value="none" control={<Radio color="default" />} label={localization.get("content_creators.none")} />
                <FormControlLabel value="default" control={<Radio color="default" />} label={localization.get("content_creators.content_creator")} />
                <FormControlLabel value="livestream" control={<Radio color="default" />} label={localization.get("livestream")} />
              </RadioGroup>
              <HomeElementEventPreferences
                activeColor={this.state.activeColor}
                hoverBackgroundColor={this.state.hoverBackgroundColor}
                hoverTextColor={this.state.hoverTextColor}
                hideDuration={this.state.hideDuration}
                onChange={this.onChange}
              />
            </>
          }
      </div>
      {this.props.isModal && 
        <DialogActions>
          <Button onClick={() => this.props.onClose()}>{localization.get('cancel')}</Button>
          {this.makeSubmitButton()}
        </DialogActions>
      }
      </>
    )}
}

export default HomeGridAndCarouselBase

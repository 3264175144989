export default {
  // MAINTENANCE_MODE
  SET_MAINTENANCE_MODE: "SET_MAINTENANCE_MODE",

  // Misc
  NAVBAR_HOME_BUTTON_PRESSED: "NAVBAR_HOME_BUTTON_PRESSED",
  HOME_ELEMENT_ITEM_CLICKED: "HOME_ELEMENT_ITEM_CLICKED",
  BANNER_SOUND_ENABLED: "BANNER_SOUND_ENABLED",
  RESERVATION_REQUESTED: "RESERVATION_REQUESTED",
  ADD_TO_FAVORITE_BUTTON_PRESS: "ADD_TO_FAVORITE_BUTTON_PRESS",
  REMOVE_FROM_FAVORITE_BUTTON_PRESS: "REMOVE_FROM_FAVORITE_BUTTON_PRESS",

  ADD_TO_FAVORITE_ITEM_DESCRIPTION: "ADD_TO_FAVORITE_ITEM_DESCRIPTION",
  REMOVE_FROM_FAVORITE_ITEM_DESCRIPTION: "REMOVE_FROM_FAVORITE_ITEM_DESCRIPTION",

  // Favorites
  ADD_ALBUM_TO_FAVORITES: 'ADD_ALBUM_TO_FAVORITES',
  REMOVE_ALBUM_FROM_FAVORITES: 'REMOVE_ALBUM_FROM_FAVORITES', 
  ADD_TRACK_TO_FAVORITES: "ADD_TRACK_TO_FAVORITES",
  REMOVE_TRACK_FROM_FAVORITES: "REMOVE_TRACK_FROM_FAVORITES",
  ADD_SPEAKER_TO_FAVORITES: "ADD_SPEAKER_TO_FAVORITES",
  REMOVE_SPEAKER_FROM_FAVORITES: "REMOVE_SPEAKER_FROM_FAVORITES",
  ADD_PLAYLIST_TO_FAVORITES: "ADD_PLAYLIST_TO_FAVORITES",
  REMOVE_PLAYLIST_FROM_FAVORITES: "REMOVE_PLAYLIST_FROM_FAVORITES",


  // Track Table Tags
  TAG_PRESSED_IN_TRACK_TABLE: "TAG_PRESSED_IN_TRACK_TABLE",
  TAG_SELECTED_PRESSED_IN_TRACK_TABLE: "TAG_SELECTED_PRESSED_IN_TRACK_TABLE",

  // Globals
  SHOW_SNACKBAR: "SHOW_SNACKBAR",
  SHOW_GLOBAL_CONFIRMATION_DIALOG: "SHOW_GLOBAL_CONFIRMATION_DIALOG",
  HIDE_GLOBAL_CONFIRMATION_DIALOG: "HIDE_GLOBAL_CONFIRMATION_DIALOG",
  CONFIRMATION_DIALOG_CLOSED: "CONFIRMATION_DIALOG_CLOSED",
  OPEN_ADD_TO_PLAYLIST_DIALOG: "OPEN_ADD_TO_PLAYLIST_DIALOG",
  SHOW_GLOBAL_LICENSE_WIZARD_DIALOG: "SHOW_GLOBAL_LICENSE_WIZARD_DIALOG",
  SHOW_GLOBAL_LOGIN_DIALOG: "SHOW_GLOBAL_LOGIN_DIALOG",
  HIDE_GLOBAL_LOGIN_DIALOG: "HIDE_GLOBAL_LOGIN_DIALOG",
  LOGIN_DIALOG_CLOSED: "LOGIN_DIALOG_CLOSED",
  CHANGE_VIDEO_SLIDER_BANNER: "CHANGE_VIDEO_SLIDER_BANNER",
  OPEN_MATERIAL_MODAL: "OPEN_MATERIAL_MODAL",
  OPEN_CONTACT_DIALOG: "OPEN_CONTACT_DIALOG",

  // Licenses
  LICENSED_MUSIC: "LICENSED_MUSIC",

  // Notifications
  UPDATE_DASHBOARD_NOTIFICATIONS: "UPDATE_DASHBOARD_NOTIFICATIONS",
  UPDATE_HOME_NOTIFICATIONS: "UPDATE_HOME_NOTIFICATIONS",

  // Translate
  CHECK_MISSING_TRANSLATABLE_INPUTS: "CHECK_MISSING_TRANSLATABLE_INPUTS",
  MISSING_FIELDS_TRANSLATED: "MISSING_FIELDS_TRANSLATED",

  //Content Creators
  OPEN_VIEWS_LIMIT_MODAL: "OPEN_VIEWS_LIMIT_MODAL",
  OPEN_CONTENT_MODAL: "OPEN_CONTENT_MODAL",
  OPEN_EDITING_MODAL: "OPEN_EDITING_MODAL",

  //NAVBAR
  EXPLORE_NAVBAR_SEARCH: "EXPLORE_NAVBAR_SEARCH",

  //SECTION
  SECTION_CHANGED: "SECTION_CHANGED",

  PLAY_AT_TIME: "PLAY_AT_TIME",
  OPEN_AD_DIALOG: "OPEN_AD_DIALOG",
  SEARCH_QUERY_CHANGED: 'SEARCH_QUERY_CHANGED'
};

import React, {Component} from "react";
import {ServerSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import {request} from '../../../api-client'
import localization from '../../../config/localization'
import moment from "moment";
import Card from "@material-ui/core/Card";
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import {Edit, FilterNone, PlayArrow} from "@material-ui/icons";
import {IconButton, Tooltip} from "@material-ui/core";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {addHttpsToUrl, channelPathGenerator} from "../../../api-client/core/authentication/utils";

let table

class Table extends Component {

  componentDidUpdate = prevProps => {
    if (prevProps.value !== this.props.value) {
        table.forceUpdate();
    }
  };

  makeLivestreamTypeColumn = (livestream) => {
    let type = localization.get(`livestream${livestream.fake ? '.fake' : ''}`)
    let source = localization.get(`form.${livestream.source}`)

    return `${type} ${livestream.source ? `(${source})` : ''}`
  }

  columns = () => [
    {
      Header: localization.get('table.name'),
      accessor: "name",
      sortable: false,
      Filter: ({filter, onChange}) => (
        <input
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : ''}
          placeholder={localization.get('table.search')}
        />
      ),
    },
    {
      Header: localization.get('table.type'),
      Cell: ({ original }) => <span>{this.makeLivestreamTypeColumn(original)}</span>,
      sortable: false,
      filterable: false,
    },
    {
      Header: localization.get("table.date"),
      accessor: "start_time",
      Cell: ({ value }) => (
        <span>
          {moment.utc(value).local().format("DD/MM/YYYY HH:mm")}
        </span>
      ),
      filterable: false,
      sortable: false,
    },
    {
      id: "actions",
      sortable: false,
      filterable: false,
      maxWidth: 145,
      accessor: livestream => {
        const buttonBuilder = new TableButtonsBuilder()

        if (this.props.value !== 'finished') {
          buttonBuilder.withOther(
            <TableButton
              title={localization.get('copy')}
            >
              <Tooltip placement="top" title={this.state.copied}>
                <CopyToClipboard text={!!this.props.channel.domain ?
                    `${addHttpsToUrl(this.props.channel.domain.url)}/vivo/${livestream.id}-${livestream.name}` :
                  `${window.location.protocol}//${window.location.hostname}${channelPathGenerator(`vivo/${livestream.id}-${livestream.name}`, this.props.channel)}`
                }
                >
                  <IconButton>
                    <FilterNone />
                  </IconButton>
                </CopyToClipboard>
              </Tooltip>
            </TableButton>, !!this.props.channel.domain ?
              `${addHttpsToUrl(this.props.channel.domain.url)}/vivo/${livestream.id}-${livestream.name}` :
              `${window.location.protocol}//${window.location.hostname}${channelPathGenerator(`vivo/${livestream.id}-${livestream.name}`, this.props.channel)}`)

          buttonBuilder.withOther(
            <TableButton
              title={localization.get("reel.edit_thumbnail_tooltip")}
              onClick={() => window.open(`/panel/channels/${this.props.channelId}/livestream_preview/${livestream.id}`, '_blank')}
            >
              <PlayArrow />
            </TableButton>
          );

          buttonBuilder.withOther(
            <TableButton
              title={localization.get("edit")}
              onClick={() => this.props.onEdit(livestream.id)}
            >
              <Edit />
            </TableButton>
          );

          buttonBuilder.withDelete(
            livestream.name,
            () => request.liveStream.delete(this.props.channelId, livestream.id),
            state => {
              if (state === "passed") {
                table.removeById(livestream.id)
              }
            }
          )
        }else {
          buttonBuilder.withOther(
            <TableButton
              title={localization.get("edit")}
              onClick={() => this.props.onEdit(livestream.id)}
            >
              <Edit />
            </TableButton>
          );
        }
        return buttonBuilder.build()
      }
    },
  ]

  render = () => {
    return (
      <>
        <Card raised>
          <div style={{padding: "0 15px 15px"}}>
            <ServerSidePaginationTable
              ref={r => table = r}
              columns={this.columns()}
              perPage={8}
              showPaginationBottom
              readAndWriteUrl={false}
              requestTableData={paginationConfig => {
                paginationConfig.orderBy = {column: "start_time", type: this.props.value !== 'finished' ? "asc" : "desc"}

                paginationConfig = { ...paginationConfig, filterBy: paginationConfig.filterBy.concat([{ column: 'finished', filter: this.props.value === 'finished' ? 1 : 0}])}

                if (this.props.channelId) {
                  return request.liveStream.getAllForChannel(paginationConfig, this.props.channelId).then(response => {
                    this.setState({total: response.meta.total});
                    return response;
                  });
                } else {
                  return {data: []}
                }

              }}
            />
          </div>
        </Card>
      </>
    )
  }
}


export default Table

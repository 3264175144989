import {isNullOrUndefined} from "util";
import {communityValidationOptions} from "../../../views/Channels/utils"


export const makeJsonData = ({
   name,
   visibility,
   email,
   detail,
   client,
   type,
   googleAnalyticsId,
   googleTagManagerId,
   customUrl,
   channelContentCreatorTypes,
   translations,
   catalogs,
   socialLinks,
   imageId,
   loginImageId,
   footerImageId,
   sidebarImageId,
   faviconId,
   showBanner,
   showAdvancedSearch,
   showSupport,
   showAbout,
   tags,
   navbarElements,
   navbarElementsToDelete,
   binaryId,
   policyId,
   bloomcloudChannel,
   loginType,
   languages,
   canDownloadTracks,
   tagGroups,
   supportNegativeSearch,
   trackingGroup,
   showLoginButton,
   preRollVideoId,
   extraRegistration,
    showTagSelection,
   loginClientId,
   loginClientSecret,
   dashboardLoginClientId,
   dashboardLoginClientSecret,
   dashboardLoginType,
   mercadoPagoClientId,
   mercadoPagoClientSecret,
   paypalClientId,
   paypalClientSecret,
   googleAdsensePublisherId,
   adUnitHomeDesktop,
   adUnitHomeMobile,
   adUnitContentDesktop,
   adUnitContentMobile,
   searchType,
   hasEvents,
   showFilterOnSmartSearch,
   notifyEndpoint,
   notifySecret,
   radioEnabled,
   radioLink,
   addresses,
   channelShipments,
   commercialMail,
   hasCommunity,
   communityCatalog,
   communityCCTypes,
   communityTranslations,
   customShare,
   useTools,
   channelRedirects,
   showDate,
   noIndex,
   customScripts,
   isDemo,
   skipTimePreRoll,
   footerType,
   footerLogoSide,
   footerTranslations,
   footerSocialPosition,
   footerMargin,
   communityRequiresApproval,
   communityPreferences,
   communityEmail,
   communityCanDeleteMaterials,
   useLicenses,
   usePlaylists,
   extendRegistration,
   hasExtendRegistration,
   useBrandButton,
   editableFor,
   purpose,
   navbarContentCreatorTypeId,
   navbarSearchType,
   mobileNavbarType,
   useSidebarDesktop,
   useFloatingContactButton,
   floatingButtonImageId,
   footerSocialWithLogo,
   ads,
   clearAds,
   disableShare
 }) => {
  const form = new FormData();
  if (name) form.append("name", name);
  if (visibility) form.append("visibility", visibility)
  if (type) form.append("type", type);
  if (!isNullOrUndefined(email)) form.append("email", email);
  if (detail) form.append("detail", detail);
  if (client) form.append("client_id", client.value);
  if (!isNullOrUndefined(googleAnalyticsId)) form.append("google_analytics_id", googleAnalyticsId);
  if (!isNullOrUndefined(googleTagManagerId)) form.append("google_tag_manager_id", googleTagManagerId);
  if (catalogs) catalogs.forEach(x => form.append("catalogs_ids[]", x.value))
  if (navbarElements) navbarElements.sort((a, b) => a.order - b.order).forEach(x => form.append("navbar_elements_ids[]", x.id))
  if (navbarElementsToDelete) navbarElementsToDelete.forEach(x => form.append("navbar_elements_ids_to_delete[]", x))
  if (tags) tags.forEach(x => form.append("tags_ids[]", x.value))
  if (!isNullOrUndefined(customUrl)) form.append("domain", customUrl);
  if (showBanner) form.append("show_banner", showBanner);
  if (!isNullOrUndefined(showSupport)) form.append('show_support', showSupport ? 1 : 0)
  if (showAdvancedSearch) form.append('show_advanced_search', showAdvancedSearch)
  if (showAbout) form.append('show_about', showAbout)
  if (showAbout) form.append('login_type', loginType)
  if (!isNullOrUndefined(bloomcloudChannel)) form.append('bloomcloud_channel', bloomcloudChannel ? 1 : 0)
  if (!isNullOrUndefined(canDownloadTracks)) form.append('can_download_tracks', canDownloadTracks ? 1 : 0)

  languages.forEach((lang, index) => {
    form.append(`langs[${index}][language]`, lang.lang);
    form.append(`langs[${index}][is_main]`, lang.isMain ? 1: 0);
  });

  form.append(`language`, languages.find(lang => lang.isMain).lang);

  if (trackingGroup) form.append('tag_group_tracking', trackingGroup.value)
  if (!isNullOrUndefined(showLoginButton)) form.append('show_login_button', showLoginButton ? 1 : 0)
  if (!isNullOrUndefined(extraRegistration)) form.append('extra_registration', extraRegistration.split("#")[0])
  if (!isNullOrUndefined(notifyEndpoint)) form.append('notify_endpoint', notifyEndpoint)
  if (!isNullOrUndefined(notifySecret)) form.append('notify_secret', notifySecret)
  if (!isNullOrUndefined(tagGroups)) {
    if (tagGroups.length > 0) {
      tagGroups.forEach((tg, index) => {
        form.append(`tag_groups[${index}][id]`, tg.id);
        form.append(`tag_groups[${index}][view_type]`, tg.view_type);
        form.append(`tag_groups[${index}][target]`, tg.target);
      });
    }else {
      form.append('tagGroups', 'empty')
    }
  }

  if (!isNullOrUndefined(channelContentCreatorTypes)) {
    if (channelContentCreatorTypes.length > 0) {
      channelContentCreatorTypes.forEach((cr, index) => {
        if (cr.content_creator_type_id !== "music") {
          form.append(`channel_content_creator_types[${index}][content_creator_type_id]`, cr.content_creator_type_id);
          form.append(`channel_content_creator_types[${index}][show_on_global_search]`, cr.show_on_global_search ? 1 : 0);
          form.append(`channel_content_creator_types[${index}][show_on_smart_search]`, cr.show_on_smart_search ? 1 : 0);
          form.append(`channel_content_creator_types[${index}][tracking_tag_group_id]`, cr.tracking_tag_group_id || 0);
          form.append(`channel_content_creator_types[${index}][view_type]`, cr.view_type || "grid6");
          form.append(`channel_content_creator_types[${index}][creation_view_type]`, cr.creation_view_type || "grid4");
          form.append(`channel_content_creator_types[${index}][contact_email]`, cr.contact_email || "");
          form.append(`channel_content_creator_types[${index}][show_filter_on_smart_search]`, cr.show_filter_on_smart_search ? 1 : 0);
          form.append(`channel_content_creator_types[${index}][show_search_input_on_smart_search]`, cr.show_search_input_on_smart_search ? 1 : 0);
          form.append(`channel_content_creator_types[${index}][support_negative_search]`, cr.support_negative_search ? 1 : 0);
          form.append(`channel_content_creator_types[${index}][support_publish_search]`, cr.support_publish_search ? 1 : 0);
          form.append(`channel_content_creator_types[${index}][has_multiple_tags_allowed]`, cr.has_multiple_tags_allowed ? 1 : 0);
          form.append(`channel_content_creator_types[${index}][allow_content_search]`, cr.allow_content_search? 1 : 0);
          form.append(`channel_content_creator_types[${index}][allow_creation_search]`, cr.allow_creation_search ? 1 : 0);
          form.append(`channel_content_creator_types[${index}][allow_event_search]`, cr.allow_event_search? 1 : 0);
          form.append(`channel_content_creator_types[${index}][allow_tag_search]`, cr.allow_tag_search? 1 : 0);
          form.append(`channel_content_creator_types[${index}][allow_people_search]`, cr.allow_people_search? 1 : 0);
          form.append(`channel_content_creator_types[${index}][show_tag_selection]`, cr.show_tag_selection ? 1 : 0);
          form.append(`channel_content_creator_types[${index}][creator_view_type]`, cr.creator_view_type);
          form.append(`channel_content_creator_types[${index}][creator_view_type_mobile]`, cr.creator_view_type_mobile);
          form.append(`channel_content_creator_types[${index}][restricted_content_search]`, cr.restricted_content_search === "1" ? 1 : 0);
          form.append(`channel_content_creator_types[${index}][restricted_material_search]`, cr.restricted_material_search === "1" ? 1 : 0);
          form.append(`channel_content_creator_types[${index}][sidebar_view]`, cr.sidebar_view ? 1 : 0);
          form.append(`channel_content_creator_types[${index}][content_sidebar_view]`, cr.content_sidebar_view ? 1 : 0);
          form.append(`channel_content_creator_types[${index}][content_sort_order]`, cr.content_sort_order);
          
          for (const attr in cr.preferences) {
            if (!isNullOrUndefined(cr.preferences[attr])) {
              form.append(`channel_content_creator_types[${index}][preferences][${attr}]`, cr.preferences[attr]);
            }
          }          
          
          if (!isNullOrUndefined(cr.content_image_id)) form.append(`channel_content_creator_types[${index}][content_image_id]`, cr.content_image_id);
          if (!isNullOrUndefined(cr.event_image_id)) form.append(`channel_content_creator_types[${index}][event_image_id]`, cr.event_image_id);
          if (!isNullOrUndefined(cr.material_image_id)) form.append(`channel_content_creator_types[${index}][material_image_id]`, cr.material_image_id);
          if (cr.translations) {
            cr.translations.forEach(translation => {
              if (!isNullOrUndefined(translation.content_title)) form.append(`channel_content_creator_types[${index}][translations][${translation.locale}][content_title]`, translation.content_title);
              if (!isNullOrUndefined(translation.event_title)) form.append(`channel_content_creator_types[${index}][translations][${translation.locale}][event_title]`, translation.event_title);
              if (!isNullOrUndefined(translation.material_title)) form.append(`channel_content_creator_types[${index}][translations][${translation.locale}][material_title]`, translation.material_title)
            });
          }
        } else {
          form.append(`allow_music`, 1);
          form.append(`show_on_global_search`, cr.show_on_global_search ? 1 : 0);
          form.append(`show_on_smart_search`, cr.show_on_smart_search ? 1 : 0);
        }
      });
      !channelContentCreatorTypes.find(element => element.content_creator_type_id === "music") && form.append(`allow_music`, 0);
    }else {
      form.append(`channel_content_creator_types`, "empty");
    }
  }

  if (!isNullOrUndefined(channelRedirects)) {
    if (channelRedirects.length > 0) {
      channelRedirects.forEach((channelRedirect, index) => {
        form.append(`redirects[${index}][redirect_to]`, channelRedirect.redirectTo);
        form.append(`redirects[${index}][target_path]`, channelRedirect.targetPath || "");
        if (!channelRedirect.targetPath) form.append(`redirects[${index}][is_permanent]`, channelRedirect.type == "permanent" ? 1 : 0);
        if (channelRedirect.home_element_id) form.append(`redirects[${index}][home_element_id]`, channelRedirect.home_element_id);
      });
    }else {
      form.append('redirects', 'empty')
    }
  }

  if (!isNullOrUndefined(addresses)) {
    if (addresses.length > 0) {
      addresses.forEach((address, index) => {
        form.append(`addresses[${index}][id]`,address.id || null);
        form.append(`addresses[${index}][name]`, address.name);
        // form.append(`addresses[${index}][active]`, address.active ? 1 : 0);
        form.append(`addresses[${index}][address]`, address.address);
        form.append(`addresses[${index}][city]`, address.city);
        form.append(`addresses[${index}][zip_code]`, address.zip_code);
        form.append(`addresses[${index}][google_maps_link]`, address.google_maps_link || "");
        form.append(`addresses[${index}][phone]`, address.phone || "");
        form.append(`addresses[${index}][description]`, address.description || "");
      });
    }else {
      form.append('addresses', 'empty')
    }
  }

  if (!isNullOrUndefined(channelShipments)) {
    if (channelShipments.length > 0) {
      channelShipments.forEach((shipment, index) => {
        form.append(`shipments[${index}][id]`,shipment.id || null);
        form.append(`shipments[${index}][location]`, shipment.location);
        form.append(`shipments[${index}][price_ars]`, shipment.prices.find(prices => prices.currency === "ARS").price);
        form.append(`shipments[${index}][price_usd]`, shipment.prices.find(prices => prices.currency === "USD").price);
      });
    }else {
      form.append('shipments', 'empty')
    }
  }

  if (translations) {
    translations.forEach(translation => {
      if (!isNullOrUndefined(translation.about)) form.append(`translations[${translation.language}][about]`, translation.about);
      if (!isNullOrUndefined(translation.welcome)) form.append(`translations[${translation.language}][welcome]`, translation.welcome);
      if (!isNullOrUndefined(translation.register)) form.append(`translations[${translation.language}][register]`, translation.register)
      if (!isNullOrUndefined(translation.registerConfirmed)) form.append(`translations[${translation.language}][register_confirmed]`, translation.registerConfirmed)
      if (!isNullOrUndefined(translation.registerEvaluating)) form.append(`translations[${translation.language}][register_evaluating]`, translation.registerEvaluating)
      if (!isNullOrUndefined(translation.evaluateApproved)) form.append(`translations[${translation.language}][evaluate_approved]`, translation.evaluateApproved)
      if (!isNullOrUndefined(translation.globalSearchText)) form.append(`translations[${translation.language}][global_search_text]`, translation.globalSearchText)
      if (!isNullOrUndefined(translation.loginRedirectText)) form.append(`translations[${translation.language}][login_redirect_text]`, translation.loginRedirectText)
      if (!isNullOrUndefined(translation.login)) form.append(`translations[${translation.language}][login]`, translation.login)
      if (!isNullOrUndefined(translation.viewsLimit)) form.append(`translations[${translation.language}][views_limit]`, translation.viewsLimit)
      if (!isNullOrUndefined(translation.regionLimit)) form.append(`translations[${translation.language}][region_blocked_text]`, translation.regionLimit)
      if (!isNullOrUndefined(translation.restrictedContentText)) form.append(`translations[${translation.language}][restricted_content_text]`, translation.restrictedContentText)
      if (!isNullOrUndefined(translation.fpsCertificateLimit)) form.append(`translations[${translation.language}][fps_certificate_limit]`, translation.fpsCertificateLimit)
      if (!isNullOrUndefined(translation.requireDrmText)) form.append(`translations[${translation.language}][require_drm_text]`, translation.requireDrmText)
      if (!isNullOrUndefined(translation.supportText)) form.append(`translations[${translation.language}][support_text]`, translation.supportText)
      if (!isNullOrUndefined(translation.boughtCompleteText)) form.append(`translations[${translation.language}][bought_complete_text]`, translation.boughtCompleteText)
      if (!isNullOrUndefined(translation.keywordsSEO)) form.append(`translations[${translation.language}][seo_keywords]`, translation.keywordsSEO)
      if (!isNullOrUndefined(translation.descriptionSEO)) form.append(`translations[${translation.language}][seo_description]`, translation.descriptionSEO)
      if (!isNullOrUndefined(translation.suggestedText)) form.append(`translations[${translation.language}][suggested_text]`, translation.suggestedText)
      if (!isNullOrUndefined(translation.liveText)) form.append(`translations[${translation.language}][live_text]`, translation.liveText)
      if (!isNullOrUndefined(translation.radioText)) form.append(`translations[${translation.language}][radio_text]`, translation.radioText)
    });
  }

  if (socialLinks) {
    if (socialLinks.length > 0) {
      socialLinks.forEach(socialLink => {
        if (!isNullOrUndefined(socialLink.url)) form.append(`social_links[${socialLink.type}][url]`, socialLink.url);
      });
    }else{
      form.append('clear_social_links', 1);
    }
  }
  if (imageId) form.append("imageId", imageId);
  if (loginImageId) form.append("loginImageId", loginImageId);
  if (footerImageId) form.append("footerImageId", footerImageId);
  if (sidebarImageId) form.append("sidebarImageId", sidebarImageId);
  if (floatingButtonImageId) form.append("floatingButtonImageId", floatingButtonImageId);
  if (faviconId) form.append("faviconId", faviconId);
  if (binaryId) form.append("binaryId", binaryId);
  if (policyId) form.append("policyFileId", policyId);
  if (preRollVideoId == -1) {
    form.append("removePreRollVideo", 1);
  } else if (preRollVideoId) {
    form.append("preRollVideoId", preRollVideoId);
  }

  if(loginClientId) form.append('login_client_id', loginClientId)
  if(loginClientSecret) form.append('login_client_secret', loginClientSecret)
  if(dashboardLoginClientId) form.append('dashboard_login_client_id', dashboardLoginClientId)
  if(dashboardLoginClientSecret) form.append('dashboard_login_client_secret', dashboardLoginClientSecret)
  if(dashboardLoginType) form.append('dashboard_login_type', dashboardLoginType)
  if(mercadoPagoClientId) form.append('mercadopago_provider_key', mercadoPagoClientId)
  if(mercadoPagoClientSecret) form.append('mercadopago_provider_secret', mercadoPagoClientSecret)
  if(paypalClientId) form.append('paypal_provider_key', paypalClientId)
  if(paypalClientSecret) form.append('paypal_provider_secret', paypalClientSecret)
  if(!isNullOrUndefined(googleAdsensePublisherId)) form.append('google_publisher_id', googleAdsensePublisherId)
  if(hasEvents) form.append('has_events', hasEvents == 'active' ? 1: 0 )

  if (!isNullOrUndefined(radioEnabled)) form.append('radio_enabled', radioEnabled ? 1 : 0);
  if (radioLink) form.append('radio_link', radioLink);
  if (commercialMail) form.append('commercial_mail', commercialMail);
  if (!isNullOrUndefined(customScripts)) form.append('scripts', customScripts)

  form.append('channel_community', hasCommunity ? 1 : 0);
  form.append('community_requires_approval', communityRequiresApproval);
  form.append('skip_time_pre_roll', (!skipTimePreRoll || skipTimePreRoll < 1) ? 0 : skipTimePreRoll)

  if (hasCommunity) {
    form.append('community_catalog_id', communityCatalog ? communityCatalog.value : null);
    form.append('community_email', communityEmail);
    console.log(communityCanDeleteMaterials)
    if (!isNullOrUndefined(communityCanDeleteMaterials)) form.append('community_can_delete_materials', communityCanDeleteMaterials);

    if (!isNullOrUndefined(communityCCTypes)) {
      if (communityCCTypes.length > 0) {
        communityCCTypes.forEach((ccType, index) => {
          form.append(`community_content_creator_types_ids[${index}]`, ccType.value || null);
        });
      }else {
        form.append('community_content_creator_types_ids', 'empty')
      }
    }

    form.append('community_preferences[redirect_to]', communityPreferences.redirectTo);  
    communityValidationOptions.forEach(validation =>
      form.append(`community_preferences[validations][${validation}]`, communityPreferences.validations[validation]== "true")
    )
    
    if (communityTranslations) {
      communityTranslations.forEach(translation => {
        if (!isNullOrUndefined(translation.title)) form.append(`community_translations[${translation.language}][title]`, translation.title);
        if (!isNullOrUndefined(translation.description)) form.append(`community_translations[${translation.language}][description]`, translation.description);
        if (!isNullOrUndefined(translation.slogan)) form.append(`community_translations[${translation.language}][slogan]`, translation.slogan);
        if (!isNullOrUndefined(translation.successMessage)) form.append(`community_translations[${translation.language}][success_message]`, translation.successMessage);
        if (!isNullOrUndefined(translation.pendingMessage)) form.append(`community_translations[${translation.language}][pending_message]`, translation.pendingMessage);
        if (!isNullOrUndefined(translation.approvedMessage)) form.append(`community_translations[${translation.language}][approved_message]`, translation.approvedMessage);
        if (!isNullOrUndefined(translation.declinedMessage)) form.append(`community_translations[${translation.language}][declined_message]`, translation.declinedMessage);
        if (!isNullOrUndefined(translation.termsOfUse)) form.append(`community_translations[${translation.language}][terms_of_use]`, translation.termsOfUse);
      });
    }
  }

  if (!isNullOrUndefined(customShare)) form.append(`custom_share`, customShare ? 1 : 0);
  if (!isNullOrUndefined(useTools)) form.append(`use_tools`, useTools ? 1 : 0);
  if (!isNullOrUndefined(useTools)) form.append(`use_tools`, useTools ? 1 : 0);
  if (!isNullOrUndefined(useLicenses)) form.append(`use_licenses`, useLicenses ? 1 : 0);
  if (!isNullOrUndefined(usePlaylists)) form.append(`use_playlists`, usePlaylists ? 1 : 0);
  if (!isNullOrUndefined(showDate)) form.append(`show_date`, showDate ? 1 : 0);
  if (!isNullOrUndefined(noIndex)) form.append('no_index', (noIndex || isDemo === "1") ? 1: 0)

  let adUnits = [];
  adUnitHomeDesktop && adUnits.push({ad_unit: adUnitHomeDesktop, purpose: 'home'});
  adUnitHomeMobile && adUnits.push({ad_unit: adUnitHomeMobile, purpose: 'home-mobile'});
  adUnitContentDesktop && adUnits.push({ad_unit: adUnitContentDesktop, purpose: 'content'});
  adUnitContentMobile && adUnits.push({ad_unit: adUnitContentMobile, purpose: 'content-mobile'});


  if (adUnits.length > 0) {
    adUnits.forEach((adUnit, index) => {
      form.append(`ad_units[${index}][ad_unit]`, adUnit.ad_unit);
      form.append(`ad_units[${index}][purpose]`, adUnit.purpose);
    })
  } else {
    form.append('ad_units', "empty");
  }

  if (isDemo) form.append("demo", isDemo === "1" ? 1: 0)

  form.append(`footer_type`, footerType);
  if (footerType == "free" && footerTranslations) {
    footerTranslations.forEach(translation => {
      if (!isNullOrUndefined(translation.title)) form.append(`footer_translations[${translation.language}][title]`, translation.title);
    });
  }

  if (footerType == "advanced") {
    form.append(`footer_logo_side`, footerLogoSide);
    form.append(`footer_social_position`, footerSocialPosition);
    form.append(`footer_margin`, footerMargin);
    form.append(`footer_social_with_logo`, footerSocialWithLogo);
  }

  if (!isNullOrUndefined(hasExtendRegistration)) {
    if(hasExtendRegistration) {
      form.append(`has_extend_registration`, 1);
      form.append(`registration_identity_number`, extendRegistration.identityNumber);
      form.append(`registration_address`, extendRegistration.address);
      form.append(`registration_birthdate`, extendRegistration.birthdate); 
    }else {
      form.append(`has_extend_registration`, 0);
    }
  }

  form.append('use_brand_button', !!useBrandButton? 1 : 0)
  form.append('editable_for', editableFor)
  if (purpose && purpose.value) form.append('purpose', purpose.value)

  form.append('navbar_content_creator_type_id', navbarContentCreatorTypeId)
  form.append('navbar_search_type', navbarSearchType)
  form.append('navbar_tabs', mobileNavbarType ? 1 : 0)
  form.append('use_sidebar_desktop', useSidebarDesktop ? 1 : 0)
  form.append('use_floating_button', useFloatingContactButton ? 1 : 0)
  
  if (ads) {
    ads.forEach((ad, index) => {
      form.append(`ads[${index}][id]`, ad.id);
      form.append(`ads[${index}][tag_ids]`, ad.tagIds.join(','));
      form.append(`ads[${index}][weight]`, ad.weight);
      form.append(`ads[${index}][section_ids]`, ad.sectionIds.join(','));
    });
  }
  if (clearAds) form.append('clear_ads', 1)
  if (!isNullOrUndefined(disableShare)) form.append('disable_share', disableShare ? 1 : 0)

  return form;
}

export const includeQuery = "include=ads;sections.image;image;preRollVideo;client.permissions;client.licensePlan;channelContentCreatorTypes.contentCreatorType.image;channelContentCreatorTypes.contentCreatorType.translations;catalogs;translations;socialLinks;loginImage;footerImage;faviconImage;tags;binary;policy;domain;tagGroups.types.contentCreatorType;trackingGroup;paymentGateways;community;community.catalog;community.contentCreatorTypes;adUnits;languages;navbarContentCreatorType;floatingButtonImage";

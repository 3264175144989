import React from "react";
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types'
import localization from "../../../config/localization";
import TextField from "@material-ui/core/TextField/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {Select} from "@material-ui/core";
import styled from "styled-components";

class AudiovisualTagSelector extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tags: this.props.tags,
      outlined: this.props.outlined || false,
      searchInput: "",
      selectedTags: [],
      people: this.props.people || [],
      selectedPeople: null
    }
  }

  componentDidMount = () => {
    if (!this.props.isClickable) {
      this.state.tags.forEach((tag) => {
        tag.state = 'disable'
      });
      this.setState(this.state)
    }
  }

  componentWillReceiveProps = (nextProps)  =>{
    if (this.state.tags !== nextProps.tags) {
      this.setState({tags: nextProps.tags})
    }
  };


  handleClickTag = (e, tagId) => {
    let tag = this.state.tags.find((tag) => tag.id === tagId);
    if (tag.state === 'available' && this.props.singleTagSelection) {
      this.state.tags.forEach(tag => {
        tag.state = 'available'
      });
      tag.state = 'selected'
      this.setState(this.state)
    } else if (tag.state === 'available' || !tag.state) {
      tag.state = 'selected'
      this.setState(this.state)
    } else if (tag.state === 'selected') {
      tag.state = 'available'
      this.setState(this.state)
    }
    if (this.props.onSelectedTagChanged) {
      this.props.onSelectedTagChanged(tagId);
    }
  }

  handleChange = () => {
    let selected = []
    this.state.tags.forEach(tag=> {
      if (tag.state === "selected") {
        selected.push(tag.id)
      }
    });
    if (this.props.onSelectedTagsChanged) {
      this.props.onSelectedTagsChanged(selected);
    }
  }

  makeChipStyle = (tag) =>  this.props.outlined ?
    {
      margin: "0 4px",
      border: "1px solid #0000003d",
      backgroundColor: tag.state === "selected" ? 'darkgray' : 'white',
      color: tag.state === "selected" ? 'white' : tag.state === "disable" ? 'grey' : 'black',
      cursor: tag.state === "disable" ? 'auto' : 'pointer',
    } :
    {
      width: "auto",
      maxWidth: "200px", fontWeight: "400",
      backgroundColor: tag.state === "selected" ? 'darkgray' : 'transparent',
      color: tag.state === "selected" ? 'white' : tag.state === "disable" ? 'grey' : 'darkgray',
      cursor: tag.state === "disable" ? 'auto' : 'pointer',
      height: "20px",
      fontSize: "12px",
      margin: "3px 4px 3px 4px",

    }

  handleAction = tag => this.props.handleAction ? this.props.handleAction(tag) : false

  actionIcon = () => this.props.actionIcon ? this.props.actionIcon : false

  render() {
    const tagGrouped = group(this.state.tags);
    const showPills = (countGroup(this.state.tags).length <= 1 || this.state.tags.length < 10);

    return (
      <div style={{height: this.props.outlined ? 'auto' : 55, display: "flex", flexDirection:"column", width: "100%"}}>
        {this.props.showSearchInput &&
          <div style={{display: "flex", flexDirection:"column", maxWidth: "100%", marginBottom: 30, width: "100%" }}>
            <TextField
              inputProps={{
                ref: input => {
                  this.searchInput = input;
                },
                className: "audiovisual-tag-selector-search",
                spellCheck: false,
                style:{
                  color: "var(--secondary-font-color, #2d2d2d)",
                  borderBottom: "2px solid var(--secondary-font-color, #2d2d2d)",
                  width: 250,
                  maxWidth: 250,
                  position: "relative",
                  float: "left",
                }
              }}
              InputProps={{disableUnderline: true}}
              autoComplete={false}
              placeholder={localization.get("searchbar.contents")}
              value={this.state.searchInput}
              onChange={e => this.setState({searchInput: e.target.value},() => this.props.handleFilters(this.state.selectedTags.map(selected => selected.selectedTag), this.state.searchInput))}
              style={{color: "var(--secondary-font-color, black)"}}
            />
          </div>
        }
        {this.props.people.length > 0 &&
          <div>
            <Select
              style={{width: "20%"}}
              value={this.state.selectedPeople}
              onChange={(e) => {
                this.setState({selectedPeople: e.target.value == "0" ? null : e.target.value})
                if (this.props.handleFilters) {
                  this.props.handleFilters(this.state.selectedTags.map(selected => selected.selectedTag), this.state.searchInput, e.target.value == "0" ? null : e.target.value);
                }
              }}
              >
                <MenuItem value="0"><em style={{opacity: 0.6}}>{localization.get("people.list")}</em></MenuItem>
              {this.props.people.map(person => <MenuItem value={person.id}>{person.name}</MenuItem>)}
            </Select>
          </div>
        }
        {!showPills && tagGrouped.length > 2 ?
          <FilterContainer>
          {tagGrouped.map((tag, key) =>
            <>
                <Select
                  disabled={tag.every(t => t._noContent && !t._selected)}
                  style={{width: "20%", margin: "0.5rem 0", marginRight: (key%4 == 3) ? 0 : (key%4 == 0) ? '5%' : 'calc(5% - 10px)', marginLeft: (key%4 == 0) ? 0 : '10px'}}
                  value={this.state.selectedTags.find(selectedTag => selectedTag.tagGroupId === key) ? this.state.selectedTags.find(selectedTag => selectedTag.tagGroupId === key).selectedTag : "0"}
                  onChange={(e) => {
                    let newSelectedTags = this.state.selectedTags.filter(selectedTag => selectedTag.tagGroupId !== key)
                    newSelectedTags.push({tagGroupId: key, selectedTag: e.target.value})
                    this.setState({selectedTags: newSelectedTags}, () => {
                      if (this.props.handleFilters) {
                        this.props.handleFilters(this.state.selectedTags.map(selected => selected.selectedTag), this.state.searchInput);
                      }
                    })
                  }}
                >
                  <MenuItem value="0"><em style={{opacity: 0.6}}>{tag[0].group.name}</em></MenuItem>
                  {tag.map(t => <MenuItem disabled={t._noContent && !tag.some(t => t._selected)} value={t.id}>{t.name}</MenuItem>)}
                </Select>
            </>
          )}

          </FilterContainer>
          :
          <div style={{position: "relative", float: "left", width: "100%"}}>
            {this.state.tags.map((tag, j) => {
              return (
                <div className={!this.props.displayOnMobile && "home-item-description-tag-container"}
                     style={{position: "relative", float: "left", marginTop: this.props.outlined ? 5 : 0}}>
                    <Chip
                      variant="outlined"
                      key={j}
                      style={this.makeChipStyle(tag)}
                      label={tag.name}
                      onClick={(e) => {
                        this.handleClickTag(e, tag.id);
                        this.handleChange()
                      }}
                    />
                </div>
              )
            })}
          </div>
        }
      </div>
    )
  }

}

AudiovisualTagSelector.propTypes = {
  tags: PropTypes.array.isRequired,
  onSelectedTagsChanged: PropTypes.func,
  onSelectedTagChanged: PropTypes.func
}

export default AudiovisualTagSelector;

const FilterContainer = styled.div`
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
  // margin-left: -0.5rem;
  // margin-right: -0.5rem;
  margin-bottom: 30px;
`


const group = (list) => {
  return list.reduce((acc, obj) => {
    if (!obj.group) return acc;
    let key = obj.group.id;
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(obj)
    return acc
  }, [])
    .filter(item => item.length > 1)
}

const countGroup = (list) => {
  return list.reduce((acc, obj) => {
    if (!obj.group) return acc;
    let key = obj.group.id;
    if (!acc.includes(key)) {
      acc.push(key);
    }

    return acc
  }, [])
}
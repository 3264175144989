import "../Home.css";
import "./index.css"
import React, {Component} from "react";
import Button from "../../../components/CustomButtons/Button.jsx";
import TagSelection from "../../../components/Letflow/TagSelection/TagSelectionSmartSearch.jsx"
import _ from 'lodash'
import {request} from "../../../api-client";
import localization from "../../../config/localization";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import { DateRangePicker } from "materialui-daterange-picker";
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
  ClickAwayListener,
  Paper,
  Grow,
  Popper,
  MenuList,
} from "@material-ui/core";
import 'react-input-range/lib/css/index.css'
import HomeElementGridContainer from "../HomeElements/HomeElementGridContainer";
import GridItem from "../HomeElements/GridItem";
import HomeElementFeaturedItem from "../HomeElements/HomeElementFeaturedItem";
import DeleteOutline from "../../../assets/icons/outline-delete.svg";
import qs from "qs";
import browserHistory from "../../../utils/browserHistory";
import AgeInputRange from "./AgeInputRange";
import {
  channelPathGenerator, getActiveChannel,
  getActiveClient,
  isPublicClient,
  setPageTitle,
  slugify,
  getStoredChannel, getStoredUser, getMainTranslation, getContentCreatorFavorites,
  getUseTools
} from "../../../api-client/core/authentication/utils";
import LoadingSpinner from "../../../assets/img/loading_spinner.gif";
import EventManager, {events} from "../../../utils/EventManager";
import GlobalRedirectLoginConfirmationDialog
  from "../../../components/Letflow/Dialog/GlobalRedirectLoginConfirmationDialog";
import TextField from "@material-ui/core/TextField/TextField";
import moment from "moment";
import font from "../HomeElements/Styles/Fonts/fonts.module.css"
import analyticsInstance from "../../../analytics/analyticsInstance";
import FilterContainer from "./FilterContainer";
import {get} from "lodash"
import TableButton from "../../../components/Letflow/Table/Buttons/TableButton";
import HomeElementCarouselItem from "../HomeElements/HomeElementCarouselItem";
import InfiniteScroll from "react-infinite-scroll-component";
import {Close, Search,GridOn, List, FilterList, SortByAlpha, StarBorder, Star, PlaylistAdd} from "@material-ui/icons";
import {makeImageUrlWithSize,  onImageError} from "../HomeElements/utils";
import {ClientSidePaginationTable, TableButtonsBuilder} from "../../../components/Letflow/Table";
import AddToPlaylist from "../../../components/Letflow/AddToPlaylist";

const makeSearchButton = (onClick) =>
  <Button className="smart-search-search-button" onClick={onClick}>
    {localization.get('home_editor.tag_selection.filter')}
  </Button>

const hasAnySelectedTag = tagGroups =>
  _.some(_.flatMap(tagGroups, tagGroup => tagGroup.tags), tag => (tag.state === 'selected' || tag.state === 'unselected'));

const makeCleanTagsButton = (onClick) =>
  <Tooltip title={localization.get('smart_search.clean_search')} placement='top'>
    <IconButton onClick={onClick}>
      <img alt="delete-icon" src={DeleteOutline}/>
    </IconButton>
  </Tooltip>

const makeAddFiltersButton = (onClick, searchType='content') =>{
  let filterLabel = searchType == 'event' ? localization.get('searchbar.content_creators_for_events') : localization.get('searchbar.content_creators_for_tags');
  return (
    <Tooltip title={filterLabel} placement='top'>
      <div onClick={onClick} className={"smart-search-filter-label"} >
        <span className="material-icons" style={{color: "var(--main-font-color, black)"}}>filter_alt</span>
        {getActiveChannel && <span style={{top: 7, color: "var(--main-font-color, black)"}} >{filterLabel}</span>}
      </div>
    </Tooltip>
  )
}

const sortTypes = [
  {value: "default", label: localization.get('sort.default'), column: "order", direction: "desc"},
  {value: "nameA", label: localization.get('sort.title.asc'), column: "name", direction: "asc"},
  {value: "nameD", label: localization.get('sort.title.desc'), column: "name", direction: "desc"},
  // {value: "contentA", label: localization.get('sort.content_creator.asc'), column: "content_creators.name", direction: "asc"},
  // {value: "contentD", label: localization.get('sort.content_creator.desc'), column: "content_creators.name", direction: "desc"},
]


const removeQueryParam = (url, paramToRemove) => {
  const [baseUrl, queryString] = url.split('?');

  if (!queryString) return "";

  const parsedQuery = qs.parse(queryString);
  delete parsedQuery[paramToRemove];
  const newQueryString = qs.stringify(parsedQuery);

  return newQueryString;
};
    

class SmartSearchContentCreator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tagGroups: [],
      searchResultsTags: [],
      searchResultsTitle: [],
      showTagSelection: false,
      showTagDialog: false,
      searchGroup: "contentCreator",
      type: '',
      age: null,
      selectedCountry: '',
      availableCountries: [],
      showTags: false,
      pageReady: false,
      favorites: getContentCreatorFavorites(),
      channel: props.channel || null,
      firstRequest: true,
      isTalent: false,
      currentPage: 0,
      hasQueryParams: false,
      searchInput: "",
      eventRangeOpen: false,
      selectedDateRange: props.searchType == 'event' ? "next30days" : "",
      selectedTimeRange: "all",
      selectedEventRange: {
        startDate: props.searchType == 'event' ? moment().format('YYYY-MM-DD') : null,
        endDate: props.searchType == 'event' ? moment().add(30, 'day').format('YYYY-MM-DD') : null,
      },
      from: props.searchType == 'event' ? moment().format('DD/MM/YYYY') : '',
      to: props.searchType == 'event' ? moment().add(30, 'day').format('DD/MM/YYYY') : '',
      searchType: props.searchType || 'content',
      contentCreatorType: null,
      filterDate: null,
      selectedPublishDateRange: 'last30days',
      publishRangeOpen: false,
      selectedPublishRange: {
        startDate: moment().subtract(30, 'day').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      },
      publishFrom: moment().subtract(30, 'day').format('YYYY-MM-DD'),
      publishTo: moment().format('YYYY-MM-DD'),
      total: 0,
      openSidebar: window.innerWidth > 600,
      loading: true,
      sortType: "default",
      viewFormat: "grid",
      customFrom: '',
      customTo: '',
    }
  }

  componentDidMount = () => {
    document.getElementsByTagName('html')[0].scrollTop = 0

    this.requestHasQueryParams()
      .then(() => {
        let eventSearchEnabled = this.state.searchType == 'event' && getStoredChannel() && this.state.contentCreatorType && !!this.state.contentCreatorType.allow_event_search;

        Promise.all([this.requestTagGroups(), this.requestOnlyAvailableTags()])
          .then((values) => {
            const tags = _.flatMap(this.state.tagGroups, x => x.tags)
            tags.forEach(x => x.state = x.state === 'selected' ? 'selected' : x.state === 'unselected' ? 'unselected' : values[1].map(x => x.id).includes(x.id) ? 'available' : 'disable')

            this.setState({ tagGroups: this.state.tagGroups})
            return values[1]
          })
          .then(() => this.clearUnusedTags())
          .then(() => { 
              const pref = (getStoredChannel() && this.state.type) ? getStoredChannel().channelContentCreatorTypes.find(ct => ct.contentCreatorType.slug === this.state.type) : {show_search_input_on_smart_search: 1, show_filter_on_smart_search: 1};
              if (pref) this.setState({
                viewType: pref.creation_view_type,
                sidebarView: pref.content_sidebar_view,
              })
            
            this.setState({showTagSelection: (eventSearchEnabled || (this.state.tagGroups && this.state.tagGroups.length > 0)) ? this.state.showTagSelection : false })
          })
          .then(() => !(this.state.hasQueryParams || (this.state.showTagSelection)) && this.requestTracks())
          .then(() => this.setState({showTags: true}))
          .then(() => (this.state.hasQueryParams || this.state.showTagSelection) && this.readQueryStringParams())
      })

    setPageTitle(localization.get('title.smart_search'))

    EventManager.getInstance().subscribe(events.ADD_TO_FAVORITE_BUTTON_PRESS, this.addToFavorites)
    EventManager.getInstance().subscribe(events.EXPLORE_NAVBAR_SEARCH, this.exploreGlobalSearch)
    EventManager.getInstance().subscribe(events.REMOVE_FROM_FAVORITE_BUTTON_PRESS, this.removeFromFavorites)

    let queryString = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
      depth: 2,
      plainObjects: true})

     if(queryString && queryString.contenido) {
       EventManager.getInstance().dispatch(events.OPEN_CONTENT_MODAL, {
         id: queryString.contenido.split("-")[0],
         dataType: "contentCreator",
         dataName: queryString.contenido.split("-")[1] || "contenido",
         onTagChange: this.handleNewSearch,
         origin: "Search"
       });
     }
  }

  componentWillUnmount = () => {
    EventManager.getInstance().unsubscribe(events.ADD_TO_FAVORITE_BUTTON_PRESS, this.addToFavorites)
    EventManager.getInstance().unsubscribe(events.REMOVE_FROM_FAVORITE_BUTTON_PRESS, this.removeFromFavorites)
    EventManager.getInstance().unsubscribe(events.EXPLORE_NAVBAR_SEARCH, this.exploreGlobalSearch)
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.location.search !== this.props.location.search && typeof nextProps.location.search === 'string') {
      this.readQueryStringParams(nextProps.location.search, this.props.location.search )
    }
  }

  addToFavorites = ({id}) => {
    if (getActiveChannel()) {
      if(!getStoredUser()) {
        GlobalRedirectLoginConfirmationDialog.show({
          title: localization.get("login_redirect"),
          content: getStoredChannel().login_redirect_text || localization.get("login_redirect_text"),
          request: () => new Promise(resolve => {
            browserHistory.push(channelPathGenerator(`login?redirect_to=${window.location.pathname+window.location.search}`))
            resolve()
          }),
          confirmationLabel: localization.get("login_continue")
        });
      }else {
        request.contentCreatorChannelFavorite.add(id)
          .then(() => this.state.favorites.push(id))
      }
    } else {
      request.contentCreatorFavorite.add(id)
        .then(() => this.state.favorites.push(id))
    }
  }

  removeFromFavorites = ({id}) => {
    if (getActiveChannel()) {
      request.contentCreatorChannelFavorite.remove(id)
        .then(() => {
          if (this.state.favorites.indexOf(id) > -1) {
            this.state.favorites.splice(this.state.favorites.indexOf(id), 1)
          }
        })
    }else {
      request.contentCreatorFavorite.remove(id)
        .then(() => {
          if (this.state.favorites.indexOf(id) > -1) {
            this.state.favorites.splice(this.state.favorites.indexOf(id), 1)
          }
        })
    }
  }

  exploreGlobalSearch = () => this.setState(state => ({
    ...state,
    tagGroups: state.tagGroups.map(tgs => ({
        ...tgs,
        tags: tgs.tags.map(tag => ({
            ...tag,
            state: 'available'
          })
        )}
    ))
  }), this.requestTracks)

  requestHasQueryParams = () =>
    new Promise(resolve => {
      let search = qs.parse(this.props.location.search.slice(1))
      let {tags_ids, not_tags_ids, age, type, name, schedule, date_start, date_end, date_range, publish_range, publish_date_start, publish_date_end,fromEditorial} = search
      let creatorTypes = getActiveChannel() && getStoredChannel().channelContentCreatorTypes;
      let contentCreatorType = creatorTypes && creatorTypes.find(ct => ct.contentCreatorType.slug == type);
      this.setState({type, contentCreatorType});
      if (tags_ids || not_tags_ids || age || name || (schedule && schedule !== "all") ||date_start || date_end || date_range || publish_range || publish_date_start || publish_date_end ||fromEditorial) {
        this.setState({showTagSelection: false, hasQueryParams: true})
      } else {
        this.setState({showTagSelection: (contentCreatorType && contentCreatorType.show_tag_selection) || !getActiveChannel()})        
      }
      resolve()
    })

  readQueryStringParams = (queryString = this.props.location.search, prevQueryString = null) => {
    let search = qs.parse(queryString.toString().slice(1))
    let {tags_ids, not_tags_ids, age, countryId, name, date_start, date_end, schedule, type, date_range, publish_date_end, publish_date_start, publish_range, disablePublishDate, clean_search} = search

    if (type && type !== this.state.type) {
      let creatorTypes = getActiveChannel() && getStoredChannel().channelContentCreatorTypes;
      let contentCreatorType = creatorTypes && creatorTypes.find(ct => ct.contentCreatorType.slug == type);
      this.setState({type, contentCreatorType});
    }
    
    const currentUrl = window.location.href
    if (tags_ids || not_tags_ids || date_start || date_end || date_range || publish_date_end || publish_date_start || publish_range || (schedule && schedule !== 'all') || name || clean_search) {
      let shouldUpdate = false;
      
      if(prevQueryString) {
        let prevSearch = qs.parse(prevQueryString.toString().slice(1))
        let prev_tags_ids = prevSearch.tags_ids
        let prev_not_tags_ids = prevSearch.not_tags_ids
        let prev_name = prevSearch.name
        let prev_date_start = prevSearch.date_start
        let prev_date_end = prevSearch.date_end
        let prev_schedule = prevSearch.schedule
        let prev_date_range = prevSearch.date_range
        let prev_publish_date_end = prevSearch.publish_date_end
        let prev_publish_date_start = prevSearch.publish_date_start
        let prev_publish_range = prevSearch.publish_range
        
        if(!tags_ids || !Array.isArray(tags_ids)) tags_ids = []
        if(!prev_tags_ids || !Array.isArray(prev_tags_ids)) prev_tags_ids = []
        if(!not_tags_ids || !Array.isArray(not_tags_ids)) not_tags_ids = []
        if(!prev_not_tags_ids || !Array.isArray(prev_not_tags_ids)) prev_not_tags_ids = []
        
        if(
          (JSON.stringify(prev_tags_ids)!==JSON.stringify(tags_ids)) ||
          (JSON.stringify(prev_not_tags_ids)!==JSON.stringify(not_tags_ids)) ||
          (prev_name !== name) ||
          (prev_date_start !==date_start)||
          (prev_date_end !==date_end)||
          (prev_date_range !==date_range)||
          (prev_publish_date_end !==publish_date_end)||
          (prev_publish_date_start !==publish_date_start)||
          (prev_publish_range !==publish_range)||
          (prev_schedule !==schedule)
          )
          {
            shouldUpdate =true;
          }
        }else {
          shouldUpdate =true;
        }
        
        if (clean_search) {
          this.cleanSearch();
        }
        
        if (tags_ids) {
          if (Array.isArray(tags_ids)) {
            tags_ids.forEach(tag => this.markTagAsSelected(tag))
          } else {
            this.markTagAsSelected(tags_ids)
          }
          
        }
        if (not_tags_ids) {
          if (Array.isArray(not_tags_ids)) {
            not_tags_ids.forEach(tag => this.markTagAsUnselected(tag))
          } else {
            this.markTagAsUnselected(not_tags_ids)
          }
        }
        if (age) {
          this.setState({age: JSON.parse(age)})
        }
        
        if (name) {
          this.setState({searchInput: name})
        }
        if (countryId) {
          this.setState({selectedCountry: parseInt(countryId)})
        }
        
        if (schedule) {
          this.setState({selectedTimeRange: schedule})
        }
        
        this.onSelectedDateRangeChange(date_range, false, {startDate: date_start, endDate: date_end});

        if (!! publish_range) this.onSelectedPublishDateRangeChange(publish_range);
        
        if ((!date_range) && (date_start && date_end )) {
          
          let range =  {
            startDate: moment(date_start).format('YYYY-MM-DD'),
            endDate: moment(date_end).format('YYYY-MM-DD')
          }

          this.setState({selectedEventRange: range, searchType: 'event', from: moment(date_start).format('DD/MM/YYYY'), to: moment(date_end).format('DD/MM/YYYY')})
      }

      if (publish_date_start && publish_date_end) {
        let range =  {
          startDate: moment(publish_date_start).format('YYYY-MM-DD'),
          endDate: moment(publish_date_end).format('YYYY-MM-DD')
        }
        this.setState({selectedPublishRange: range, publishFrom: moment(publish_date_start).format('DD/MM/YYYY'), publishTo: moment(publish_date_end).format('DD/MM/YYYY')})
      }

      !getActiveChannel() && this.requestAvailableCountries()
      if (this.getSelectedTags().concat(this.getUnselectedTags()).length > 0) {
        if (!isPublicClient() && this.state.firstRequest) {
          this.setState({firstRequest: false})
        }
      }

      if (disablePublishDate) this.cleanSelectedPublishDate()

      if (clean_search) {
        const newPath = removeQueryParam(currentUrl, 'clean_search')

        browserHistory.push({
          pathname: window.location.pathname,
          search: newPath,
        })
      }

      shouldUpdate && this.requestTracks()
    }
  }

  writeQueryStringParams = () => {
    let { type, selectedPublishRange, searchInput, selectedEventRange, selectedTimeRange, selectedDateRange, selectedPublishDateRange} = this.state
    const hasPublishSearch = this.hasPublishFilter();

    browserHistory.push({
      pathname: window.location.pathname,
      search: qs.stringify({
        type,
        tags_ids: this.getSelectedTags().map(tag => tag.id),
        not_tags_ids: this.getUnselectedTags().map(tag => tag.id),
        name: searchInput,
        date_start: (selectedEventRange && selectedEventRange.startDate) ? moment(selectedEventRange.startDate).format('YYYY-MM-DD') : '',
        date_end: (selectedEventRange && selectedEventRange.endDate) ? moment(selectedEventRange.endDate).format('YYYY-MM-DD') : '',
        schedule: selectedTimeRange,
        date_range: selectedDateRange,
        publish_range:  hasPublishSearch ? selectedPublishDateRange: "",
        publish_date_start: hasPublishSearch && (selectedPublishRange && selectedPublishRange.startDate) ? moment(selectedPublishRange.startDate).format('YYYY-MM-DD') : '',
        publish_date_end: hasPublishSearch && (selectedPublishRange && selectedPublishRange.endDate) ? moment(selectedPublishRange.endDate).format('YYYY-MM-DD') : '',
      })
    })
  }

  markTagAsSelected = tagId => new Promise(resolve => {
    const tags = _.flatMap(this.state.tagGroups, tagGroup => tagGroup.tags)
    const tag = _.find(tags, tag => tag.id.toString() === tagId.toString())
    if (tag) {
      tag.state = 'selected'
    }
    this.forceUpdate(() => resolve({}))
  })

  markTagAsUnselected = tagId => new Promise(resolve => {
    const tags = _.flatMap(this.state.tagGroups, tagGroup => tagGroup.tags)
    const tag = _.find(tags, tag => tag.id.toString() === tagId.toString())
    if (tag) {
      tag.state = 'unselected'
    }
    this.forceUpdate(() => resolve({}))
  })

  requestTagGroups = () =>  new Promise(resolve => {
    const filters = [
      { column: 'type', filter: `${this.state.type}`},
      { column: 'creation_group', filter: '0'},
      { column: 'show_on_smart_search', filter: '1'},
    ]

    getActiveChannel() && filters.push({column: 'channel_id', filter: `${getActiveChannel()}`})

    request.tagGroup.getAll({filterBy: filters}, 'content')
      .then(tagGroups => {
        let availableTagGroups = tagGroups.filter(tagGroup => tagGroup.tags.length)
        if(getActiveChannel() && getStoredChannel().tagGroups.length) {
          getStoredChannel().tagGroups
            .filter(tg => tg.target === 'content')
            .forEach(channelTg => {
              availableTagGroups.forEach(tg => {
                if (tg.id === channelTg.id) {
                  tg.order = channelTg.order
                  tg.view_type = channelTg.view_type
                }
              })
            })
        }
        
        this.setState({ tagGroups: availableTagGroups })
      })
      .then(() => resolve())
  })

  requestAvailableCountries = () =>
    request.country.getForEntityAndClient(
    'content_creators',
    this.state.type,
    this.getSearchTags(),
    this.getUnselectedSearchTags())
  .then(availableCountries => this.setState({availableCountries}))



  makeSmartSearchTagSelector = () => {
    let showEventSearch = getStoredChannel() && this.state.contentCreatorType && !!this.state.contentCreatorType.allow_event_search;
    return (
      <div>
        <div style={{ backgroundColor: "var(--main-color, rgb(255,255,255))", display: this.state.showTagSelection ? 'block' : 'none'}} className="category">
          <div style={{ float: "left", position: "relative", width: "100%", textAlign: "center", padding: window.innerWidth > 750 ? "10px 50px" : "10px 30px", height: "auto" , minHeight: "520px"}}>
            {!this.state.showTags ?
              <div style={{height: "100%", display: "flex", justifyContent: "center", alignContent: "center"}}>
                <img style={{width: 150, height: 150, marginTop: "calc(43vh - 70px)"}} src={LoadingSpinner} alt="Loading logo"/>
              </div>
            :
              <>
                {(getActiveChannel() && this.state.contentCreatorType) &&  this.props.searchType !== "event" && !!this.state.contentCreatorType.support_publish_search && this.state.contentCreatorType.contentCreatorType.type === "editorial" && this.renderPublishSearch()}
                {showEventSearch && this.renderEventSearch()}
                <TagSelection
                  horizontal={true}
                  singleTagSelection={(getActiveChannel() && this.state.contentCreatorType) ? !this.state.contentCreatorType.has_multiple_tags_allowed : true}
                  notWriteQueryString={false}
                  onSelectedTagsChanged={this.handleSmartSearchSelectedTagsChanged}
                  data={this.state.tagGroups.sort((a,b) => a.order - b.order)}
                  allowUnselected={(getActiveChannel() && this.state.contentCreatorType) ? this.state.contentCreatorType.support_negative_search : true}
                  checkAvailableTags={(getActiveChannel() && this.state.contentCreatorType) ? !(this.state.contentCreatorType.has_multiple_tags_allowed && !this.state.contentCreatorType.restricted_content_search) : true}
                />
              </>
            }
          </div>
        </div>
      </div>
    )
  }

  getSelectedTags = () =>  this.state.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'selected')
  getUnselectedTags = () =>  this.state.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'unselected')

  handleSmartSearchSelectedTagsChanged = (selectedTags, unselected, lastTagId, checkAvailableTags = true, writeQueryParams = true) => {
    this.setState(state => ({
      ...state,
      tagGroups: state.tagGroups.map(tgs => ({
        ...tgs,
        tags: tgs.tags.map(tag => ({
          ...tag,
          state: selectedTags.includes(tag.id) ? 'selected' : unselected.includes(tag.id) ? 'unselected' : "available"
        }))
      }))
    }), () => {
      checkAvailableTags && this.requestAvailableTags({lastTagId})
      writeQueryParams && this.writeQueryStringParams()
      writeQueryParams && selectedTags.length === 0 && this.requestTracks(); 
    })
  }

  clearUnusedTags = () => {
    this.setState(state => ({
      ...state,
      tagGroups: state.tagGroups.map(tgs => {
        return {
          ...tgs,
          tags: tgs.tags.filter(tag => tag.state !== 'disable' || tgs.parent)
        }
      }).filter(tg => tg.tags.length > 0 || tg.parent)
    }))
  }

  requestAvailableTags = ({
      clientId = this.state.channel ? this.state.channel.client.id : getActiveClient(),
      searchGroup = this.state.searchGroup,
      type = this.state.type,
      unselectedSearchTags = this.getUnselectedSearchTags(),
      searchTags = this.getSearchTags(),
      lastTagId = null,
      channelId = this.state.channel ? this.state.channel.id : null,
      startDate =  (this.state.selectedEventRange && this.state.selectedEventRange.startDate) ? this.state.selectedEventRange.startDate : null,
      endDate =  (this.state.selectedEventRange && this.state.selectedEventRange.endDate) ? this.state.selectedEventRange.endDate : null,
      publishStartDate =  (this.state.hasPublishSearch && this.state.selectedPublishRange && this.state.selectedPublishRange.startDate) ? this.state.selectedPublishRange.startDate : null,
      publishEndDate =  (this.state.hasPublishSearch && this.state.selectedPublishRange && this.state.selectedPublishRange.endDate) ? this.state.selectedPublishRange.endDate : null,
    } = {}) => {
      
    return request.tag.getAvailableTagsForClient({
      clientId,
      tagGroupType: searchGroup,
      contentCreatorType: type,
      tags: searchTags,
      notTags: unselectedSearchTags,
      lastTagId,
      channelId,
      startDate,
      endDate,
      publishStartDate,
      publishEndDate,
    }).then(availableTags => {
      const mappedAvailableTags = availableTags.map(x => x.id)

      console.log(this.state.tagGroups, availableTags)
      return this.setState({
         tagGroups: this.state.tagGroups.map(tgs => ({
          ...tgs,
          tags: tgs.tags.map(tag => ({
            ...tag,
            state: ['selected', 'unselected'].includes(tag.state) ? tag.state : (mappedAvailableTags.includes(tag.id) ? 'available' : 'disable')
          }))
        })) 
      })
    })
  }

  requestOnlyAvailableTags = ({
    clientId = this.state.channel ? this.state.channel.client.id : getActiveClient(),
    searchGroup = this.state.searchGroup,
    type = this.state.type,
    unselectedSearchTags = this.getUnselectedSearchTags(),
    searchTags = this.getSearchTags(),
    lastTagId = null,
    channelId = this.state.channel ? this.state.channel.id : null,
    startDate =  (this.state.selectedEventRange && this.state.selectedEventRange.startDate) ? this.state.selectedEventRange.startDate : null,
    endDate =  (this.state.selectedEventRange && this.state.selectedEventRange.endDate) ? this.state.selectedEventRange.endDate : null,
    publishStartDate =  (this.state.hasPublishSearch && this.state.selectedPublishRange && this.state.selectedPublishRange.startDate) ? this.state.selectedPublishRange.startDate : null,
    publishEndDate =  (this.state.hasPublishSearch && this.state.selectedPublishRange && this.state.selectedPublishRange.endDate) ? this.state.selectedPublishRange.endDate : null,
  } = {}) =>
    request.tag.getAvailableTagsForClient({
      clientId,
      tagGroupType: searchGroup,
      contentCreatorType: type,
      tags: searchTags,
      notTags: unselectedSearchTags,
      lastTagId,
      channelId,
      startDate,
      endDate,
      publishStartDate,
      publishEndDate,
    })


  disableAllTagsForRequest = () => {
    this.setState(state => ({
      ...state,
      tagGroups: state.tagGroups.map(tgs => ({
        ...tgs,
        tags: tgs.tags.map(tag => ({
          ...tag,
          state: 'disable'
        }))
      }))
    }))
  }

  getSearchTags = () => this.state.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'selected').map(tag => tag.id)

  getUnselectedSearchTags = () => this.state.tagGroups.reduce((tags, tagGroup) => tags.concat(tagGroup.tags), []).filter(tag => tag.state === 'unselected').map(tag => tag.id)

  handleSmartSearch = (writeQueryParams = false) => {
    if (!getActiveChannel() || (this.getSelectedTags().concat(this.getUnselectedTags()).length > 0 ||
      ((!!this.state.from && !!this.state.to) || this.state.selectedTimeRange !==  "all") ||
      (this.props.searchType !== "event" && !!this.state.contentCreatorType.support_publish_search &&
        this.state.contentCreatorType.contentCreatorType.type === "editorial" &&
        (this.state.selectedPublishRange))
    ))
    {
      if (this.state.firstRequest){
        if (!this.state.channel) {
          request.contentCreatorFavorite.getAll()
            .then(favorites => this.setState({favorites}))
        }else {
          request.contentCreatorChannelFavorite.getAll()
            .then(favorites => this.setState({favorites}))
        }
        this.setState({ firstRequest: false })
      }

      writeQueryParams ? this.writeQueryStringParams() : this.requestTracks()
      !getActiveChannel() && this.requestAvailableCountries()
      this.setState({showTagDialog: false})
    } else {
      GlobalSnackbar.show({message: localization.get('home.smart_search.missing_tags'), type: GlobalSnackbarTypes.ERROR})
    }
  }

  removeSelectedTag = (id) => {
    if (this.getSelectedTags().concat(this.getUnselectedTags()).length === 1) {
      !getActiveChannel() && this.setState({showTagDialog: true, emptyTagDialog: true}, () => {
        this.requestAvailableCountries()
        this.removeSelectedTags(id, true)
      })
      !!getActiveChannel() && this.removeSelectedTags(id, true)
    } else {
      this.removeSelectedTags(id, false)
      !getActiveChannel() && this.requestAvailableCountries()
    }
  }

  removeSelectedTags = (id, clearAll) => {
    this.setState({
      tagGroups: this.state.tagGroups.map(tagGroup => ({
        ...tagGroup,
        selectedTag: null,
        tags: tagGroup.tags.map(tag => {
          if (tag.id === id || clearAll) {
            tag.state = 'available'
          }
          return tag
        })
      }))
    }, () => {
      this.writeQueryStringParams()
      if(clearAll) {
        this.requestTracks()
      }
    })
  }

  handleNewSearch = tagId => {
    new Promise(resolve => {
      this.setState({
        tagGroups: this.state.tagGroups.map(tagGroup => ({
          ...tagGroup,
          selectedTag: null,
          tags: tagGroup.tags.map(tag => {
            tag.state = 'available'
            return tag
          })
        }))
      }, () => this.readQueryStringParams())

      resolve()
    })
  }

  makeTrackTypeProp = () => {
     return () => 'voice'
  }

  makeDataTypeProp = () => {
    return 'content_creator'
  }

  makeHomeElementProp = (contentCreators) => {
    return {
      ...contentCreators,
      items: {
        data: contentCreators.map(item => ({
          ...item,
          title: item.name,
          shortDescription: item.subtitle,
          kind: 'content_creator',
          favorite: this.state.favorites && this.state.favorites.includes(item.id)
        }))
      }
    }
  }

  requestTracks = ({
     type = this.state.type,
     searchTags = this.state.tagsIdsFromQS || this.getSearchTags(),
     unselectedSearchTags = this.state.notTagsIdsFromQS || this.getUnselectedSearchTags(),
     age = this.state.age,
     countryId = this.state.selectedCountry,
     channelId = this.state.channel ? this.state.channel.id : null,
     clearResults = true,
     nextPage = false,
     search = this.state.searchInput,
     startDate =  (this.state.selectedEventRange && this.state.selectedEventRange.startDate) ? this.state.selectedEventRange.startDate : null,
     endDate =  (this.state.selectedEventRange && this.state.selectedEventRange.endDate) ? this.state.selectedEventRange.endDate : null,
     schedule = (this.state.selectedTimeRange.length > 0 && this.state.selectedTimeRange != 'all') ? this.state.selectedTimeRange : null,
     publishStartDate =  (this.state.selectedPublishRange && this.state.selectedPublishRange.startDate) ? this.state.selectedPublishRange.startDate : null,
     publishEndDate =  (this.state.selectedPublishRange && this.state.selectedPublishRange.endDate) ? this.state.selectedPublishRange.endDate : null,
   } = {}) => {
    let pagination = {};
    let page = this.state.currentPage
    const hasPublishSearch = this.hasPublishFilter();
    const sort = sortTypes.find(st => st.value ===this.state.sortType);

    
    if (nextPage) page++;
    if (clearResults) page = 0;
    pagination = {perPage: 37, page: page};
    
    if (this.state.sortType !== "default") pagination.orderBy = {column: get(sort, 'column', 'order'), type: get(sort, 'direction', 'desc')}

    if (!hasPublishSearch) {
        publishStartDate = null;
        publishEndDate = null;
    }

    return this.setState({loading: true, currentPage: page}, () =>
     request.contentCreator.getAllWithTags(
      type,
      searchTags,
      unselectedSearchTags,
      age,
      countryId, pagination,
      channelId,
      search,
      startDate,
      endDate,
      schedule,
      publishStartDate,
      publishEndDate,
    ).then(searchResults => {
      let results = searchResults.data
      
      if(!clearResults) {
        results = this.state.searchResultsTags.concat(searchResults.data)
      } else {
        setTimeout(() => window.scrollTo({top: 0, behavior: "smooth"}), 500)
      }

      this.setState({
        searchResultsTags: results,
        total: get(searchResults, 'meta.total', 0),
        showTagSelection: false,
        lastPage: searchResults.meta.last_page,
        currentPage: searchResults.meta.current_page,
      })

       searchResults.meta.current_page === 1 && analyticsInstance.smartSearch({
        category_name: getMainTranslation(this.state.contentCreatorType.contentCreatorType, "name"),
        category_type: this.props.searchType === 'event' ? "event" : "content",
      })
    })
      .then(() => this.state.searchResultsTags && this.state.searchResultsTags.length && this.state.searchResultsTags[0].type && this.setState({isTalent: !!this.state.searchResultsTags[0].type.is_talent}))
      .then(() => this.setState({pageReady: true, loading: false}))
      .catch(e => {
        console.log(e)
        GlobalSnackbar.show({
          message: 'smart_search_request_failed',
          type: GlobalSnackbarTypes.ERROR,
        })
      })
    )}

  getDateChip = (dateRange, dateType = "event") => {
    let label = "";
    const onDelete = dateType === "publish" ? this.cleanSelectedPublishDate : this.cleanSelectedDate

    switch (dateRange){
      case 'today': 
        label = localization.get("smart_search.today");
        break;
      case 'tomorrow':
        label = localization.get("smart_search.tomorrow");
        break;
      case 'thisWeekend':
        label = localization.get("smart_search.this_weekend");
        break;
      case 'thisWeek': 
        label = localization.get("smart_search.this_week");
        break;
      case 'nextWeek':
        label = localization.get("smart_search.next_week");
        break;
      case 'next30days':
        label = localization.get("smart_search.next_thirty_days");
        break;
      case 'yesterday':
        label = localization.get("smart_search.yesterday");
        break;
      case 'lastWeek':
        label = localization.get("smart_search.last_seven_days");
        break;
      case 'last30days':
        label = localization.get("smart_search.last_thirty_days");
        break;
      case 'custom':
      default:
        label = localization.get("other");
        break;
    }

    return(
      <Chip
        style={{
          backgroundColor: 'var(--secondary-color, lightgray)',
          color: 'var(--secondary-font-color, black)'
        }}
        label={`${localization.get(dateType === "publish" ? "smart_search.date_publish_label" : "smart_search.date_range_label")}: ${label}`}
        className="smart-search-filters-tag"
        onDelete={onDelete}
      />
    )
  }

  cleanSelectedDate = () =>
    this.setState({selectedDateRange: '', from: '', to: '', selectedEventRange: {startDate: null, endDate: null}},
      () => this.writeQueryStringParams())


  cleanSelectedPublishDate = () =>
    this.setState({selectedPublishDateRange: '', publishFrom: '', publishTo: '', selectedPublishRange: {startDate: null, endDate: null}},
      () => this.writeQueryStringParams())

  cleanSelectedSchedule = () => {
    this.setState({selectedTimeRange: 'all'}, () => {
      this.writeQueryStringParams();
      this.requestTracks();
    });
  }

  renderFilters = () => {
    let selectedTags = this.getSelectedTags()
    let unselectedTags= this.getUnselectedTags()
    const hasPublishSearch = this.hasPublishFilter();

    const pref = (getStoredChannel() && this.state.type) ? getStoredChannel().channelContentCreatorTypes.find(ct => ct.contentCreatorType.slug === this.state.type) : {show_search_input_on_smart_search: 1, show_filter_on_smart_search: 1};
    if (!this.state.showTagSelection && (!!pref.show_filter_on_smart_search || !!pref.show_search_input_on_smart_search)) {
      return (
        <div className="smart-search-filters-container" style={window.innerWidth > 600 ? {padding: "35px 30px 0 25px", zIndex: 200} : {zIndex: 200}}>
          <div className="smart-search-filters-tag-container" style={{margin: 0}}>
            {!!pref.show_filter_on_smart_search && (selectedTags.length > 0 || unselectedTags.length > 0 ||
                (this.state.selectedDateRange && this.state.from && this.state.to) ||
                (this.state.selectedPublishRange && this.state.publishFrom && this.state.publishTo)
              || this.state.selectedTimeRange != 'all') ?
            (
              <>
                {this.state.selectedDateRange && this.state.from && this.state.to && this.getDateChip(this.state.selectedDateRange)}
                {hasPublishSearch && this.state.selectedPublishRange && this.state.publishFrom && this.state.publishTo && this.getDateChip(this.state.selectedPublishDateRange, 'publish')}
                {this.state.selectedTimeRange != 'all' && 
                  <Chip
                    style={{
                      backgroundColor: 'var(--secondary-color, lightgray)',
                      color: 'var(--secondary-font-color, black)'
                    }}
                    label={`${localization.get("smart_search.schedule")}: ${localization.get(`smart_search.schedule.${this.state.selectedTimeRange}`)}`}
                    className="smart-search-filters-tag"
                    onDelete={() => this.cleanSelectedSchedule()}
                  />
                }
                {selectedTags.concat(unselectedTags).map(tag => {
                  return (
                    <Chip
                      style={{
                        backgroundColor: tag.state === 'unselected' ? '#e68484' : 'var(--secondary-color, lightgray)',
                        color: tag.state === 'unselected' ? 'white' : 'var(--secondary-font-color, black)'
                      }}
                      label={tag.name}
                      className="smart-search-filters-tag"
                      onDelete={() => this.removeSelectedTag(tag.id)}
                    />
                  )
                })}
              </>
            ) : <span>&nbsp;</span>}
          </div>
            
          <div className="smart-search-filters-buttons-container" style={{top: 0, right: 0}}>
            {!!pref.show_search_input_on_smart_search &&  
              <div style={{
                marginBottom: 10,
                marginRight: 10,
                position: "relative",
                float: "left",
                display: "flex",
                maxWidth: 185
              }}>
                <TextField
                  inputProps={{
                    ref: input => {
                      this.searchInput = input;
                    },
                    spellCheck: false,
                    style: {
                      color: "var(--main-font-color, black)",
                      borderBottom: `2px solid var(--main-font-color, rgba(0,0,0, .5))`,
                      width: 250,
                      maxWidth: 250,
                    },
                  }}
                  InputProps={{disableUnderline: true}}
                  autoComplete={false}
                  className="smart-search-input-search"
                  placeholder={localization.get("searchbar.content_creators")}
                  value={this.state.searchInput}
                  onChange={e => this.setState({searchInput: e.target.value})}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      this.writeQueryStringParams()
                      if (!this.state.searchInput) {
                        this.requestTracks();
                      }
                    }
                  }}
                  style={{color: "var(--main-font-color, black)", position: "relative", float: "left",}}
                />
                {window.innerWidth < 600 &&
                  <Search style={{marginTop: 7, marginLeft: 7, color: "var(--main-font-color, black)"}}
                          onClick={this.writeQueryStringParams}/>
                }
              </div>
            }
            {!getActiveChannel() && this.state.isTalent &&
            <>
              <div style={{marginTop: "-8px", float: "left", width: 120, marginRight: 50}}>
                <FormControl fullWidth>
                  <InputLabel>{localization.get('smart_search.country')}</InputLabel>
                  <Select
                    disableUnderline
                    value={this.state.selectedCountry}
                    onChange={(e) => this.setState({selectedCountry: e.target.value}, () => this.requestTracks().then(() => this.writeQueryStringParams()))}
                  >
                    <MenuItem value="">{localization.get('all')}</MenuItem>
                    {this.state.availableCountries.map(availableCountry =>
                      <MenuItem value={availableCountry.id}>{availableCountry.name}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
              <div style={{marginTop: "-8px", float: "left", width: 120, marginRight: 50}}>
                <FormControl fullWidth>
                  <InputLabel>{localization.get('smart_search.age')}</InputLabel>
                  <Select
                    disableUnderline
                    value={this.state.age ? this.state.age.min + ' - ' + this.state.age.max : ''}
                  >
                    <div style={{float: "left", position: "relative", width: "340px", padding: "65px 40px"}}>
                      <AgeInputRange
                        onChangeAge={value => {
                          this.setState({age: value})
                        }}
                        onChangeCompleteAge={() => this.requestTracks().then(() => {
                          this.writeQueryStringParams();
                          this.requestAvailableCountries()
                        })}
                        value={this.state.age}
                      />
                    </div>
                    <MenuItem style={{display: "none"}} selected
                              value={this.state.age ? this.state.age.min + ' - ' + this.state.age.max : null}>
                      {this.state.age ? this.state.age.min + ' - ' + this.state.age.max : null}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </>
            }
            {getActiveChannel() && this.state.contentCreatorType && !!this.state.contentCreatorType.show_filter_on_smart_search &&
              ((this.state.searchType == 'event' && !!this.state.contentCreatorType.allow_event_search) || (this.state.tagGroups && this.state.tagGroups.length > 0)) &&
              makeAddFiltersButton(() => {
                if (this.state.sidebarView) {
                  this.setState({showSidebar: true})
                }else {
                  this.setState({showTagDialog: true})
                }
              }, this.state.searchType)
            }
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  closeDialog = () =>  {
    let search = qs.parse(this.props.location.search.slice(1))
    let {tags_ids, not_tags_ids} = search

    if (tags_ids || not_tags_ids ) {
      if (tags_ids) {
        if (Array.isArray(tags_ids)) {
          this.state.tagGroups.forEach(tagGroup =>
            tagGroup.tags.forEach(tag =>
              tag.state = tags_ids.includes(tag.id.toString()) ? 'selected' : 'available'
            ));
        }
      }
      if (not_tags_ids) {
        if (Array.isArray(not_tags_ids)) {
          this.state.tagGroup.tags.forEach(tag =>
            tag.state = tags_ids.includes(tag.id) && 'unselected'
          )
        }
      }
    }else {
      this.state.tagGroups.forEach(tagGroup =>
        tagGroup.tags.forEach(tag =>
          tag.state = 'available'
        ));
    }

    this.setState({showTagDialog: false, tagGroups: this.state.tagGroups})
    //this.setState({showTagDialog: false})
  }

  onSelectedDateRangeChange = (dr = null, withSearch = false, customRange={}) => {
    if (!dr) {
      return this.setState({
        selectedEventRange: {
          startDate: null,
          endDate: null,
        }, from: '', to: '', customFrom: '', customTo: '',
      });
    }
    if (dr != "custom") this.setState({customFrom: '', customTo: ''});
    let today = moment();
    let tomorrow = moment().add(1, 'day');
    let yesterday = moment().subtract(1, 'day');
    let dateStart = '';
    let dateEnd = '';
    
    switch (dr) {
      case 'today': 
        dateStart = today;
        dateEnd = today;
        break;
      case 'tomorrow':
        dateStart = tomorrow;
        dateEnd = tomorrow;
        break;
      case 'thisWeekend':
        dateStart = moment().endOf('week').subtract(2, 'day');
        dateEnd = moment().endOf('week');
        break;
      case 'thisWeek': 
        dateStart = today;
        dateEnd = moment().endOf('week');
        break;
      case 'nextWeek':
        dateStart = moment().add(1, 'week').startOf('week');
        dateEnd = moment().add(1, 'week').endOf('week');
        break;
      case 'next30days':
        dateStart = today;
        dateEnd = moment().add(30, 'day');
        break;
      case 'yesterday':
        dateStart = yesterday;
        dateEnd = yesterday;
        break;
      case 'lastWeek':
        dateStart= moment().subtract(7, 'day');
        dateEnd= today;
        break;
      case 'last30days':
        dateEnd = today;
        dateStart = moment().subtract(30, 'day');
        break;
      case 'custom':
        const {startDate, endDate} = customRange;
        
        if (startDate && endDate) {
          this.setState({customFrom: startDate, customTo: endDate});
          dateStart = moment(startDate)
          dateEnd = moment(endDate)
        }else {
          this.setState({ selectedDateRange: dr });
          return;
        }
        break;
      default:
        this.setState({from: '', to: '', customFrom: '', customTo: '', selectedDateRange: dr});
        return;
    }

    let range =  {
      startDate: dateStart.format('YYYY-MM-DD'),
      endDate: dateEnd.format('YYYY-MM-DD')
    }
    
    return this.setState({selectedEventRange: range, from: dateStart.format('DD/MM/YYYY'), to: dateEnd.format('DD/MM/YYYY'), selectedDateRange: dr}, 
      () => withSearch && this.requestTracks({}));
  }

  onSelectedPublishDateRangeChange = (dr = null) => {

    if (!dr) {
      return this.setState({
        selectedPublishRange: { startDate: null, endDate: null},
        publishFrom: "",
        publishTo: "",
      })
    }

    let today = moment();
    let tomorrow = moment().add(1, 'day');
    let yesterday = moment().subtract(1, 'day');
    let dateStart = '';
    let dateEnd = '';
    switch (dr) {
      case 'today':
        dateStart = today;
        dateEnd = today;
        break;
      case 'tomorrow':
        dateStart = tomorrow;
        dateEnd = tomorrow;
        break;
      case 'thisWeekend':
        dateStart = moment().endOf('week').subtract(2, 'day');
        dateEnd = moment().endOf('week');
        break;
      case 'thisWeek':
        dateStart = today;
        dateEnd = moment().endOf('week');
        break;
      case 'nextWeek':
        dateStart = moment().add(1, 'week').startOf('week');
        dateEnd = moment().add(1, 'week').endOf('week');
        break;
      case 'next30days':
        dateStart = today;
        dateEnd = moment().add(30, 'day');
        break;
      case 'yesterday':
        dateStart = yesterday;
        dateEnd = yesterday;
        break;
      case 'lastWeek':
        dateStart= moment().subtract(7, 'day');
        dateEnd= today;
        break;
      case 'last30days':
        dateEnd = today;
        dateStart = moment().subtract(30, 'day');
        break;
      case 'custom':
      default:
        this.setState({publishFrom: '', publishTo: '', selectedPublishDateRange: dr});
        return;
    }
    let range =  {
      startDate: dateStart.format('YYYY-MM-DD'),
      endDate: dateEnd.format('YYYY-MM-DD')
    }
    this.setState({selectedPublishRange: range, publishFrom: dateStart.format('DD/MM/YYYY'), publishTo: dateEnd.format('DD/MM/YYYY'), selectedPublishDateRange: dr});
  }

  makeDefinedRanges = (type = 'events') => {
    let today = moment();
    let ranges;

    let todayRange = {
      label: localization.get("smart_search.today"),
      startDate: today,
      endDate: today
    };

    if (type === 'publish') {
      let yesterday = moment().subtract(1, 'day');

      let yesterdayRange = {
        label: localization.get("smart_search.yesterday"),
        startDate: yesterday,
        endDate: yesterday
      };

      let lastWeek = {
        label: localization.get("smart_search.last_seven_days"),
        startDate: moment().subtract(7, "day"),
        endDate: today,
      };

      let last30days = {
        label: localization.get("smart_search.last_thirty_days"),
        startDate: moment().subtract(30, 'day'),
        endDate: today,
      }

      ranges = [todayRange, yesterdayRange, lastWeek, last30days].map(
        (range) => {
          range.startDate = range.startDate.toDate();
          range.endDate = range.endDate.toDate();
          return range;
        }
      )
    }else {
      let tomorrow = moment().add(1, 'day');
      let tomorrowRange = {
        label: localization.get("smart_search.tomorrow"),
        startDate: tomorrow,
        endDate: tomorrow
      };
      let thisWeekend = {
        label: localization.get("smart_search.this_weekend"),
        startDate: moment().endOf('week').subtract(2, 'day'),
        endDate: moment().endOf('week')
      }
      let thisWeek = {
        label: localization.get("smart_search.this_week"),
        startDate: today,
        endDate: moment().endOf('week')
      };
      let nextWeek = {
        label: localization.get("smart_search.next_week"),
        startDate: moment().add(1, 'week').startOf('week'),
        endDate: moment().add(1, 'week').endOf('week')
      };
      let next30days = {
        label: localization.get("smart_search.next_thirty_days"),
        startDate: today,
        endDate: moment().add(30, 'day')
      }

      ranges = [todayRange, tomorrowRange, thisWeekend, thisWeek, nextWeek, next30days].map(
        (range) => {
          range.startDate = range.startDate.toDate();
          range.endDate = range.endDate.toDate();
          return range;
        }
      )
    }

    return ranges;
  }

  renderEventSearch = () => {
    return (
      <div style={{paddingTop: '30px'}}>
        {!this.props.searchType &&
          <>
            <h4 style={{margin: "10px 0px 10px 20px", fontWeight: "600", textAlign: 'left'}}>{localization.get("smart_search.search_type")}</h4>
            <FormControl style={{width: "100%", paddingLeft: 10}}>
              <RadioGroup
                name="searchType"
                value={this.state.searchType}
                onChange={e => e.target.value == 'content' ? this.setState({searchType: e.target.value, selectedTimeRange: '', from: '', to: '', selectedEventRange: {startDate: null, endDate: null}}) : this.setState({searchType: e.target.value})}
                style={{width: "100%", float: "left", position: "relative", flexDirection: "row", alignItems: "center"}}
              >
                <FormControlLabel value="content" control={<Radio color="default"/>} label={<span style={{color: 'var(--secondary-font-color, rgba(0, 0, 0, 0.54))'}}>{localization.get("smart_search.content_search")}</span>}/>
                <FormControlLabel value="event" control={<Radio color="default"/>} label={<span style={{color: 'var(--secondary-font-color, rgba(0, 0, 0, 0.54))'}}>{localization.get("smart_search.event_search")}</span>} style={{color: 'var(--secondary-font-color, rgba(0, 0, 0, 0.54))'}}/>
              </RadioGroup>
            </FormControl>
          </>
        }
        {this.state.searchType == 'event' &&
          <>
            {this.state.selectedDateRange == 'custom' &&
              <DateRangePicker 
                wrapperClassName="date-range-picker"
                open={this.state.eventRangeOpen} 
                initialDateRange={this.state.selectedEventRange} 
                toggle={() => this.setState({eventRangeOpen: !this.state.eventRangeOpen})} 
                onChange={(range) =>
                  this.setState({
                    from: moment(range.startDate).format('DD/MM/YYYY'),
                    to: moment(range.endDate).format('DD/MM/YYYY'),
                    selectedEventRange: {
                      startDate: moment(range.startDate).format('YYYY-MM-DD'),
                      endDate: moment(range.endDate).format('YYYY-MM-DD')
                    }}, () => this.setState({eventRangeOpen: false}))}
                definedRanges={this.makeDefinedRanges()}
              >
              </DateRangePicker>
            }
            <div style={window.innerWidth > 600 ? {display: 'flex', flexDirection: 'row', alignItems: "center"}:{display: 'flex', flexDirection: 'column'}}>
              <div style={{float: "left", width: 230, marginLeft: 20, marginBottom: 30}}>
                <h4 className={`${font[getActiveChannel() ? getStoredChannel().preferences.title.font : '']}`} style={{margin: "10px 0px 10px 0px", fontWeight: "600", textAlign: 'left'}}>{localization.get("smart_search.date_range_label")}</h4>
                <FormControl style={{width: 200, float: 'left'}}>
                  {/* <InputLabel style={{color: 'var(--secondary-font-color, rgba(0, 0, 0, 0.54))'}}>{localization.get("smart_search.date_range_label")}</InputLabel> */}
                  <Select
                    // disableUnderline
                    className="smart-search-input-search"
                    value={this.state.selectedDateRange}
                    onChange={(e) => this.onSelectedDateRangeChange(e.target.value)}
                    style={{color: "var(--secondary-font-color, black)", position: "relative", float: "left",}}
                    MenuProps={{
                      MenuListProps: { 
                        style: {
                          backgroundColor: 'var(--main-color, white)',
                        },
                      }
                    }}
                  >
                    <MenuItem value="today" style={{color: "var(--secondary-font-color, black)"}}>{localization.get("smart_search.today")}</MenuItem>
                    <MenuItem value="tomorrow" style={{color: "var(--secondary-font-color, black)"}}>{localization.get("smart_search.tomorrow")}</MenuItem>
                    <MenuItem value="thisWeekend" style={{color: "var(--secondary-font-color, black)"}}>{localization.get("smart_search.this_weekend")}</MenuItem>
                    <MenuItem value="thisWeek" style={{color: "var(--secondary-font-color, black)"}}>{localization.get("smart_search.this_week")}</MenuItem>
                    <MenuItem value="nextWeek" style={{color: "var(--secondary-font-color, black)"}}>{localization.get("smart_search.next_week")}</MenuItem>
                    <MenuItem value="next30days" style={{color: "var(--secondary-font-color, black)"}}>{localization.get("smart_search.next_thirty_days")}</MenuItem>
                    {window.innerWidth >= 600 && <MenuItem value="custom" style={{color: "var(--secondary-font-color, black)"}}>{localization.get("other")}</MenuItem>}
                  </Select>
                </FormControl>
              </div>
              <div style={{float: "left", width: 230, marginLeft: 20, marginBottom: 30}}>
                <h4 className={`${font[getActiveChannel() ? getStoredChannel().preferences.title.font : '']}`} style={{margin: "10px 0px 10px 0px", fontWeight: "600", textAlign: 'left'}}>{localization.get("smart_search.schedule")}</h4>
                <FormControl style={{width: 200, float: 'left'}}>
                  {/* <InputLabel style={{color: 'var(--secondary-font-color, rgba(0, 0, 0, 0.54))'}}>{localization.get("smart_search.schedule")}</InputLabel> */}
                  <Select
                    // disableUnderline
                    className="smart-search-input-search"
                    value={this.state.selectedTimeRange}
                    onChange={(e) => this.setState({selectedTimeRange: e.target.value})}
                    style={{color: "var(--secondary-font-color, black)", position: "relative", float: "left",}}
                    MenuProps={{
                      MenuListProps: { 
                        style: {
                          backgroundColor: 'var(--main-color, white)',
                        },
                      }
                    }}
                  >
                    <MenuItem value="all" style={{color: "var(--secondary-font-color, black)"}}>{localization.get("smart_search.schedule.all")}</MenuItem>
                    <MenuItem value="morning" style={{color: "var(--secondary-font-color, black)"}}>{localization.get("smart_search.schedule.morning")}</MenuItem>
                    <MenuItem value="afternoon" style={{color: "var(--secondary-font-color, black)"}}>{localization.get("smart_search.schedule.afternoon")}</MenuItem>
                    <MenuItem value="night" style={{color: "var(--secondary-font-color, black)"}}>{localization.get("smart_search.schedule.night")}</MenuItem>
                    
                  </Select>
                </FormControl>
              </div>
            </div>
            { this.state.selectedDateRange == 'custom' &&
              <>
                <div style={{display: "flex", flexDirection: 'row', alignItems: "center", textAlign: 'left', paddingLeft: 20}}>
                  <TextField
                    inputProps={{
                      style:{
                        color: "var(--secondary-font-color, #2d2d2d)",
                        borderBottom: "2px solid var(--secondary-font-color, #2d2d2d)",
                        width: 100,
                        maxWidth: 100,
                      },
                    }}
                    InputProps={{disableUnderline: true}}
                    readOnly={true}
                    className="smart-search-input-search"
                    placeholder={localization.get("smart_search.date_start")}
                    value={this.state.from}
                    style={{color: "var(--secondary-font-color, black)", position: "relative", float: "left", marginRight: 20}}
                    onClick={() => this.setState({eventRangeOpen: true})}
                  />
                  <TextField
                    inputProps={{
                      style:{
                        color: "var(--secondary-font-color, #2d2d2d)",
                        borderBottom: "2px solid var(--secondary-font-color, #2d2d2d)",
                        width: 100,
                        maxWidth: 100,
                      },
                    }}
                    InputProps={{disableUnderline: true}}
                    readOnly={true}
                    className="smart-search-input-search"
                    placeholder={localization.get("smart_search.date_end")}
                    value={this.state.to}
                    style={{color: "var(--secondary-font-color, black)", position: "relative", float: "left", marginRight: 40}}
                    onClick={() => this.setState({eventRangeOpen: true})}
                  />
                </div>
              </>
            }
          </>
        }  
      </div>
    )
  }

  renderPublishSearch = () => {
    return (
      <div style={{paddingTop: '30px'}}>
        {this.state.selectedPublishDateRange === 'custom' &&
          <DateRangePicker
            wrapperClassName="date-range-picker"
            open={this.state.publishRangeOpen}
            initialDateRange={this.state.selectedPublishRange}
            toggle={() => this.setState({publishRangeOpen: !this.state.publishRangeOpen})}
            onChange={(range) =>
              this.setState({
                publishFrom: moment(range.startDate).format('DD/MM/YYYY'),
                publishTo: moment(range.endDate).format('DD/MM/YYYY'),
                selectedPublishRange: {
                  startDate: moment(range.startDate).format('YYYY-MM-DD'),
                  endDate: moment(range.endDate).format('YYYY-MM-DD')
                }}, () => this.setState({publishRangeOpen: false}))}
            definedRanges={this.makeDefinedRanges('publish')}
          >
          </DateRangePicker>
        }
        <div style={window.innerWidth > 600 ? {display: 'flex', flexDirection: 'row', alignItems: "center"}:{display: 'flex', flexDirection: 'column'}}>
          <div style={{float: "left", width: 230, marginLeft: 20, marginBottom: 30}}>
            <h4 className={`${font[getActiveChannel() ? getStoredChannel().preferences.title.font : '']}`} style={{margin: "10px 0px 10px 0px", fontWeight: "600", textAlign: 'left'}}>{localization.get("smart_search.date_publish_label")}</h4>
            <FormControl style={{width: 200, float: 'left'}}>
              {/* <InputLabel style={{color: 'var(--secondary-font-color, rgba(0, 0, 0, 0.54))'}}>{localization.get("smart_search.date_range_label")}</InputLabel> */}
              <Select
                // disableUnderline
                className="smart-search-input-search"
                value={this.state.selectedPublishDateRange}
                onChange={(e) => this.onSelectedPublishDateRangeChange(e.target.value)}
                style={{color: "var(--secondary-font-color, black)", position: "relative", float: "left",}}
                MenuProps={{
                  MenuListProps: {
                    style: {
                      backgroundColor: 'var(--main-color, white)',
                    },
                  }
                }}
              >
                <MenuItem value="today" style={{color: "var(--secondary-font-color, black)"}}>{localization.get("smart_search.today")}</MenuItem>
                <MenuItem value="yesterday" style={{color: "var(--secondary-font-color, black)"}}>{localization.get("smart_search.yesterday")}</MenuItem>
                <MenuItem value="lastWeek" style={{color: "var(--secondary-font-color, black)"}}>{localization.get("smart_search.last_seven_days")}</MenuItem>
                <MenuItem value="last30days" style={{color: "var(--secondary-font-color, black)"}}>{localization.get("smart_search.last_thirty_days")}</MenuItem>
                {window.innerWidth >= 600 && <MenuItem value="custom" style={{color: "var(--secondary-font-color, black)"}}>{localization.get("other")}</MenuItem>}
              </Select>
            </FormControl>
          </div>
        </div>
        {this.state.selectedPublishDateRange == 'custom' &&
          <div style={{display: "flex", flexDirection: 'row', alignItems: "center", textAlign: 'left', paddingLeft: 20}}>
            <TextField
              inputProps={{
                style:{
                  color: "var(--secondary-font-color, #2d2d2d)",
                  borderBottom: "2px solid var(--secondary-font-color, #2d2d2d)",
                  width: 100,
                  maxWidth: 100,
                },
              }}
              InputProps={{disableUnderline: true}}
              readOnly={true}
              className="smart-search-input-search"
              placeholder={localization.get("smart_search.date_start")}
              value={this.state.publishFrom}
              style={{color: "var(--secondary-font-color, black)", position: "relative", float: "left", marginRight: 20}}
              onClick={() => this.setState({publishRangeOpen: true})}
            />
            <TextField
              inputProps={{
                style:{
                  color: "var(--secondary-font-color, #2d2d2d)",
                  borderBottom: "2px solid var(--secondary-font-color, #2d2d2d)",
                  width: 100,
                  maxWidth: 100,
                },
              }}
              InputProps={{disableUnderline: true}}
              readOnly={true}
              className="smart-search-input-search"
              placeholder={localization.get("smart_search.date_end")}
              value={this.state.publishTo}
              style={{color: "var(--secondary-font-color, black)", position: "relative", float: "left", marginRight: 40}}
              onClick={() => this.setState({publishRangeOpen: true})}
            />
          </div>
        }
      </div>
    )
  }

  addToFavorites = id => {
    let onAdded = () => {
      this.state.favorites.push(id);
      this.setState(state => ({
        ...state,
        favorites: this.state.favorites,
        searchResultsTags: state.searchResultsTags.map(creation => {
          if (creation.id === id) {
            return ({...creation, favorite: true})
          }
          return creation
        })
      }))
    }
    if (getActiveChannel()) {
      request.contentCreationChannelFavorite.add(id)
        .then(() => onAdded())
    }else {
      request.contentCreationFavorite.add(id)
        .then(() => onAdded())
    }
  }

  hasPublishFilter = () => (!!(getActiveChannel() && this.state.contentCreatorType) &&
    this.props.searchType !== "event" && !!this.state.contentCreatorType.support_publish_search &&
  this.state.contentCreatorType.contentCreatorType.type === "editorial")

  getColumns = () => {
    const columns = [
    {
      id: "image",
        accessor: contenCreator => (
      <img
        alt="contenCreator"
        src={makeImageUrlWithSize(contenCreator, 'xs')}
        width={50}
        height={50}
        onError={onImageError}
      />
    ),
      style: {
      float: "left"
    },
      resizeable: false,
      sortable: false,
      filterable: false,
      width: 60
    },
    {
      Header: localization.get("table.name"),
        accessor: "name",
      Filter: ({filter, onChange}) => (
      <input
        onChange={event => {
          onChange(event.target.value)
          this.setState({name: event.target.value || ""})
        }}
        value={filter ? filter.value : ""}
        placeholder={localization.get("table.search")}
      />
    )
    },
    {
      id: "actions",
      Header: <div style={{display: "flex", flexDirection: "column"}}>
          <span style={{float: "right", fontSize: "18px",}}>{localization.get("table.actions")}</span>
        </div>,
      sortable: false,
      filterable: false,
      accessor: contentCreator => {
      const buttonBuilder = new TableButtonsBuilder();

      {!!getStoredUser() && getActiveClient() && getUseTools() &&
        buttonBuilder.withOther(
          <TableButton>
            <AddToPlaylist
              clientId={getActiveClient()}
              content={"contentCreators"}
              item={contentCreator}
              icon={<PlaylistAdd style={{marginTop: 8, marginLeft: 3, color: "var(--main-font-color, inherit)"}} />}
            />
          </TableButton>
        )
      }
      

      buttonBuilder.withOther(
        <TableButton
          title={localization.get(contentCreator.favorite ? "soundbar.remove_from_favorites" : "soundbar.add_to_favorites")}
          onClick={() => contentCreator.favorite ? this.removeFromFavorites(contentCreator.id):  this.addToFavorites(contentCreator.id)}
        >
          {!contentCreator.favorite ? <StarBorder style={{color: "var(--main-font-color, inherit)"}} /> : <Star style={{color: "var(--main-font-color, inherit)"}} />}
        </TableButton>
      )


      return buttonBuilder.build();
      },
      width: 145,
      Footer: <span style={{float: "right", fontSize: "18px", marginTop: "15px"}}><strong>Total: </strong>{this.state.total}</span>
    }];

    return columns;
  }

  renderSearchResultsTableTags = () => {
    const { searchResultsTags } = this.state
    const viewType = getStoredChannel() ? getStoredChannel().channelContentCreatorTypes.find(ct => ct.contentCreatorType.slug === this.state.type) : false;

    if (this.state.loading && this.state.currentPage == 0) {
      return (
        <div className="smart-search-no-result-container">
          <img style={{width: 100, height: 100}} src={LoadingSpinner} alt="Loading logo"/>
        </div>
      )
    }

    return (<div ref={r => this.resultsTableTags = r}
       style={{
        float: 'left',
        position: 'relative',
        width: '100%',
        backgroundColor: "var(--main-color, white)",
        marginTop: "15px",
        padding: !this.state.sidebarView ? 55 : 10
      }}>
        {this.state.showTagSelection ? null : (!this.state.showTagSelection && searchResultsTags.length === 0) ?
          <div className="smart-search-no-result-container">
            <p>{localization.get('smart_search.no_result')}</p>
          </div>
          :
          <div className="smart-search-result-container" style={{margin: this.state.sidebarView ? 0 : (window.innerWidth > 600 ? "0px -50px 0 -45px": "0px -15px 0 -10px")}}>
            {this.state.total && (this.state.channel.use_tools || this.state.channel.use_licenses) && <p style={{textAlign: "right", width: "100%", paddingRight: 15}}>Total: {this.state.total}</p>}
            {this.state.viewFormat != "list" ? 
              <InfiniteScroll
                style={{display: "flex", flexWrap: "wrap", position: "relative", float: "left", width: "100%", padding: this.state.sidebarView ? "0 5px" :"0 20px 0 15px"}}
                dataLength={this.state.searchResultsTags.length}
                next={() => this.requestTracks({ nextPage: true, clearResults: false })}
                hasMore={this.state.currentPage < this.state.lastPage}
                scrollableTarget={this.state.sidebarView ? 'sidebarScrollingDiv' : null}
                className="smart-search-infinite-scroll"
                loader={<div
                  style={{height: "70px", paddingTop: 20, width: "100%", display: "flex", justifyContent: "center", alignContent: "center"}}
                >
                  <img style={{width: 50, height: 50}} src={LoadingSpinner} alt="Loading logo"/>
                </div>}
              >
                <HomeElementGridContainer
                  {...this.props}
                  hidePadding={this.state.sidebarView || viewType.view_type == "rectangle_event"}
                  rounded={viewType && ["grid6_rounded", "grid4_rounded"].includes(viewType.view_type)}
                  alternative={viewType && ["movie_alternative"].includes(viewType.view_type)}
                  maxPerRow={viewType && ["featured2", "featured2_alternative"].includes(viewType.view_type) ? 2 : (viewType && ["movie", "movie_alternative"].includes(viewType.view_type))? 1 : (["grid4", "grid4_rounded", 'rectangle_event'].includes(viewType.view_type) ? 4 : 6)}
                  homeElement={this.makeHomeElementProp(this.state.searchResultsTags)}
                  trackType={this.makeTrackTypeProp()}
                  dataType={this.makeDataTypeProp()}
                  showSectionTitle={false}
                  fixedTitle={!["grid6_rounded", "grid4_rounded"].includes(viewType.view_type)}
                  sizes={{
                    xs: viewType && ["movie", "movie_alternative"].includes(viewType.view_type) ? 2 : 6,
                    sm: viewType && ["movie", "movie_alternative"].includes(viewType.view_type) ? 2 : 4,
                    md: viewType && ["movie", "movie_alternative"].includes(viewType.view_type) ? 2 : 3,
                    lg: 2,
                    xl: 2,
                  }}
                  // showMore={true}
                  rowsToShow={3}
                  itemComponent={viewType && ["featured2", "featured2_alternative", "movie", "movie_alternative"].includes(viewType.view_type) ? HomeElementFeaturedItem : (viewType.view_type == "rectangle_event" ? HomeElementCarouselItem : GridItem)}
                  handleNewSearch={this.handleNewSearch}
                  editorial={viewType && viewType.view_type === "featured2"}
                  editorialInverted={viewType && viewType.view_type === "featured2_alternative"}
                  shape={viewType && (["grid6_rounded", "grid4_rounded"].includes(viewType.view_type) ? "round" : ["featured2", "featured2_alternative"].includes(viewType.view_type) ? "x2" : (viewType.view_type == "rectangle_event" ? "rectangle_event" : 'x1'))}
                  movie={viewType && ["movie", "movie_alternative"].includes(viewType.view_type)}
                  requestTracks={this.requestTracks}
                  search={true}
                  pillColor={get(viewType, 'preferences.pillFontColor')}
                  pillBackground={get(viewType, 'preferences.pillBackgroundColor')}
                />
              </InfiniteScroll>
            : 
              <div style={{float: "left", position: "relative", width: "100%", padding: "0px 5px 15px"}}>
                <ClientSidePaginationTable
                  data={this.state.searchResultsTags.map((creation, index) => ({...creation, index}))}
                  columns={this.getColumns()}
                  filterable={false}
                  sortable={false}
                  showPagination={false}
                  zebraStyle={false}
                  perPage={999}
                />
              </div>
            }
          </div>
        }
      </div>
    )
  }

  renderFilterInput = () =>  {
    const pref = (getStoredChannel() && this.state.type) ? getStoredChannel().channelContentCreatorTypes.find(ct => ct.contentCreatorType.slug === this.state.type) : {show_search_input_on_smart_search: 1, show_filter_on_smart_search: 1};

    const urlParams = new URLSearchParams(window.location.search);
    const nameParam = urlParams.get('name');
   
    return (
      <div className="smart-search-filters-buttons-container" style={{right: 0, top: 0, display: "flex", justifyContent: "end"}}>
        {(!!pref.show_search_input_on_smart_search || !!nameParam) && 
          <div style={{
            marginBottom: 10,
            marginRight: 10,
            position: "relative",
            float: "left",
            display: "flex",
            maxWidth: 185
          }}>
            <TextField
              inputProps={{
                ref: input => {
                  this.searchInput = input;
                },
                spellCheck: false,
                style: {
                  color: "var(--main-font-color, black)",
                  borderBottom: `2px solid var(--main-font-color, rgba(0,0,0, .5))`,
                  width: 250,
                  maxWidth: 250,
                },
              }}
              InputProps={{disableUnderline: true}}
              autoComplete={false}
              className="smart-search-input-search"
              placeholder={localization.get("searchbar.content_creators")}
              value={this.state.searchInput}
              onChange={e => this.setState({searchInput: e.target.value})}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  this.writeQueryStringParams(true)
                }
              }}
              style={{color: "var(--main-font-color, black)", position: "relative", float: "left",}}
            />
            {window.innerWidth < 600 &&
              <Search style={{marginTop: 7, marginLeft: 7, color: "var(--main-font-color, black)"}}
                      onClick={this.writeQueryStringParams}/>
            }
          </div>
        }
        {get(this.state,'tagGroups', []).length > 0 && !!get(pref, 'show_filter_on_smart_search', 1) && !this.state.sidebarView &&
          <TableButton
            title={localization.get('searchbar.content_creators_for_tags')}
            onClick={() => { 
              if (this.state.sidebarView) { 
                this.setState({openSidebar: true})
              }else {
                this.setState({showTagDialog: true})
              }
            }}
          >
              <FilterList style={{color: "var(--main-font-color, black)"}}/>
          </TableButton>
        }
        {getUseTools() &&
        <>
        <Tooltip title={localization.get('searchbar.sort')} placement={"top"}>
          <IconButton
            style={{position: "relative", float: "left"}}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              this.setState({openSort: true})
            }}
          >
          <div
            aria-describedby={"id"}
            ref={node => this.anchorEl = node}
            aria-owns={"menu-list-grow"}
            aria-haspopup="true"

          >
            <SortByAlpha style={{color: "var(--main-font-color, black)", marginTop: -5 }} />
          </div>
        </IconButton>
        </Tooltip>
        <Popper
          open={this.state.openSort}
          id={"id"}
          anchorEl={this.anchorEl}
          transition
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}

          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          style={{zIndex:11000}}
        >
          {({TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{transformOrigin: "center right", zIndex:10}}
            >
              <Paper style={{zIndex:10}}>
                <ClickAwayListener onClickAway={() => this.setState({openSort: false})}>
                  <MenuList style={{paddingBottom: 0, paddingTop: 0, minWidth: 250}}>
                    {sortTypes.map(st =>
                      <MenuItem
                        onClick={e => {
                          e.preventDefault()
                          e.stopPropagation()

                          this.setState({openSort: false, sortType: st.value}, this.requestTracks)
                        }}
                        selected={st.value === this.state.sortType}
                        style={{cursor: "pointer"}}
                      >
                        {st.label}
                      </MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <TableButton title={localization.get("searchbar.change_view")} onClick={() => this.setState({viewFormat: this.state.viewFormat == "grid" ? "list" : "grid"})}>
          {this.state.viewFormat == "grid" ?
            <List  style={{color: "var(--main-font-color, black)"}} /> :
            <GridOn  style={{color: "var(--main-font-color, black)"}} />
          }
        </TableButton>
        </>
        }
      </div>)
  }

  cleanSearch = () => {
    this.setState( {
      tagGroup: this.state.tagGroups.forEach(tagGroup => tagGroup.tags.forEach(tag => tag.state = "available")),
      searchResultsTags: [],
      showTagSelection: false, // TODO check this
    })
    let ccType = this.state.contentCreatorType && this.state.contentCreatorType.contentCreatorType;
    const searchType = this.props.searchType == 'content' ? "contenido" : "eventos";
    ccType && browserHistory.push(channelPathGenerator(`busqueda-inteligente/${searchType}/${ccType.id}-${slugify(ccType.name)}?type=${ccType.slug}`))
    this.requestAvailableTags()
    this.requestTracks();
    document.getElementsByTagName('html')[0].scrollTop = 0
  }
  
  render = () => {
    const hasPublishSearch = this.hasPublishFilter();

      return (
        <div id="innerinner" style={{marginBottom: '10px'}}>
          {this.makeSmartSearchTagSelector()}
          <div style={{display: this.state.showTagSelection ? 'block' : 'none'}}
               className="smart-search-buttons-container">
            {makeSearchButton(() => this.handleSmartSearch())}
          </div>
          {this.state.pageReady ?
            <>
             {!this.state.sidebarView ? 
                <>
                  {this.renderFilters()}
                  {this.renderSearchResultsTableTags()}
                </>
                :
                <FilterContainer
                  onSelectTag={this.handleSmartSearchSelectedTagsChanged}
                  tagGroups={this.state.tagGroups}
                  selectedTags={this.getSelectedTags().map(tag => tag.id)}
                  unselectedTags={this.getUnselectedTags().map(tag => tag.id)}
                  children={this.renderSearchResultsTableTags()}
                  filterInput={() => this.renderFilterInput()}
                  onCleanSearch={this.cleanSearch}
                  total={this.state.total}
                  open={this.state.openSidebar}
                  from={this.state.customFrom}
                  to={this.state.customTo}
                  onChange={(id, value) => {
                      this.setState({ [id]: value }, () => {
                        this.onSelectedDateRangeChange(this.state.selectedDateRange, true, {startDate: this.state.customFrom, endDate: this.state.customTo})
                      })
                  }}
                  handleOpenSidebar={(value) => this.setState({openSidebar: value})}
                  supportEventSearch={this.props.searchType === "event"}
                  selectedRange={this.state.selectedDateRange}
                  onSelectedDateRangeChange={this.onSelectedDateRangeChange}
                />
              }
            </>
             : !this.state.showTagSelection ?
            <div className="smart-search-no-result-container">
              <img style={{width: 100, height: 100}} src={LoadingSpinner} alt="Loading logo"/>
            </div> : null
          }
          {this.state.showTagDialog &&
          <Dialog
            open
            onClose={this.closeDialog}
            maxWidth={false}
            PaperProps={{
              style: {
                backgroundColor: 'var(--main-color, white)',
              },
            }}
          >
            <DialogContent style={{ float: "left", position: "relative", width: "100%", textAlign: "center", padding: window.innerWidth > 750 ? "10px 50px" : "10px 30px", height: "auto" , minHeight: "520px", backgroundColor: "var(--main-color, inherit)", color: "var(--secondary-font-color, inherit)", overflowX: 'hidden'}}>
              <IconButton style={{position: "absolute", top: 10, right: 10, zIndex: 1}} onClick={this.closeDialog}>
                <Close/>
              </IconButton>

              {(getActiveChannel() && this.state.contentCreatorType) && !!this.state.contentCreatorType.allow_event_search && this.renderEventSearch()}
              {hasPublishSearch && this.renderPublishSearch()}
              <TagSelection
                modal
                horizontal={true}
                onSelectedTagsChanged={this.handleSmartSearchSelectedTagsChanged}
                singleTagSelection={(getActiveChannel() && this.state.contentCreatorType) ? !this.state.contentCreatorType.has_multiple_tags_allowed : true}
                data={this.state.tagGroups.sort((a,b) => a.order - b.order)}
                notWriteQueryString={true}
                allowUnselected={(getActiveChannel() && this.state.contentCreatorType) ? this.state.contentCreatorType.support_negative_search : true}
                checkAvailableTags={(getActiveChannel() && this.state.contentCreatorType) ? !(this.state.contentCreatorType.has_multiple_tags_allowed && !this.state.contentCreatorType.restricted_content_search) : true}
              />
            </DialogContent>
            <DialogActions style={{backgroundColor: "var(--main-color, inherit)"}}>
              <div style={{float: "left", position: "relative", width: "100%", textAlign: "center"}}>
                {makeSearchButton(() => this.handleSmartSearch(true))}
              </div>
            </DialogActions>
          </Dialog>
          }
        </div>
      )
    }
}

export default SmartSearchContentCreator;

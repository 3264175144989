import {appendPaginationQueryParamsToUrl, get} from '../utils'
import {getActiveClient} from "../authentication/utils";
const joinQueryParams = (filters) => Object.entries(filters)
    .reduce((acc, [key, value]) => {
      if (value !== null) {
        acc.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
      return acc;
    }, [])
    .join('&');

const url = (host, type, tags, notTags, options, channelId = null, search="", contentCreatorId=null, orderBy='order', sortType='asc', startDate = null, endDate = null, clearCache = false, people=[], faceFilters={}) => {
  const faceFiltersQueryParams = joinQueryParams(faceFilters);

  return appendPaginationQueryParamsToUrl(`${host}/clients/${getActiveClient()}${channelId ? `/channels/${channelId}` : ''}/contentCreations?category=${type}${(tags && tags.length > 0) ? `&tags_ids=${tags.join(',')}` : ''}${people.length > 0 ? `&people_ids=${people.map(p => p.value).join(',')}` : ''}${faceFiltersQueryParams? `&${faceFiltersQueryParams}` : ""}${(notTags && notTags.length > 0) ? `&not_tags_ids=${notTags.join(',')}`: ''}${search ? `&name=${search}` : ''}${contentCreatorId ? `&content_creator_id=${contentCreatorId}` : ''}&order_by=${orderBy}&sort_type=${sortType}${endDate ? `&endDate=${endDate}` : ''}${startDate ? `&startDate=${startDate}` : ''}${clearCache ? `&cc=${Date.now()}` : ''}&active=1&include=translations;tags;image.people;binary;video.thumbnail;audio.waveform;embededContent;contentCreator.image`, {...options, alreadyHasQueryParams: true});
}

export default host => ({type, tags, notTags, options, channelId, search="", contentCreatorId=null, orderBy='order', sortType='asc', startDate = null, endDate = null, clearCache = false, people=[], faceFilters={}}) =>
  get(url(host, type, tags, notTags, options, channelId, search, contentCreatorId, orderBy, sortType, startDate, endDate, clearCache, people, faceFilters))
    .then(response => response.json())
    .then(json => json.meta ? json : json.data)
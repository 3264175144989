import getAllForClient from './getAllForClient'
import show from './show'
import update from './update'
import removePhoto from './removePhoto'
import getAllPhotosForClient from './getAllPhotosForClient'
import getFilters from './getFilters'
import getAllForChannel from './getAllForChannel'
import mergePeople from './mergePeople'
import setMainImage from './setMainImage'

export default host => ({
  getAllForClient: getAllForClient(host),
  show: show(host),
  update: update(host),
  removePhoto: removePhoto(host),
  getAllPhotosForClient: getAllPhotosForClient(host),
  getFilters: getFilters(host),
  getAllForChannel: getAllForChannel(host),
  mergePeople: mergePeople(host),
  setMainImage: setMainImage(host)
})

import React from 'react';
import ColorPicker from "../../components/Letflow/ColorPicker";
import localization from '../../config/localization';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const HomeElementEventPreferences = ({ activeColor, hoverBackgroundColor, hoverTextColor, onChange, hideDuration }) => {
  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: 15}}>
        <h4>{localization.get('home_editor.event_preferences')}</h4>
        <div style={{display: 'flex', flexDirection: 'row', gap: 15, position: 'relative'}}>
            <div>
                <span style={{marginBottom: 15}}>{localization.get('home_editor.active_color')}</span>
                <ColorPicker
                    popoverStyle={{top: -350}}
                    id={'activeColor'}
                    handleColorChange={color =>
                    onChange({
                        target: {
                        id: "activeColor",
                        value: color
                        }
                    })
                    }
                    startColor={activeColor}
                    disableAlpha={true}
                />
            </div>
            <div>
                <span style={{marginBottom: 15}}>{localization.get('home_editor.hover_background_color')}</span>
                <ColorPicker
                    popoverStyle={{top: -350}}
                    id={'hoverBackgroundColor'}
                    handleColorChange={color =>
                    onChange({
                        target: {
                        id: "hoverBackgroundColor",
                        value: color
                        }
                    })
                    }
                    startColor={hoverBackgroundColor}
                    disableAlpha={true}
                />
            </div>
            <div >
                <span style={{marginBottom: 15}}>{localization.get('home_editor.hover_text_color')}</span>
                <ColorPicker
                    popoverStyle={{top: -350}}
                    id={'hoverTextColor'}

                    handleColorChange={color =>
                    onChange({
                        target: {
                        id: "hoverTextColor",
                        value: color
                        }
                    })
                    }
                    startColor={hoverTextColor}
                    disableAlpha={true}
                />
            </div>
        </div>
        <FormControlLabel
                control={
                <Checkbox
                    style={{color: "#4cc2d5"}}
                    checked={hideDuration}
                    onChange={() => {
                        onChange({
                            target: {
                                id: "hideDuration",
                                value: !hideDuration
                            }
                        })
                    }}
                />
                }
                label={localization.get('home_editor.hide_duration')}
            />
    </div>
  );
};

export default HomeElementEventPreferences;

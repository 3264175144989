import React from "react";
import styled from "styled-components";
import { request } from "../../../api-client";
import { Link, withRouter } from "react-router-dom";
import { 
  channelPathGenerator, 
  slugify, 
  getStoredChannel,
  getStoredUser,
  channelHasPlaylists
} from "../../../api-client/core/authentication/utils";
import localization from "../../../config/localization";
import { makeImageUrlWithSize } from "../../Home/HomeElements/utils";
import { Search as SearchIcon, Person, LibraryMusic, LocalOffer as Tag, Album, QueueMusic, Category, Subscriptions, Audiotrack, People, OndemandVideo, RssFeed, Event, MusicNote, Mic } from "@material-ui/icons";
import TrackTable from "../../../components/Letflow/TrackTable";
import CreationItem from "../../../components/Letflow/CreationItem";
import EventManager, {events} from "../../../utils/EventManager";
import {ReactComponent as PlaylistIcon} from "../../../assets/img/Letflow/playlist_folder.svg";
import {ReactComponent as ArtistIcon} from "../../../components/Letflow/SvgIcons/artist.svg"
import { pushIfDoesNotExist } from "../../../utils";
import { get } from "lodash"
import LoadingSpinner from "../../../assets/img/loading_spinner.gif";


const SearchContainer = styled.div`
  padding: 4px 16px;
  background: var(--main-color, #fff);
  min-height: calc(100vh - 75px);
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    padding: 24px;
  }
`;

const FilterSection = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  background: var(--main-color, #fff);
  margin-bottom: 24px;
  border-bottom: 1px solid var(--border-color, #eee);
`;

const FilterPills = styled.div`
  display: flex;
  gap: 12px;
  overflow-x: auto;
  padding: 16px 5px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;

const FilterPill = styled.button`
  padding: 8px 16px;
  border-radius: 20px;
  border: none;
  background: ${props => !props.active ? 'var(--secondary-color)' : 'var(--secondary-font-color)'};
  color: ${props => !props.active ? 'var(--secondary-font-color)' : 'var(--secondary-color, #000)'};
  fill: ${props => !props.active ? 'var(--secondary-font-color)' : 'var(--secondary-color, #000)'};
  border: 1px solid ${props => !props.active ? 'var(--secondary-font-color)' : 'var(--secondary-color, #000)'};
  font-weight: ${props => props.active ? '600' : '400'};
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  }

  svg {
    font-size: 18px;
  }
`;

const ResultsSection = styled.div`
  margin-bottom: 48px;
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  
  h2 {
    font-size: 24px;
    font-weight: 700;
    color: var(--main-font-color, #000);
    margin: 0;
  }
  
  svg {
    margin-right: 12px;
    color: var(--main-font-color, #000);
  }
`;

const ResultsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 16px;
  
  @media (max-width: 767px) {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    gap: 16px;
    margin: 0 -16px;
    padding: 0 16px;
    
    &::-webkit-scrollbar {
      display: none;
    }
    
    scrollbar-width: none;
  }
`;

const Card = styled(Link)`
  position: relative;
  background: var(--secondary-color, #f5f5f5);
  border-radius: 12px;
  overflow: hidden;
  text-decoration: none;
  transition: all 0.2s ease;
  
  @media (max-width: 767px) {
    flex: 0 0 160px;
    scroll-snap-align: start;
  }
  
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  }
`;

const CardImage = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  background: ${props => props.background || '#333'};
  background-size: cover;
  background-position: center;
`;

const CardContent = styled.div`
  padding: 10px 0px;
  
  @media (min-width: 768px) {
    padding: 16px 5px;
  }
`;

const CardTitle = styled.h3`
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  color: var(--main-font-color, #000);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  
  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

const CardSubtitle = styled.p`
  margin: 4px 0 0;
  font-size: 12px;
  color: var(--secondary-font-color, #666);
  
  @media (min-width: 768px) {
    font-size: 14px;
    margin-top: 8px;
  }
`;

const NoResults = styled.div`
  text-align: center;
  padding: 48px 0;
  color: var(--secondary-font-color, #666);
  
  svg {
    font-size: 48px;
    margin-bottom: 16px;
  }
`;

const TagsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding: 8px 0;
`;

const TagItem = styled(Link)`
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  background: var(--secondary-color, #f5f5f5);
  border-radius: 20px;
  text-decoration: none;
  transition: all 0.2s ease;
  color: var(--secondary-font-color, #666);
  border: 0.5px solid var(--secondary-font-color, #666);
  svg {
    font-size: 16px;
    margin-right: 8px;
    color: var(--secondary-font-color, #666);
  }
  
  &:hover {
    transform: translateY(-2px);
    background: var(--secondary-font-color, #000);
    color: var(--secondary-color, #fff);
    
    svg {
      color: var(--secondary-color, #fff);
    }
  }
`;

const TagName = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

const TagType = styled.span`
  font-size: 12px;
  margin-left: 8px;
  padding-left: 8px;
  border-left: 1px solid currentColor;
`;

const PersonCircle = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  gap: 8px;
  transition: transform 0.2s;
  
  
  &:hover {
    transform: translateY(-4px);
  }
`;

const CircleImage = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background: var(--secondary-color, #f5f5f5);
  border: 3px solid var(--main-font-color);
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: ${props => {
      if (props.boundingBox) {
        const x = props.boundingBox.Left * 100;
        const y = props.boundingBox.Top * 100;
        return `${x}% ${y}%`;
      }
      return 'center';
    }};
    transform: ${props => {
      if (props.boundingBox) {
        const targetHeight = 0.5;
        const scale = targetHeight / props.boundingBox.Height;
        const clampedScale = Math.min(Math.max(scale, 1), 2);
        return `scale(${clampedScale})`;
      }
      return 'scale(1)';
    }};
    transform-origin: ${props => {
      if (props.boundingBox) {
        const x = props.boundingBox.Left * 100;
        const y = props.boundingBox.Top * 100;
        return `${x}% ${y}%`;
      }
      return 'center';
    }};
  }
`;

const PersonName = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color, #000);
  text-align: center;
`;

const PersonRole = styled.span`
  font-size: 12px;
  color: var(--text-color-secondary, #666);
  text-align: center;
`;

const PeopleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 24px;
  
  @media (max-width: 767px) {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    padding: 8px 4px;
    margin: 0 -16px;
    padding: 0 16px;
    
    &::-webkit-scrollbar {
      display: none;
    }
    
    scrollbar-width: none;
    
    ${PersonCircle} {
      flex: 0 0 120px;
      scroll-snap-align: start;
    }
  }
`;

class SearchView extends React.Component {
  state = {
    results: {
      musics: [],
      albums: [],
      artists: [],
      playlists: [],
      savedQueries: [],
      searchResultComponents: [],
      contentCreations: [],
      talentContentCreators: [],
      audiovisualContentCreators: [],
      eventContentCreators: [],
      editorialContentCreators: [],
      contentCreatorsTags: [],
      contentCreationsTags: [],
      musicTags: [],
      people: []
    },
    query: '',
    activeFilter: 'all',
    loading: false,
    lastAvailableFilters: [],
    recentSearches: []
  };

  filters = [
    { id: 'all', label: "Top", icon: <SearchIcon /> },
    { id: 'talent', label: getStoredChannel().purpose === "music" ? localization.get("permissions.speaker") : localization.get("searchbar.content"), icon: <Person /> },
    { id: 'audiovisual', label: localization.get("searchbar.audiovisual"), icon: <OndemandVideo /> },
    { id: 'event', label: localization.get("searchbar.event"), icon: <Event /> },
    { id: 'editorial', label: localization.get("searchbar.editorial"), icon: <RssFeed /> },
    { id: 'contentCreations', label: localization.get("content_creations.materials"), icon: <LibraryMusic /> },
    { id: 'music', label: localization.get("music"), icon: <Audiotrack /> },
    { id: 'albums', label: localization.get("searchbar.albums"), icon: <Album /> },
    { id: 'artists', label: localization.get("searchbar.artists"), icon: <ArtistIcon alt="Artist" style={{ width: "24px", height: "24px", fill: "var(--main-font-color)!important", stroke: "var(--main-font-color)!important" }} /> },
    { id: 'playlists', label: localization.get("searchbar.playlists"), icon: <QueueMusic /> },
    { id: 'people', label: localization.get("people.list"), icon: <People /> },
    { id: 'tags', label: 'Tags', icon: <Tag /> },
    // { id: 'categories', label: localization.get("client.categories"), icon: <Category /> },
  ];

  componentDidMount() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const query = searchParams.get('q');
    this.searchTimer = null;

    if (query && query.length > 2) {
      this.handleSearch(query, searchParams.get('filter') ? searchParams.get('filter') : 'all');
    } else {
      this.setState({ query })
    }
  }

  componentDidUpdate(prevProps) {
    const prevParams = new URLSearchParams(prevProps.location.search);
    const currentParams = new URLSearchParams(this.props.location.search);
    
    const prevQuery = prevParams.get('q');
    const currentQuery = currentParams.get('q');
    
    if (prevQuery !== currentQuery) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }

      if (currentQuery && currentQuery.length > 2) {
        this.searchTimer = setTimeout(() => {
        this.handleSearch(
          currentQuery, 
          currentParams.get('filter') ? currentParams.get('filter') : 'all'
        );
      }, 350);
      } else {
        this.setState({
          results: {
            musics: [],
            albums: [],
            artists: [],
            playlists: [],
            savedQueries: [],
            searchResultComponents: [],
            contentCreators: [],
            contentCreatorsTags: [],
            contentCreationsTags: [],
            contentCreations: [],
            people: [],
            musicTags: [],
          },
          query: currentQuery
        });
      }
    }
  }

  componentWillUnmount() {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
  }
  
  onClick = (item, target) => {
    const channel = getStoredChannel();
    const isLoggedIn = !!getStoredUser();
    
    if (isLoggedIn) {
      request.user.addRecentSearch(channel.id, {
        query: this.state.query,
        searchableId: item.id,
        searchableType: target
      });
    } else {
      this.saveRecentSearchToLocalStorage({
        query: this.state.query,
        model: target,
        searchable: {...item},
        created_at: new Date().toISOString()
      });
    }
  }

  saveRecentSearchToLocalStorage = (searchItem) => {
    try {
      const channel = getStoredChannel();
      const storageKey = `recentSearches_${channel.id}`;
      
      let recentSearches = JSON.parse(localStorage.getItem(storageKey) || '[]');
      
      const existingIndex = recentSearches.findIndex(
        search => search.searchable.id === searchItem.searchable.id && 
                  search.searchable.type === searchItem.searchable.type
      );
      
      if (existingIndex !== -1) {
        recentSearches.splice(existingIndex, 1);
      }
      
      recentSearches.unshift(searchItem);
      
      if (recentSearches.length > 10) {
        recentSearches = recentSearches.slice(0, 10);
      }
      
      localStorage.setItem(storageKey, JSON.stringify(recentSearches));
    } catch (error) {
      console.error('Error saving recent search to localStorage:', error);
    }
  }

  handleSearch2 = async (query, filter = 'all') => {
    this.setState({ loading: true, activeFilter: filter });
    window.scrollTo(0, 0);

    try {
      const channel = getStoredChannel();
      const response = await request.user.globalSearch(
        channel.client.id,
        query,
        channel.id,
        filter
      );


      const results = {
        albums: response.albums || [],
        artists: response.artists || [],
        musics: response.musics || [],
        playlists: response.playlists || [],
        savedQueries: response.savedQueries || [],
        searchResultComponents: response.searchResultComponents || [],
        contentCreations: response.contentCreations || [],
        talentContentCreators: response.talentContentCreators || [],
        audiovisualContentCreators: response.audiovisualContentCreators || [],
        eventContentCreators: response.eventContentCreators || [],
        editorialContentCreators: response.editorialContentCreators || [],
        contentCreatorsTags: response.contentCreatorsTags || [],
        contentCreationsTags: response.contentCreationsTags || [],
        musicTags: response.musicTags || [],
        people: response.people || []
      };

      const hasResultsForFilter = this.checkResultsForFilter(results, filter);

      if (!hasResultsForFilter && this.hasAnyResults(results) && filter !== 'all') {
        const searchParams = new URLSearchParams(this.props.location.search);
        searchParams.set('filter', 'all');
        
        this.props.history.replace({
          pathname: this.props.location.pathname,
          search: searchParams.toString()
        });
        
        this.setState({
          query,
          results,
          activeFilter: 'all'
        });
      } else {
        this.setState({
          query,
          results
        });
      }
    } catch (error) {
      console.error("Search failed:", error);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleSearch = async (query, filter = 'all', onlyFilter = false) => {
    this.setState({ loading: true, activeFilter: filter });
    window.scrollTo(0, 0);

    try {
      const channel = getStoredChannel();
      
      const permissionsMap = {
        contentCreations: {
          allowed: channel.channelContentCreatorTypes.some(
            type => type.show_on_global_search && type.allow_creation_search
          ),
          key: 'contentCreations'
        },
        music: {
          allowed: channel.type !== "content",
          key: 'musics'
        },
        artists: {
          allowed: channel.type !== "content",
          key: 'artists'
        },
        albums: {
          allowed: channel.type !== "content",
          key: 'albums'
        },
        people: {
          allowed: channel.channelContentCreatorTypes.some(
            type => type.show_on_global_search && type.allow_people_search && type.allow_creation_search
          ),
          key: 'people'
        },
        tags: {
          allowed: true, 
          key: ['contentCreatorsTags', 'contentCreationsTags', 'musicTags']
        },
        playlists: {
          allowed: channelHasPlaylists(),
          key: 'playlists'
        },
        talent: {
          allowed: channel.channelContentCreatorTypes.some(
            type => type.show_on_global_search && type.allow_content_search
          ),
          key: 'talentContentCreators'
        },
        audiovisual: {
          allowed: channel.channelContentCreatorTypes.some(
            type => type.show_on_global_search && type.allow_content_search
          ),
          key: 'audiovisualContentCreators'
        },
        event: {
          allowed: channel.channelContentCreatorTypes.some(
            type => type.show_on_global_search && type.allow_event_search
          ),
          key: 'eventContentCreators'
        },
        editorial: {
          allowed: channel.channelContentCreatorTypes.some(
            type => type.show_on_global_search && type.allow_content_search
          ),
          key: 'editorialContentCreators'
        }
      };
      
      const searchTypes = onlyFilter ? 
        [filter] : 
        Object.keys(permissionsMap).filter(type => !!permissionsMap[type].allowed);

      if (onlyFilter && (!permissionsMap[filter] || !permissionsMap[filter].allowed)) {
        console.error(`Filter ${filter} is not allowed`);
        return;
      }

      const searchPromises = searchTypes.map(type => {
        return request.user.globalEntitySearch(type, query, type === filter ? 48 : 8)
      });

      const searchResults = await Promise.all(searchPromises);

      const results = onlyFilter ? { ...this.state.results } : {
        albums: [],
        artists: [],
        musics: [],
        playlists: [],
        savedQueries: [],
        searchResultComponents: [],
        contentCreations: [],
        talentContentCreators: [],
        audiovisualContentCreators: [],
        eventContentCreators: [],
        editorialContentCreators: [],
        contentCreatorsTags: [],
        contentCreationsTags: [],
        musicTags: [],
        people: []
      };

      if (onlyFilter) {
        const mappedKey = permissionsMap[filter].key;
        if (Array.isArray(mappedKey)) {
          mappedKey.forEach(key => {
            results[key] = [];
          });
        } else {
          results[mappedKey] = [];
        }
      }

      searchTypes.forEach((type, index) => {
        const searchResult = searchResults[index];
        const mappedKey = permissionsMap[type].key;

        if (Array.isArray(mappedKey)) {
          mappedKey.forEach(key => {
            if (searchResult[key]) {
              results[key] = [...results[key], ...searchResult[key]];
            }
          });
        } else {
          if (searchResult[mappedKey]) {
            results[mappedKey] = [...results[mappedKey], ...searchResult[mappedKey]];
          }
        }
      });

      const hasResultsForFilter = this.checkResultsForFilter(results, filter);

      if (!hasResultsForFilter && this.hasAnyResults(results) && filter !== 'all') {
        const searchParams = new URLSearchParams(this.props.location.search);
        searchParams.set('filter', 'all');
        
        this.props.history.replace({
          pathname: this.props.location.pathname,
          search: searchParams.toString()
        });
        
        this.setState({
          query,
          results,
          activeFilter: 'all'
        });
      } else {
        this.setState({
          query,
          results
        });
      }
    } catch (error) {
      console.error("Search failed:", error);
    } finally {
      this.setState({ loading: false });
    }
  };

  checkResultsForFilter(results, filter) {
    switch (filter) {
      case 'talent':
        return results.talentContentCreators && results.talentContentCreators.length > 0;
      case 'audiovisual':
        return results.audiovisualContentCreators && results.audiovisualContentCreators.length > 0;
      case 'event':
        return results.eventContentCreators && results.eventContentCreators.length > 0;
      case 'editorial':
        return results.editorialContentCreators && results.editorialContentCreators.length > 0;
      case 'contentCreations':
        return results.contentCreations && results.contentCreations.length > 0;
      case 'music':
        return results.musics && results.musics.length > 0;
      case 'albums':
        return results.albums && results.albums.length > 0;
      case 'artists':
        return results.artists && results.artists.length > 0;
      case 'playlists':
        return results.playlists && results.playlists.length > 0;
      case 'people':
        return results.people && results.people.length > 0;
      case 'tags':
        return (results.contentCreatorsTags && results.contentCreatorsTags.length > 0) ||
               (results.contentCreationsTags && results.contentCreationsTags.length > 0) ||
               (results.musicTags && results.musicTags.length > 0);
      case 'all':
        return this.hasAnyResults(results);
      default:
        return false;
    }
  }

  hasAnyResults(results) {
    return Object.values(results).some(arr => Array.isArray(arr) && arr.length > 0);
  }

  handleFilterChange = (filterId) => {
    const searchParams = new URLSearchParams(this.props.location.search);
    searchParams.set('filter', filterId);
    
    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: searchParams.toString()
    });
    
    this.setState({ activeFilter: filterId }, () => this.handleSearch(this.state.query, filterId, true));
  };

  renderPeople() {
    const { people } = this.state.results;
    if (!people.length || !["people", "all"].includes(this.state.activeFilter)) return null;

    return (
      <ResultsSection>
        <SectionHeader>
          <Person />
          <h2>{localization.get("people.list")}</h2>
        </SectionHeader>
        <PeopleGrid>
          {people.map(person => {
            let boundingBox = get(person, 'mainImage.face_metadata');
            if (boundingBox) {
              boundingBox = boundingBox.boundingBox;
            }

            let contentCreatorType = getStoredChannel().channelContentCreatorTypes.find(contentCreatorType => get(contentCreatorType, "contentCreatorType.id") === person.content_creator_type_id);
            
            if (!contentCreatorType) return null;
            return (
              <PersonCircle
                key={person.id}
                to={channelPathGenerator(`busqueda-inteligente/content_creations/${get(contentCreatorType, "contentCreatorType.slug")}-${slugify(get(contentCreatorType, "contentCreatorType.name"))}?type=${get(contentCreatorType, "contentCreatorType.slug")}&people_ids=${person.id}`)}
              >
                <CircleImage boundingBox={boundingBox}>
                  <img 
                    src={makeImageUrlWithSize({image: person.mainImage}, 'md')} 
                    alt={person.name}
                  />
                </CircleImage>
                <PersonName style={{textAlign: "center"}}>{person.name}</PersonName>
                {person.role && <PersonRole>{person.role}</PersonRole>}
              </PersonCircle>
            );
          })}
        </PeopleGrid>
      </ResultsSection>
    );
  }

  renderContentCreations() {
    const { contentCreations } = this.state.results;
    if (!contentCreations.length || !["contentCreations", "all"].includes(this.state.activeFilter)) return null;

    return (
      <ResultsSection>
        <SectionHeader>
          <Subscriptions />
          <h2>{localization.get("content_creations.materials")}</h2>
        </SectionHeader>
        <ResultsGrid>
          {contentCreations.map(creation => (
            <CreationItem 
              key={creation.id}
              contentCreation={{...creation}}
              hideAuthor={true}
              onPlay={(event) => {
                this.onClick(creation, 'ContentCreation');
                EventManager.getInstance().dispatch(events.OPEN_MATERIAL_MODAL, {id:  creation.id, materialList: contentCreations.map((item, index) => ({index, id: item.id}))});
              }}
              noAuthorLink={true}
            />
          ))}
        </ResultsGrid>
      </ResultsSection>
    );
  }

  getFilteredResults() {
    const { results, activeFilter } = this.state;
    
    switch (activeFilter) {
      case 'talent':
        return { contentCreators: results.talentContentCreators || [] };
      case 'audiovisual':
        return { contentCreators: results.audiovisualContentCreators || [] };
      case 'event':
        return { contentCreators: results.eventContentCreators || [] };
      case 'editorial':
        return { contentCreators: results.editorialContentCreators || [] };
      default:
        return results;
    }
  }

  renderContentCreators() {
    const { activeFilter } = this.state;
    
    if (!["all", "talent", "audiovisual", "event", "editorial"].includes(activeFilter)) {
      return null;
    }
    if (activeFilter === "all") {
      return (
        <>
          {this.renderCreatorsByType("talent")}
          {this.renderCreatorsByType("audiovisual")}
          {this.renderCreatorsByType("event")}
          {this.renderCreatorsByType("editorial")}
        </>
      );
    } else {
      return this.renderCreatorsByType(activeFilter);
    }
  }

  renderCreatorsByType(type) {
    const { results } = this.state;
    let creators = [];
    let title = "";
    let icon = null;
    
    switch (type) {
      case "talent":
        creators = results.talentContentCreators || [];
        title = getStoredChannel().purpose === "music" 
          ? localization.get("permissions.speaker") 
          : localization.get("searchbar.content");
        icon =  getStoredChannel().purpose === "music" ?<Mic/> : <Person />;
        break;
      case "audiovisual":
        creators = results.audiovisualContentCreators || [];
        title = localization.get("searchbar.audiovisual");
        icon = <OndemandVideo />;
        break;
      case "event":
        creators = results.eventContentCreators || [];
        title = localization.get("searchbar.event");
        icon = <Event />;
        break;
      case "editorial":
        creators = results.editorialContentCreators || [];
        title = localization.get("searchbar.editorial");
        icon = <RssFeed />;
        break;
      default:
        return null;
    }
    
    if (!creators.length) return null;
    
    return (
      <ResultsSection key={type}>
        <SectionHeader>
          {icon}
          <h2>{title}</h2>
        </SectionHeader>
        <ResultsGrid>
          {creators.map(creator => (
            <Card
              key={creator.id}
              to={channelPathGenerator(`contenido/${creator.id}-${slugify(creator.name)}?origin=Search`)}
              onClick={() => this.onClick(creator, 'ContentCreator')}
            >
              <CardImage
                style={{borderRadius: type === "talent" ? "50%" : "0px", border: type === "talent" ? "3px solid var(--secondary-font-color, #666)" : "none"}}
                background={`url(${makeImageUrlWithSize(creator, 'md')})`}
              />
              <CardContent style={{paddingLeft: type === "talent" ? "0px" : "5apx"}}>
                <CardTitle style={{textAlign: type === "talent" ? "center" : "left"}}>{creator.name}</CardTitle>
              </CardContent>
            </Card> 
          ))}
        </ResultsGrid>
      </ResultsSection>
    );
  }

  renderMusic() {
    const { musics } = this.state.results;
    
    if (!musics.length || !["music", "all"].includes(this.state.activeFilter)) return null;

    return (
      <ResultsSection>
        <SectionHeader>
          <LibraryMusic />
          <h2>{localization.get("music")}</h2>
        </SectionHeader>
        <TrackTable {...this.props} channel={this.state.channel} type={'music'} tracks={musics} hideSuggestions={true} onPlay={(music) => this.onClick(music, 'Music')} onClick={(music) => this.onClick(music, 'Music')} />
      </ResultsSection>
    );
  }

  renderAlbums() {
    const { albums } = this.state.results;
    if (!albums.length || !["albums", "all"].includes(this.state.activeFilter)) return null;

    return (
      <ResultsSection>
        <SectionHeader>
          <Album />
          <h2>{localization.get("searchbar.albums")}</h2>
        </SectionHeader>
        <ResultsGrid>
          {albums.map(album => (
            <Card
              key={album.id}
              to={channelPathGenerator(`album/${album.id}-${slugify(album.title)}`)}
              onClick={() => this.onClick(album, 'Album')}
            >
              <CardImage
                background={`url(${makeImageUrlWithSize(album, 'md')})`}
              />
              <CardContent>
                <CardTitle>{album.title}</CardTitle>
                <CardSubtitle>{album.type && album.type.name}</CardSubtitle>
              </CardContent>
            </Card>
          ))}
        </ResultsGrid>
      </ResultsSection>
    );
  }

  renderArtists() {
    const { artists } = this.state.results;
    if (!artists.length || !["artists", "all"].includes(this.state.activeFilter)) return null;

    return (
      <ResultsSection>
        <SectionHeader>
          <ArtistIcon alt="Artist" style={{ width: "24px", height: "24px", fill: "var(--main-font-color)!important", stroke: "var(--main-font-color)!important" }} />
          <h2>{localization.get("searchbar.artists")}</h2>
        </SectionHeader>
        <ResultsGrid>
          {artists.map(artist => (
            <Card
              key={artist.id}
              to={channelPathGenerator(`artista/${artist.id}-${slugify(artist.name)}`)}
              onClick={() => this.onClick(artist, 'Artist')}
            >
              <CardImage
                style={{borderRadius: "50%", border: "3px solid var(--secondary-font-color, #666)"}}
                background={`url(${makeImageUrlWithSize(artist.profile ? {image: artist.profile} : artist, 'md')})`} 
              />
              <CardContent>
                <CardTitle style={{textAlign: "center"}}>{artist.name}</CardTitle>
              </CardContent>
            </Card> 
          ))}
        </ResultsGrid>
      </ResultsSection>
    );
  }

  renderPlaylists() {
    const { playlists } = this.state.results;
    if (!playlists.length || !["playlists", "all"].includes(this.state.activeFilter)) return null;

    return (
      <ResultsSection>
        <SectionHeader>
          <QueueMusic />
          <h2>{localization.get("searchbar.playlists")}</h2>
        </SectionHeader>
        <ResultsGrid>
          {playlists.map(playlist => (
            <Card
              key={playlist.id}
              to={channelPathGenerator(`playlist/${playlist.id}`)}
              onClick={() => this.onClick(playlist, 'Playlist')}
              style={{display: "flex", alignItems: "center", flexDirection: "column"}}
            >
              {playlist.image ?
                <CardImage background={`url(${makeImageUrlWithSize(playlist, 'md')})`} /> :
                <div style={{width: "100px", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                  <PlaylistIcon style={{width: "100px", height: "100px", fill: "var(--main-font-color)"}}/>
                </div>
              }
              <CardContent>
                <CardTitle>{playlist.title}</CardTitle>
              </CardContent>
            </Card>
          ))}
        </ResultsGrid>
      </ResultsSection>
    );
  }

  renderTags() {
    const { contentCreatorsTags, contentCreationsTags, musicTags } = this.state.results;

    if ((!contentCreatorsTags.length && !contentCreationsTags.length && !musicTags.length) || !["tags", "all"].includes(this.state.activeFilter)) return null;

    return (
          <ResultsSection key={"tags"}>
            <SectionHeader>
              <Tag />
              <h2>{localization.get("Tags")}</h2>
            </SectionHeader>
            <TagsGrid>
              {contentCreatorsTags.map(tag => (
                <TagItem
                  key={`creator-${tag.id}`}
                to={channelPathGenerator(`busqueda-inteligente/contenido/${tag.group.types[0].id}-${slugify(tag.group.types[0].type)}?type=${tag.group.types[0].type}&tags_ids=${tag.id}`)}
              >
                  <Tag />
                  <TagName>{tag.name}</TagName>
                  <TagType>Contenido</TagType>
                </TagItem>
              ))}
              {contentCreationsTags.map(tag => (
                <TagItem
                  key={`content-${tag.id}`}
                  to={channelPathGenerator(`busqueda-inteligente/content_creations/${tag.group.types[0].id}-${slugify(tag.group.types[0].type)}?type=${tag.group.types[0].type}&tags_ids=${tag.id}`)}
                >
                  <Tag />
                  <TagName>{tag.name}</TagName>
                  <TagType>Materiales</TagType>
                </TagItem>
              ))} 
              {musicTags.map(tag => (
                <TagItem
                  key={`music-${tag.id}`}
                  to={channelPathGenerator(`busqueda-inteligente/musica?tags_ids[]=${tag.id}`)}
                >
                  <Tag />
                  <TagName>{tag.name}</TagName>
                  <TagType>{localization.get("music")}</TagType>
                </TagItem>
              ))}
            </TagsGrid>
          </ResultsSection>
    )
  }

  renderCategories() {
    const { contentCreators } = this.state.results;

    if (!contentCreators.length || !["categories", "all"].includes(this.state.activeFilter)) return null;
    
    const channelCategories = getStoredChannel().channelContentCreatorTypes;
    let categories = []
    contentCreators.forEach(creator => pushIfDoesNotExist(categories, creator.type));

    if (!categories.length) return null;

    return (
      <ResultsSection>
        <SectionHeader>
          <Category />
          <h2>{localization.get("client.categories")}</h2>
        </SectionHeader>
        <ResultsGrid>
          {categories.map(category => {
            const channelCategory = channelCategories.find(c => c.contentCreatorType && c.contentCreatorType.id === category.id);
            const searchTarget = channelCategory.allow_event_search ? 'eventos' : 'contenido';
            return (
            <Card
            key={category.id}
            to={channelPathGenerator(`busqueda-inteligente/${searchTarget}/${category.id}-${slugify(category.name)}?type=${category.slug}&name=${this.state.query}&disablePublishDate=null`)}
            >
              <CardImage
                background={`url(${makeImageUrlWithSize(category, 'md')})`}
              />
              <CardContent>
                <CardTitle>{category.name}</CardTitle>
              </CardContent>
              </Card>
            )
          })}
        </ResultsGrid>
      </ResultsSection>
    );
  }

  getAvailableFilters() {
    const { results } = this.state;
    
    const filters = [
      {
        id: 'all',
        label: 'Top',
        icon: <SearchIcon />,
        show: Object.values(results).some(arr => arr && arr.length > 0)
      },
      {
        id: "music",
        label: localization.get("music"),
        icon: <MusicNote />,
        show: results.musics && results.musics.length > 0
      },
      {
        id: 'contentCreations',
        label: localization.get("content_creations.materials"),
        icon: <Subscriptions />,
        show: (results.contentCreations && results.contentCreations.length > 0)
      },
      {
        id: 'talent',
        label: getStoredChannel().purpose === "music" ? localization.get("permissions.speaker") : localization.get("searchbar.content"),
        icon: getStoredChannel().purpose === "music" ? <Mic /> : <Person />,
        show: results.talentContentCreators && results.talentContentCreators.length > 0
      },
      {
        id: 'audiovisual',
        label: localization.get("searchbar.audiovisual"),
        icon: <OndemandVideo />,
        show: results.audiovisualContentCreators && results.audiovisualContentCreators.length > 0
      },
      {
        id: 'event',
        label: localization.get("searchbar.event"),
        icon: <Event />,
        show: results.eventContentCreators && results.eventContentCreators.length > 0
      },
      {
        id: 'editorial',
        label: localization.get("searchbar.editorial"),
        icon: <RssFeed />,
        show: results.editorialContentCreators && results.editorialContentCreators.length > 0
      },
      {
        id: 'albums',
        label: localization.get("albums"),
        icon: <Album />,
        show: results.albums && results.albums.length > 0
      },
      {
        id: 'artists',
        label: localization.get("searchbar.artists"),
        icon: <ArtistIcon alt="Artist" style={{ width: "24px", height: "24px", fill: "var(--main-font-color)!important", stroke: "var(--main-font-color)!important" }} />,
        show: results.artists && results.artists.length > 0
      },
      {
        id: 'playlists',
        label: localization.get("playlist"),
        icon: <QueueMusic />,
        show: results.playlists && results.playlists.length > 0
      },
      {
        id: 'people',
        label: localization.get("people.list"),
        icon: <People />,
        show: results.people && results.people.length > 0
      },
      // {
      //   id: 'categories',
      //   label: localization.get("title.content_creator_types"),
      //   icon: <Category />,
      //   show: (results.contentCreators && results.contentCreators.length > 0)
      // },
      {
        id: 'tags',
        label: 'Tags',
        icon: <Tag />,
        show: (results.contentCreatorsTags && results.contentCreatorsTags.length > 0) ||
              (results.contentCreationsTags && results.contentCreationsTags.length > 0) ||
              (results.musicTags && results.musicTags.length > 0)
      }
    ];

    const visibleFilters = filters.filter(filter => filter.show);

    return visibleFilters;
  }

  renderFilters() {
    const availableFilters = this.getAvailableFilters();

    return (
      <FilterSection>
        <FilterPills>
          {availableFilters.map(filter => (
            <FilterPill
              key={filter.id}
              active={this.state.activeFilter === filter.id}
              onClick={() => this.handleFilterChange(filter.id)}
            >
              {filter.icon}
              {filter.label}
            </FilterPill>
          ))}
        </FilterPills>
      </FilterSection>
    );
  }

  render() {
    const { loading, query } = this.state;
    const filteredResults = this.getFilteredResults();
    const hasResults = Object.values(filteredResults).some(arr => arr && arr.length > 0);



    if (!query || query.length < 3) {
      return (
        <SearchContainer>
           {loading ? (
          <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%", minHeight: "300px"}}>
            <img style={{width: 100, height: 100}} src={LoadingSpinner} alt="Loading logo"/>
          </div>
        ) : (!query || query.length === 0) && (
          <NoResults>
            <SearchIcon />
            <h3>{localization.get("searchbar.write_to_search")}</h3>
          </NoResults>
        )}
        </SearchContainer>
      )
    }

    return (
      <SearchContainer>
        {this.renderFilters()}
        
        {loading ? (
          <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%", minHeight: "300px"}}>
            <img style={{width: 100, height: 100}} src={LoadingSpinner} alt="Loading logo"/>
          </div>
        ) : !hasResults ? (
          <NoResults>
            <SearchIcon />
            <h3>{localization.get("searchbar.no_result")}</h3>
          </NoResults>
        ) : (
          <>
            {this.renderMusic()}
            {this.renderContentCreations()}
            {this.renderContentCreators()}
            {this.renderAlbums()}
            {this.renderArtists()}
            {this.renderPlaylists()}
            {this.renderPeople()}
            {/* {this.renderCategories()} */}
            {this.renderTags()}
          </>
        )}
      </SearchContainer>
    );
  }
}

export default withRouter(SearchView); 
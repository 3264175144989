import "./TrackTable.css";
import React, {Component} from "react";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {
  getMostImportantAlbum,
  makeImageUrl,
  makeUrlImageFromAlbums,
  makeWaveformUrl,
  secondsToMMSS
} from '../../../utils'
import {Link} from "react-router-dom";
import {request} from "../../../api-client";
import SoundWave from "../../../assets/img/Letflow/sound-wave.png";
import GlobalSnackbar, {GlobalSnackbarTypes} from "../Snackbar/GlobalSnackbar";
import localization from "../../../config/localization";
import noImage from "../../../assets/img/Letflow/no-image.jpg";
import GlobalSoundBar from "../../../sound-bar/GlobalSoundBar";
import {
  clientCanLicense,
  getActiveChannel,
  getActiveClient,
  getSignatureTime,
  homePathGenerator,
  channelPathGenerator,
  userCanAddTracksToPlaylists,
  userCanDownloadTracks,
  userCanLicenseTracks,
  userIsSysAdmin,
  slugify,
  channelCanAddToFavorites,
  getStoredChannel,
  userisMultiClient,
  getUseTools, getChannelCanLicense, getStoredUser, getMainTranslation,
  channelHasPlaylists, channelHasLicenses, userIsMusicSupervisor, channelDisableShare
} from "../../../api-client/core/authentication/utils";
import {Dialog, DialogContent, DialogTitle, Tooltip, Typography, MenuItem} from "@material-ui/core";
import EventManager from "../../../utils/EventManager";
import events from "../../../utils/EventManager/events";
import GlobalAddToPlaylistDialog from "../AddToPlaylistDialog/GlobalAddToPlaylistDialog";
import GlobalLicenseWizardDialog from "../Dialog/GlobalLicenseWizardDialog";
import browserHistory from "../../../utils/browserHistory";
import {withStyles} from "@material-ui/core/styles";
import ErrorBoundary from "../ErrorBoundary";
import {isNullOrUndefined} from "util";
import ChannelMusic from "../../../layouts/Channel/Music"
import moment from 'moment';
import {
  AddShoppingCart,
  CloudDownload,
  KeyboardArrowDown,
  KeyboardArrowUp, Pause,
  PlayArrow as Play,
  PlaylistAdd,
  RemoveCircle, Share,
  Star,
  StarBorder
} from "@material-ui/icons";
import qs from "qs";
import TrackTableSubComponent from "./TrackTableSubComponent";
import Options from "../../../sound-bar/components/Options";
import Artist from "../../../layouts/Home/Artist";
import Album from "../../../layouts/Home/Album";
import Speaker from "../../../layouts/Home/Speaker";
import {makeImageUrlWithSize, emptyImage, onImageError} from "../../../layouts/Home/HomeElements/utils";
import AddToPlaylist from "../AddToPlaylist";
import analyticsInstance from "../../../analytics/analyticsInstance";
import Progress from "../../../sound-bar/components/Progress";
import {get} from "lodash"
import { DownloadContext } from '../../../contexts/DownloadContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Queue } from '@material-ui/icons';

class TrackTable extends React.Component {
  static contextType = DownloadContext;

  constructor(props) {
    super(props);
    this.state = {
      tracks: this.props.tracks.data || this.props.tracks,
      playing: null,
      type: this.props.type || "music",
      trackContainer: this.props.trackContainer || null,
      suggestions: [],
      showLicenseDialogForTrialUsers: false,
      openLicenseDialog: false,
      licenses: [],
      expandedVersionsIndex: null,
      selectedTrack: null,
      channel: props.channel || null,
      playingTime: 0,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ tracks: nextProps.tracks.data || nextProps.tracks, suggestions: [] }, () => {
      this.setSoundbarSongAsPlayingIfPresent();
      !this.props.hideSuggestions && this.fetchSuggestions();
    });
  }

  componentDidMount = () => {
    this.setState({innerWidth: window.innerWidth})
    this.trackPausedSubscription = GlobalSoundBar.trackPaused$().subscribe(this.handleTrackPausedViaSoundbar);
    this.trackStartedPlayingSubscription = GlobalSoundBar.trackStartedPlaying$().subscribe(this.handleTrackStartedPlayingInSoundbar);
    
    this.setSoundbarSongAsPlayingIfPresent();
    (!this.props.hideSuggestions || isNullOrUndefined(this.props.hideSuggestions)) && this.fetchSuggestions();

    window.addEventListener('resize', () => this.setState({innerWidth: window.innerWidth}))

    this.refreshInterval = setInterval(() => {
        this.setState({playingTime: GlobalSoundBar.currentPlaylingTime()})
    }, 200)
  };

  fetchSuggestions = () => {
    // dont fetch if client is in smart_search screen
    if (this.props.match.path.includes("smart_search") || this.props.match.path.includes("public")) return;
    const tracks = this.state.tracks;
    const musics = tracks.filter(x => (x.type !== "voice" && x.type !== "contentCreation"));
    if (musics.length > 0) {
      const ids = musics.map(x => x.id);
      const clientId = getActiveClient();
      request.music.suggestions(ids, clientId).then(x => this.setState({ suggestions: x }));
    }
  };

  setSoundbarSongAsPlayingIfPresent = () => {
    if (!GlobalSoundBar.isPlaying()) {
      return;
    }

    const alreadyPlayingTrackInSoundbar = this.state.tracks.find(track =>
      GlobalSoundBar.currentTrackIsTitled(track.title)
    );

    if (alreadyPlayingTrackInSoundbar) {
      this.setState({ playing: alreadyPlayingTrackInSoundbar.id });
    }
  };

  handleTrackPausedViaSoundbar = () => {
    const { tracks } = this.state;
    const pausableTrackFromOwnTracks = tracks.find(track => GlobalSoundBar.currentTrackIsTitled(track.title));

    if (pausableTrackFromOwnTracks) {
      this.setState({ playing: null });
    }
  };

  handleTrackStartedPlayingInSoundbar = () => {
    const { tracks } = this.state;
    const playableTrackFromOwnTracks = tracks.find(track => GlobalSoundBar.currentTrackIsTitled(track.title));
    
    if (playableTrackFromOwnTracks) {
      this.setState({ playing: playableTrackFromOwnTracks.id, soundbarOpen: true });
    } else {
      this.setState({ playing: null });
    }
  };

  componentWillUnmount = () => {
    this.trackPausedSubscription.unsubscribe();
    this.trackStartedPlayingSubscription.unsubscribe();
    window.removeEventListener('resize', () => this.setState({innerWidth: window.innerWidth}))

    clearInterval(this.refreshInterval)
  };

  handlePlayButtonPress = (track, trackContainer) => () => {
    const { tracks, type } = this.state;

    if (this.props.onPlay) {
      this.props.onPlay(track);
    }

    analyticsInstance.playContent({
      name: getMainTranslation(track, "title"),
      type: "Audio",
      content: trackContainer && trackContainer.contentCreator && getMainTranslation(trackContainer.contentCreator, "name")
    })

    const soundbarCompatibleTracks = tracks.map(track => {
      return {
        fetchAudioUrl: () => new Promise(resolve => {
          let url = '';
          const date = new Date()
          const now_utc = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

          if((now_utc - Date.parse(track.time)) < getSignatureTime()) {
            url = (!getActiveClient() && track.demo) ? track.demo.url : track.audio.url
          }else{
            url = (track.type === 'voice' || type === 'voice') ?
              request.voice.getForClient(track.id).then(track => track.audio.url) :
              this.trackIsAContentCreation(track) ?
                (getStoredUser() ? request.contentCreation.get(track.id, "include=audio:url").then(track => track.audio.url) : request.contentCreation.getPublic(track.hashcode).then(track => track.audio.url)) :
                (getActiveClient() ?
                  request.music.getForClient(track.id).then(track => track.audio.url) :
                  request.music.getPublic(track.id).then(track => track.demo.url))
          }

          resolve(url);
        }),
        id: track.id,
        title: track.title,
        subtitle: (track.albums && track.albums.length > 0) ? getMostImportantAlbum(track.albums).title : this.trackIsAContentCreation(track) ? track.subtitle : trackContainer.title,
        image: this.makeSoundbarTrackImage(track, trackContainer),
        waveform: makeWaveformUrl(track),
        isFavorite: track.favorite,
        onToggleFavorite: !this.trackIsAVoice(track) && !this.trackIsAContentCreation(track)
          ? state => (state ? this.addToFavorites(track) : this.removeFromFavorites(track))
          : undefined,
        onDownload: userCanDownloadTracks() ? () => this.openDownloadDialog(track, trackContainer) : undefined,
        onAddToPlaylist: (!getActiveChannel() || channelHasPlaylists()) && userCanAddTracksToPlaylists()  && !this.trackIsAContentCreation(track)
          ? () =>
            GlobalAddToPlaylistDialog.show({
              type: track.type || this.props.type || this.state.type,
              clientId: getActiveClient(),
              selectedTrack: track,
            })
          : undefined,
        playlistButtonData: (!getActiveChannel() || channelHasPlaylists()) && userCanAddTracksToPlaylists()  && !this.trackIsAContentCreation(track)
          ? 
          {
            type: track.type || this.props.type || this.state.type,
            clientId: getActiveClient(),
            selectedTrack: track,
          }
          :
          undefined,
        onLicense:
          !this.trackIsAVoice(track) && !this.trackIsAContentCreation(track) && userCanLicenseTracks()
            ? () => this.openLicenseWizard(track, trackContainer)
            : !this.trackIsAVoice(track) && !this.trackIsAContentCreation(track) ? () => this.setState({showLicenseDialogForTrialUsers: true})
            : undefined,
        onTitleClick: !this.trackIsAVoice(track) && !this.trackIsAContentCreation(track) ? () => this.props.history.push(getActiveChannel() ? channelPathGenerator(`musica/${track.id}-${slugify(track.title || track.name)}`) : homePathGenerator(`music/${track.id}`)) : undefined,
        onSubtitleClick: () => this.handleTrackContainerPressedInSoundbar(track, trackContainer),
      };
    });

    const playableTrackIndex = tracks.indexOf(track);

    this.setState({ playing: track.id });

    GlobalSoundBar.loadTracksAndPlay(soundbarCompatibleTracks, playableTrackIndex);
  };

  openLicenseWizard = (track, trackContainer) =>
      GlobalLicenseWizardDialog.show({
        track: {
          id: track.id,
          title: track.title,
          owner: track.owner,
          waveform: makeWaveformUrl(track),
          image: this.makeSoundbarTrackImage(track, trackContainer),
        },
        client: GlobalLicenseWizardDialog.mapApiClient(get(getStoredChannel(), "client")),
      });

  makeSoundbarTrackImage = (track, trackContainer) => {
    if (track.albums && track.albums.length > 0) {
      return makeUrlImageFromAlbums(track.albums);
    } else if (this.trackIsAContentCreation(track)) {
        return makeImageUrlWithSize(trackContainer.contentCreator, 'sm');
    }
    return makeImageUrlWithSize(trackContainer, 'sm');
  };

  openDownloadDialog = (track, trackContainer) => this.handleDownload(track, trackContainer)

  handleDownload = async (track, trackContainer) => {
    this.context.startDownload(track.id);
    
    try {
      let type = track.contentCreation ? "contentCreation"
        : track.type
          ? track.type
          : trackContainer
            ? this.props.type || this.state.type
            : "music";

      if (type == 'music') {
        await request[type]["downloadDemo"](track.id, {
          title: track.title,
          image: this.makeSoundbarTrackImage(track, trackContainer),
        });
      } else {
        await request.contentCreation.getDownloadLink(track.id)
          .then(response => window.open(response.url, '_blank'));
      }
    } finally {
      this.context.finishDownload(track.id);
    }
  };

  openLicensesDialog = track => {
    request.music.getForClient(track.id, 'include=licenses(10).client;licenses.project;licenses.type;licenses.user;licenses.brand;reservedBy')
      .then(track => this.setState({
        openLicenseDialog: true,
        licenses: track.licenses
      }))
  }

  makeLicenseDialog = () =>
    <Dialog open={this.state.openLicenseDialog} onBackdropClick={() => this.setState({openLicenseDialog: false})} onClose={() => this.setState({openLicenseDialog: false})}>
      {this.state.licenses.length > 0 ?
        <div style={{float: "left", position: "relative", width: "100%"}}>
          <table id="music-table">
            <tr>
              <th>{localization.get('music.license_table.date')}</th>
              <th>{localization.get(userIsMusicSupervisor() ? "brand" : 'music.license_table.client')}</th>
              <th>{localization.get('music.license_table.type')}</th>
              <th>{localization.get('music.license_table.brand')}</th>
              <th>{localization.get('music.license_table.campaign')}</th>
              <th>{localization.get('user')}</th>
              <th>{localization.get('license_wizard.exclusivity_reason')}</th>
            </tr>
            {this.state.licenses.map(license => {
              return (
                <tr>
                  <td>{moment(license.starts_at).format('DD-MM-YYYY')}</td>
                  <td style={{textDecoration: license.client.deleted_at ? "line-through" : "none"}}>
                    {userIsMusicSupervisor() ? license.brand.name : (license.client.deleted_at ? `${license.client.name} (${localization.get('deleted')})` : license.client.name)}
                    </td>
                  <td>{license.type.name}</td>
                  <td>{license.project.brand}</td>
                  <td>{license.project.title}</td>
                  <td>{license.user.name}</td>
                  <td>{license.exclusivity_reason}</td>
                </tr>
              )
            })}
          </table>
        </div>
        :
        <DialogContent style={{
          width: "400px",
          textAlign: "center",
          fontSize: "22px",
          height: "100px",
          lineHeight: "50px"
        }}>
          {localization.get('track_is_not_licensed')}
        </DialogContent>
      }
    </Dialog>

  trackIsAVoice = track =>
    track.type === "voice" || track.speaker || this.props.type === "voice" || this.state.type === "voice";

  trackIsAContentCreation = track =>
    track.type === "contentCreation" || track.contentCreation || this.props.type === "contentCreation" || this.state.type === "contentCreation";

  trackIsAMusic = track =>
    track.type === "music" || track.artist || track.albums || this.props.type === "music" || this.state.type === "music";

  handleTrackContainerPressedInSoundbar = (track, trackContainer) => {
    let destinationPath = this.makeTrackContainerPathFrom(track, trackContainer);

    if (destinationPath) {
      this.props.history.push(destinationPath);
    }
  };

  makeTrackContainerPathFrom = (track, trackContainer) => {
    let destinationPath;

    if (track.albums && track.albums.length > 0) {
      destinationPath = getActiveClient() ? channelPathGenerator(`album/${getMostImportantAlbum(track.albums).id}`) : `album/${getMostImportantAlbum(track.albums).id}`;
    } else if (trackContainer && track.type === "music") {
      destinationPath = getActiveClient() ? channelPathGenerator(`album/${trackContainer.id}`) : `album/${trackContainer.id}`;
    } else if (trackContainer && track.type === "contentCreation") {
      destinationPath = getActiveClient() ? channelPathGenerator(`contenido/${trackContainer.contentCreator.id}-${slugify(trackContainer.contentCreator.name)}`) : `content_creator/${trackContainer.id}`;
    }

    return destinationPath;
  };

  handlePauseButtonPress = () => {
    this.setState({ playing: null });
    GlobalSoundBar.pause();
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ open: false });
  };

  addToFavorites = track => {
    EventManager.getInstance().dispatch(events.ADD_TRACK_TO_FAVORITES, track);
    this.handleTrackFavoritePropertyUpdate(track, true);
  };

  removeFromFavorites = track => {
    EventManager.getInstance().dispatch(events.REMOVE_TRACK_FROM_FAVORITES, track);
    this.handleTrackFavoritePropertyUpdate(track, false);
  };


  handleTrackFavoritePropertyUpdate = (track, isFavorite) => {
    this.setIsFavoritePropertyFromStateTrack(track.id, isFavorite);
    this.updateStateTracks(this.getTracksFromState());
    this.requestTrackIsFavoriteUpdate(track, isFavorite).catch(() => {
      this.setIsFavoritePropertyFromStateTrack(track.id, !isFavorite);
      this.updateStateTracks(this.getTracksFromState());
    });
  };

  setIsFavoritePropertyFromStateTrack = (id, value) => {
    const trackWithId = this.getSingleTrackFromStateWithId(id);

    if (trackWithId) {
      if(getActiveChannel()) {
        trackWithId.channelFavorite = value
      }else {
        trackWithId.favorite = value
      };
      GlobalSoundBar.setIsFavoriteFromTrackWithTitle(trackWithId.title, value);
    }
  };

  getSingleTrackFromStateWithId = trackId => this.getTracksFromState().find(track => track.id === trackId);

  getTracksFromState = () => this.state.tracks.data || this.state.tracks;

  updateStateTracks = tracks =>
    this.state.tracks.data
      ? this.setState({ tracks: { ...this.state.tracks, data: tracks } })
      : this.setState({ tracks: tracks });

  requestTrackIsFavoriteUpdate = (track, isFavorite) => {
    const trackId = track.id;
    analyticsInstance.favorite({
      name: getMainTranslation(track, "title"),
      type: this.props.type === 'contentCreation' ?  "Material" : "Music",
      content: isFavorite ? "Add" : "Remove"
    })

    if(getActiveChannel()) {
      if(this.props.type =='contentCreation') {
        return request.contentCreationChannelFavorite[isFavorite ? "add" : "remove"](trackId);
      }
      return request.musicChannelFavorite[isFavorite ? "add" : "remove"](trackId);
    }else {
      if(this.props.type =='contentCreation') {
        return request.contentCreationFavorite[isFavorite ? "add" : "remove"](trackId);
      }
      return request.musicFavorite[isFavorite ? "add" : "remove"](trackId);
    }
  };

  handleRemoveFromPlaylistButtonPress = track => {
    let type = this.trackIsAVoice(track) ? "voices" : this.trackIsAContentCreation(track) ? "contentCreations" : "musics";
    let musicId = track.id;
    let playlistId = this.props.match.params.playlistId
    request.playlist
      .removeFromPlaylist({ type, playlistId, musicId })
      .then(() => {
        analyticsInstance.playlist({
          playlist: this.props.playlist ? this.props.playlist.title : "",
          type: "Music",
          content_name: track.title,
          action: "Remove",
        });
        GlobalSnackbar.show({ message: localization.get("playlist.remove"), type: GlobalSnackbarTypes.SUCCESS });
      })
      .then(() => this.removeTrackFromList(musicId));
  };

  removeTrackFromList = musicId => {
    let tracks = this.state.tracks.filter(track => track.id !== musicId);
    this.setState({ tracks });
  };

  licenseDialogForTrialUsers = () =>
    <Dialog open={this.state.showLicenseDialogForTrialUsers} onBackdropClick={() => this.setState({showLicenseDialogForTrialUsers:false})} onEscapeKeyDown={() => this.setState({showLicenseDialogForTrialUsers:false})}>
      <DialogTitle>{localization.get("license_wizard.licenses")}</DialogTitle>
      <DialogContent>
        <div>{localization.get('license_wizard.trial_cant_license')}</div>
      </DialogContent>
    </Dialog>

  handleModalClose = () => {
    this.setState({showModal: false, selectedTrack: '', showArtist: false, speaker: '', album: '', artist: ''})
  }

  renderShowModal = () =>
    <Dialog
      disableEnforceFocus
      style={{zIndex: 999}}
      open={this.state.showModal}
      onEscapeKeyDown={this.handleModalClose}
      fullScreen={true}
    >
      {this.state.selectedTrack && this.trackIsAMusic(this.state.selectedTrack) && !this.state.showArtist &&
          <ChannelMusic fromHome={this.props.fromHome} musicId={this.state.selectedTrack.id} client={this.props.client} modal={true} onModalClose={this.handleModalClose} {...this.props} />
      }
      {(this.state.artist && this.state.showArtist) &&
        <Artist fromHome={this.props.fromHome} artistId={this.state.artist.id} client={this.props.client} modal={true} onModalClose={this.handleModalClose} {...this.props}/>
      }
      {(this.state.album && !this.state.showArtist) &&
        <Album fromHome={this.props.fromHome} albumId={this.state.album.id} client={this.props.client} modal={true} onModalClose={this.handleModalClose} {...this.props}/>
      }
      {this.state.speaker &&
        <Speaker fromHome={this.props.fromHome} speakerId={this.state.speaker.id} client={this.props.client} modal={true} onModalClose={this.handleModalClose} {...this.props}/>
      }
    </Dialog>

    getOptions = (key, track) => {
      const options = [{label: localization.get("see_licenses"), onClick: () => this.openLicensesDialog(track)}]

      if (userIsSysAdmin() || userisMultiClient()){ 
        options.push(
          {builder: (key) => 
          <AddToPlaylist
            key={key}
            item={track}
            useGlobalPlaylists
            icon={<MenuItem>{localization.get("add_to_global_playlist")}</MenuItem>}
          />
        })
      }

      return options;
    } 


  getTrackSubtitle = (track, trackContainer) => {
      if(this.props.useAlbum && track.albums && track.albums.length > 0) {
        return <Link
          style={{color: this.props.color ? this.props.color : (getActiveChannel() ? getStoredChannel().preferences.title.color :  '#2b2b2b')}}
          className="track-table-name-auxiliary"
          to={this.props.fromHome ? '#' : (getActiveChannel() ? channelPathGenerator(`album/${getMostImportantAlbum(track.albums).id}`) : homePathGenerator(`album/${getMostImportantAlbum(track.albums).id}`))}
          onClick={e => {
            if (this.props.fromHome) {
              e.preventDefault()
              if (e.ctrlKey) {
                window.open(getActiveChannel() ? channelPathGenerator(`album/${getMostImportantAlbum(track.albums).id}`) : homePathGenerator(`album/${getMostImportantAlbum(track.albums).id}`))
              } else {
                this.setState({showModal: true, album: getMostImportantAlbum(track.albums)})
              }
            }
          }}
        >
          {getMostImportantAlbum(track.albums).title}
        </Link>
      }


    return track.artist ? (
      <React.Fragment>
        <Link
          style={{color: this.props.color ? this.props.color : (getActiveChannel() ? getStoredChannel().preferences.title.color :  '#3c4858')}}
          className="track-table-name-auxiliary"
          to={this.props.fromHome ? '#' : getActiveClient() ? (getActiveChannel() ? channelPathGenerator(`artista/${track.artist.id}-${slugify(track.artist.name)}`) : homePathGenerator(`artist/${track.artist.id}`)) : `/public/artist/${track.artist.id}`}
          onClick={(e) => {
            if (this.props.fromHome) {
              e.preventDefault()
              if (e.ctrlKey && getActiveClient()) {
                window.open(getActiveChannel() ? channelPathGenerator(`artista/${track.artist.id}-${slugify(track.artist.name)}`) : homePathGenerator(`artist/${track.artist.id}`))
              } else if (e.ctrlKey && !getActiveClient()) {
                window.open(`/public/artist/${track.artist.id}`)
              } else {
                this.setState({showModal: true, artist: track.artist, showArtist: true})
              }
            }
          }}
        >
          by {track.artist.name}
        </Link>
      </React.Fragment>
    ) :  track.albums && track.albums.length > 0 ? (
      getActiveClient() ?
        <Link
          style={{color: this.props.color ? this.props.color : (getActiveChannel() ? getStoredChannel().preferences.title.color :  '#2b2b2b')}}
          className="track-table-name-auxiliary"
          to={this.props.fromHome ? '#' : (getActiveChannel() ? channelPathGenerator(`album/${getMostImportantAlbum(track.albums).id}`) : homePathGenerator(`album/${getMostImportantAlbum(track.albums).id}`))}
          onClick={e => {
            if (this.props.fromHome) {
              e.preventDefault()
              if (e.ctrlKey) {
                window.open(getActiveChannel() ? channelPathGenerator(`album/${getMostImportantAlbum(track.albums).id}`) : homePathGenerator(`album/${getMostImportantAlbum(track.albums).id}`))
              } else {
                this.setState({showModal: true, album: getMostImportantAlbum(track.albums)})
              }
            }
          }}
        >
          {getMostImportantAlbum(track.albums).title}
        </Link>
        :
        <div>{getMostImportantAlbum(track.albums).title}</div>
    ) : trackContainer && track.type === "music" ? (
      getActiveClient() ?
        <Link
          style={{color: this.props.color ? this.props.color : (getActiveChannel() ? getStoredChannel().preferences.title.color :  '#2b2b2b') }}
          className="track-table-name-auxiliary"
          to={getActiveChannel() ? channelPathGenerator(`album/${trackContainer.id}`) : homePathGenerator(`album/${trackContainer.id}`)}
        >
          {trackContainer.title}
        </Link> :
        <div>{trackContainer.title}</div>
    ) : track.subtitle ? (
        <div style={{color: '#333333', alignSelf: 'baseline'}}>{track.subtitle}</div>
    ): null;
  }

  renderDownloadButton = (track, trackContainer) => {
    const isDownloading = this.context.isDownloading(track.id);
    const isQueued = this.context.isQueued(track.id);

    return (
      <Tooltip 
        placement="top" 
        title={
          isQueued ? localization.get('download.queued') :
          isDownloading ? localization.get('download.in_progress') :
          this.trackIsAMusic(track) ? localization.get('download.demo') : 
          localization.get('download')
        }
      >
        {isDownloading ? (
          <CircularProgress
            size={24}
            style={{
              color: this.props.color || (getActiveChannel() ? getStoredChannel().preferences.title.color : '#2b2b2b'),
              margin: "0 5px"
            }}
          />
        ) : isQueued ? (
          <Queue 
            style={{
              color: this.props.color || (getActiveChannel() ? getStoredChannel().preferences.title.color : '#2b2b2b')
            }}
            className="track-table-action-icon"
          />
        ) : (
          <CloudDownload
            onClick={() => this.handleDownload(track, trackContainer)}
            style={{
              color: this.props.color || (getActiveChannel() ? getStoredChannel().preferences.title.color : '#2b2b2b')
            }}
            className="track-table-action-icon"
          />
        )}
      </Tooltip>
    );
  }

  render = () => {
    const tracks = this.state.tracks.data || this.state.tracks;
    const {trackContainer, expandedVersionsIndex} = this.state;

    return (
      <React.Fragment>
        {this.renderShowModal()}
        {tracks.map((track, index) => (
          <>
            <div className="track-table-row">
              {((!getActiveChannel() && getActiveClient()) || (getActiveChannel() && channelCanAddToFavorites() && !!getStoredUser())) &&
              <div className="track-table-favorite">
                {(this.trackIsAVoice(track) || this.trackIsAContentCreation(track)) && this.props.type !== "contentCreation" ? (
                  <span>&nbsp;</span>
                ) : (!getActiveChannel() ?
                  <>
                    {track.favorite ? (
                      <Tooltip placement="bottom" title={localization.get("button.remove_from_favorites")}>
                        <IconButton
                          onClick={() => this.removeFromFavorites(track)}
                          className="track-table-remove-from-favorites-button"
                        >
                          <Star style={{color: this.props.color ? this.props.color : (getActiveChannel() ? getStoredChannel().preferences.title.color :  '#2b2b2b')}} />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip placement="bottom" title={localization.get("ranking_config.favorites_add")}>
                        <IconButton
                          className="track-table-add-to-favorites-button"
                          onClick={() => {
                            // GAevent('Contenido', "Favorite", track.title)
                            this.addToFavorites(track)
                          }}
                        >
                          <StarBorder style={{color: this.props.color ? this.props.color : (getActiveChannel() ? getStoredChannel().preferences.title.color :  '#2b2b2b')}} />
                        </IconButton>
                      </Tooltip>
                    )
                    }
                  </>
                :
                  <>
                    {track.channelFavorite ? (
                      <Tooltip placement="bottom" title={localization.get("button.remove_from_favorites")}>
                        <IconButton
                          onClick={() => this.removeFromFavorites(track)}
                          className="track-table-remove-from-favorites-button"
                        >
                          <Star style={{color: this.props.color ? this.props.color : (getActiveChannel() ? getStoredChannel().preferences.title.color :  '#2b2b2b')}} />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip placement="bottom" title={localization.get("ranking_config.favorites_add")}>
                        <IconButton
                          className="track-table-add-to-favorites-button"
                          onClick={() => {
                            // GAevent('Contenido', "Favorite", track.title)
                            this.addToFavorites(track)
                          }}
                        >
                          <StarBorder style={{color: this.props.color ? this.props.color : (getActiveChannel() ? getStoredChannel().preferences.title.color :  '#2b2b2b')}} />
                        </IconButton>
                      </Tooltip>
                    )
                    }
                  </>
                )}
              </div>
              }
              <div className="track-table-image-container">
                {!this.props.hideImage ?
                  <div
                    className="track-table-image"
                    style={{
                      backgroundImage: track.singer
                        ? `url(${makeImageUrl(track.singer, 100, 100, 'grayscale')}), url(${emptyImage})`
                        : track.speaker
                          ? `url(${makeImageUrl(track.speaker, 100, 100, 'grayscale')}), url(${emptyImage})`
                          : track.albums && track.albums.length > 0
                            ? `url(${makeUrlImageFromAlbums(track.albums)}), url(${emptyImage})`
                            : trackContainer
                              ? `url(${makeImageUrlWithSize(trackContainer, 'xs')}), url(${emptyImage})`
                              : `url(${noImage})`,
                    }}
                  >
                    {this.state.playing === track.id ? (
                      <IconButton onClick={this.handlePauseButtonPress} className="track-table-pause-button">
                        <Pause className="track-table-pause-icon"/>
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={this.handlePlayButtonPress(
                          track,
                          track.albums ? getMostImportantAlbum(track.albums)
                            : trackContainer ? trackContainer : null
                        )}
                        className="track-table-play-button"
                      >
                        <Play className="track-table-play-icon"/>
                      </IconButton>
                    )}
                  </div>
                  :
                  <div className="track-table-index" style={{color: this.props.color ? this.props.color : "var(--main-font-color, #2b2b2b)"}}>
                    {index + 1}
                    {this.state.playing === track.id ? (
                      <IconButton onClick={this.handlePauseButtonPress} className="track-table-pause-button">
                        <Pause className="track-table-pause-icon"/>
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={this.handlePlayButtonPress(
                          track,
                          track.albums
                            ? getMostImportantAlbum(track.albums)
                            : trackContainer ? trackContainer : null
                        )}
                        className="track-table-play-button"
                      >
                        <Play className="track-table-play-icon"/>
                      </IconButton>
                    )}
                  </div>
                }
              </div>

              <div className={`track-table-name-container ${(this.trackIsAVoice(track) || this.trackIsAContentCreation(track)) ? 'voice' : ''}`}>
                {this.trackIsAContentCreation(track) ? (
                  <div
                    style={{
                      alignSelf: track.subtitle && 'end',
                      color: this.props.color ? this.props.color : (getActiveChannel() ? getStoredChannel().preferences.title.color :  '#3c4858')
                  }}
                    className="track-table-name"
                    onClick={this.handlePlayButtonPress(track, track.contentCreator ? track.contentCreator : null)}
                  >
                    {track.title}
                  </div>
                ) : (
                  <Link
                    to={(getActiveClient() && !this.props.fromHome) ? channelPathGenerator(`musica/${track.id}-${slugify(track.title)}`) : '#'}
                    onClick={(e) => {
                      this.props.onClick && this.props.onClick(track)
                      if (getActiveClient() && this.props.fromHome) {
                        e.preventDefault()
                        if (e.ctrlKey) {
                          window.open(channelPathGenerator(`musica/${track.id}-${slugify(track.title)}`))
                        } else {
                          this.setState({showModal: true, selectedTrack: track})
                        }
                      }
                      
                    }}
                  >
                    <div
                      style={{
                        float: "left",
                        position: "relative",
                        width: "100%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        color: this.props.color ? this.props.color : (getActiveChannel() ? getStoredChannel().preferences.title.color :  '#3c4858')
                      }}
                    >
                      {track.title}
                    </div>

                  </Link>
                )}

                {this.getTrackSubtitle(track, trackContainer)}

                {track.versions_count > 0 &&
                <IconButton
                  className="track-table-show-versions-button"
                  onClick={() => {
                    this.setState({expandedVersionsIndex: expandedVersionsIndex === index ? null : index})
                  }}
                >
                  {expandedVersionsIndex === index ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                </IconButton>
                }
              </div>
              {!this.trackIsAVoice(track) &&
                <div className="track-table-sound-wave-container" >
                  <Progress
                    key={track.id}
                    imageSrc={(track.audio && track.audio.waveform )? `${process.env.REACT_APP_CDN_HOST}/files/images/${track.audio.waveform.id}/crop/300x50/waveform.png` : SoundWave }
                    currentSeconds={GlobalSoundBar.currentTrackIsTitled(track.title) ? this.state.playingTime : 0}
                    totalSeconds={GlobalSoundBar.currentTrackIsTitled(track.title) ? GlobalSoundBar.totalSeconds() : track.audio.duration}
                    onManualChange={GlobalSoundBar.handleProgressManualChange(GlobalSoundBar.currentTrackIsTitled(track.title) ? GlobalSoundBar.totalSeconds() : track.audio.duration,
                       GlobalSoundBar.currentTrackIsTitled(track.title) ? null : {
                        fetchAudioUrl: () => new Promise(resolve => {
                          let url = '';
                          const date = new Date()
                          const now_utc = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
                
                          if((now_utc - Date.parse(track.time)) < getSignatureTime()) {
                            url = (!getActiveClient() && track.demo) ? track.demo.url : track.audio.url
                          }else{
                            url = (getActiveClient() ? request.music.getForClient(track.id).then(track => track.audio.url) : request.music.getPublic(track.id).then(track => track.demo.url))
                          }
                
                          resolve(url);
                        }),
                        id: track.id,
                        title: track.title,
                        subtitle: (track.albums && track.albums.length > 0) ? getMostImportantAlbum(track.albums).title : this.trackIsAContentCreation(track) ? track.subtitle : trackContainer.title,
                        image: this.makeSoundbarTrackImage(track, trackContainer),
                        waveform: makeWaveformUrl(track),
                        isFavorite: track.favorite,
                        onToggleFavorite: !this.trackIsAVoice(track) && !this.trackIsAContentCreation(track)
                          ? state => (state ? this.addToFavorites(track) : this.removeFromFavorites(track))
                          : undefined,
                        onDownload: userCanDownloadTracks() ? () => this.openDownloadDialog(track, trackContainer) : undefined,
                        onAddToPlaylist: (!getActiveChannel() || channelHasPlaylists()) && userCanAddTracksToPlaylists()  && !this.trackIsAContentCreation(track)
                          ? () =>
                            GlobalAddToPlaylistDialog.show({
                              type: track.type || this.props.type || this.state.type,
                              clientId: getActiveClient(),
                              selectedTrack: track,
                            })
                          : undefined,
                        playlistButtonData: (!getActiveChannel() || channelHasPlaylists()) && userCanAddTracksToPlaylists()  && !this.trackIsAContentCreation(track)
                          ? 
                          {
                            type: track.type || this.props.type || this.state.type,
                            clientId: getActiveClient(),
                            selectedTrack: track,
                          }
                          :
                          undefined,
                        onLicense:
                          !this.trackIsAVoice(track) && !this.trackIsAContentCreation(track) && userCanLicenseTracks()
                            ? () => this.openLicenseWizard(track, trackContainer)
                            : !this.trackIsAVoice(track) && !this.trackIsAContentCreation(track) ? () => this.setState({showLicenseDialogForTrialUsers: true})
                            : undefined,
                        onTitleClick: !this.trackIsAVoice(track) && !this.trackIsAContentCreation(track) ? () => this.props.history.push(getActiveChannel() ? channelPathGenerator(`musica/${track.id}-${slugify(track.title || track.name)}`) : homePathGenerator(`music/${track.id}`)) : undefined,
                        onSubtitleClick: () => this.handleTrackContainerPressedInSoundbar(track, trackContainer),
                      }
                    )} 
                    hideSeconds
                  />               
                </div>
              }

              <div className="track-table-tags">
                {track.tags && track.tags.length > 0 && (
                  (this.trackIsAVoice(track) || this.trackIsAContentCreation(track)) ? (
                    <div style={{color: this.props.color ? this.props.color : (getActiveChannel() ? getStoredChannel().preferences.title.color :  '#2b2b2b')}} >{track.tags.map(tag => tag.name).join(", ")}</div>
                  ) : (
                    <div className="track-table-tags-link-container">
                      {track.tags
                        .map(tag =>
                          this.props.match.path.includes("busqueda-inteligente") ?
                            this.props.selectedTags && this.props.selectedTags.includes(tag.id) ? (
                              <div
                                className="track-table-tags-link-selected"
                                style={{color: this.props.color ? this.props.color : (getActiveChannel() ? getStoredChannel().preferences.title.color :  '#2b2b2b')}}
                                onClick={() => {
                                  EventManager.getInstance().dispatch(events.TAG_SELECTED_PRESSED_IN_TRACK_TABLE, tag);
                                }}
                              >
                                {tag.name}
                              </div>
                            ) : (
                              <div
                                className="track-table-tags-link"
                                style={{color: this.props.color ? this.props.color : (getActiveChannel() ? getStoredChannel().preferences.title.color :  '#2b2b2b')}}
                                onClick={() => {
                                  EventManager.getInstance().dispatch(events.TAG_PRESSED_IN_TRACK_TABLE, tag);
                                }}
                              >
                                {tag.name}
                              </div>
                            )
                            :
                            getActiveClient() &&
                            <Link
                              className="track-table-tags-link"
                              style={{color: this.props.color ? this.props.color : (getActiveChannel() ? getStoredChannel().preferences.title.color :  '#2b2b2b')}}
                              to={getActiveChannel() ? channelPathGenerator(`busqueda-inteligente/musica?${
                                  qs.stringify({
                                    tags_ids: [tag.id],
                                  })}`)
                                :
                                homePathGenerator(`smart_search/musics?${
                                qs.stringify({
                                  tags_ids: [tag.id],
                                })}`)}
                            >
                              {tag.name}
                            </Link>
                        )
                        .slice(0, 5)}
                    </div>
                  )
                )}
              </div>
              <div className="track-table-duration" style={{color: this.props.color ? this.props.color : (getActiveChannel() ? getStoredChannel().preferences.title.color :  '#2b2b2b')}} >{track.audio ? secondsToMMSS(track.audio.duration) : "--:--"}</div>

              <div className="track-table-action-buttons" style={{display: 'flex', alignItems: 'center'}}>
                {(getActiveChannel() && channelHasPlaylists() && !window.location.pathname.includes("public") && userCanAddTracksToPlaylists() ? (
                    <AddToPlaylist
                      content={(track.type || this.props.type || this.state.type) ? `${track.type || this.props.type || this.state.type}s` : undefined}
                      clientId={getActiveClient()}
                      item={track}
                      tooltipTitle={localization.get("add_to_playlist")}
                      tooltipPlacement="top"
                      extraClass="track-table-action-icon"
                      extraStyle={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                      icon={
                        <PlaylistAdd
                          style={{color: this.props.color ? this.props.color : (getActiveChannel() ? getStoredChannel().preferences.title.color :  '#2b2b2b')}}
                        />
                      }
                    />
                  ) : ((!getActiveChannel() || (getActiveChannel() && channelHasPlaylists())) && userCanAddTracksToPlaylists() && this.props.playlistType !== 'global')? (
                    <Tooltip placement="top" title={localization.get("remove_from_playlist")}>
                      <RemoveCircle
                        onClick={() => this.handleRemoveFromPlaylistButtonPress(track)}
                        className="track-table-action-icon"
                        style={{color: this.props.color ? this.props.color : (getActiveChannel() ? getStoredChannel().preferences.title.color :  '#2b2b2b')}}
                      />
                    </Tooltip>
                  ) : null
                )}

                {!!getStoredUser() && getActiveClient() && (!getActiveChannel() || getUseTools()) && !this.trackIsAMusic(track) && !!this.props.onShare && !channelDisableShare() &&
                  <Tooltip placement="top" title={localization.get("share")}>
                    <Share
                      onClick={() => this.props.onShare(track)}
                      className="track-table-action-icon"
                      style={{color: this.props.color ? this.props.color : (getActiveChannel() ? getStoredChannel().preferences.title.color :  '#2b2b2b')}}
                    />
                  </Tooltip>
                }
                {!!getStoredUser() && ((!getActiveChannel() && window.innerWidth > 600) ||
                    (getActiveChannel() && this.state.innerWidth > 600 &&
                      ((getStoredChannel().can_download_tracks && this.trackIsAMusic(track)) || !this.trackIsAMusic(track) && track.allowDownload))
                  ) && this.renderDownloadButton(track, trackContainer)}
                {getActiveChannel() && channelHasLicenses() && getChannelCanLicense() && (clientCanLicense() || userIsSysAdmin()) &&
                <React.Fragment>
                  {userCanLicenseTracks() && !this.trackIsAVoice(track) && !this.trackIsAContentCreation(track) && this.state.innerWidth > 600 ?
                    <Tooltip placement="top" title={localization.get("license")}>
                      <AddShoppingCart
                        onClick={() => this.openLicenseWizard(track, trackContainer)}
                        className="track-table-action-icon"
                        style={{color: this.props.color ? this.props.color : (getActiveChannel() ? getStoredChannel().preferences.title.color :  '#2b2b2b')}}
                      />
                    </Tooltip>
                    : (!this.trackIsAVoice(track) && !this.trackIsAContentCreation(track) && this.state.innerWidth > 600) ?
                      <Tooltip placement="top" title={localization.get("license")}>
                        <AddShoppingCart
                          onClick={() => this.setState({showLicenseDialogForTrialUsers: true})}
                          className="track-table-action-icon"
                          style={{color: this.props.color ? this.props.color : (getActiveChannel() ? getStoredChannel().preferences.title.color :  '#2b2b2b')}}
                        />
                      </Tooltip>
                      : null
                  }

                  {(userIsSysAdmin() || userisMultiClient() || userCanLicenseTracks()) && this.state.innerWidth > 600 && !this.trackIsAVoice(track) && !this.trackIsAContentCreation(track) &&
                  <Tooltip placement="top" title={localization.get("see_more")}>
                    <Options
                     key={index}
                     options={this.getOptions(index, track)}
                     color={(!track.recentLicenses || track.recentLicenses.length == 0) ? "green" : track.recentLicenses.find(lic => lic.request_exclusivity) ? "red" : "yellow" }
                    />
                  </Tooltip>
                  }
                </React.Fragment>
                }

                {this.state.innerWidth < 600 && this.state.playing === track.id &&
                <Pause onClick={() => this.handlePauseButtonPress()} className="track-table-action-icon"/>
                }
                {this.state.innerWidth < 600 && this.state.playing !== track.id &&
                <Play onClick={
                  this.handlePlayButtonPress(
                    track,
                    track.albums
                      ? getMostImportantAlbum(track.albums)
                      : trackContainer ? trackContainer :null
                  )
                } className="track-table-action-icon"/>
                }
              </div>
            </div>
            {(this.trackIsAMusic(track) && track.versions_count > 0 && expandedVersionsIndex === index) &&
            <TrackTableSubComponent
              music={track}
              album={(track.albums && track.albums.length > 0) ? getMostImportantAlbum(track.albums) : trackContainer}
              musicIndex={index + 1}
            />
            }
          </>
        ))}
        {(!this.props.hideSuggestions || isNullOrUndefined(this.props.hideSuggestions)) && <Suggestions suggestions={this.state.suggestions} color={ (this.props && this.props.color) ? this.props.color : "var(--main-font-color, black)"} clientId={getActiveClient()} />}
        {this.licenseDialogForTrialUsers()}
        {this.makeLicenseDialog()}
      </React.Fragment>
    );
  }
}

const Suggestions = ({ suggestions, clientId, color= "var(--main-font-color, black)" }) =>
  suggestions.length > 0 && (
    <ErrorBoundary>
      <div className="suggestions-container" >
        <h5 style={{ marginTop: 50, marginBottom: 40, textTransform: "uppercase", color:color }} className="title bold">
          {localization.get('suggestions')}
        </h5>
        <div className="suggestion-elements-container">
          {suggestions.map(track => (
            <SuggestionElement track={track} clientId={clientId} />
          ))}
        </div>
      </div>
    </ErrorBoundary>
  );

class SuggestionElement extends Component {

  toggleFavorite = (state, trackId) => {
    if(getActiveChannel()) {
      return state ? request.musicChannelFavorite.add(trackId) : request.musicChannelFavorite.remove(trackId)
    }else {
      return state ? request.musicFavorite.add(trackId) : request.musicFavorite.remove(trackId)
    }
  }

  playSong = (track, clientId) => {

    analyticsInstance.playContent({
      name: getMainTranslation(track, "title"),
      type: "Audio",
      content: !!track.contentCreator && getMainTranslation(track.contentCreator, "name")
    })

    GlobalSoundBar.loadSingleTrackAndPlay({
      fetchAudioUrl: () => new Promise(resolve => {
        let url = '';
        const date = new Date()
        const now_utc = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

         /*
        let expiredAt = new Date(get(track, 'previewAudio.requested_at').replace(/-/g, '/'));
        expiredAt.setMilliseconds(expiredAt.getMilliseconds() + getSignatureTime())
        */
        if((now_utc - Date.parse(track.time)) < getSignatureTime()) {
          url = (!getActiveClient() && track.demo) ? track.demo.url : track.audio.url
        }else{
          url = track.type === "contentCreation" ?
            (getStoredUser() ? request.contentCreation.get(track.id, "include=audio:url").then(track => track.audio.url) : request.contentCreation.getPublic(track.hashcode).then(track => track.audio.url)) :
              (getActiveClient() ?
                request.music.getForClient(track.id).then(track => track.audio.url) :
                request.music.getPublic(track.id).then(track => track.demo.url))
        }

        resolve(url);
      }),
      id: track.id,
      title: track.title,
      subtitle: getMostImportantAlbum(track.albums).title,
      image: makeUrlImageFromAlbums(track.albums),
      waveform: makeWaveformUrl(track),
      isFavorite: track.favorite,
      onToggleFavorite: state => this.toggleFavorite(state, track.id),
      onDownload: () => userCanDownloadTracks() ? this.openDownloadDialog(track, getMostImportantAlbum(track.albums)) : null,
      onAddToPlaylist:
        !getActiveChannel() && userCanAddTracksToPlaylists() &&
        (() =>
          GlobalAddToPlaylistDialog.show({
            type: "music",
            clientId,
            selectedTrack: track,
          })),
      onLicense:
        userCanLicenseTracks() &&
        (() =>
          GlobalLicenseWizardDialog.show({
            track: {
              id: track.id,
              title: track.title,
              owner: track.owner,
              waveform: makeWaveformUrl(track),
              image: makeUrlImageFromAlbums(track.albums),
            },
            client: GlobalLicenseWizardDialog.mapApiClient(getStoredChannel().client),
          })),
      onSubtitleClick: () => browserHistory.push(getActiveClient() ? channelPathGenerator(`album/${getMostImportantAlbum(track.albums).id}`) : `/public/album/${getMostImportantAlbum(track.albums).id}`),
    });
  };

  renderStateButton = () => {
    return (
      <IconButton style={{ position: "absolute", pointerEvents: "none" }} className="track-table-play-button">
        <Play className="track-table-play-icon" />
      </IconButton>
    );
    return null;
  };

  render = () => {
    const { track, clientId, classes, formMusic } = this.props;

    return (
      <div className="suggestion-element">
        <div style={{ display: "flex" }}>
          <div class="track-suggestion-container">
            <img
              alt="suggestion"
              style={{ width: 60, height: 60 }}
              className={classes.img}
              src={makeUrlImageFromAlbums(track.albums)}
              onClick={() => this.playSong(track, clientId)}
              onError={onImageError}
            />
            {this.renderStateButton()}
          </div>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "left" }}>
            {getActiveClient() ?
              <Link
              onClick={() => {
                this.props.onClick && this.props.onClick(track)
              }}
              to={channelPathGenerator(`musica/${track.id}-${slugify(track.title)}`)}>
                <Typography style={{color: formMusic? "black" : 'var(--main-font-color, rgb(60, 72, 88))'}}>{track.title}</Typography>
              </Link>
              :
              <Typography style={{color: formMusic? "black" :'var(--main-font-color, rgb(60, 72, 88))'}}>{track.title}</Typography>
            }
            {track.artist &&
            <Link to={getActiveClient() ? (getActiveChannel() ? channelPathGenerator(`artista/${track.artist.id}-${slugify(track.artist.name)}`) : homePathGenerator(`artist/${track.artist.id}`)) : `/public/artist/${track.artist.id}`}>
              <Typography style={{color:formMusic? "black" : 'var(--main-font-color, darkgrey)'}} variant="caption">{track.artist.name}</Typography>
            </Link>
            }
            {!track.artist && track.albums && track.albums.length > 0 &&
            <Link to={getActiveClient() ? (getActiveChannel() ? channelPathGenerator(`album/${getMostImportantAlbum(track.albums).id}`) : homePathGenerator(`album/${getMostImportantAlbum(track.albums).id}`)) : `/public/album/${getMostImportantAlbum(track.albums).id}`}>
              <Typography variant="caption" style={{color: formMusic? "black" : 'var(--main-font-color, rgb(60, 72, 88))'}}>{getMostImportantAlbum(track.albums).title}</Typography>
            </Link>
            }
          </div>
        </div>
      </div>
    );
  };
}

SuggestionElement = withStyles({
  img: {
    width: 100,
    height: 100,
    borderRadius: 10,
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0 0 5px 3px var(--color, secondary-color, #d2d2d2)",
    },
  },
})(SuggestionElement);

export default TrackTable;

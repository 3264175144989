import React, {Component} from "react";
import {getValueFromQueryParam, makeFitImageUrl, makeFitImageUrlWithSize} from "../../../utils";
import {
  catalogClientView, channelCanAddToFavorites, channelPathGenerator, userCanEditCreators,
  getActiveChannel, getActiveClient, getUseTools, getStoredUser,
  getStoredChannel, homePathGenerator, slugify, addHttpsToUrl, getMainTranslation,
} from "../../../api-client/core/authentication/utils";
import Logo from "../../../assets/img/logo-black.png";
import RichEditor from "../../../components/Letflow/RichTextEditor/EditorialEditor";
import {convertFromRaw, EditorState} from "draft-js";
import {IconButton, Tooltip} from "@material-ui/core";
import {Close, GetApp, Share, ArrowUpward, Redeem, FilterNone, Edit} from "@material-ui/icons";
import {renderSocialLinksButtons} from "../../../components/Letflow/ContentCreatorViewComponents/SocialBanner";
import * as VCS from "../../../components/Letflow/ContentCreatorViewComponents";
import EventSchedule from "../../../components/Letflow/ContentCreatorViewComponents/EventSchedule";
import styled from "styled-components";
import {ReactComponent as Star} from "../../../components/Letflow/SvgIcons/star.svg"
import style from "./contentCreator.module.css"
import localization from "../../../config/localization";
import GlobalRedirectLoginConfirmationDialog
  from "../../../components/Letflow/Dialog/GlobalRedirectLoginConfirmationDialog";
import browserHistory from "../../../utils/browserHistory";
import {request} from "../../../api-client";
import {get} from "lodash"
import Chip from "@material-ui/core/Chip";
import font from "../HomeElements/Styles/Fonts/fonts.module.css";
import ShareOnSocials from "../../../components/Letflow/ShareOnSocials";
import Button from "../../../components/CustomButtons/Button";
import {replaceEventCalendarText, hasReservedEventCalendarText} from "../../../components/Letflow/RichTextEditor/utils";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import AddToPlaylist from "../../../components/Letflow/AddToPlaylist";
import GlobalSnackbar from "../../../components/Letflow/Snackbar/GlobalSnackbar";
import {Helmet} from "react-helmet/es/Helmet";
import ProductDialog from "../../../components/Letflow/ProductsDialog";
import qs from 'qs';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import {isNullOrUndefined} from "util";
import {Link} from "react-router-dom";
import './EditorialCreator.css';
import RelatedContent from "../../../components/Letflow/RelatedContent/RelatedContent";
import GoogleAdsenseAd from "../../../components/Letflow/GoogleAd";
import {getNoteDate, onImageError} from "../HomeElements/utils";
import analyticsInstance from "../../../analytics/analyticsInstance";
import {
  FacebookIcon,
  FacebookShareButton,
  XIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import {CopyToClipboard} from "react-copy-to-clipboard";
import EditContentCreatorDialog from "../../../components/Letflow/EditContentCreatorDialog";
import GlobalConfirmationDialog from "../../../components/Letflow/Dialog/GlobalConfirmationDialog";
import moment from "moment";


const getImageHeight = (image) => {
  if (!image || !image.height || !image.width) return 'auto';
  let ratio = Math.round(image.height / image.width * 100);
  return `${ratio}vw`;
}

export default class EditorialCreator extends Component {
  constructor(props) {
    super(props)

    this.state = {
      contentCreator: this.props.contentCreator,
      favorite: this.props.favorite || false,
      playlist: null,
      showShareDialog: false,
      suggestedCreators: [],
      showUpButton: false,
      eventsPosition: get(getStoredChannel(), "preferences.content_creator.editorial.events.position", "bottom"),
      shareUrl: null,
      description: null,
      openProductDialog: false,
      productTargetId: null,
      productId: null,
      isGift: false,
      productReturnUrl: null,
      productName: "",
      checkingPurchases: false,
      purchaseCheckAttempts: 20,
      buyButtonProduct: null,
      copied: false,
      hasGoogleAdsense: getActiveChannel() && getStoredChannel().google_publisher_id,
      mobileResize: (getActiveChannel() && getStoredChannel().preferences && getStoredChannel().preferences.mobile_font_resize) ? (Number(getStoredChannel().preferences.mobile_font_resize) / 100) : 1,
      editContentCreatorDialogOpen: false,
    };
    this.myRef = React.createRef();
    
  }


  componentDidMount = () => {
    if (this.props.contentCreator.hasSuggested) {
      if (this.props.contentCreator.suggestedPlaylist) {
        request.playlist.getForChannel(this.props.contentCreator.suggestedPlaylist.id, 'include=contentCreators;channel').then(playlist => this.setState({playlist}))
      } else if (this.props.contentCreator.suggestedCreators) {
        this.setState({suggestedCreators: this.props.contentCreator.suggestedCreators})
      } else {
        request.contentCreator.getSuggested(this.props.contentCreator.id, this.props.contentCreator.suggestedTagGroup).then(suggestedCreators => this.setState({suggestedCreators}))
      }
    }


    this.handleScroll = this.onScroll.bind(this);
    window.addEventListener('scroll', this.handleScroll, true);

    if (this.props.contentCreator.buyButton && getStoredUser()) {
      request.products.getForChannel(this.props.contentCreator.buyButton.productId)
        .then(product => this.setState({buyButtonProduct: product}));
    }

    if (!this.props.isPublic && (this.props.contentCreator.buyButton || this.state.contentCreator.paymentRequired) && getStoredUser()) {
      if (get(this.props, "contentCreator.buyButton.productId")) {
        request.products.getForChannel(this.props.contentCreator.buyButton.productId)
          .then(product => this.setState({buyButtonProduct: product}));
      } else {
        request.contentCreator.isPurchased(this.state.contentCreator.id)
          .then(res => this.setState({ contentCreator: {...this.state.contentCreator, paymentRequired: !res.bought }}))
      }
    }

    let token = getValueFromQueryParam("token");
    if (token) {
      this.checkPurchases(token);
    }
    this.fixResponsiveEmbedSize();

    let content = document.getElementById("editorial-creator-content")
    let linkList = content ? (content.querySelectorAll('a') || []) : [];
    linkList.forEach((elem) => {
      if (elem.href.startsWith(`${window.location.protocol}//${window.location.hostname}`) || elem.href.startsWith('#')) {
        elem.setAttribute('target', '_self');
      } else {
        elem.setAttribute('target', '_blank');
        elem.setAttribute('rel', "nofollow");
      }
    })
    let initialHash = window.location.hash;
    this.handleProductHash(initialHash, true);
    window.addEventListener('hashchange', (e) => {
      if (e && e.target) {
        this.handleProductHash(e.target.location.hash);
      }
    })
  };

  handleProductHash = (hash, askForLogin = true) => {
    if (hash && hash.length > 1) {
      let splittedHash = hash.slice(1).split('_');
      if (splittedHash.length == 2 && splittedHash[0] == 'product') {
        let productId = splittedHash[1];
        if (productId) {
          if (!getStoredUser()) {
            if (askForLogin) {
              this.props.showLoginDialog();
            } else {
              window.location.hash = "";
            }
          } else {
            this.setState({openProductDialog: true, productId: productId}, () => window.location.hash = "");
          }
        }
      }
    }
  }

  checkPurchases = async (token) => {
    const poll = async (fn, fnCondition, ms) => {
      try {
        let result = await fn();
        while (fnCondition(result)) {
          await wait(ms);
          result = await fn();
        }
        this.setState({checkingPurchases: false});
        return result;
      } catch (e) {
        this.setState({checkingPurchases: false})
      }
    };

    const wait = (ms = 1000) => {
      return new Promise(resolve => {
        setTimeout(resolve, ms);
      });
    };
    let checkPurchase = () => request.products.getPaypalWebhook(token);
    let validate = result => {
      if (this.state.purchaseCheckAttempts <= 0) {
        return false;
      } else {
        this.setState({purchaseCheckAttempts: this.state.purchaseCheckAttempts - 1});
        return result && result.some(t => t.status == 'pending');
      }
    };

    this.setState({checkingPurchases: true}, async () => {
      await poll(checkPurchase.bind(this), validate.bind(this), 3000);
      let content = getValueFromQueryParam("contenido");
      let url = {
        pathname: window.location.pathname
      };
      if (content) {
        url['search'] = qs.stringify({contenido: content});
      }
      browserHistory.push(url);
      window.location.reload();
    });
  }

  onScroll = (e) => {
    if (!e || !e.target) return;
    let element = null;
    if (this.props.modal && e.target.id === 'scrollableDiv') {
      element = e.target;
    } else if (!this.props.modal && e.target.scrollingElement) {
      element = e.target.scrollingElement;
    }
    if (element) {
      if (element.scrollTop > 100) {
        !this.state.showUpButton && this.setState({showUpButton: true})
      } else {
        this.state.showUpButton && this.setState({showUpButton: false})
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.contentCreatorId !== prevProps.contentCreatorId) {
    }
  }

  makeBuyButton = (contentCreator) => {
    let onlySocialButton = false;
    const showBuyButton = !!(isNullOrUndefined(this.props.preview) && (
        (this.state.contentCreator.buyButton && this.state.buyButtonProduct &&
          (!this.state.buyButtonProduct.already_bought || !getStoredUser()))
        || this.state.contentCreator.paymentRequired) &&
      getStoredChannel() && getStoredChannel().preferences && getStoredChannel().preferences.buy_button)

    if ((this.state.buyButtonProduct || (!getStoredUser() && contentCreator.buyButton)) && !onlySocialButton) {
      let product = this.state.buyButtonProduct;
      let returnUrl = window.location.href;
      if (this.state.buyButtonProduct) {
        switch (product.content_type) {
          case "App\\ContentCreator":
            let contentCreator = product.content;
            if (contentCreator) {
              returnUrl = channelPathGenerator(`contenido/${contentCreator.id}-${slugify(contentCreator.name)}`);
            }
            break;
          case "App\\ContentCreation":
            let contentCreation = product.content;
            if (contentCreation) {
              returnUrl = channelPathGenerator(`contenido/${product.content.content_creator_id}-${contentCreation.title}`);
            }
            break;
          case "App\\ContentPack":
            if (product.content && product.content.products) {
              let targetProduct = product.content.products[0];
              if (targetProduct.content_type == "App\\ContentCreator") {
                let contentCreator = targetProduct.content;
                if (contentCreator) {
                  returnUrl = channelPathGenerator(`contenido/${contentCreator.id}-${slugify(contentCreator.name)}`);
                }
              }
              if (targetProduct.content_type == "App\\ContentCreation") {
                let contentCreation = targetProduct.content;
                if (contentCreation) {
                  returnUrl = channelPathGenerator(`contenido/${product.content.content_creator_id}-${contentCreation.title}`);
                }
              }
            }
        }
      }
      if (product && product.already_bought) {
        if (product.is_giftable) {
          return (
            <>
              <span
                style={{color: "var(--main-font-color, inherit)"}}>{localization.get("gift")}</span>
              <IconButton
                style={{
                  color: "var(--main-font-color, inherit)"
                }}
                title={localization.get('gift')}
                onClick={() => {
                  if (!getStoredUser()) {
                    this.props.showLoginDialog()
                  } else {
                    this.setState({
                      openProductDialog: true,
                      productId: product.id,
                      productReturnUrl: returnUrl,
                      isGift: true,
                    })
                  }
                }}
              >
                <Redeem/>
              </IconButton>
            </>
          )

        }
        return (
          <Link to={returnUrl} target="_blank">
            <span
              style={{color: "var(--main-font-color, inherit)"}}>{localization.get("product.go_to_content")}</span>
          </Link>
        );
      } else {
        return (
          <>
            <span
              style={{color: "var(--main-font-color, inherit)"}}>{contentCreator.buyButton.text || localization.get("buy")}</span>
            <IconButton
              style={{
                color: "var(--main-font-color, inherit)"
              }}
              title={localization.get('buy')}
              onClick={() => {
                if (!getStoredUser()) {
                  this.props.showLoginDialog()
                } else {
                  this.setState({
                    openProductDialog: true,
                    productId: product.id,
                    productReturnUrl: returnUrl
                  })
                }
              }}
            >
              <ShoppingCartOutlinedIcon/>
            </IconButton>
            {((product && product.is_giftable) || !!(this.state.contentCreator.buyButton && this.state.contentCreator.buyButton.isGiftable)) &&
              <IconButton
                style={{
                  color: "var(--main-font-color, inherit)"
                }}
                title={localization.get('gift')}
                onClick={() => {
                  if (!getStoredUser()) {
                    this.props.showLoginDialog()
                  } else {
                    this.setState({
                      openProductDialog: true,
                      productId: product.id,
                      productReturnUrl: returnUrl,
                      isGift: true,
                    })
                  }
                }}
              >
                <Redeem/>
              </IconButton>
            }
          </>
        )
      }
    } else if (showBuyButton) {
      return (
        <>
          <span
            style={{color: "var(--main-font-color, inherit)"}}
          >{localization.get("buy")}</span>
          <IconButton
            style={{
              marginLeft: 5,
              color: "var(--main-font-color, inherit)"
            }}
            title={localization.get('buy')}
            onClick={() => this.onBuyProduct()}
          >
            <ShoppingCartOutlinedIcon/>
          </IconButton>
        </>
      )
    }
  }

  openProductDialog = (content, isGift = false) =>
    this.setState({
      openProductDialog: true,
      productTargetId: content.id,
      productName: content.name || content.title,
      productImageUrl: content.imageUrl,
      isGift: isGift,
    })

  renderProductDialog = () =>
    <ProductDialog
      open={this.state.openProductDialog}
      productTargetId={this.state.productTargetId}
      productTargetType={this.state.productId ? null : this.state.contentCreator.paymentRequired ? "contentCreator" : "contentCreation"}
      name={this.state.productName}
      imageUrl={this.state.productImageUrl}
      productId={this.state.productId}
      returnUrl={this.state.productReturnUrl}
      onClose={() => this.setState({
        openProductDialog: false,
        productTargetId: null,
        productName: "",
        productImageUrl: "",
        productId: null,
        productReturnUrl: null,
        isGift: false,
      })}
      isGift={this.state.isGift}
    />

  onBuyProduct = (isGift = false) =>
    !getStoredUser() ?
      this.props.showLoginDialog() :
      (
        (!!this.state.buyButtonProduct && !!this.state.contentCreator.buyButton && !!this.state.contentCreator.buyButton.productId) ?
          this.setState({
            openProductDialog: true,
            productId: this.state.contentCreator.buyButton.productId,
            isGift: isGift
          }) :
          this.openProductDialog(this.state.contentCreator, isGift)
      )


  downloadMaterial = (downloadableMaterial) => {
    GlobalConfirmationDialog.show({
      title: localization.get("download.confirmation_title"),
      content: localization.get("download.confirmation_content", get(this.state.contentCreator, "downloadableMaterial.name"), localization.get("content_creation.content")),
      request: () => new Promise(resolve => {
          analyticsInstance.download({
            name: getMainTranslation(this.state.contentCreator, "name"),
            type: "Channel",
            from: "Original_Material",
          })
          window.open(request.contentCreator.downloadMaterial(downloadableMaterial.id), "_blank")
          resolve()
        })
      })
    }

  toggleFavorite = () => {
    if (!getStoredUser()) {
      GlobalRedirectLoginConfirmationDialog.show({
        title: localization.get("login_redirect"),
        content: getStoredChannel().login_redirect_text || localization.get("login_redirect_text"),
        request: () => new Promise(resolve => {
          browserHistory.push(channelPathGenerator(`login?redirect_to=${this.props.modal ? window.location.pathname + window.location.search : window.location.pathname}`))
          resolve()
        }),
        confirmationLabel: localization.get("login_continue")
      });
    } else {
      this.setState({favorite: !this.state.favorite}, () => {
        if (!getActiveChannel()) {
          request.contentCreatorFavorite[this.state.favorite ? 'add' : 'remove'](this.state.contentCreator.id)
        } else {
          // GAevent('Contenido', "Favorite", this.state.contentCreator.name)
          analyticsInstance.favorite({
            name: getMainTranslation(this.state.contentCreator, "name"),
            type: "Content",
            content: this.state.favorite ? "Add" : "Remove"
          })
          request.contentCreatorChannelFavorite[this.state.favorite ? 'add' : 'remove'](this.state.contentCreator.id)
        }
      })
      this.props.modal && this.props.toggleModalFavorite && this.props.toggleModalFavorite()
    }
  };

  renderShareDialog = () => {
    return (
      <ShareOnSocials
        show={this.state.showShareDialog}
        handleClose={() => this.setState({showShareDialog: false})}
        url={this.state.shareUrl ? this.state.shareUrl : getActiveChannel() ? `${window.location.protocol}//${window.location.hostname}${channelPathGenerator("")}?contenido=${this.state.contentCreator.id}-${slugify(this.state.contentCreator.name)}` :
          getActiveClient() ? `${window.location.protocol}//${window.location.hostname}${homePathGenerator(`content_creator/${this.state.contentCreator.id}-${slugify(this.state.contentCreator.name)}`)}` :
            window.location.href
        }
        darkMode={true}
        shareTitle={this.state.contentCreator.name}
      />
    )
  };

  
           

  shareContent = () => {
    analyticsInstance.share({
      name: getMainTranslation(this.state.contentCreator, "name"),
      type: "Content",
    })
    if (getUseTools()) {
      return request.contentCreator.shareByChannel(this.state.contentCreator.id)
        .then(rest => this.setState({showShareDialog: true, shareUrl: rest.url}))
        .catch(GlobalSnackbar.showGenericError)
    } else {
      const isPublic = getStoredChannel() && getStoredChannel().visibility == 'public';

      if (isPublic) {
        this.setState({
          showShareDialog: true,
          shareUrl: `${window.location.protocol}//${window.location.hostname}${channelPathGenerator('')}?contenido=${this.state.contentCreator.id}-${slugify(this.state.contentCreator.name)}`
        })
      } else {
        this.setState({showShareDialog: true})
      }
    }
  }

  renderEventSchedule = (position) => {
    let eventDates = this.state.contentCreator.eventDates;
    let style;

    switch (position) {
      case 'left':
        style = {marginTop: '30px', marginBottom: '30px', marginLeft: '0', marginRight: 'auto'};
        break;
      case 'right':
        style = {marginTop: '30px', marginBottom: '30px', marginLeft: 'auto', marginRight: '0'};
        break;
      case 'center':
        style = {marginTop: '30px', marginBottom: '30px', marginLeft: 'auto', marginRight: 'auto'};
        break;
      default:
        style = {marginTop: '30px', marginBottom: '30px', marginLeft: 'auto', marginRight: 'auto'};
    }

    if (eventDates && eventDates.length == 0) return null;

    return <EventSchedule
      events={eventDates}
      additionalStyle={style} 
      fontColor={get(this.state, 'contentCreator.preferences.events.button.color', 'var(--main-font-color, inherit)')}
      backgroundColor={get(this.state, 'contentCreator.preferences.events.button.background_color', 'var(--main-color, inherit)')}
      text={get(this.state, 'contentCreator.preferences.events.button.text', 'Eventos')} 
    />
  }

  makeHomeElementProp = (contentCreators) => {
    return {
      ...contentCreators,
      items: {
        data: contentCreators.map(item => ({
          ...item,
          title: item.name,
          shortDescription: item.description,
          kind: "content_creator",
        }))
      }
    }
  }

  fixResponsiveEmbedSize = () => {
    let description = JSON.parse(this.props.contentCreator.description);
    if (!description) return;
    let entityMap = description.entityMap;
    if (entityMap) {
      Object.entries(entityMap).forEach(([k, v]) => {
        let isEmbed = v.type == "EMBEDDED_LINK";
        let isLink = v.type == "LINK";
        let isImg = v.type == "IMAGE";
        if (isEmbed || isImg) {
          if (v.data && v.data.width) {
            let maxWidth = (window.innerWidth < 650) ? (window.innerWidth - 72) : Math.round(window.innerWidth * 0.6);
            let width = v.data.width.endsWith("px") ? v.data.width.substr(0, v.data.width.length - 2) : null;
            if (!width || (width > maxWidth)) {
              if (isEmbed) {
                v.data.width = `${maxWidth}px`;
                v.data.height = `${Math.round(maxWidth / 16 * 9)}px`;
              } else if (isImg) {
                // let ratio = v.data.height / width;
                v.data.width = v.data.width == "auto" ? "auto" : `${maxWidth}px`;
                v.data.height = "auto";
                // v.data.height = `${Math.round(maxWidth * ratio)}px`;
              }
            }
          }
        } else if (getActiveChannel() && this.props.modal && isLink) {
          if (v.data && v.data.url && v.data.url.startsWith('#')) {
            v.data.url = `${window.location.protocol}//${window.location.hostname}${channelPathGenerator(`contenido/${this.state.contentCreator.id}-${slugify(this.state.contentCreator.name)}`)}${v.data.url}`;
          }
        }
      })
    }
    if (window.innerWidth <= 600) {
      let blocks = description.blocks;
      if (blocks) {
        blocks.map(b => {
          b.inlineStyleRanges = b.inlineStyleRanges.map(isr => {
            isr.style = (isr.style && isr.style.startsWith("fontsize-")) ? `fontsize-${Math.round(Number(isr.style.split("fontsize-")[1]) * this.state.mobileResize)}` : isr.style;
            return isr;
          });
          return b;
        });
      }
    }
    let contentCreator = this.state.contentCreator;
    contentCreator.description = description.toString();
    this.setState({description: description});
  }

  renderAdsenseAd = () => {
    let channel = getStoredChannel();
    let defaultAdUnit = channel && channel.adUnits && channel.adUnits.find(adUnit => adUnit.purpose === 'content');
    defaultAdUnit = defaultAdUnit ? defaultAdUnit.ad_unit : null;
    let mobileAdUnit = channel && channel.adUnits && channel.adUnits.find(adUnit => adUnit.purpose === 'content-mobile');
    mobileAdUnit = mobileAdUnit ? mobileAdUnit.ad_unit : null;
    let adUnit = (window.innerWidth < 650 && mobileAdUnit) ? mobileAdUnit : defaultAdUnit;

    return <GoogleAdsenseAd data={adUnit}/>
  }

  render = () => {
    let {contentCreator} = this.props
    let {showUpButton, eventsPosition, favorite, description, mobileResize} = this.state
    let tags = contentCreator.tags;
    
    let hasCalendarInserted = hasReservedEventCalendarText(description);
    let contentCreatorDescription;
    if (description) {
      contentCreatorDescription = replaceEventCalendarText(description);
    }
    //preferences
    let backgroundColor = (contentCreator.preferences && contentCreator.preferences.background_color) || ((getActiveChannel() && getStoredChannel().preferences.content_creator && getStoredChannel().preferences.content_creator.editorial) ? getStoredChannel().preferences.content_creator.editorial.background_color : 'white');
    let titleColor = (contentCreator.preferences && contentCreator.preferences.title && contentCreator.preferences.title.color) || ((getActiveChannel() && getStoredChannel().preferences.content_creator && getStoredChannel().preferences.content_creator.editorial && getStoredChannel().preferences.content_creator.editorial.title) ? getStoredChannel().preferences.content_creator.editorial.title.color : 'black');
    let fontColor = (contentCreator.preferences && contentCreator.preferences.title && contentCreator.preferences.title.color) || ((getActiveChannel() && getStoredChannel().preferences.content_creator && getStoredChannel().preferences.content_creator.editorial && getStoredChannel().preferences.content_creator.editorial.title) ? getStoredChannel().preferences.content_creator.editorial.title.color : 'var(--main-font-color, inherit)');
    let hideCover = get(contentCreator, 'preferences.hide_cover_image', get(getStoredChannel(), 'preferences.content_creator.editorial.hide_cover_image', false))
    const useSynopsis = !!get(contentCreator, 'preferences.use_synopsis', get(getStoredChannel(), 'preferences.content_creator.editorial.use_synopsis', false))
    const sharePosition = get(getStoredChannel(), 'preferences.content_creator.editorial.share_position', "top");
    const socialFill = get(getStoredChannel(), 'preferences.content_creator.editorial.social_fill', false);

    let whereText = null;

    if (contentCreator.additions) {
      const splittedText = contentCreator.additions.split(";");
      whereText = splittedText[0];
    }

    const showBuyButton = !!(isNullOrUndefined(this.props.preview) && (
      (this.state.contentCreator.buyButton && ((this.state.buyButtonProduct && !this.state.buyButtonProduct.already_bought) || !getStoredUser())) ||
      (!this.state.contentCreator.buyButton && this.state.contentCreator.paymentRequired)));

    const showGiftButton = !!(isNullOrUndefined(this.props.preview) && getStoredUser() &&
      this.state.contentCreator.buyButton && this.state.buyButtonProduct && this.state.buyButtonProduct.already_bought);

    let centerMode = getStoredChannel() ? get(getStoredChannel(), "preferences.navbar.center_mode", false) :false;

    const copyStringToClipboard = (text) => {
      this.setState({copied: true});
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(function() {
        console.log('Async: Copying to clipboard was successful!');
      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
    };

    const fallbackCopyTextToClipboard = (text) =>  {
      var textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
  
      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }
  
      document.body.removeChild(textArea);
    };
    const isPublic = getStoredChannel() && getStoredChannel().visibility == 'public';
    const mobileCoverImageUrl = contentCreator.mobileCoverImageUrl ? contentCreator.mobileCoverImageUrl : contentCreator.coverImageUrl;

    return (
      <React.Fragment>
        {!!userCanEditCreators() &&
          <EditContentCreatorDialog 
            open={this.state.editContentCreatorDialogOpen}
            contentCreator={contentCreator}
            onClose={() => this.setState({editContentCreatorDialogOpen: false})}
          />
        }
        {getActiveChannel() &&
          <Helmet>
            <link
              rel="canonical"
              href={(!!contentCreator.canonicalChannel) ?
                `${addHttpsToUrl(contentCreator.canonicalChannel)}${channelPathGenerator(`contenido/${contentCreator.id}-${slugify(contentCreator.name)}`)}` :
                `${window.location.protocol}//${window.location.hostname}${channelPathGenerator(`contenido/${contentCreator.id}-${slugify(contentCreator.name)}`)}`
              }
            />
          </Helmet>
        }
        {this.props.modal &&
          <>
            {(!!contentCreator.coverImageUrl || hideCover) && <TopShadow/>}
            <div style={{marginTop: '15px', position: 'absolute', width: '100%', zIndex: 5}}>
              <div style={
                window.innerWidth > 600 ?
                  {
                    left: (catalogClientView() && this.state.width > 1200) && "66px",
                    position: "absolute",
                    float: "left",
                    width: "auto",
                    cursor: "pointer",
                    marginLeft: contentCreator.coverImageUrl ? '35px' : '20%',
                    filter: contentCreator.coverImageUrl && 'brightness(0) invert(1)',
                    zIndex: 5,
                  }
                  :
                  {
                    position: "absolute",
                    cursor: "pointer",
                    top: 0,
                    left: 0,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    filter: contentCreator.coverImageUrl && 'brightness(0) invert(1)',
                    zIndex: 5,
                  }
              }>
                <div className="navbar-image-container-link" style={{
                  maxWidth: window.innerWidth < 769 ? "180px" : "auto",
                  width: window.innerWidth > 600 ? "100%" : "auto"
                }} onClick={() => this.props.onModalClose()}>
                  {getActiveChannel() ?
                    <img style={{
                      position: "relative",
                      float: "left",
                      width: "100%",
                      maxWidth: 250,
                      maxHeight: window.innerWidth > 600 ? 100 : 40
                    }}
                         src={(getStoredChannel() && getStoredChannel().image) ?
                           makeFitImageUrl(getStoredChannel(), 250, 40, '', 'png')
                           :
                           (getStoredChannel() ?
                             makeFitImageUrl(getStoredChannel().client, 250, 40, '', 'png')
                             : Logo)}
                         alt={`Logo ${getActiveChannel() ? getStoredChannel().name : "Feater"}`}/>
                    :
                    <img
                      className="navbar-image" style={{maxWidth: "130px"}}
                      src={(this.state.client && this.state.client.use_logo && this.state.client.image) ?
                        makeFitImageUrlWithSize(this.state.client, 'sm', 'png') : Logo}
                      alt={`Logo ${getActiveChannel() ? getStoredChannel().name : "Feater"}`}/>
                  }
                </div>
              </div>
              {window.innerWidth > 600 &&
                <div style={{
                  position: 'absolute',
                  float: 'right',
                  right: contentCreator.coverImageUrl ? '35px' : '20%',
                  zIndex: 5,
                  top: centerMode ? 20 : 0,
                }}>
                  <IconButton onClick={() => this.props.onModalClose()}>
                    <Close style={{color: "white", fontSize: "35px"}}/>
                  </IconButton>
                </div>
              }
            </div>
          </>
        }
        <div ref={(ref) => this.myRef = ref} style={{
          width: "100%", maxWidth: "100%", float: "left", backgroundColor: backgroundColor, display: 'flex', flexDirection: 'column',
          marginTop: (this.props.modal && !this.props.contentCreator.coverImageUrl) ? 100 : 
            (!this.props.modal && !this.props.contentCreator.coverImageUrl && !this.props.preview) ? 40 :
           (!this.props.modal && hideCover) ? 25 : 0
        }}>
          {(!!contentCreator.coverImageUrl && !hideCover) &&
            (window.innerWidth > 650 ?
                <div>
                  <div style={{
                    maxHeight: this.props.modal ? '100vh' : 'calc(100vh - 75px)',
                    overflow: 'hidden',
                    position: "relative"
                  }}>
                    {(contentCreator.coverImage && contentCreator.coverImage.width && contentCreator.coverImage.height) ?
                      <>
                        <img
                         width="100%"
                          style={{
                          display: this.state.imgLoaded ? 'block' : 'none',
                          height: getImageHeight(contentCreator.coverImage)
                        }}
                        onLoad={() => this.setState({imgLoaded: true})}
                        src={contentCreator.coverImageUrl}
                        onError={onImageError}
                             alt="portada"/>
                        <img width="100%" style={{
                          display: this.state.imgLoaded ? 'none' : 'block',
                          height: getImageHeight(contentCreator.coverImage)
                        }} src={contentCreator.tinyCoverImageUrl} onError={onImageError}/>
                      </>
                      :
                      <img width="100%" src={contentCreator.coverImageUrl} onError={onImageError} alt="portada"/>
                    }
                    <BottomShadow/>
                  </div>
                  <ArrowBar>
                    {window.innerWidth > 650 &&
                      <>
                        <div style={{marginLeft: '35px', fontWeight: 800, fontSize: 18, color: "black"}}>
                          {contentCreator.showDate && getNoteDate(contentCreator.activityStart).split(' ').slice(1).join(' ')}
                        </div>
                        <div style={{display: 'flex', placeContent: 'center'}}/>
                      </>
                    }
                    <SocialButtons>
                      {contentCreator.downloadableMaterial && contentCreator.downloadableMaterial.type !== 'link' &&
                        <>
                          <div style={{
                            top: contentCreator.socialLinks.length === 0 ? 10 : 7,
                            position: 'relative',
                            marginRight: 10,
                            cursor: 'pointer'
                          }}>
                            <GetApp onClick={() => this.downloadMaterial(contentCreator.downloadableMaterial)}/>
                          </div>
                          <div style={{
                            marginTop: 10,
                            marginRight: 20,
                            alignSelf: 'center',
                            fontSize: 18,
                            color: '#000'
                          }}>{contentCreator.downloadableMaterial.name ? contentCreator.downloadableMaterial.name : localization.get('download.editorial')}</div>
                        </>
                      }
                      {window.innerWidth > 650 && (
                        contentCreator.socialLinks.length > 0 ? 
                          renderSocialLinksButtons(contentCreator.socialLinks, false, () => analyticsInstance.contentAction({name: getMainTranslation(contentCreator, "name"), action: "Visit_Social_Network" })) : 
                        socialFill ?  
                          renderSocialLinksButtons(getStoredChannel().socialLinks, false, () => analyticsInstance.contentAction({ name: getMainTranslation(contentCreator, "name"), action: "Visit_Social_Network" })) 
                        : null
                      )}
                    </SocialButtons>
                  </ArrowBar>
                </div>
                :
                <div style={{
                  maxHeight: this.props.modal ? '100vh' : 'calc(100vh - 75px)',
                  overflow: 'hidden',
                  position: "relative",
                  background: `linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url(${mobileCoverImageUrl})`,
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                  height: 200,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}>
                  <div style={{width: "80%", marginLeft: "10%"}}>
                    <VCS.SocialBanner
                      socialLinks={contentCreator.socialLinks}
                      name={""}
                      showFavoriteButton={(!!getActiveClient() && !!getStoredUser() && !getActiveChannel()) || (getActiveChannel() && channelCanAddToFavorites())}
                      showShareButton={!isPublic}
                      favorite={favorite}
                      onToggleFavorite={this.toggleFavorite}
                      showAddToPlaylistButton={getActiveChannel() && getUseTools()}
                      onAddToPlaylist={() => this.handleAddToPlaylistButtonPress(contentCreator, 'contentCreator')}
                      playlistButton={
                        <AddToPlaylist
                          icon={<PlaylistAdd style={{fontSize: "25px", color: "white", marginLeft: 4}}/>}
                          content='contentCreators'
                          clientId={getActiveClient()}
                          item={contentCreator}
                          contentCreatorType={contentCreator.type.slug}
                          extraStyle={{
                            height: 33,
                            width: 33,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                          tooltipTitle={localization.get("ranking_config.playlist_add")}
                        />
                      }
                      onClose={() => !this.props.modal ? browserHistory.goBack() : this.props.onModalClose()}
                      onShare={() => this.shareContent()}
                      color={"#FFFFFF"}
                      shape={"rounded"}
                      backgroundColor={"transparent"}
                      isModal={true}
                      isEditorial={true}
                      showDownload={contentCreator.downloadableMaterial && contentCreator.downloadableMaterial.type !== 'link'}
                      onDownload={() => this.downloadMaterial(contentCreator.downloadableMaterial)}
                      // showBuyButton={showBuyButton && this.state.contentCreator.preferences && this.state.contentCreator.preferences.buy_button && this.state.contentCreator.preferences.buy_button.position !== 'description'}
                      showBuyButton={showBuyButton}
                      onBuyProduct={() => this.onBuyProduct()}
                      showGiftButton={showGiftButton}
                      onGiftProduct={() => this.onBuyProduct(true)}
                    />
                  </div>
                </div>
              )
          }

          <NoteContainer id="editorial-creator-content" mobileDefaultFontSize={Math.round(14 * mobileResize)}
                         fontColor={fontColor}>
            {hideCover && !this.props.modal &&
              <div style={{
                position: 'absolute',
                float: 'right',
                right: '20%',
                zIndex: 5,
                top: window.innerWidth < 650 ? -10 : 0,
              }}>
                <IconButton onClick={() => browserHistory.goBack()}>
                  <Close style={{color: "var(--main-font-color)", fontSize: "35px"}}/>
                </IconButton>
              </div>
            }
            {(contentCreator.socialLinks && contentCreator.socialLinks.length > 0 || (getStoredChannel().socialLinks.length > 0 && socialFill)) && hideCover &&
              <div>
                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end'}}>
                    <div style={{marginRight: '20%', display: "flex",marginRight: 50, placeContent: "flex-end"}}>
                      {contentCreator.downloadableMaterial && contentCreator.downloadableMaterial.type !== 'link' &&
                        <>
                          <div style={{
                            top: contentCreator.socialLinks.length === 0 ? 8 : 7,
                            position: 'relative',
                            marginRight: 5,
                            cursor: 'pointer'
                          }}>
                            <GetApp onClick={() => this.downloadMaterial(contentCreator.downloadableMaterial)}/>
                          </div>
                          <div style={{
                            marginRight: 10,
                            alignSelf: contentCreator.socialLinks.length === 0 ? 'flex-end' : 'center',
                            color: '#000'
                          }}>{localization.get('download.editorial')}</div>
                        </>
                      }
                      {contentCreator.socialLinks.length > 0 ? renderSocialLinksButtons(contentCreator.socialLinks) : renderSocialLinksButtons(getStoredChannel().socialLinks)}
                    </div>
                  </div>
              </div>
            }

            <IconContainer mainColor={titleColor}>
              {(!contentCreator.coverImageUrl || hideCover || window.innerWidth < 650) && contentCreator.showDate && 
                <p style={{ textAlign: "right", paddingBottom: 10 }}>
                  {getNoteDate(contentCreator.activityStart).split(' ').slice(1).join(' ')}
                </p>
              }
              
              <div style={{display: 'flex', }}>
                <Tooltip placement="bottom" title={localization.get("reel.share")}>
                  <SocialButton style={{width: 33, height: 33, margin: 3, top: 0}}>
                    <Share className={`${style['star']}`} style={{margin: 0, top: 0}}
                          onClick={this.shareContent}/>
                  </SocialButton>
                </Tooltip>
                {getActiveChannel() && getUseTools() &&
                    <IconButton style={{height: 35, width: 35}}>
                      <AddToPlaylist
                        icon={<SocialButton style={{width: 33, height: 33, margin: 3, top: 0}}>
                            <PlaylistAdd style={{color: titleColor, stroke: titleColor}}/>
                          </SocialButton>}
                        content='contentCreators'
                        clientId={getActiveClient()}
                        item={contentCreator}
                        contentCreatorType={contentCreator.type.slug}
                        extraClass={style['star']}
                        tooltipTitle={localization.get("ranking_config.playlist_add")}
                      />
                    </IconButton>
                  }
                  {((!!getActiveClient() && !!getStoredUser() && !getActiveChannel()) || (getActiveChannel() && channelCanAddToFavorites())) &&
                      <Tooltip placement="bottom"
                              title={localization.get(favorite ? "button.remove_from_favorites" : "ranking_config.favorites_add")}>
                                <SocialButton style={{width: 33, height: 33, margin: 3, top: 0}}>
                        <div className={`${style['star']} ${favorite ? style['active'] : style['notactive']}`} style={{color: "var(--main-font-color)!important", margin: 0, top: 0}}>
                          <Star style={{color: "var(--main-font-color)!important", stroke: "var(--main-font-color)", fill: "var(--main-font-color)", width: 20, margin: "2px 3px", top: 0}}
                                onClick={this.toggleFavorite}/>
                        </div>
                        </SocialButton>
                      </Tooltip>
                  }
                  {(this.state.buyButtonProduct || !getStoredUser() || showBuyButton) &&
                    <React.Fragment>
                      {isNullOrUndefined(this.props.preview) && <h3 className={style["editorial-description-h3"]}
                                                                    style={{color: titleColor}}>{this.makeBuyButton(contentCreator)}</h3>}
                    </React.Fragment>
                  }
              </div>
            </IconContainer>

          
            <ContentCreatorName
              style={{
                textAlign: 'center',
                margin: (window.innerWidth < 650 && this.props.modal) ? "30px 0" :" 20px 0",
                color: titleColor || 'black'
              }}
              >
                {contentCreator.name}
                {!!userCanEditCreators() &&
                  <Tooltip title={localization.get('edit')} onClick={() => this.setState({editContentCreatorDialogOpen: true})} placement="top">
                    <Edit style={{margin: "0 30px"}}  onClick={() => this.setState({editContentCreatorDialogOpen: true})} />
                  </Tooltip>
                }
            </ContentCreatorName>

            {useSynopsis && contentCreator.synopsis &&
              <p style={{
                textAlign: 'center',
                color: (titleColor || 'black'),
              }}>
                  {contentCreator.synopsis}
              </p>
            }

            <br/>
            
            <br/>
            {useSynopsis && contentCreator.when &&
              <div style={{marginBottom: 15}}>
                <span style={{fontWeight: 800}}><bold>{localization.get('form.when_question')}</bold></span>
                <br/>
                <span>{contentCreator.when}</span>
              </div>
            }
            {useSynopsis && whereText &&
              <div style={{marginBottom: 15}}>
                <span style={{fontWeight: 800}}><bold>{localization.get('form.where_question')}</bold></span>
                <br/>
                <span>{whereText}</span>
              </div>
            }
            {useSynopsis && contentCreator.entrance &&
              <div style={{marginBottom: 15}}>
                <span style={{fontWeight: 800}}><bold>{localization.get('form.entrance_question')}</bold></span>
                <br/>
                <span>{contentCreator.entrance}</span>
              </div>
            }

            {contentCreator.isEvent && !hasCalendarInserted && eventsPosition == 'top' && this.renderEventSchedule()}
            
            {description && <RichEditor
              editorState={EditorState.createWithContent(convertFromRaw(contentCreatorDescription))}
              readOnly={true}
              onChange={() => console.log('read only')}
              // bgColor={(contentCreator.preferences && contentCreator.preferences.background_color) || 'white'}
              eventScheduleGen={contentCreator.isEvent ? this.renderEventSchedule : null}
            />}
            {contentCreator.isEvent && !hasCalendarInserted && eventsPosition == 'bottom' && this.renderEventSchedule()}
            {contentCreator.showTags && get(tags, 'length', 0) > 0 &&
              <div id={'tags-container'}>
                <h4>{localization.get('tags')}</h4>
                <div style={{marginBottom: 30}}>
                  {tags.filter(tag => tag.show_on_smart_search).map((tag, j) => {
                    return (
                      <div className={`smart-search-tag-containter horizontal`}
                           style={{width: "100%", position: "relative", display: "inline-block", marginRight: 10}}>
                        <Chip
                          key={j}
                          className={'editorial-chip'}
                          label={<span
                          className={`${font[getActiveChannel() ? getStoredChannel().preferences.text_font : '']}`}>{tag.name}</span>}
                          onClick={(e) => {
                            const channelCategory = (getStoredChannel().channelContentCreatorTypes.find(type => type.contentCreatorType && type.contentCreatorType.id === contentCreator.type.id))
                            
                            if (!channelCategory) {
                              e.preventDefault()
                              e.stopPropagation()
                              return;
                            }
                            this.props.modal && this.props.onModalClose()


                            if (getActiveChannel()) {
                              const searchTarget = contentCreator.isEvent && channelCategory.allow_event_search ? 'eventos' : channelCategory.allow_content_search ?  'contenido' : null;
                              if (!searchTarget) return;
                              let redirectUrl = `busqueda-inteligente/${searchTarget}/${contentCreator.type.id}-${slugify(contentCreator.type.name)}?type=${contentCreator.type.slug}&tags_ids=${tag.id}`
                              if (searchTarget === 'eventos') {
                                let search = qs.parse(this.props.location.search.toString().slice(1))
                                let {date_start, date_end, date_range} = search

                                if (!date_start && !date_end) {
                                  let today = moment();
                                  date_start = today.format('YYYY-MM-DD')
                                  date_end = today.add(30, 'day').format('YYYY-MM-DD')
                                  date_range = null
                                }

                                redirectUrl += `&date_start=${date_start}&date_end=${date_end}&date_range=${date_range}`
                              }

                              browserHistory.push(channelPathGenerator(`${redirectUrl}&fromEditorial=true`))
                            }

                            this.props.onTagChange && this.props.onTagChange(tag.id)
                          }}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            }
            {sharePosition.startsWith("bottom") &&
                <div style={{width: "100%", display: "flex", flexDirection: "row"}}>
                  {isPublic ?
                  <>
                  <Tooltip title={localization.get(this.state.copied? "copied": "copy_link")}>
                    <CopyToClipboard
                      onCopy={() => copyStringToClipboard(`${window.location.protocol}//${window.location.hostname}${channelPathGenerator('')}?contenido=${this.state.contentCreator.id}-${slugify(this.state.contentCreator.name)}`)}
                    >
                      <div style={{width: 40, height: 40, background: "var(--main-font-color, #ccc)", marginBottom: 5, padding: 10, borderRadius: "50%", cursor: "pointer"}}>
                        <FilterNone style={{fontSize: 20, zIndex: 2, color: "var(--main-color)"}}/>
                      </div>
                    </CopyToClipboard>
                  </Tooltip>
                  <WhatsappShareButton url={`${window.location.protocol}//${window.location.hostname}${channelPathGenerator('')}?contenido=${this.state.contentCreator.id}-${slugify(this.state.contentCreator.name)}`} >
                    <WhatsappIcon size={40} round={true} bgStyle={{fill: "var(--main-font-color)"}} iconFillColor= {"var(--main-color)"}/>
                  </WhatsappShareButton>
                  <TwitterShareButton url={`${window.location.protocol}//${window.location.hostname}${channelPathGenerator('')}?contenido=${this.state.contentCreator.id}-${slugify(this.state.contentCreator.name)}`} >
                    <XIcon size={40} round={true} bgStyle={{fill: "var(--main-font-color)"}} iconFillColor= {"var(--main-color)"}/>
                  </TwitterShareButton>
                  <FacebookShareButton url={`${window.location.protocol}//${window.location.hostname}${channelPathGenerator('')}?contenido=${this.state.contentCreator.id}-${slugify(this.state.contentCreator.name)}`} >
                    <FacebookIcon size={40} round={true} bgStyle={{fill: "var(--main-font-color)"}} iconFillColor={"var(--main-color)"}/>
                  </FacebookShareButton>
                  </>
                  :getActiveChannel() &&
                    <Tooltip placement="bottom" title={localization.get("reel.share")}>
                      <SocialButton style={{width: 33, height: 33, margin: 3, top: 0}}>
                        <Share className={`${style['star']}`} style={{margin: 0, top: 0}}
                              onClick={this.shareContent}/>
                      </SocialButton>
                    </Tooltip>
                  }
              </div>
            }
            {this.state.hasGoogleAdsense && this.renderAdsenseAd()}
            {contentCreator.hasSuggested && ((this.state.playlist && (this.state.playlist.contentCreators.length > 0)) || (this.state.suggestedCreators && (this.state.suggestedCreators.length > 0))) &&
              <RelatedContent
                {...this.props}
                contentCreator={this.state.contentCreator}
                related={this.state.playlist ? this.state.playlist.contentCreators : this.state.suggestedCreators}
              />
            }
          </NoteContainer>
        </div>
        {contentCreator && this.renderShareDialog()}
        {contentCreator && this.renderProductDialog()}
        {showUpButton &&
          <Button
            style={{'--main-font-color': titleColor, "--main-color": backgroundColor}}
            className={'editorial-scroll-button'}
            onClick={() => this.myRef.scrollIntoView()}
            size="lg" justIcon round
          >
            <ArrowUpward/>
          </Button>
        }
      </React.Fragment>
    );
  };
}


const SocialButtons = styled.div`
  display: flex;
  margin-right: 50px;
  place-content: flex-end;
  @media (max-width: 600px) {
    display: none;
  }
`;

const ArrowBar = styled.div`
  display: grid;
  grid-template-columns: 500px auto 500px;
  margin-top: -55px;
  filter: invert(1);
  align-items: center;
  @media (max-width: 1000px) {
    grid-template-columns: 375px auto 375px;
  }
  min-height: 50px;
`;

const BottomShadow = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0), rgb(0, 0, 0)) !important;
  height: 100px;
  position: absolute;
  bottom: -18px;
  right: 0;
  overflow: hidden;
  width: 100%;
`
const TopShadow = styled.div`
  background: linear-gradient(rgb(43 43 43 / 0.8), rgb(76 76 76 / 0)) !important;
  height: 100px;
  position: absolute;
  top: -18px;
  right: 0;
  overflow: hidden;
  width: 100%;
  z-index: 1;
`
const IconContainer = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  --main-font-color: ${props => props.mainColor ? props.mainColor : "var(--main-font-color)"};
  @media (max-width: 650px) {
    margin-top: 50px;
    flex-direction: column;
  }
  @media (max-width: 600px) {
    margin-top: 0;
  }
`

const NoteContainer = styled.div`
  position: relative;
  color: ${props => props.fontColor ? props.fontColor : "var(--main-font-color, inherit)"};
  padding: 70px 20% 35px;
  @media (max-width: 650px) {
    padding: 20px 10% 35px;
    font-size: ${props => props.mobileDefaultFontSize ? `${props.mobileDefaultFontSize}px` : "14px"};
  }
`;


const SocialButton = styled.div`
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  padding: 2px;
  border-color: black;
  cursor: pointer;
  margin: 2px;
`;

const ContentCreatorName = styled.h1`
  @media (max-width: 600px) {
    font-size: 2em;
  }
` 